import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { NzTableFilterFn, NzTableFilterList } from 'ng-zorro-antd/table'
import { User } from '../../../models/user.model'

/**
 * Tabla de usuario.
 *
 * @interface DataUserTable
 */
interface DataUserTable {
  /**
   * Nombre del usuario.
   *
   * @type {string}
   * @memberof DataUserTable
   */
  name: string
  /**
   * Correo del usuario.
   *
   * @type {string}
   * @memberof DataUserTable
   */
  email: string
  /**
   * Verifica si el correo fue verificado.
   *
   * @type {boolean}
   * @memberof DataUserTable
   */
  email_verified_at: boolean
  /**
   * Control de acceso del usuario.
   *
   * @type {boolean}
   * @memberof DataUserTable
   */
  access: boolean
  /**
   * Rol del usuario.
   *
   * @type {string}
   * @memberof DataUserTable
   */
  rol: string
}

/**
 * Tabla para elementos.
 *
 * @interface ColumnItem
 */
interface ColumnItem {
  /**
   * Etiqueta del elemento.
   *
   * @type {string}
   * @memberof ColumnItem
   */
  name: string
  /**
   * lista de elementos de tabla para filtrar.
   *
   * @type {NzTableFilterList}
   * @memberof ColumnItem
   */
  listOfFilter: NzTableFilterList
  /**
   * Funcion para realizar un filtro.
   *
   * @type {(NzTableFilterFn | null)}
   * @memberof ColumnItem
   */
  filterFn: NzTableFilterFn | null
}

/**
 * Componente widget de tablar para visualizar usuarios.
 *
 * @export
 * @class TableUsersComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'vb-table-users',
  templateUrl: './table-users.component.html',
  styleUrls: ['./table-users.component.scss'],
  styles: [
    `
      .table-operations > button {
        margin-right: 8px;
      }
    `,
  ],
})
export class TableUsersComponent implements OnInit {
  /**
   * Usuario seleccionado.
   *
   * @type {User}
   * @memberof TableUsersComponent
   */
  @Input() currentUser: User
  /**
   * Lista de usuarios registrados en la plataforma.
   *
   * @type {User[]}
   * @memberof TableUsersComponent
   */
  @Input() listOfData: User[] = []
  /**
   * Variable de control para el manejo de estado de la carga de informacion.
   *
   * @type {boolean}
   * @memberof TableUsersComponent
   */
  @Input() loading: boolean = false
  /**
   * Identificador del usuario actual loggeado.
   *
   * @type {number}
   * @memberof TableUsersComponent
   */
  @Input() userIdLogged: number
  /**
   * Event Emitter para enviar peticion de edicion de elemento.
   *
   * @type {EventEmitter<User>}
   * @memberof TableUsersComponent
   */
  @Output() editedItem: EventEmitter<User> = new EventEmitter<User>()
  /**
   * Event emitter para cambiar estado de acceso del usuario.
   *
   * @type {EventEmitter<User>}
   * @memberof TableUsersComponent
   */
  @Output() editedAccess: EventEmitter<User> = new EventEmitter<User>()
  /**
   * Event emitter para enviar peticion de eliminacion de usuario.
   *
   * @type {EventEmitter<User>}
   * @memberof TableUsersComponent
   */
  @Output() deletedItem: EventEmitter<User> = new EventEmitter<User>()

  /**
   * Columnas simples para la tabla.
   *
   * @type {*}
   * @memberof TableUsersComponent
   */
  columnSimple: any = [
    {
      name: 'Acceso',
    },
    {
      name: 'Acciones',
    },
  ]

  /**
   * Columnas con ordenamiento de cadenas para la tabla.
   *
   * @type {*}
   * @memberof TableUsersComponent
   */
  listStringSort: any = [
    {
      name: 'Nombre',
      sortFn: (a: DataUserTable, b: DataUserTable) => a.name.localeCompare(b.name),
    },
    {
      name: 'Correo',
      sortFn: (a: DataUserTable, b: DataUserTable) => a.name.localeCompare(b.name),
    },
  ]

  /**
   * Columnas con ordenamiento meidante el tipo de correo confirmado.
   *
   * @type {ColumnItem[]}
   * @memberof TableUsersComponent
   */
  listOfColumns: ColumnItem[] = [
    {
      name: 'Correo confirmado',
      listOfFilter: [
        { text: 'Confirmado', value: 'true' },
        { text: 'Esperando confirmación', value: 'false' },
      ],
      filterFn: (list: string[], item: DataUserTable) => list.some(name => item.email_verified_at),
    },
    {
      name: 'Rol',
      listOfFilter: [
        { text: 'Administrador', value: 'Administrador' },
        { text: 'Supervisor', value: 'Supervisor' },
      ],
      filterFn: (address: string, item: DataUserTable) => item.rol.indexOf(address) !== -1,
    },
  ]

  /**
   * Funcion para hacer un track por nombre del elemento.
   *
   * @param {number} _
   * @param {ColumnItem} item
   * @return {*}  {string}
   * @memberof TableUsersComponent
   */
  trackByName(_: number, item: ColumnItem): string {
    return item.name
  }

  /**
   * Funcion para restablecer filtros.
   *
   * @memberof TableUsersComponent
   */
  resetFilters(): void {
    this.listOfColumns.forEach(item => {
      if (item.name === 'Name') {
        item.listOfFilter = [
          { text: 'Joe', value: 'Joe' },
          { text: 'Jim', value: 'Jim' },
        ]
      } else if (item.name === 'Address') {
        item.listOfFilter = [
          { text: 'London', value: 'London' },
          { text: 'Sidney', value: 'Sidney' },
        ]
      }
    })
  }

  /**
   * onInit.
   *
   * @memberof TableUsersComponent
   */
  ngOnInit() {}

  /**
   * Callback para editar elemento seleccionado.
   *
   * @param {User} data
   * @memberof TableUsersComponent
   */
  public editItem(data: User) {
    this.editedItem.emit(data)
  }

  /**
   * Callback para eliminar elemento seleccionado.
   *
   * @param {User} data
   * @memberof TableUsersComponent
   */
  public deleteItem(data: User) {
    this.deletedItem.emit(data)
  }

  /**
   * Callback para cambio de switch para el acceso del usuario.
   *
   * @param {boolean} $event
   * @param {User} data
   * @memberof TableUsersComponent
   */
  public changeSwitch($event: boolean, data: User) {
    this.currentUser = data
    this.currentUser.access = $event
    this.editedAccess.emit(this.currentUser)
  }
}
