import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import * as Reducers from '../../store/reducers'
import { NotificationPlant } from '../../models/models.index'

import { NotificationsState } from './notifications.reducer'
import * as NotificationActions from './notifications.actions'

@Injectable({
    providedIn: 'root'
})
export class NotificationsFacade {
    notificationsSelector$ = this._store.select(Reducers.getNotifications)

    constructor(private _store: Store<NotificationsState>) { }

    addNotificationPlant(notificationPlant: NotificationPlant): void {
        this._store.dispatch(NotificationActions.addNotificationPlant({ notification: notificationPlant }))
    }

    getNotificationsUser(userId: number, params: any): void {
        this._store.dispatch(NotificationActions.getNotificationsUser({ userId, params }))
    }

    updateNotificationPlant(notificationPlant: NotificationPlant, params: any): void {
        this._store.dispatch(NotificationActions.updateNotificationPlant({ notification: notificationPlant, params }))
    }
}
