import { URL_API_V2 } from './../../config/enviroment'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { CommonService } from '../shared/common.service'
import { Observable } from 'rxjs'
import { Legal } from '../../models/models.index'
import { CrudRepository } from '../shared/crud.repository'
import { ParamRequest } from 'src/app/interfaces/interfaces.index'

/**
 * Servicio web para obtener informacion legal.
 *
 * @export
 * @class LegalRepository
 * @extends {CrudRepository}
 */
 @Injectable({
  providedIn: 'root',
})
export class LegalRepository extends CrudRepository {
  /**
   * Creates an instance of LegalService.
   * @param {HttpClient} _httpClient
   * @param {CommonService} _commonService
   * @memberof LegalService
   */
  constructor(protected _httpClient: HttpClient, protected _commonService: CommonService) {
    super(_httpClient, _commonService, 'legal-param')
  }

  /**
   * Funcion para servicio para obtener la informacion legal de una empresa.
   *
   * @param {number} plantId Identificador de la planta.
   * @return {Observable<Legal[]>} Observable con la respuesta de nuestro peticion.
   * @memberof LegaService
   */
  public getLegalInfoByPlant(plantId: number): Observable<Legal[]> {
    const url = `${URL_API_V2}/${this._endpointPrefix}`
    const paramsRequest: ParamRequest[] = [{ param: 'id_plant', value: plantId }]
    return this._commonService.handlerGetElements(url, paramsRequest, 'Data')
  }
}
