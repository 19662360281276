import { Injectable } from '@angular/core'
import { Observable, of, throwError } from 'rxjs'
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators'
import { NzNotificationService } from 'ng-zorro-antd/notification'

import { Actions, ofType, createEffect } from '@ngrx/effects'
import * as ReportActions from './report.actions'

import { AVAILABLE_REPORT } from '../../config/enviroment'
import { Report } from '../../models/report.model'

import { ReportOfficialService } from '../../services/reports/official/report-official.service'
import { ReportTransactionalService } from '../../services/reports/transactional/report-transactional.service'
import { ReportBillingService } from './../../services/reports/billing/report-billing.service'

@Injectable()
export class ReportEffects {
  constructor(private _actions$: Actions,
    private _reportTransactionalService: ReportTransactionalService,
    private _reportOfficialService: ReportOfficialService,
    private _reportBillingService: ReportBillingService,
    private _nzNotificationService: NzNotificationService,
  ) {
  }

  addReportToPlant$ = createEffect(() => this._actions$.pipe(
    ofType(ReportActions.addReportPlant),
    exhaustMap(action => {
      const report: Report = {
        id_corporate: action.corporateId,
        id_plant: action.plantId,
        id_user: action.userId,
        init_date: action.params.startDate,
        end_date: action.params.endDate,
      }
      return this._reportTransactionalService.generateReport(report).pipe(
        map(report => ReportActions.createReportSuccess({ report })),
        catchError((error: any) => of(ReportActions.createReportFailed(error))))
    },
    ),
  ),
  )

  updateReport$ = createEffect(() => this._actions$.pipe(
    ofType(ReportActions.updateReport),
    exhaustMap(action => {
      const reportId: number = action.reportId
      return this._reportTransactionalService.getReport(reportId).pipe(
        map(report => ReportActions.updateReportSuccess({ report })),
        catchError((error: any) => of(ReportActions.updateReportFailed(error))))
    }),
  ))

  deleteReport$ = createEffect(() => this._actions$.pipe(
    ofType(ReportActions.deleteReport),
    exhaustMap(action => {
      const reportId: number = action.report.id
      return this._reportTransactionalService.deleteReport(reportId).pipe(
        map(report => ReportActions.deleteReportSuccess({ reportId })),
        catchError((error: any) => of(ReportActions.deleteReportFailure(error))))
    }),
  ))

  getReportByPlant$ = createEffect(() => this._actions$.pipe(
    ofType(ReportActions.getReportsPlant),
    exhaustMap(action => {
      const year: number = action.params?.year
      let reportService: Observable<Report[]>
      switch (action.typeReport) {
        case AVAILABLE_REPORT.transactional:
        case AVAILABLE_REPORT.week:
        case AVAILABLE_REPORT.unofficial:
          reportService = this._reportTransactionalService.getReportsByPlant(action.plantId)
          break
        case AVAILABLE_REPORT.billing:
          reportService = this._reportBillingService.getReportsByYear(action.plantId, 1, year)
          break
        case AVAILABLE_REPORT.official:
        default:
          reportService = this._reportOfficialService.getReportsByYear(action.plantId, action.cdrVersion, year, ['rel_report_type']).pipe(
            map(reports => {
              return reports.map(report => {
                const reportType = Array.from(report['rel_report_type']).find(r => r['id_report_type'] === Number(action.cdrVersion))
                report.compliance = reportType?.['compliance']
                return report
              })
            })
          )
          break
      }
      return reportService.pipe(
        map(reports => {
          return ReportActions.loadReportsSuccessful({ reports })
        }),
        catchError((error: any) => of(ReportActions.loadReportsUnsuccessful(error))))
    },
    ),
  ),
  )

  downloadReportByUrl$ = createEffect(() => this._actions$
    .pipe(
      ofType(ReportActions.downloadReport),
      exhaustMap(action => {
        let reportService: Observable<Report[]>

        switch (action.typeReport) {
          case AVAILABLE_REPORT.official:
            reportService = this._reportOfficialService.getUrlReportFile(action.reportId)
            break
          case AVAILABLE_REPORT.billing:
            reportService = this._reportBillingService.getUrlReportFile(action.reportId, 1)
            break
          default:
            reportService = this._reportTransactionalService.getUrlReportFile(action.reportId, action.cdrVersion)
            break
        }
        return reportService.pipe(
          switchMap((response: any) => {
            if (response.url) {
              return of(response.url).pipe(
                map((url: string) => {
                  this._reportOfficialService.downloadReportByUrl(url)
                  return ReportActions.downloadTerminated(null)
                }))
            } else {
              return throwError(response)
            }
          }),
          catchError(error => {
            this._nzNotificationService.error('Lo sentimos...', 'El archivo PDF no existe...')
            return of(ReportActions.downloadTerminated(error))
          }),
        )
      }),
    )
  )
}
