import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { NotificationPlant } from '../../../models/notification-plant.model'

@Component({
  selector: 'widget-alert-menu',
  templateUrl: './alert-menu.component.html',
  styleUrls: ['./alert-menu.component.scss'],
})
export class AlertMenuComponent implements OnInit {
  @Input() alerts: NotificationPlant[] = []

  @Output() eventClick: EventEmitter<NotificationPlant> = new EventEmitter<NotificationPlant>()

  constructor() {
  }

  ngOnInit() {
  }

  onClickEvent(event: NotificationPlant): void {
    if (event) {
      this.eventClick.emit(event)
    }
  }
}
