<nz-table #filterTable [nzData]='listOfData' [nzPageSize]='10' nzTableLayout='fixed'>
  <thead>
  <tr>
    <th *ngFor='let column of listStringSort;' [(nzSortOrder)]='column.sortOrder'
        [nzSortFn]='column.sortFn'>
      {{ column.name }}
    </th>
    <!--    <th [nzFilterFn]='listOfColumns[0].filterFn' [nzFilters]='listOfColumns[0].listOfFilter'>-->
    <!--      {{ listOfColumns[0].name }}-->
    <!--    </th>-->
    <th>
      {{ listOfColumns[0].name }}
    </th>
    <th>
      {{ columnSimple[0].name }}
    </th>
    <!--    <th [nzFilterFn]='listOfColumns[1].filterFn' [nzFilters]='listOfColumns[1].listOfFilter'>-->
    <!--      {{ listOfColumns[1].name }}-->
    <!--    </th>-->
    <th>
      {{ listOfColumns[1].name }}
    </th>
    <th>
      {{ columnSimple[1].name }}
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor='let data of filterTable.data'>
    <td>{{ data.name }}</td>
    <td>{{ data.email ? data.email : 'N/A' }}</td>
    <td>
      <ng-container *ngIf='data.email_verified_at; else emailWaiting'>
        <nz-badge nzStatus='success' nzText='Confirmado'></nz-badge>
      </ng-container>
    </td>
    <td>
      <nz-switch (ngModelChange)='changeSwitch($event, data)' [(ngModel)]='data.access'
                 [disabled]='data?.id === userIdLogged'
                 [nzLoading]='loading && data?.id === currentUser?.id'></nz-switch>
    </td>
    <td>
      <nz-tag nzColor='default'>
        <span>{{ data?.roles && data?.roles.length > 0 ? data.roles[0].name : 'Invitado' }}</span>
      </nz-tag>
    </td>
    <td>
      <button [disabled]='data?.id === userIdLogged' class='mr-3' nz-button nzType='primary' nz-tooltip nzShape='circle'
              nzTooltipPlacement='topCenter'
              nzTooltipTitle='Editar'>
        <i (click)='editItem(data)' nz-icon nzType='edit'></i></button>
      <button [disabled]='data?.id === userIdLogged' nz-button class='ant-btn-danger' nz-tooltip nzShape='circle'
              nzTooltipPlacement='topCenter'
              nzTooltipTitle='Eliminar'><i
        (click)='deleteItem(data)' nz-icon nzType='delete'></i></button>
    </td>
  </tr>
  </tbody>
</nz-table>

<ng-template #emailWaiting>
  <nz-badge nzStatus='warning' nzText='Esperando confirmacion'>
  </nz-badge>
</ng-template>
