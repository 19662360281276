import { Pipe, PipeTransform } from '@angular/core'
import { ROLES_SLUGS } from '../config/enviroment'
import { User } from '../models/models.index'

/**
 * Pipe para establecer los valores con base a los parametros de los objetos
 * que vienen en ellos.
 *
 * @export
 * @class SetValueByParameterPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'hideMenu',
})
export class HideMenuPipe implements PipeTransform {
  transform(user: User, externalValid?: boolean): boolean {
    if (
      user.roles[0].slug === ROLES_SLUGS.admin_diram ||
      (user.roles[0].slug === ROLES_SLUGS.admin && externalValid)
    ) {
      return true
    } else {
      return false
    }
  }
}
