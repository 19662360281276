import { createAction, props } from '@ngrx/store'

export const GET_EVENTS_BY_PLANT = '[Event] Obtencion de eventos por planta'

export const LOAD_EVENTS_SUCCESS = '[Event] Carga de eventos completada'
export const LOAD_EVENTS_FAILURE = '[Event] Carga de eventos no completada'

export const getEventsByPlant = createAction(GET_EVENTS_BY_PLANT, props<{plantId: number, params: any}>())
export const loadEventsSucces = createAction(LOAD_EVENTS_SUCCESS, props<any>())
export const loadEventsFailure = createAction(LOAD_EVENTS_FAILURE, props<{any:any}>())
