import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import * as _ from 'lodash'
import { getMenuData } from './config'
import { TYPE_MENU_LEFT } from '../../config/enviroment'
import { MenuItem } from '../../interfaces/menu-item'

/**
 * Servicio para manejo de informacion del menu.
 *
 * @export
 * @class MenuService
 */
@Injectable({
  providedIn: 'root',
})
export class MenuService {

  private menuData$: Subject<any> = new Subject()

  /**
   * Creates an instance of MenuService.
   * @memberof MenuService
   */
  constructor() {
  }

  /**
   * Servicio para obtener la informacion de un menu.
   *
   * @return {*}  {Observable<any[]>}
   * @memberof MenuService
   */
  getMenuData(): Observable<any[]> {
    return this.menuData$
  }

  // TODO MEJORAR METODO
  setMenuData(data: MenuItem[] = [], typeMenu: number, url: string) {
    const menu: MenuItem[] = []

    getMenuData.map(i => {
      i.hidden = true
    })

    switch (typeMenu) {
      case TYPE_MENU_LEFT.SIMPLE:
        menu.push.apply(menu, [getMenuData[0]])
        menu[0].hidden = false
        menu[0].children = data
        data.map(d => {
          d.children = null,
          d.url = null
        }
        )
        break
      case TYPE_MENU_LEFT.SIMPLE_USERS:
        menu.push.apply(menu, [getMenuData[3]])
        menu[0].hidden = false
        data.map(d => {
          d.children = null
          d.url = menu[0].children[1].url
        })
        menu[0].children[1].children = data
        break
      case TYPE_MENU_LEFT.COMPLEX:
        data.map(d => {
          d.url = null
        })
        data.filter(d => d.children).map(d => {
          d.children.map(c => {
            c.url = url
          })
        })
        menu.push.apply(menu, [getMenuData[0]])
        menu[0].hidden = false
        menu[0].children = data
        break
      case TYPE_MENU_LEFT.COMPOUND:
        data.map(d => {
          d.url = null
        })
        data.filter(d => d.children).map(d => {
          d.children.map(c => {
            c.url = url
          })
        })
        menu.push.apply(menu, [getMenuData[0]])
        menu[0].hidden = false
        menu[0].children = data
        break
      case TYPE_MENU_LEFT.NONE:
      default:
        break
    }

    this.menuData$.next(menu)
  }

  /**
   * Funcion para seleccionar los elementos del menu activo.
   *
   * @param {*} url
   * @param {*} [menuData=this.menuData]
   * @memberof MenuService
   */
  public activateMenu(url: any, menuData: any = getMenuData): any[] {
    menuData = JSON.parse(JSON.stringify(menuData))
    const pathWithSelection = this.getPath({ url: url }, menuData, (entry: any) => entry, 'url')
    if (pathWithSelection) {
      pathWithSelection.pop().selected = true
      _.each(pathWithSelection, (parent: any) => (parent.open = true))
    }
    return menuData.slice()
  }

  /**
   * Funcion para obtener la ruta del menu seleccionado.
   *
   * @param {*} element
   * @param {*} source
   * @param {*} property
   * @param {string} [keyProperty='key']
   * @param {string} [childrenProperty='children']
   * @param {*} [path=[]]
   * @return {*}  {*}
   * @memberof MenuService
   */
  public getPath(
    element: any,
    source: any,
    property: any,
    keyProperty: string = 'key',
    childrenProperty: string = 'children',
    path: any = [],
  ): any {
    let found = false
    const getElementChildren = (value: any) => _.get(value, childrenProperty)
    const getElementKey = (value: any) => _.get(value, keyProperty)
    const key = getElementKey(element)
    return (
      _.some(source, (e: any) => {
        if (getElementKey(e) === key || String(key).includes(String(getElementKey(e)))) {
          path.push(e)
          return true
        } else {
          return (found = this.getPath(
            element,
            getElementChildren(e),
            property,
            keyProperty,
            childrenProperty,
            path.concat(e),
          ))
        }
      }) &&
      (found || _.map(path, property))
    )
  }
}
