<div>
  <a nz-dropdown nzTrigger='click' [nzDropdownMenu]='menu'>
    <i nz-icon nzType="setting" nzTheme="outline"></i>
  </a>
  <nz-dropdown-menu #menu='nzDropdownMenu'>
    <ul nz-menu>
      <li nz-menu-item>
        <a href='javascript: void(0);' routerLink='/profile/business'>{{ 'topBar.settingMenu.profile-plant' | translate }}</a>
      </li>
      <li nz-menu-divider></li>
      <li nz-menu-item>
        <a href='javascript: void(0);' routerLink='/profile/electric-plants'>{{ 'topBar.settingMenu.electrical-plant' | translate }}</a>
      </li>
      <ng-container *ngIf='user ? (user | hideMenu : true) : false'>
        <li nz-menu-divider></li>
        <li nz-menu-item>
          <a href='javascript: void(0);' routerLink='/notification/notification-settings'>{{ 'topBar.settingMenu.notifications' | translate }}</a>
        </li>
      </ng-container>
      <ng-container *ngIf='user ? (user | hideMenu : true) : false'>
        <li nz-menu-divider></li>
        <li nz-menu-item>
          <a href='javascript: void(0);' routerLink='/administration/users-diram'>{{ 'topBar.settingMenu.users' | translate }}</a>
        </li>
      </ng-container>
    </ul>
  </nz-dropdown-menu>
</div>

