<nz-spin [nzSpinning]='isLoadingData' [nzTip]="'Cargando información...'">
  <form [formGroup]='validateForm' autocomplete='off' nz-form>
    <nz-form-item>
      <nz-form-label [nzSpan]='5' nzFor='nickname' nzRequired>Nombre(s)</nz-form-label>
      <nz-form-control [nzSpan]='12' nzErrorTip='¡Introduce un nombre de usuario!'>
        <input formControlName='nickname' id='nickname' nz-input placeholder='Nombre(s) del usuario' />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSpan]='5' nzFor='lastname' nzRequired>Apellido(s)</nz-form-label>
      <nz-form-control [nzSpan]='12' nzErrorTip='¡Introduce un apellido de usuario!'>
        <input formControlName='lastname' id='lastname' nz-input placeholder='Apellido(s) del usuario' />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSpan]='5' nzFor='email' nzRequired>Correo</nz-form-label>
      <nz-form-control [nzSpan]='12' nzErrorTip='¡No es un correo valido!'>
        <input formControlName='email' id='email' nz-input placeholder='Correo del usuario' />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSpan]='5' nzFor='phoneNumber' nzRequired>Celular</nz-form-label>
      <nz-form-control [nzSpan]='12' [nzErrorTip]=phoneNumberError>
        <nz-input-group [nzAddOnBefore]='addOnBeforeTemplate'>
          <ng-template #addOnBeforeTemplate>
            <nz-select formControlName='phoneNumberPrefix' class='phone-select'>
              <nz-option nzLabel='+1' nzValue='+1'></nz-option>
              <nz-option nzLabel='+52' nzValue='+52'></nz-option>
            </nz-select>
          </ng-template>
          <input formControlName='phoneNumber' id="'phoneNumber'" nz-input placeholder='Celular del usuario' />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSpan]='5' nzRequired>Rol</nz-form-label>
      <nz-form-control [nzSpan]='12' nzErrorTip='¡Seleccione un rol!'>
        <nz-select (ngModelChange)='onChangeRol($event)' formControlName='rol' name='select-rol'
                   nzPlaceHolder='Seleccione un Rol'>
          <nz-option *ngFor='let rol of roles' [nzLabel]='rol.name' [nzValue]='rol.id'></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item *ngIf='selectedRol && selectedRol.slug !== SLUGS_ROLES.admin_diram else alertBadge'>
      <nz-form-label [nzSpan]='5' nzRequired>Accesos</nz-form-label>
      <nz-form-control nzErrorTip='¡Seleccione el nivel de acceso!'>
        <nz-tree-select (ngModelChange)='onChange($event)' (nzExpandChange)='onExpandChange($event)'
                        [nzAsyncData]='true' [nzMultiple]='true' [nzNodes]='nodes'
                        formControlName='levelAccess'
                        nzVirtualHeight='150px'
                        nzPlaceHolder='Seleccione el acceso' nzShowSearch>
        </nz-tree-select>
      </nz-form-control>
    </nz-form-item>
    <div class='row'>
      <div class='save-area col'>
        <button (click)='submitForm()' [nzLoading]='isSaving' [nzType]="'primary'" nz-button>
          <i nz-icon nzTheme='outline' nzType='check'></i>
          {{this.currentUser ? 'Editar' : 'Agregar'}}
        </button>
        <button (click)='handleCancel()' nz-button class='ant-btn-danger'>
          <i nz-icon nzTheme='outline' nzType='close'></i>
          Cancelar
        </button>
      </div>
    </div>
  </form>
</nz-spin>

<ng-template #phoneNumberError>
  <ng-container *ngIf='phoneNumber.touched && phoneNumber.invalid'>
    <div *ngIf='phoneNumber.errors?.required'>¡Introduce el celular del usuario!</div>
    <div *ngIf='phoneNumber.errors?.pattern'>¡Número celular no valido!</div>
  </ng-container>
</ng-template>

<ng-template #alertBadge>
  <nz-alert *ngIf='selectedRol && selectedRol?.slug === SLUGS_ROLES.admin_diram'
            [nzMessage]='currentUser ? "El usuario tiene permiso para toda acción en la aplicación" : "El usuario tendrá permiso para toda acción en la aplicación"'
            class='alert-badge' nzType='warning'></nz-alert>
</ng-template>
