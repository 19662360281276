import { URL_API_V2 } from './../../config/enviroment'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { CommonService } from '../shared/common.service'
import { Observable } from 'rxjs'
import { Notification } from '../../models/models.index'
import { CrudRepository } from '../shared/crud.repository'
import { ParamRequest } from 'src/app/interfaces/interfaces.index'

/**
 * Servicio para obtener las notificaciones.
 *
 * @export
 * @class NotificationRepository
 * @extends {CrudRepository}
 */
@Injectable({
  providedIn: 'root',
})
export class NotificationRepository extends CrudRepository {
  /**
   * Creates an instance of NotificationService.
   * @param {HttpClient} _httpClient
   * @param {CommonService} _commonService
   * @memberof NotificationRepository
   */
   constructor(protected _httpClient: HttpClient, protected _commonService: CommonService) {
    super(_httpClient, _commonService, 'person-notification')
  }

  /**
  * Obtiene lista de notificaciones que tiene una planta.
  *
  * @param {number} plantId
  * @return {Observable<Notification[]>}  {Observable<Notification[]>} Observable para obtener las notificaciones.
  * @memberof NotificationService
  */
  public getNotificationsByPlant(plantId: number): Observable<Notification[]> {
    const url = `${URL_API_V2}/${this._endpointPrefix}/plant/${plantId}`
    return this._commonService.handlerGetElements(url, null, 'Data')
  }

  /**
  * Obtiene lista de notificaciones por tipo de notificacion
  *
  * @param {number} notificationTypeId
  * @return {Observable<Notification[]>}  {Observable<Notification[]>} Observable para obtener las notificaciones.
  * @memberof NotificationService
  */
   public getNotificationsByType(notificationTypeId: number): Observable<Notification[]> {
    const url = `${URL_API_V2}/${this._endpointPrefix}`
    const paramsRequest: ParamRequest[] = [{ param: 'id_notification_type', value: notificationTypeId }]
    return this._commonService.handlerGetElements(url, paramsRequest, 'Data')
  }

  /**
  * Obtiene lista de notificaciones por person
  *
  * @param {number} personId
  * @return {Observable<Notification[]>}  {Observable<Notification[]>} Observable para obtener las notificaciones.
  * @memberof NotificationService
  */
   public getNotificationsByPerson(personId: number): Observable<Notification[]> {
    const url = `${URL_API_V2}/${this._endpointPrefix}`
    const paramsRequest: ParamRequest[] = [{ param: 'id_person', value: personId}]
    return this._commonService.handlerGetElements(url, paramsRequest, 'Data')
  }
}
