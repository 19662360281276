<section>
  <nz-card style='height: calc(100vh - 234px); position: relative; overflow: auto;'>
    <h3 class='title-section'>{{ 'usersAdmin.secctionTitle' | translate }}&nbsp;
      {{ (typeOfView === 0 ? 'usersAdmin.externals.title' : 'usersAdmin.internals.title') | translate }}
    </h3>

    <div *ngIf='!isLoading || typeOfView === 1 else loadingUsers' class='row'>
      <ng-container *ngIf='corporateSelected || typeOfView === 1'>
        <div class='col-12 mb-3'>
          <div class='d-flex flex-row justify-content-end'>
            <div *ngIf='isUsersReady || searchingByFilter' style='width: 22%'>
              <nz-input-group [nzAddOnAfter]='suffixIconButton' nzSearch>
                <input [(ngModel)]='textFilter' nz-input placeholder='{{textHTMLInputSearch}}' type='text' />
              </nz-input-group>
            </div>
            <button nz-button class='ml-2' nzType='primary' (click)='openCreatorUserModal(modalCreateUser, null)'>
              <i aria-hidden='true' class='fe fe-user-plus mr-1'></i>
              <span>Agregar usuario</span>
            </button>
          </div>
        </div>
        <div *ngIf='users && users?.length > 0 && isUsersReady else loadingUsers' class='col-12'>
          <vb-table-users (deletedItem)='modalDeleteUser($event)' (editedAccess)='changeAccessUser($event)'
                          (editedItem)='editUser($event, modalCreateUser)' [currentUser]='selectedUser'
                          [listOfData]='users'
                          [loading]='isSavingUser' [userIdLogged]='userIdLogged'>
          </vb-table-users>
        </div>
      </ng-container>
    </div>
  </nz-card>
</section>

<ng-template #modalCreateUser>
  <vb-create-user-modal (sendUser)='sendUserChange($event)' [corporate]='corporateSelected' [currentModal]='currentModal'
                        [currentUser]='selectedUser'
                        [isLoadingData]='isLoadDataModal' [isSaving]='isSavingUser' [roles]='roles' [type]='typeOfView'>
  </vb-create-user-modal>
</ng-template>

<ng-template #loadingUsers>
  <nz-spin *ngIf='isLoading' [nzSpinning]='isLoading' [nzTip]='this.textHTMLSpin' class='col-12'>
    <div class='card loader-show w-100'>
    </div>
  </nz-spin>
</ng-template>

<ng-template #suffixIconButton>
  <button (click)='searchUserByFilter()' nz-button nzSearch nzType='primary'><i nz-icon nzType='search'></i></button>
</ng-template>
