import { ParamRequest } from './../../interfaces/param-request';
import { Injectable } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects'
import { Action, select, Store } from '@ngrx/store'
import { environment } from '../../../environments/environment'
import { Observable, of, throwError, forkJoin, Subscription } from 'rxjs'
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators'
import { NzNotificationService } from 'ng-zorro-antd/notification'

import * as Reducers from 'src/app/store/reducers'
import * as UserActions from './actions'
import * as PlantActions from '../plant/plant.actions'
import * as FilesActions from '../files/files.actions'
import store from 'store'
import { AuthService } from '../../services/auth/auth.service'
import { Auth } from '../../models/auth'
import { CorporatesService } from 'src/app/services/corporates/corporates.service'
import { UsersService } from '../../services/users/users.service'
import { PushNotificationService } from '../../services/push-notification/push-notification.service'
import { ROLES_SLUGS } from '../../config/enviroment'

declare function loader_show()

declare function init_plugins()

/**
 * Effects para usuario.
 *
 * @export
 * @class UserEffects
 * @implements {OnInitEffects}
 */
@Injectable()
export class UserEffects implements OnInitEffects {

  private suscriptionPushNotification: Subscription = new Subscription()

  /**
   * Creates an instance of UserEffects.
   * @param {Actions} actions
   * @param {AuthService} _authService
   * @param _corporatesService
   * @param _usersService
   * @param {Router} router
   * @param {ActivatedRoute} route
   * @param {Store<any>} rxStore
   * @param {NzNotificationService} notification
   * @memberof UserEffects
   */
  constructor(
    private actions: Actions,
    private _authService: AuthService,
    private _corporatesService: CorporatesService,
    private _usersService: UsersService,
    private _pushNotificationService: PushNotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private rxStore: Store<any>,
    private notification: NzNotificationService,
  ) {
  }

  /**
   * Effect para login.
   *
   * @type {Observable<any>}
   * @memberof UserEffects
   */
  login: Observable<any> = createEffect(() => this.actions.pipe(
    ofType(UserActions.LOGIN),
    map((action: UserActions.Login) => action.payload),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => this._authService.login(payload.email, payload.password)
      .pipe(
        map((response: Auth) => {
          if (response && response.token) {
            store.set('roles', response.roles)
            store.set('accessToken', response.token)
            store.set('TokenType', response.type)
            return new UserActions.LoadCurrentAccount()
          }
          return new UserActions.LoginUnsuccessful({ message: 'Usuario sin acceso.', status: 403, response })
        }),
        catchError((error) => {
          return of(new UserActions.LoginUnsuccessful(error))
        }),
      ),
    ),
  ))

  /**
   * Effect para registro de usuario.
   *
   * @type {Observable<any>}
   * @memberof UserEffects
   */
  register: Observable<any> = createEffect(() => this.actions.pipe(
    ofType(UserActions.REGISTER),
    map((action: UserActions.Register) => action.payload),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([_, settings]) => {
      // jwt register
      if (settings.authProvider === 'jwt') {
      }

      return of(null)
    }),
  ))

  /**
   * Effect para carga de cuenta de usuario.
   *
   * @type {Observable<any>}
   * @memberof UserEffects
   */
  loadCurrentAccount: Observable<any> = createEffect(() => this.actions.pipe(
    ofType(UserActions.LOAD_CURRENT_ACCOUNT),
    map((_: UserActions.LoadCurrentAccount) => true),
    switchMap(_ => {
      const loggedUser = store.get('app.user_logged')
      if (loggedUser) {
        loader_show()
      }
      return this._authService.currentAccount().pipe(
        catchError(error => {
          return throwError(error)
        }))
    }),
    switchMap(auth => {
      const params: ParamRequest[] = [{param: 'active', value: true}]
      return forkJoin([
        of(auth),
        this._corporatesService.getAllElements(params).pipe(
          catchError(error => {
            return throwError(error)
          }),
        )])
    }),
    switchMap(([auth, corporates]) => {
      if (auth && auth.token) {
        return forkJoin([
          of(auth),
          of(corporates),
          this._usersService.getImage(auth.id),
        ])
      }
      return throwError({ message: 'Usuario no existente o Usuario no genero un token valido...', auth })
    }),
    switchMap(([auth, corporates, urlImage]) => {
      auth = { ...auth, corporates, url: urlImage, avatar: urlImage }
      if (this.route.snapshot.queryParams.returnUrl) {
        this.router.navigate([this.route.snapshot.queryParams.returnUrl]).then() // redirect to returnUrl
      } else if (this.router.url.includes('/auth')) {
        this.router.navigate(['/']).then() // redirect to root route on auth pages
      }

      if (auth.roles[0]?.slug !== ROLES_SLUGS.admin_diram) {
        this.suscriptionPushNotification = this._pushNotificationService.requestPermission().subscribe((token) => {
          if (token) {
            const userId: number = Number(auth.id)
            this._usersService.updateUserTokenNotificationPush(userId, token).toPromise().then().catch( (reason) => console.error(reason))
          }
        })
      } else {
        if (!environment.production) {
          console.log('TEMPORALMENTE NOTIFICACIONES DESACTIVADAS')
        }
      }
      store.set('app.user_logged', true)
      init_plugins()
      this.notification.success('¡Sesión iniciada!', '¡Inicio de sesión exitosa!')
      return [new UserActions.LoadCurrentAccountSuccessful(auth)]
    }),
    catchError((error, caught) => {
      if (!environment.production) {
        console.error('ACCOUNT LOAD ERROR: ', error?.message ? error.message : error)
      }
      init_plugins()
      this.rxStore.dispatch(new UserActions.LoadCurrentAccountUnsuccessful())
      return caught
    }),
  ))

  /**
   * Effect para salida de sesion de usuario.
   *
   * @type {Observable<any>}
   * @memberof UserEffects
   */
  logout: Observable<any> = createEffect(() => this.actions.pipe(
    ofType(UserActions.LOGOUT),
    map((_: UserActions.Logout) => true),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([, _]) => {
      return this._authService.logout().pipe(
        map(() => {
          return new UserActions.FlushUser()
        }),
        catchError(() => {
          return [new UserActions.FlushUser()]
        }),
      )
    }),
  ))

  flushUser: Observable<any> = createEffect(() => this.actions.pipe(
    ofType(UserActions.FLUSH_USER),
    map((_: UserActions.FlushUser) => {
      this.router.navigate(['/auth/login']).then(() => {
        this.suscriptionPushNotification.unsubscribe()
        this._pushNotificationService.deleteToken()
        this.rxStore.dispatch(PlantActions.flushPlant())
        this.rxStore.dispatch(FilesActions.flushFile())
      })
      store.clearAll()
      return new UserActions.EmptyAction()
    }),
  ))

  /**
   * Iniciador de efectos.
   *
   * @return {*}  {Action}
   * @memberof UserEffects
   */
  ngrxOnInitEffects(): Action {
    const accessToken = store.get('accessToken')
    return { type: accessToken ? UserActions.LOAD_CURRENT_ACCOUNT : UserActions.EMPTY_ACTION }
  }
}
