import { FooterComponent } from './components/Footer/footer.component'
import { NgModule } from '@angular/core'
import { CommonModule, DecimalPipe } from '@angular/common'
import { RouterModule } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'

// Plotly Module
import * as PlotlyJS from 'plotly.js/dist/plotly.js'
import { PlotlyModule } from 'angular-plotly.js'
// Date extensions
import '../app/extensions/date.extensions'
// antd components module
import { AntdModule } from 'src/app/antd.module'
import { TableUsersComponent } from './pages/widgets/tables-users/table-users.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CreateUserModalComponent } from './pages/users-admin/modals/create-user-modal/create-user-modal.component'
import { TopbarComponent } from './components/Topbar/topbar.component'
import { TopbarUserMenuComponent } from './components/Topbar/UserMenu/user-menu.component'
import { TopbarLanguageSwitcherComponent } from './components/Topbar/LanguageSwitcher/language-switcher.component'
import { ListInterpretationComponent } from './pages/widgets/list-interpretation/list-interpretation.component'
import { SetInterpretationTextPipe } from './pipes/set-interpretation-text.pipe'
import { SetComplianceTextPipe } from './pipes/set-compliance-text.pipe'
import { GenerateReportComponent } from './pages/widgets/generate-report/generate-report.component'
import { TableReportsComponent } from './pages/widgets/table-reports/table-reports.component'
import { MenuLeftComponent } from './components/MenuLeft/menu-left.component'
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar'
import { ErrorsLabelsConfirmPasswordPipe } from './pipes/errors-labels-confirm-password.pipe'
import { CustomScrollTargetComponent } from './pages/widgets/custom-scroll-target/custom-scroll-target.component'
import { NotificationOptionsCardComponent } from './pages/notification/notification-options-card/notification-options-card.component'
import { SetFullNamePipe } from './pipes/set-full-name.pipe'
import { QuestionFormStaticComponent } from './pages/widgets/question-form-static/question-form-static.component'
import { ProfileImagePipe } from './pipes/profile-image.pipe'
import { TextVerifyPipe } from './pipes/text-verify.pipe'
import { SetValueByParameterPipe } from './pipes/set-value-by-parameter.pipe'
import { HideMenuPipe } from './pipes/hide-menu.pipe'
import { SetNamePlantPipe } from './pipes/set-name-plant.pipe'
import { WelcomeTopComponent } from './pages/widgets/welcome-top/welcome-top.component'
import { TopbarSettingsMenuComponent } from './pages/widgets/topbar-settings-menu/topbar-settings-menu.component'
import { AlertMenuComponent } from './pages/widgets/alert-menu/alert-menu.component'
import { ReportComponent } from './pages/manager/report/report.component'
import { PeriodTextPipe } from './pipes/period-text.pipe'
import { LoaderCirclesComponent } from './pages/widgets/loader-circles/loader-circles.component'
import { MenuPanelControlComponent } from './pages/widgets/menu-panel-control/menu-panel-control.component'
import { SetDateViewPipe } from './pipes/set-date-view.pipe'
import { WrapMessageInfoComponent } from './pages/widgets/wrap-message-info/wrap-message-info.component'
import { GaugeChartComponent } from './pages/widgets/gauge-chart/gauge-chart.component'
import { BarButtonsComponent } from './pages/widgets/bar-buttons/bar-buttons.component'
import { TableBillingComponent } from './pages/widgets/table-billing/table-billing.component'
import { FormattNumberPipe } from './pipes/formatt-number.pipe'

PlotlyModule.plotlyjs = PlotlyJS

/** Modulos compartidos @type {*} */
const MODULES = [CommonModule, RouterModule, AntdModule, TranslateModule, PlotlyModule]

@NgModule({
  imports: [...MODULES, FormsModule, ReactiveFormsModule, PerfectScrollbarModule],
  providers: [DecimalPipe],
  declarations: [
    TopbarComponent,
    TopbarUserMenuComponent,
    TopbarLanguageSwitcherComponent,
    TableUsersComponent,
    CreateUserModalComponent,
    ListInterpretationComponent,
    SetInterpretationTextPipe,
    SetComplianceTextPipe,
    ErrorsLabelsConfirmPasswordPipe,
    GenerateReportComponent,
    TableReportsComponent,
    MenuLeftComponent,
    FooterComponent,
    CustomScrollTargetComponent,
    NotificationOptionsCardComponent,
    WelcomeTopComponent,
    TopbarSettingsMenuComponent,
    AlertMenuComponent,
    SetFullNamePipe,
    QuestionFormStaticComponent,
    ProfileImagePipe,
    TextVerifyPipe,
    HideMenuPipe,
    SetValueByParameterPipe,
    SetNamePlantPipe,
    ReportComponent,
    PeriodTextPipe,
    FormattNumberPipe,
    LoaderCirclesComponent,
    MenuPanelControlComponent,
    SetDateViewPipe,
    WrapMessageInfoComponent,
    GaugeChartComponent,
    BarButtonsComponent,
    TableBillingComponent
  ],
  exports: [
    ...MODULES,
    TopbarComponent,
    TopbarUserMenuComponent,
    TopbarLanguageSwitcherComponent,
    TableUsersComponent,
    CreateUserModalComponent,
    ListInterpretationComponent,
    SetInterpretationTextPipe,
    GenerateReportComponent,
    TableReportsComponent,
    MenuLeftComponent,
    ErrorsLabelsConfirmPasswordPipe,
    CustomScrollTargetComponent,
    NotificationOptionsCardComponent,
    WelcomeTopComponent,
    TopbarSettingsMenuComponent,
    AlertMenuComponent,
    SetFullNamePipe,
    QuestionFormStaticComponent,
    ProfileImagePipe,
    TextVerifyPipe,
    HideMenuPipe,
    SetValueByParameterPipe,
    SetNamePlantPipe,
    ReportComponent,
    PeriodTextPipe,
    SetComplianceTextPipe,
    FormattNumberPipe,
    LoaderCirclesComponent,
    WrapMessageInfoComponent,
    GaugeChartComponent,
    BarButtonsComponent,
    TableBillingComponent
  ],
})
export class SharedModule {}
