import { createReducer, on } from '@ngrx/store'
import * as states from './events.actions'

export interface EventsState {
  events?: any[]
  isLoading?: boolean
  isLoadingSuccess?: boolean
  isLoadingFailure?: boolean
}

export const initialState: EventsState = {
  events: [],
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
}

const _eventsReducer = createReducer(
  initialState,
  on(states.getEventsByPlant, (state) => {
    return {
      ...state,
      isLoading: true,
      isLoadingSuccess: false,
      isLoadingFailure: false,
    }
  }),
  on(states.loadEventsSucces, (state, result) => {
    return {
      ...state,
      isLoading: false,
      isLoadingSuccess: true,
      isLoadingFailure: false,
      events: result.response,
    }
  }),
  on(states.loadEventsFailure, (state) => {
    return {
      ...state,
      isLoading: false,
      isLoadingSuccess: false,
      isLoadingFailure: true,
      events: []
    }
  })
)

export function eventsReducer(state, action) {
  return _eventsReducer(state, action)
}

export const getEvetns = (state: EventsState) => state