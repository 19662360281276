import { Corporate, Project } from 'src/app/models/models.index'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { Plant } from '../../../models/plant.model'
import { finalize, mergeMap, takeUntil } from 'rxjs/operators'
import { PlantsService } from '../../../services/plants/plants.service'
import { Observable, Subject, throwError } from 'rxjs'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { Router } from '@angular/router'
import { CalculatedParameters, FormGram, Transformer, User } from '../../../models/models.index'
import { FormGramService } from '../../../services/form-gram/form-gram.service'
import { TransformerService } from '../../../services/transformer/transformer.service'
import { CalculatedParametersService } from '../../../services/calculated-parameters/calculated-parameters.service'
import { QuestionService } from '../../../services/question/question.service'
import { Question } from '../../../interfaces/question'
import { TIME_SHOW_MSG, TYPE_NOTIFICATION } from 'src/app/config/enviroment'
import { NzModalService } from 'ng-zorro-antd/modal'
import { ProjectsService } from 'src/app/services/projects/projects.service'

import { PlantFacade } from './../../../store/plant/plant.facade'
import { UserFacade } from './../../../store/user/facade'

/**
 * Componente para perfiles electricos de plantas.
 *
 * @export
 * @class ProfileElectricPlantsComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-profile-electric-plants',
  templateUrl: './profile-electric-plants.component.html',
  styleUrls: ['./profile-electric-plants.component.scss'],
})
export class ProfileElectricPlantsComponent implements OnInit, OnDestroy {
  /**
   * Usuario actual en la vista.
   *
   * @type {User}
   * @memberof ProfileBusinessComponent
   */
  public currentUser: User
  /**
   * Lista de preguntas.
   *
   * @type {Question[]}
   * @memberof ProfileBusinessComponent
   */
  public questions: Question[] = []
  /**
   * Lista de preguntas para la primera sección de informacion electrica.
   *
   * @type {Question[]}
   * @memberof ProfileElectricPlantsComponent
   */
  public questionSectionOne: Question[] = []
  /**
   * Lista de preguntas para la segunda sección de informacion electrica.
   *
   * @type {Question[]}
   * @memberof ProfileElectricPlantsComponent
   */
  public questionSectionTwo: Question[] = []
  /**
   * Lista de transformadores.
   *
   * @type {Transformer[]}
   * @memberof ProfileElectricPlantsComponent
   */
  public transformers: Transformer[] = []
  /**
   * Corporativo seleccionado.
   *
   * @type {Corporate}
   * @memberof ProfileElectricPlantsComponent
   */
  public corporateSelected: Corporate = null
  /**
   * Planta seleccionada.
   *
   * @type {Plant}
   * @memberof ProfileElectricPlantsComponent
   */
  public plantSelected: Plant = null
  /**
   * Variable para almacenar el proyecto activo.
   *
   * @type {Project}
   * @memberof ProfileElectricPlantsComponent
   */
  public proyectActive: Project = null
  /**
   * Informacion del gram para la planta.
   *
   * @type {FormGram}
   * @memberof ProfileElectricPlantsComponent
   */
  public formGram: FormGram = null
  /**
   * Valores de la planta calculado.
   *
   * @type {CalculatedParameters}
   * @memberof ProfileElectricPlantsComponent
   */
  public calculatedParameters: CalculatedParameters = null
  /**
   * Variable de control para conocer si la informacion de la planta se encuentra cargada.
   *
   * @type {boolean}
   * @memberof ProfileElectricPlantsComponent
   */
  public plantInfoReady: boolean = false

  public isLoadingPlants: boolean = true

  public isLoadingPlantData: boolean = true

  public rxStoreDestroy$: Subject<boolean> = new Subject<boolean>()

  /**
   * Creates an instance of ProfileElectricPlantsComponent.
   * @param _store
   * @param {QuestionService} _questionService
   * @param {PlantsService} _plantsService
   * @param _projectService
   * @param {FormGramService} _formGramService
   * @param {CalculatedParametersService} _calculatedParametersService
   * @param {TransformerService} _transformerService
   * @param {NzModalService} _modalService
   * @param {NzNotificationService} _nzNotificationService
   * @param {Router} _router
   * @memberof ProfileElectricPlantsComponent
   */
  constructor(
    private _questionService: QuestionService,
    private _plantsService: PlantsService,
    private _projectService: ProjectsService,
    private _formGramService: FormGramService,
    private _calculatedParametersService: CalculatedParametersService,
    private _transformerService: TransformerService,
    private _modalService: NzModalService,
    private _nzNotificationService: NzNotificationService,
    private _router: Router,
    private _userFacade: UserFacade,
    private _plantFacade: PlantFacade
  ) {
    this._getQuestionsForms().then()
    this._userFacade.userSelector$.pipe(takeUntil(this.rxStoreDestroy$)).subscribe((user) => {
      this.currentUser = user
    })

    this._plantFacade.plantSelector$.pipe(takeUntil(this.rxStoreDestroy$)).subscribe((state) => {
      this.isLoadingPlants = state.isLoading
      if (!state.isLoading && state.isLoadingSuccess) {
        this.isLoadingPlantData = true
        this.corporateSelected = state.corporate
        if (state.plant) {
          this.changePlant(state.plant.id)
        } else {
          if (this.corporateSelected) {
            const plant: Plant = state.plants[0]
            this._plantFacade.selectPlant(plant.id)
          }
        }
      }
    })
  }

  /**
   * OnInit
   *
   * @memberof ProfileElectricPlantsComponent
   */
  ngOnInit(): void {
  }

  /**
   * Funcion para obtener las preguntas del cuestionario del servicio.
   *
   * @private
   * @return {Promise<void>}
   */
  private async _getQuestionsForms(): Promise<void> {
    this.questions = await this._questionService.getQuestionsElectric().toPromise()
    this.questionSectionOne = [...this.questions].slice(0, 13)
    this.questionSectionTwo = [...this.questions].slice(13, 28)
  }

  /**
   * Callback para detectar un cambio de planta.
   *
   * @param {number} plantId Identificador de la planta seleccionada.
   * @memberof ProfileElectricPlantsComponent
   */
  public changePlant(plant_id: number): void {
    this.clearInfo()
    this.searchInfoToPlant(plant_id)
  }

  /**
   * Funcion para buscar la informacion de una planta.
   *
   * @param {number} plantId Identificador de la planta seleccionada.
   * @memberof ProfileElectricPlantsComponent
   */
  public searchInfoToPlant(plantId: number): void {
    this.plantInfoReady = false
    this._plantsService
      .getElementById(plantId)
      .pipe(
        mergeMap(plant => {
          this.plantSelected = plant
          return this.plantSelected
            ? this._projectService.getProjectActiveByPlant(this.plantSelected.id)
            : this.handleErrorNull('No exite planta')
        }),
        mergeMap(project => {
          this.proyectActive = project
          return this._formGramService.getFormGramByPlant(this.plantSelected.id)
        }),
        mergeMap((formGram: FormGram[]) => {
          this.formGram = formGram && formGram.length > 0 ? formGram[0] : null
          return this.formGram
            ? this._calculatedParametersService.getParametersByFormGram(this.formGram.id)
            : this.handleErrorNull(
              'No exite informacion electrica de la planta',
              'Añada información faltante',
            )
        }),
        mergeMap((calculatedParameters: CalculatedParameters[]) => {
          this.calculatedParameters =
            calculatedParameters && calculatedParameters.length > 0 ? calculatedParameters[0] : null
          return this._transformerService.getTransformersByPlant(this.plantSelected.id)
        }),
        finalize(() => {
          this.isLoadingPlantData = false
        }),
      )
      .subscribe(
        transforms => {
          if (transforms && transforms?.length > 0) {
            this.transformers = transforms
            let transformersInHigh = 0
            let transformersInLow = 0
            this.transformers.forEach(transformer => {
              if (transformer.high_voltage_plate < 69) {
                transformersInLow++
              } else {
                transformersInHigh++
              }
            })
            this.formGram['numbe_transformer_high'] = transformersInHigh
            this.formGram['number_transformer_medium'] = transformersInLow
          } else {
            this.transformers.length = 0
          }
          this.plantInfoReady = true
        },
        error => {
          this.plantInfoReady = false
          if (error?.code !== 404) {
            this.handleErrorNull(
              'Verifique su conexión, de lo contrario contacte a su administrador.',
            )
          }
        }
      )
  }

  /**
   * Callback para boton para ir a la vista para editar planta.
   * Si tenemos planta seleccionada y tiene informacion legal, sera precargan la informacion,
   * de lo contrario sera crear nueva informacion legal.
   *
   * @memberof ProfileBusinessComponent
   */
  public goToEditPlant(): void {
    if (this.plantSelected && this.formGram) {
      this._router
        .navigate(['profile/edit/edit-electric'], {
          queryParams: {
            corporate_id: this.corporateSelected.id,
            client_id: this.plantSelected.id_client,
            project_id: this.proyectActive.id,
            plant_id: this.plantSelected.id,
            gram_id: this.formGram.id,
          },
        })
        .then()
    } else if (!this.formGram) {
      this._router
        .navigate(['profile/edit/edit-electric'], {
          queryParams: {
            corporate_id: this.corporateSelected.id,
            client_id: this.plantSelected.id_client,
            project_id: this.proyectActive.id,
            plant_id: this.plantSelected.id,
          },
        })
        .then()
    }
  }

  /**
   * Funcion para limpiar la informacion del componente.
   * @memberof ProfileElectricPlantsComponent
   */
  public clearInfo(): void {
    if (this.plantSelected) {
      this.plantSelected = null
      this.proyectActive = null
      this.formGram = null
      this.transformers.length = 0
      this.plantInfoReady = false
    }
  }

  /**
   * Manejar errores cuando un elemento se encuentra vacio.
   * Disparamos un error en la subscripcion.
   *
   * @param title Titulo de del error
   * @param {string} msg Mensaje que del error que se dispara.
   * @return {Observable<never>} Devuelve un objeto error.
   * @memberof ProfileElectricPlantsComponent
   */
  public handleErrorNull(msg: string, title: string = null): Observable<never> {
    this._nzNotificationService.error(title ? title : 'Lo sentimos... Ha ocurrido un error', msg, {
      nzDuration: 2500,
    })
    return throwError({ code: 404, msg: msg })
  }

  /**
   * Callback del boton para eliminar personas autorizadas de planta.
   *
   * @param {Transformer} transformer
   * @memberof ProfileElectricPlantsComponent
   */
  deleteTransformer(transformer: Transformer) {
    if (transformer) {
      this._modalDeleteTransformer(transformer)
    }
  }

  /**
   * Funcion para contruir y mostrar un modal al usuario.
   *
   * @private
   * @param {Transformer} transformer Transformador que se desea eliminar.
   * @memberof ProfileElectricPlantsComponent
   */
  private _modalDeleteTransformer(transformer: Transformer): void {
    this._modalService.confirm({
      nzTitle: `¿Seguro que desea eliminar?`,
      nzContent: 'Esta acción no es reversible',
      nzOkText: 'Sí, deseo eliminar',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => this._sendDeleteTransformer(transformer),
      nzCancelText: 'No',
      nzOnCancel: () => this._modalService.closeAll(),
    })
  }

  /**
   * Funcion para enviar la peticion de eliminar una persona autorizada de una planta.
   *
   * @private
   * @param {Transformer} transformer
   * @memberof ProfileBusinessComponent
   */
  private _sendDeleteTransformer(transformer: Transformer): void {
    const transformerId: number = transformer.id
    this._transformerService
      .deleteElement(transformer.id)
      .pipe(
        finalize(() => {
          this._modalService.closeAll()
        }),
      )
      .subscribe(
        _ => {
          const findIndex = this.transformers.findIndex(
            transformer => transformer.id === transformerId,
          )
          if (findIndex >= 0) {
            this.transformers.splice(findIndex, 1)
            this.transformers = [...this.transformers]
          }
        },
        error => {
          this._showNotification(
            {
              type: TYPE_NOTIFICATION.ERROR,
              title: 'Lo sentimos...',
              body: 'Ha ocurrido un error intene nuevamente',
            },
            200,
          )
        },
        () => {
          this._showNotification(
            {
              type: TYPE_NOTIFICATION.SUCCESS,
              title: `¡Exito!`,
              body: `Se ha eliminado correctamente`,
            },
            200,
          )
        },
      )
  }

  /**
   * Funcion para mostrar notificaciones al usuario.
   *
   * @private
   * @param {{ type: string; title: string; body: string }} msg
   * @param {number} delay
   * @memberof ProfileBusinessComponent
   */
  private _showNotification(
    msg: { type: string; title: string; body: string },
    delay: number,
  ): void {
    setTimeout(() => {
      this._nzNotificationService.create(msg.type, msg.title, msg.body, {
        nzDuration: TIME_SHOW_MSG,
      })
    }, delay)
  }

  /**
   * onDestroy.
   *
   * @memberof LayoutMainComponent
   */
  ngOnDestroy(): void {
    this.rxStoreDestroy$.next(true)
    this.rxStoreDestroy$.unsubscribe()
  }
}
