import { PreloadingStrategy, Route } from '@angular/router'

import { Observable, of } from 'rxjs'
import { Injectable } from '@angular/core'

/**
 * Preloader para cambios de rutas.
 *
 * @export
 * @class AppPreloader
 * @implements {PreloadingStrategy}
 */
@Injectable()
export class AppPreloader implements PreloadingStrategy {
  /**
   * Preload.
   *
   * @param {Route} route
   * @param {Function} load
   * @return {*}  {Observable<any>}
   * @memberof AppPreloader
   */
  preload(route: Route, load: Function): Observable<any> {
    return route.data && route.data.preload ? load() : of(null)
  }
}
