import { Plant } from './models.index'
import { SerializableJSON } from '../interfaces/serializable-json'

/**
 * Modelo para el manejo de la informacion legal de una planta
 *
 * @export
 * @class Legal
 */
export class Legal implements SerializableJSON<Legal> {
   public id_plant: number
   public plant?: Plant
   public id?: number
   public voltage_level: string
   public rmu: string
   public person: string
   public business_name: string
   public demand: string
   public address: string
   public cp: string
   public municipality: string
   public state: string
   public scian_code: string
   public scian_description: string
   public rep_fname: string
   public rep_lname: string
   public rep_mlname: string
   public rep_phone: string
   public rep_mail: string
   public rep_address: string

   public q_3_1_1: number
   public q_3_1_2_a: number
   public q_3_1_2_b: number
   public q_3_2_1: number
   public q_3_2_2: number
   public q_3_3_1: number
   public q_3_3_2: number
   public q_3_3_3: number
   public q_3_4_1: number
   public q_3_5_1: number
   public q_3_5_2: number
   public q_3_6_1: number
   public q_3_6_2: number
   public rep_references: string

  constructor() {}


  deserialize(input: Legal): Legal {
    this.id_plant = input?.id_plant
    this.plant = input?.plant
    this.id = input?.id
    this.voltage_level = input?.voltage_level
    this.rmu = input?.rmu
    this.person = input?.person
    this.business_name = input?.business_name
    this.demand = input?.demand
    this.address = input?.address
    this.cp = input?.cp
    this.municipality = input?.municipality
    this.state = input?.state
    this.scian_code = input?.scian_code
    this.scian_description = input?.scian_description
    this.rep_fname = input?.rep_fname
    this.rep_lname = input?.rep_lname
    this.rep_mlname = input?.rep_mlname
    this.rep_phone = input?.rep_phone
    this.rep_mail = input?.rep_mail
    this.rep_address = input?.rep_address
    this.q_3_1_1 = input?.q_3_1_1
    this.q_3_1_2_a = input?.q_3_1_2_a
    this.q_3_1_2_b = input?.q_3_1_2_b
    this.q_3_2_1 = input?.q_3_2_1
    this.q_3_2_2 = input?.q_3_2_2
    this.q_3_3_1 = input?.q_3_3_1
    this.q_3_3_2 = input?.q_3_3_2
    this.q_3_3_3 = input?.q_3_3_3
    this.q_3_4_1 = input?.q_3_4_1
    this.q_3_5_1 = input?.q_3_5_1
    this.q_3_5_2 = input?.q_3_5_2
    this.q_3_6_1 = input?.q_3_6_1
    this.q_3_6_2 = input?.q_3_6_2
    this.rep_references = input?.rep_references
    return this
  }

  /**
   * Funcion para asignarle los valores a la clase en cada variable de la instancia.
   *
   * @param {any[]} file Documento Json que contiene la informacion.
   * @memberof Legal
   */
  public parserJsonRaw(file: any[]) {
    this.voltage_level = this._getValueByPosition(file, 3, 1)
    this.rmu = this._getValueByPosition(file, 4, 2)
    this.person = this._getValueByPosition(file, 5, 1)
    this.business_name = this._getValueByPosition(file, 6, 1)
    this.demand = this._getValueByPosition(file, 7, 1)
    this.address = this._getValueByPosition(file, 8, 2)
    this.cp = this._getValueByPosition(file, 9, 1)
    this.municipality = this._getValueByPosition(file, 10, 1)
    this.state = this._getValueByPosition(file, 11, 1)
    this.scian_code = this._getValueByPosition(file, 12, 2)
    this.scian_description = this._getValueByPosition(file, 13, 1)
    this.rep_fname = this._getValueByPosition(file, 15, 1)
    this.rep_lname = this._getValueByPosition(file, 16, 1)
    this.rep_mlname = this._getValueByPosition(file, 17, 1)
    this.rep_phone = this._getValueByPosition(file, 18, 1)
    this.rep_mail = this._getValueByPosition(file, 19, 1)
    this.rep_address = this._getValueByPosition(file, 20, 1)

    this.q_3_1_1 = this._convertToNumericValue(this._getValueByPosition(file, 38, 2))

    if (this.q_3_1_1 === 1) {
      this.q_3_1_2_a = this._convertToNumericValue(this._getValueByPosition(file, 39, 1))
      this.q_3_1_2_b = null
    } else if (this.q_3_1_1 === 0) {
      this.q_3_1_2_b = this._convertToNumericValue(this._getValueByPosition(file, 39, 1))
      this.q_3_1_2_a = null
    }

    this.q_3_2_1 = this._convertToNumericValue(this._getValueByPosition(file, 40, 2))

    if (this.q_3_2_1 === 1) {
      this.q_3_2_2 = this._convertToNumericValue(this._getValueByPosition(file, 41, 1))
    }

    this.q_3_3_1 = this._convertToNumericValue(this._getValueByPosition(file, 42, 2))

    if (this.q_3_3_1 === 1) {
      this.q_3_3_2 = this._convertToNumericValue(this._getValueByPosition(file, 43, 1))
    }
    this.q_3_3_3 = this._convertToNumericValue(this._getValueByPosition(file, 45, 1))

    this.q_3_4_1 = this._convertToNumericValue(this._getValueByPosition(file, 46, 2))

    this.q_3_5_1 = this._convertToNumericValue(this._getValueByPosition(file, 47, 2))
    this.q_3_5_2 = this._convertToNumericValue(this._getValueByPosition(file, 48, 1))

    this.q_3_6_1 = this._convertToNumericValue(this._getValueByPosition(file, 49, 2))

    if (this.q_3_6_1 === 1) {
      this.q_3_6_2 = this._convertToNumericValue(this._getValueByPosition(file, 50, 1))
    }
  }

  /**
   * Funcion para extraer la informacion en base a la posicion de la casilla.
   *
   * @private
   * @param {any[]} data Funda donde se encuentra la informacion.
   * @param {number} indexElement Fila donde se encuentra la informacion a extraer.
   * @param {number} indexValue Posion del elemento a leer basado en los datos de entrada.
   * @return {*}  {string} El valor segun la posion.
   * @memberof Legal
   */
  private _getValueByPosition(data: any[], indexElement: number, indexValue: number): string {
    const value: string = String(Object.values(data[indexElement])[indexValue])
    return value.trim()
  }

  /**
   * Funcion para convertir segun el valor 'Sí', 'No' o 'Null', un número.
   *
   * @private
   * @param {string} value Valor a convertir a entero.
   * @return {*}  {number} Variable convertida numericamente.
   * @memberof Legal
   */
  private _convertToNumericValue(value: string): number {
    const auxValue = value.toLocaleLowerCase()
    return auxValue === 'sí' ? 1 : auxValue === 'no' ? 0 : -1
  }
}
