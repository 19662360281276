import { filter, takeUntil } from 'rxjs/operators'
import { NavigationStart, Params, Router } from '@angular/router'
import { MenuTopService } from '../../services/menuTop/menu-top.service'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { User } from '../../models/user.model'
import { ROLES_SLUGS } from '../../config/enviroment'
import { NotificationPlant, StatesNotification } from '../../models/notification-plant.model'
import { PushNotificationService } from '../../services/push-notification/push-notification.service'
import { Subject } from 'rxjs'
import { parseQueryParams } from '../../extensions/controls.extension'
import { Corporate, Plant } from 'src/app/models/models.index'
import { UserFacade } from './../../store/user/facade'
import { PlantFacade } from './../../store/plant/plant.facade'
import { NotificationsFacade } from './../../store/notifications/notifications.facade'


/**
 * Componente para el topbar de la plataforma.
 *
 * @export
 * @class TopbarComponent
 */
@Component({
  selector: 'vb--topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit, OnDestroy {
  /**
   * Variable para almacenar los datos sin transformacion (Opciones de vistas) del menu.
   *
   * @type {any[]}
   * @memberof TopbarComponent
   */
  public menuTopData: any[]
  /**
   * Variable para almacenar los datos (Opciones de vistas) del menu que se encuentra activo.
   *
   * @type {any[]}
   * @memberof TopbarComponent
   */
  public menuTopDataActivated: any[]
  /**
   * Variable para usuario actual en sesion.
   *
   * @type {User}
   * @memberof LayoutMainComponent
   */
  public currentUser: User = null

  public currentCorporate: Corporate = null

  public currentPlant: Plant = null

  public rolesSlugs = ROLES_SLUGS

  public alertsNotification: NotificationPlant[] = []

  private destroy$: Subject<boolean> = new Subject<boolean>()

  constructor(
    private _menuTopService: MenuTopService,
    private _router: Router,
    private _pushNotificationService: PushNotificationService,
    private _userFacade: UserFacade,
    private _plantFacade: PlantFacade,
    private _notificationsFacade: NotificationsFacade
  ) {
    this._userFacade.userSelector$.pipe(takeUntil(this.destroy$)).subscribe(user => {
      if (!user.loading && user.authorized) {
        this.currentUser = user
        if (user.roles[0].slug !== ROLES_SLUGS.admin_diram) {
          this._notificationsFacade.getNotificationsUser(this.currentUser.id, {})
        }
      }
    })

    this._plantFacade.plantSelector$.pipe(takeUntil(this.destroy$)).subscribe((plantState) => {
      if (plantState.isLoadingSuccess && plantState.plant) {
        this.currentCorporate = plantState.corporate
        this.currentPlant = plantState.plant
      }
    })

    this._menuTopService.getMenuTopData().pipe(takeUntil(this.destroy$)).subscribe(menuData => {
      if (this.currentUser.roles[0].slug !== ROLES_SLUGS.operator) {
        menuData[0].url = '/home/overview'
      } else {
        menuData[0].url = '/home/overview/plant'
      }
      this.menuTopData = menuData
    })

    this._notificationsFacade.notificationsSelector$.pipe(takeUntil(this.destroy$)).subscribe(state => {
      if (state.isLoadingSuccess) {
        this.alertsNotification = state.notifications.filter(notification => notification.active === StatesNotification.NOT_VIEW)
      }
    })

    this._pushNotificationService.incomeMessage.pipe(takeUntil(this.destroy$)).subscribe((message) => {
      if (message) {
        this._notificationsFacade.getNotificationsUser(this.currentUser.id, {})
      }
    })
  }

  /**
   * OnInit
   *
   * @memberof TopbarComponent
   */
  ngOnInit(): void {
    this.menuTopDataActivated = this._menuTopService.activateMenu(
      this._router.url,
      this.menuTopData,
    )
    this._router.events
      .pipe(filter(event => event instanceof NavigationStart), takeUntil(this.destroy$))
      .subscribe((event: NavigationStart) => {
        this.menuTopDataActivated = this._menuTopService.activateMenu(
          event.url ? event.url : null,
          this.menuTopData,
        )
      })
  }

  goToEventAction(notificationPlant: NotificationPlant): void {
    const queryParams: Params = parseQueryParams(notificationPlant.action)

    const corporateId = parseInt(queryParams.corporate_id, 10)
    const plantId = parseInt(queryParams.plant_id, 10)

    if (this.currentCorporate.id !== corporateId) {
      this.currentCorporate = this.currentUser.corporates.find(c => c.id === corporateId)
      this._plantFacade.getPlantsByCorporate(this.currentCorporate, plantId)
    } else if (this.currentPlant.id !== plantId) {
      this._plantFacade.selectPlant(plantId)
    }

    this._router.navigate(['/managers/reports'], { queryParams }).then(() => {
      this._notificationsFacade.updateNotificationPlant(notificationPlant, {userId: this.currentUser.id})
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.unsubscribe()
  }
}
