import { Component, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import qs from 'qs'
import store from 'store'
import { Router, NavigationEnd, ActivatedRoute, NavigationStart } from '@angular/router'
import { Title } from '@angular/platform-browser'
import { filter, map, mergeMap } from 'rxjs/operators'
import { SettingsFacade } from './store/settings/facade'

import english from './locales/en-US'
import spanish from './locales/es-MX'

/** Idiomas disponibles. @type {*} */
const locales = {
  'en-US': english,
  'es-MX': spanish,
}

/**
 * Componente iniciador de la aplicacion.
 *
 * @export
 * @class AppComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-root',
  template: `
    <ng-progress></ng-progress>
    <router-outlet></router-outlet>
  `,
})
export class AppComponent implements OnInit {
  /**
   * Idioma.
   *
   * @type {String}
   * @memberof AppComponent
   */
  _locale: String
  /**
   * Tema.
   *
   * @type {String}
   * @memberof AppComponent
   */
  _theme: String
  /**
   * Version.
   *
   * @type {String}
   * @memberof AppComponent
   */
  _version: String
  /**
   * Logo.
   *
   * @type {String}
   * @memberof AppComponent
   */
  logo: String
  /**
   * Titulo de pagina.
   *
   * @type {String}
   * @memberof AppComponent
   */
  pageTitle: String = ''
  /**
   * Primer toque.
   *
   * @type {boolean}
   * @memberof AppComponent
   */
  firstTouch: boolean = false

  /**
   * Creates an instance of AppComponent.
   * @param {Router} _router
   * @param {ActivatedRoute} _activatedRoute
   * @param {Title} _titleService
   * @param {Store<any>} store
   * @param {TranslateService} _translate
   * @memberof AppComponent
   */
  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _titleService: Title,
    private _translate: TranslateService,
    private _settingsFacade: SettingsFacade,
  ) {
    Object.keys(locales).forEach(locale => {
      _translate.setTranslation(locale, locales[locale])
    })
    _translate.setDefaultLang('es-MX')

    // localization && theme listener
    this._settingsFacade.settingsSelector$.subscribe(state => {
      if (this._locale !== state.locale) {
        _translate.use(state.locale)
      }
      if (this._theme !== state.theme) {
        this.setTheme(state.theme)
      }
      if (this._version !== state.version) {
        this.setVersion(state.version)
      }
      this._locale = state.locale
      this._theme = state.theme
      this.logo = state.logo
      this.setTitle()
    })
  }

  /**
   * OnInit
   *
   * @memberof AppComponent
   */
  ngOnInit() {
    // set page title from router data variable
    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this._activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild
          }
          return route
        }),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild
          }
          return route
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data),
      )
      .subscribe(event => {
        this.pageTitle = event['title']
        this.setTitle()
      })

    // listen url query params and set them to ngrx store
    this._router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        const queryString = event.url.match(/\?(.*)/)
        if (queryString) {
          const queryParams = qs.parse(queryString[1])
          // console.log(queryParams)
          const keys = Object.keys(queryParams)
          if (keys.length) {
            keys.forEach(key => {
              let value
              switch (queryParams[key]) {
                case 'false':
                  value = false
                  break
                case 'true':
                  value = true
                  break
                default:
                  value = queryParams[key]
                  break
              }
              this._settingsFacade.setState({[key]: value})
            })
          }
        }
      })

    // detecting & set mobile/tablet/desktop viewports
    const setViewPort = (isMobileView: any = false, isTabletView: any = false) => {
      this._settingsFacade.setState({isMobileView})
      this._settingsFacade.setState({isTabletView})
    }
    const detectViewPort = (load = false) => {
      const _isMobileView = window.innerWidth < 768
      const _isTabletView = window.innerWidth < 992
      const _isDesktopView = !_isMobileView && !_isTabletView
      const isMobileView = store.get('app.settings.isMobileView')
      const isTabletView = store.get('app.settings.isTabletView')
      const isDesktopView = !isMobileView && !isTabletView
      if (_isDesktopView && (_isDesktopView !== isDesktopView || load)) {
        setViewPort(false, false)
      }
      if (_isTabletView && !_isMobileView && (_isTabletView !== isTabletView || load)) {
        setViewPort(false, true)
        this._settingsFacade.setState({isMenuCollapsed: true})
      }
      if (_isMobileView && (_isMobileView !== isMobileView || load)) {
        setViewPort(true, false)
      }
    }
    detectViewPort(true)
    window.addEventListener('resize', () => {
      detectViewPort()
    })

    // set primary color on app load
    const primaryColor = () => {
      const color = store.get('app.settings.primaryColor')
      if (color) {
        const addStyles = () => {
          const styleElement = document.querySelector('#primaryColor')
          if (styleElement) {
            styleElement.remove()
          }
          const body = document.querySelector('body')
          const styleEl = document.createElement('style')
          const css = document.createTextNode(`:root { --vb-color-primary: ${color};}`)
          styleEl.setAttribute('id', 'primaryColor')
          styleEl.appendChild(css)
          body.appendChild(styleEl)
        }
        addStyles()
        this._settingsFacade.setState({primaryColor: color})
      }
    }
    primaryColor()
  }

  /**
   * set title
   */
  setTitle = () => {
    this._titleService.setTitle(`${this.logo} | ${this.pageTitle}`)
  }

  /**
   * set version
   */
  setVersion = version => {
    document.querySelector('html').setAttribute('data-vb-version', version)
  }

  /**
   * set theme
   */
  setTheme = theme => {
    if (this.firstTouch) {
      document.querySelector('html').setAttribute('data-vb-theme', theme)
      if (theme === 'default') {
        this._settingsFacade.setState({menuColor: 'white'})
      }
      if (theme === 'dark') {
        this._settingsFacade.setState({menuColor: 'dark'})
      }
    }
    this.firstTouch = true
  }
}
