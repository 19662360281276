import { LegalRepository } from './legal.repository'
import { Injectable } from '@angular/core'
import { CrudService } from '../shared/crud.service'
import { Observable } from 'rxjs'
import { Legal } from '../../models/models.index'
import { map } from 'rxjs/operators'

/**
 * Servicio web para obtener informacion legal.
 *
 * @export
 * @class LegalService
 * @extends {CrudService}
 */
@Injectable({
  providedIn: 'root',
})
export class LegalService extends CrudService {
  /**
   * Creates an instance of CalculatedParametersService.
   * @param {LegalRepository} _legalRepository
   * @memberof LegalService
   */
  constructor(protected _legalRepository: LegalRepository) {
    super(_legalRepository)
  }

  /**
   * Funcion para servicio para obtener la informacion del gram de una empresa.
   *
   * @param {number} plantId Identificador de la planta.
   * @return {Observable<Legal>}  {Observable<FormGram>} Observable con la respuesta de nuestro peticion.
   * @memberof LegalService
   */
  public getLegalInfoByPlant(plantId: number): Observable<Legal> {
    return this._legalRepository.getLegalInfoByPlant(plantId).pipe(
      map( legal =>  new Legal().deserialize(legal[0]))
    )
  }
}
