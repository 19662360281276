import { EditProfileUserComponent } from './pages/profile/edit-profile-user/edit-profile-user.component'
import { ProfileUserComponent } from './pages/profile/profile-user/profile-user.component'
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SharedModule } from 'src/app/shared.module'
import { LayoutsModule } from 'src/app/layouts/layouts.module'
import { AppPreloader } from 'src/app/app-routing-loader'

// layouts & notfound
import { LayoutAuthComponent } from 'src/app/layouts/Auth/auth.component'
import { LayoutMainComponent } from 'src/app/layouts/Main/main.component'

// pages
import { UsersComponent } from './pages/users-admin/users/users.component'
import { ClientsComponent } from './pages/users-admin/clients/clients.component'
import { UsersAdminComponent } from './pages/users-admin/users-admin.component'
import { AuthGuard } from './services/Guard/auth.guard'
import { VerifyEmailPage } from './pages/auth/verify-email/verify-email/verify-email.component'
import { VerifyEmailComponent } from './components/Auth/verify-email/verify-email.component'
import { ResetPasswordRequestPage } from './pages/auth/reset-password-request/reset-password-request.component'
import { ResetPasswordRequestComponent } from './components/Auth/reset-password-request/reset-password-request.component'
import { AdminDiramGuard } from './services/Guard/admin-diram.guard'
import { ProfileBusinessComponent } from './pages/profile/profile-business/profile-business.component'
import { NotificationSettingsComponent } from './pages/notification/notification-settings/notification-settings.component'
import { ProfileElectricPlantsComponent } from './pages/profile/profile-electric-plants/profile-electric-plants.component'
import { EditProfileBusinessComponent } from './pages/profile/edit-profile-business/edit-profile-business.component'
import { NzTypographyModule } from 'ng-zorro-antd/typography'
import { EditProfileElectricComponent } from './pages/profile/edit-profile-electric/edit-profile-electric.component'
import { EditorUserGuard } from './services/Guard/editor-user.guard'
import { NzImageModule } from 'ng-zorro-antd/image'
import { TYPE_MENU_LEFT } from './config/enviroment'

// VB:REPLACE-END:ROUTER-IMPORTS

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home/overview',
    pathMatch: 'full',
  },
  {
    path: '',
    component: LayoutAuthComponent,
    children: [
      {
        path: `verify-email`,
        component: VerifyEmailPage,
        data: { title: 'Verificar correo' },
      },
    ],
  },
  {
    path: '',
    component: LayoutAuthComponent,
    children: [
      {
        path: `recovery-password`,
        component: ResetPasswordRequestPage,
        data: { title: 'Recuperar contraseña' },
      },
    ],
  },
  {
    path: '',
    component: LayoutMainComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'administration/users-diram',
        data: { title: 'Usuarios', menu: TYPE_MENU_LEFT.SIMPLE_USERS },
        component: UsersComponent,
        canActivate: [AdminDiramGuard],
      },
      {
        path: 'administration/clients',
        data: { title: 'Clientes', menu: TYPE_MENU_LEFT.SIMPLE_USERS },
        component: ClientsComponent,
        canActivate: [AdminDiramGuard],
      },
      {
        path: 'profile/user',
        data: { title: 'Perfil Usuario', menu: TYPE_MENU_LEFT.NONE },
        component: ProfileUserComponent,
      },
      {
        path: 'profile/business',
        data: { title: 'Perfil Corporativo', menu: TYPE_MENU_LEFT.COMPLEX },
        component: ProfileBusinessComponent,
      },
      {
        path: 'profile/electric-plants',
        data: { title: 'Perfil eléctrico', menu: TYPE_MENU_LEFT.COMPLEX },
        component: ProfileElectricPlantsComponent,
      },
      {
        path: 'profile/edit/edit-user',
        data: { title: 'Editar usuario', menu: TYPE_MENU_LEFT.NONE },
        component: EditProfileUserComponent,
      },
      {
        path: 'profile/edit/edit-business',
        data: { title: 'Editar Corporativo', menu: TYPE_MENU_LEFT.COMPLEX },
        component: EditProfileBusinessComponent,
        canActivate: [EditorUserGuard],
      },
      {
        path: 'profile/edit/edit-electric',
        data: { title: 'Editar perfil electrico', menu: TYPE_MENU_LEFT.COMPLEX },
        component: EditProfileElectricComponent,
        canActivate: [AdminDiramGuard],
      },
      {
        path: 'notification/notification-settings',
        data: { title: 'Notificaciones', menu: TYPE_MENU_LEFT.COMPLEX },
        component: NotificationSettingsComponent,
        canActivate: [EditorUserGuard],
      },
      {
        path: 'home',
        data: { title: 'Inicio', menu: TYPE_MENU_LEFT.SIMPLE },
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
          },
        ],
        canActivate: [AuthGuard],
      },
      {
        path: 'managers',
        data: { title: 'Gestores' },
        loadChildren: () => import('./pages/manager/manager.module').then(m => m.ManagerModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'reports',
        data: { title: 'Reportes' },
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/report-manager/report-manager.module').then(m => m.ReportManagerModule),
          },
        ],
        canActivate: [AuthGuard],
      },
      {
        path: 'events',
        data: { title: 'Eventos de Planta', menu: TYPE_MENU_LEFT.COMPOUND },
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/event-manager/event-manager.module').then(m => m.EventManagerModule),
          },
        ],
        canActivate: [AuthGuard], //TODO RR que es esto ?
      },
      // VB:REPLACE-END:ROUTER-CONFIG
    ],
  },
  {
    path: 'auth',
    component: LayoutAuthComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('src/app/pages/auth/auth.module').then(m => m.AuthModule),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/auth/404',
  },
]

@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: AppPreloader,
      relativeLinkResolution: 'legacy',
    }),
    LayoutsModule,
    NzTypographyModule,
    NzImageModule,
    // WidgetsComponentsModule,
  ],
  declarations: [
    // VB:REPLACE-START:ROUTER-DECLARATIONS
    UsersAdminComponent,
    UsersComponent,
    ClientsComponent,
    VerifyEmailPage,
    VerifyEmailComponent,
    ResetPasswordRequestPage,
    ResetPasswordRequestComponent,
    ProfileUserComponent,
    ProfileBusinessComponent,
    ProfileElectricPlantsComponent,
    EditProfileUserComponent,
    EditProfileBusinessComponent,
    EditProfileElectricComponent,
    NotificationSettingsComponent,
    // VB:REPLACE-END:ROUTER-DECLARATIONS
  ],
  providers: [AppPreloader],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
