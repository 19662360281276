import { MenuService } from './../menu/index'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { menuTop } from './config'

/**
 * Servicio para manejo de informacion del menu.
 *
 * @export
 * @class MenuService
 */
@Injectable({
  providedIn: 'root',
})
export class MenuTopService extends MenuService {
  /**
   * Creates an instance of MenuService.
   * @memberof MenuTopService
   */
  constructor() {
    super()
  }

  /**
   * Servicio para obtener la informacion de un menu.
   *
   * @return {*}  {Observable<any[]>}
   * @memberof MenuTopService
   */
  getMenuTopData(): Observable<any[]> {
    return of(menuTop)
  }
}
