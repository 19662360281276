<div class='flex-row justify-content-center'
     [ngStyle]='{"display": actionButtons?.length > 0 ? "flex" : "none"}'>
  <ng-container *ngFor='let button of buttons;'>
    <button nz-button nzType='primary' [class.btn-selected]='button.selected'
            (click)='onClickButton(button)'>
      <i nz-icon [nzType]='button.icon' nzTheme='outline'></i>
      <span>{{button.label}}</span>
    </button>
  </ng-container>
</div>
