import { createAction, props } from '@ngrx/store'
import { Corporate } from '../../models/corporate.model'

export const SELECT_CORPORATE = '[Corporate] Corporativo seleccionado'

export const GET_CORPORATE = '[Corporate] Obtener un coporativo'
export const LOAD_CORPORATE_SUCCESS = '[Corporate] Carga de corporativo completada'
export const LOAD_CORPORATE_FAILURE = '[Corporate] Carga de corporativo completada'

export const GET_CORPORATES = '[Corporate] Obtiene una lista corporativos'
export const LOAD_CORPORATES_SUCCESS = '[Corporate] Carga de plantas completada'
export const LOAD_CORPORATES_FAILURE = '[Corporate] Carga de Plantas no completada'

export const FLUSH_CORPORATE = '[Corporate] Flush Corporate'
export const EMPTY_CORPORATE_ACTION = '[Corporate] Empty Action Corporate'

export const getCorporate = createAction(GET_CORPORATE, props<{ corporateId: number }>())
export const loadCorporateSuccessful = createAction(LOAD_CORPORATE_SUCCESS, props<any>())
export const loadCorporateUnsuccessful = createAction(LOAD_CORPORATE_FAILURE, props<any>())

export const getCorporates = createAction(GET_CORPORATES)
export const loadCorporatesSuccessful = createAction(LOAD_CORPORATES_SUCCESS, props<any>())
export const loadCorporatesUnsuccessful = createAction(LOAD_CORPORATES_FAILURE, props<{ any }>())

export const selectCorporate = createAction(SELECT_CORPORATE, props<{ corporate: Corporate }>())
export const flushCorporate = createAction(FLUSH_CORPORATE)
export const emptyCorporateAction = createAction(EMPTY_CORPORATE_ACTION)
