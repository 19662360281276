import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { Report } from './../../models/report.model'
import * as Reducers from '../reducers'
import * as ReportActions from './report.actions'
import { ReportState } from './report.reducer'
import { CDRVersionReport } from '../../config/enviroment'


@Injectable({
    providedIn: 'root'
})
export class ReportFacade {
    reportSelector$ = this._store.select(Reducers.getReport)

    constructor(private _store: Store<ReportState>) { }

    addReportPlant(corporateId: number, plantId: number, userId: number, params: any): void {
        this._store.dispatch(ReportActions.addReportPlant({ corporateId, plantId, userId, params }))
    }

    updateReport(reportId: number) {
        this._store.dispatch(ReportActions.updateReport({ reportId }))
    }

    deleteReport(report: Report) {
        this._store.dispatch(ReportActions.deleteReport({ report }))
    }

    getReportsByPlant(corporateId: number, plantId: number, cdrVersion: CDRVersionReport, typeReport: string, params: any) {
        this._store.dispatch(ReportActions.getReportsPlant({ corporateId, plantId, cdrVersion, typeReport, params }))
    }

    downloadReport(reportId: number, cdrVersion: CDRVersionReport, typeReport: string) {
        this._store.dispatch(ReportActions.downloadReport({ reportId, cdrVersion, typeReport }))
    }

    cancelRequest(): void {
        this._store.dispatch(ReportActions.cancelRequest())
    }
}
