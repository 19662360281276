import { FileOfficialRepository } from './file-official.repository'
import { Injectable } from '@angular/core'
import { FileService } from '../file.service'

/**
 * Servicio para manejo de archivos de reportes oficiales.
 *
 * @export
 * @class FileOfficialService
 * @extends {FileService}
 */
@Injectable({
  providedIn: 'root',
})
export class FileOfficialService extends FileService {
  /**
   * Prefijo para la url de archivos.
   *
   * @type {string}
   * @memberof FileOfficialService
   */
  readonly endpointPrefix: string = 'official'

  /**
   * Creates an instance of FileOfficialService.
   * @param {HttpClient} _fileOfficialRepository
   * @memberof FileOfficialService
   */
  constructor(protected _fileOfficialRepository: FileOfficialRepository) {
    super(_fileOfficialRepository)
  }
}
