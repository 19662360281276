import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'

import * as Reducers from '../../store/reducers'
import * as UserActions from '../../store/user/actions'


@Injectable({
  providedIn: 'root'
})
export class UserFacade {

  userSelector$ = this._store.select(Reducers.getUser)

  constructor(private _store: Store<any>) { }

  login(payload: any): void {
    this._store.dispatch(new UserActions.Login(payload))
  }

  updateUser(payload: any): void {
    this._store.dispatch(new UserActions.UpdateUser(payload))
  }

  logout(): void {
    this._store.dispatch(new UserActions.Logout())
  }

  cleanUserState(): void {
    this._store.dispatch(new UserActions.FlushUser())
  }
}
