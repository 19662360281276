/**
 * Menu para paginas con todos los accesos.
 * @constant
 *  @type {*}
 * @default
 */
export const menuTop: any[] = [
  {
    title: 'topBar.menuTop.home',
    key: '_home',
    url: '/home',
    disable: false,
    paramsHandling: ''
  },
  {
    title: 'topBar.menuTop.managers',
    key: '_managers',
    url: '/managers',
    disable: false,
    paramsHandling: 'merge'
  },
  {
    title: 'topBar.menuTop.reports',
    key: '_reports',
    url: '/reports',
    disable: false,
    paramsHandling: ''
  },
  {
    title: 'topBar.menuTop.events',
    key: '_events',
    url: '/events/plant',
    disable: true,
    paramsHandling: ''
  },
]
