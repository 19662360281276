import { PointsRepository } from './points.repository'
import { Injectable } from '@angular/core'
import { CrudService } from '../shared/crud.service'
import { Observable } from 'rxjs'
import { UnifierPoint } from '../../models/models.index'

/**
 * Servicios para puntos de conexion.
 *
 * @export
 * @class PointsService
 * @extends {CrudService}
 */
@Injectable({
  providedIn: 'root',
})
export class PointsService extends CrudService {
  /**
   * Servicios para puntos unifilares PointsService
   * @param {PointsRepository} _pointsRepository Repositorio de usuarios.
   * @memberof PointsService
   */
   constructor(protected _pointsRepository: PointsRepository) {
    super(_pointsRepository)
  }

  /**
   * Servicio para obtener puntos unifilares por planta.
   *
   * @param {number} plantId Identificador de planta.
   * @return {*}  {Observable<UnifierPoint[]>} Observable de puntos unifilares.
   * @memberof PointsService
   */
  public getPointsByPlant(plantId: number): Observable<UnifierPoint[]> {
    return this._pointsRepository.getPointsByPlant(plantId)
  }

  /**
   * Servicio para guardar una imagen de diagrama unifilar.
   *
   * @param {number} plantId
   * @param {File} image
   * @return {*}  {Observable<any>}
   * @memberof PointsService
   */
   public setImage(plantId: number, image: File): Observable<any> {
    return this._pointsRepository.setImage(plantId, image)
  }

}
