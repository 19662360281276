import { OnDestroy } from '@angular/core'
import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload'
import { Observable, Observer, Subject, Subscription } from 'rxjs'
import { finalize, takeUntil } from 'rxjs/operators'
import { User } from '../../../models/user.model'
import { UsersService } from 'src/app/services/users/users.service'
import * as Reducers from '../../../store/reducers'
import { Router } from '@angular/router'

import { UserFacade } from './../../../store/user/facade'

@Component({
  selector: 'app-edit-profile-user',
  templateUrl: './edit-profile-user.component.html',
  styleUrls: ['./edit-profile-user.component.scss'],
})
export class EditProfileUserComponent implements OnInit, OnDestroy {

  public currentUser: User = null
  /**
   * Grupo de formularios para crear un usuario.
   *
   * @type {FormGroup}
   * @memberof CreateUserModalComponent
   */
  public validateForm!: FormGroup
  /**
   * Variable para almacenar la imagen de perfil de la planta
   *
   * @type {string}
   * @memberof EditProfileBusinessComponent
   */
  public avatarUrl: string = null

  public isSaving: boolean = false

  private destroy$: Subject<boolean> = new Subject<boolean>()
  /**
   * Variable funcion para realizar solicitudes customizadas a los servicios para almacenar imagenes.
   *
   * @memberof EditProfileBusinessComponent
   */
  public customRequest: (item: NzUploadXHRArgs) => Subscription

  public beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]) =>
    new Observable((observer: Observer<boolean>) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this._nzMessageService.error('¡Solo se pueden subir archivos JPG y PNG!')
        observer.complete()
        return
      }
      const isLt1_5M = file.size / 1024 / 1024 < 1.5
      if (!isLt1_5M) {
        this._nzMessageService.error('¡La imagen debe pesar menos de 1.5MB!')
        observer.complete()
        return
      }
      observer.next(isJpgOrPng && isLt1_5M)
      observer.complete()
    })

  constructor(
    private _fb: FormBuilder,
    private _store: Store<any>,
    private _router: Router,
    private _usersService: UsersService,
    private _nzMessageService: NzMessageService,
    private _userFacade: UserFacade
  ) {
    this._store.pipe(select(Reducers.getUser), takeUntil(this.destroy$)).subscribe(state => {
      this.currentUser = state
      this.avatarUrl = state.avatar
    })

    this.customRequest = (item: NzUploadXHRArgs) => {
      const image: File = item.file as any
      return this._usersService.setImage(this.currentUser.id, image)
        .subscribe(
          response => {
            item.file.url = response
            item.onSuccess(item.file, item.file, null)
            const updateUser: any = { ...this.currentUser, url: response, avatar: response }
            this._userFacade.updateUser(updateUser)
          },
          error => {
            item.onError(error, item.file)
          },
        )
    }
  }

  ngOnInit() {
    this.validateForm = this._fb.group({
      nickname: [this.currentUser.name, [Validators.required]],
      lastname: [this.currentUser.flast_name, [Validators.required]],
      phoneNumberPrefix: [this.currentUser?.country_code ? this.currentUser.country_code : '+52', [Validators.required]],
      phoneNumber: [this.currentUser.phone, [Validators.pattern('[0-9]{10}$'), Validators.required]],
    })
  }

  /**|
   * Callback para manejar los cambios en los estados del envio de la peticion al cambiar imagen.
   *
   * @param {{ file: NzUploadFile }} info
   * @memberof EditProfileBusinessComponent
   */
  handleChange(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        break
      case 'done':
        this.avatarUrl = null
        this.avatarUrl = info.file.response.url
        this._nzMessageService.success('¡Imagen actualizada con exito!')
        break
      case 'error':
        this._nzMessageService.error('Ha ocurrido un error...')
        break
    }
  }

  submitForm(): void {
    this.nickname.markAsDirty()
    this.nickname.updateValueAndValidity()

    this.lastname.markAsDirty()
    this.lastname.updateValueAndValidity()

    this.phoneNumberPrefix.markAsDirty()
    this.phoneNumberPrefix.updateValueAndValidity()

    this.phoneNumber.markAsDirty()
    this.phoneNumber.updateValueAndValidity()

    if (this.nickname.invalid ||
      this.lastname.invalid || this.phoneNumberPrefix.invalid ||
      this.phoneNumber.invalid) {
      return
    } else {
      this.isSaving = true
      const user: User = {
        // email: this.email.value,
        name: this.nickname.value,
        flast_name: this.lastname.value,
        country_code: this.phoneNumberPrefix.value,
        phone: this.phoneNumber.value,
      }

      this._usersService.updateElement(user, this.currentUser.id)
        .pipe(
          finalize(() => {
            this.isSaving = false
          }),
        )
        .subscribe((resp) => {
          this._userFacade.updateUser(resp)
          this._router.navigate(['/profile/user'])
            .then(() => {
              this._nzMessageService.success('¡Información actualizada con exito!')
            })
          // console.log(resp)
        }, error => {
          this._nzMessageService.error('Ha ocurrido un error...')
        })
    }
  }

  /**
   * Getter de form control para email.
   *
   * @readonly
   * @memberof CreateUserModalComponent
   */
  // get email() {
  //   return this.validateForm.controls.email
  // }

  /**
   * Getter de form control para nickname.
   *
   * @readonly
   * @memberof CreateUserModalComponent
   */
  get nickname() {
    return this.validateForm.controls.nickname
  }

  /**
   * Getter de form control para lastname.
   *
   * @readonly
   * @memberof CreateUserModalComponent
   */
  get lastname() {
    return this.validateForm.controls.lastname
  }

  /**
   * Getter de form control para phoneNumberPrefix.
   *
   * @readonly
   * @memberof CreateUserModalComponent
   */
  get phoneNumberPrefix() {
    return this.validateForm.controls.phoneNumberPrefix
  }

  /**
   * Getter de form control para phoneNumber.
   *
   * @readonly
   * @memberof CreateUserModalComponent
   */
  get phoneNumber() {
    return this.validateForm.controls.phoneNumber
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.unsubscribe()
  }
}
