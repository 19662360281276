import { createAction, props } from '@ngrx/store'
import { NotificationPlant } from '../../models/notification-plant.model'

export const ADD_NOTIFICATION_PLANT = '[Notifications] Añade notificacion entrante a planta'

export const GET_NOTIFICATIONS_USER = '[Notifications] Obtener notificaciones de usuario'
export const LOAD_NOTIFICATIONS_SUCCESS = '[Notifications] Completado notificaciones obtenidos'
export const LOAD_NOTIFICATIONS_FAILURE = '[Notifications] Fallido notificaciones no obtenidos'

export const UPDATE_NOTIFICATION_PLANT = '[Notifications] Actualiza un notificacion'
export const UPDATE_NOTIFICATION_SUCCESS = '[Notifications] Actualizacion de notificacion exitoso'
export const UPDATE_NOTIFICATION_FAILURE = '[Notifications] Actualizacion de notificacion fallida'

export const addNotificationPlant = createAction(ADD_NOTIFICATION_PLANT, props<{ notification: NotificationPlant }>())

export const getNotificationsUser = createAction(GET_NOTIFICATIONS_USER, props<{ userId: number, params: any }>())
export const loadNotificationsSuccessful = createAction(LOAD_NOTIFICATIONS_SUCCESS, props<{ notifications: NotificationPlant[] }>())
export const loadNotificationsUnsuccessful = createAction(LOAD_NOTIFICATIONS_FAILURE, props<any>())

export const updateNotificationPlant = createAction(UPDATE_NOTIFICATION_PLANT, props<{ notification: NotificationPlant, params: any }>())
export const updateNotificationsuccess = createAction(UPDATE_NOTIFICATION_SUCCESS, props<{ notification: NotificationPlant }>())
export const updateNotificationFailure = createAction(UPDATE_NOTIFICATION_FAILURE, props<any>())
