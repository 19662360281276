<section>
  <nz-card style='height: calc(100vh - 234px); position: relative; overflow: auto;'>
    <ng-container *ngIf='plantSelected'>
      <div class='row mb-2' *ngIf='currentUser | hideMenu'>
        <div class='col-md-6 col-12'>
          <div class='w-100 m-0 p-0'>
            <h3 class='title-section'>{{ 'profile.bussiness-secctionTitle' | translate }}</h3>
          </div>
        </div>
        <div *ngIf='!isLoadingPlantData' class='col-md-6 col-12 d-flex justify-content-end align-items-end'>
          <button class='w-100 bottom-edit btn btn-lg' (click)='goToEditPlant()'>
            <i nz-icon nzType='edit' nzTheme='outline'></i>
            Editar planta
          </button>
        </div>
      </div>

      <ng-container *ngIf='!isLoadingPlantData && plantInfoReady else statusView'>
        <div class='row mb-3'>
          <div class='col-12'>
            <nz-collapse>
              <nz-collapse-panel
                [nzHeader]="'Datos de identificación del centro de carga'"
                [nzActive]='true'>
                <div class='row'>
                  <div class='col-12'>
                    <i nz-icon nzType='thunderbolt' nzTheme='outline'></i>
                    <span class='m-0 ml-1 font-weight-bold'>Identificación del centro de carga</span>
                  </div>
                </div>
                <div class='row'>
                  <ng-container *ngFor='let item of questionsPowerCenter'>
                    <div *ngIf='this.legalInfo[item.formControlName]' class='col-12'>
                      <div>
                        <span class='text-title'>{{ item.question }}:&nbsp;</span>
                        <span class='text-value'>{{ this.legalInfo[item.formControlName] }}</span>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </nz-collapse-panel>
            </nz-collapse>
          </div>
        </div>
        <div class='row mb-3'>
          <div class='col-12'>
            <nz-collapse>
              <nz-collapse-panel
                [nzHeader]="'Datos de la acreditación de la representación legal del centro de carga'"
                [nzActive]='true'
              >
                <div class='row'>
                  <div class='col-12 col-md-6 col-lg-3 mb-3'>
                    <p>
                      <i class='mr-2' nz-icon nzType='user' nzTheme='outline'></i>
                      <span class='text-title'>Representante legal</span>
                    </p>
                    <div class='d-flex flex-column'>
                      <div>
                        <span class='text-p p-normal'>Nombre: </span>
                        <span class='text-p p-light'>{{ legalInfo | setFullName }}</span>
                      </div>
                      <div>
                        <span class='text-p p-normal'>Teléfono: </span>
                        <span class='text-p p-light'>{{ legalInfo.rep_phone }}</span>
                      </div>
                      <div>
                        <span class='text-p p-normal'>Correo: </span>
                        <span class='text-p p-light'>{{ legalInfo.rep_mail }}</span>
                      </div>
                      <div>
                        <span class='text-p p-normal'>Domicilio: </span>
                        <span class='text-p p-light'>{{ legalInfo.address }}</span>
                      </div>
                      <div>
                        <span class='text-p p-normal'>Referencias: </span>
                        <span class='text-p p-light'>{{ legalInfo.rep_references }}</span>
                      </div>
                    </div>
                  </div>
                  <ng-container *ngFor='let person of peopleAuthorization; let i = index'>
                    <div class='col-12 col-md-6 col-lg-3 mb-3'>
                      <p>
                        <i class='mr-2' nz-icon nzType='user' nzTheme='outline'></i>
                        <span class='text-title'>
                          Persona autorizada {{ i + 1 }}
                          <button *ngIf='currentUser | hideMenu : true' nz-button nzDanger nzShape='circle'
                                  nzSize='small' (click)='deletePerson(person)'>
                            <i nz-icon nzType='delete' nzTheme='outline'></i>
                          </button>
                        </span>
                      </p>
                      <div class='d-flex flex-column'>
                        <div>
                          <span class='text-p p-normal'>Nombre: </span>
                          <span class='text-p p-light'>{{ person | setFullName }}</span>
                        </div>
                        <div>
                          <span class='text-p p-normal'>Teléfono: </span>
                          <span class='text-p p-light'>{{ person.phone }}</span>
                        </div>
                        <div>
                          <span class='text-p p-normal'>Correo: </span>
                          <span class='text-p p-light'>{{ person.mail }}</span>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </nz-collapse-panel>
            </nz-collapse>
          </div>
        </div>
        <div class='row mb-3'>
          <div class='col-12'>
            <nz-collapse>
              <nz-collapse-panel
                [nzHeader]="'Requerimientos técnicos del código de red obligatorios para el centro de carga'"
                [nzActive]='true'>
                <widget-question-form-static [title]='"Tensión"' [questions]='questionsCodeRed.slice(0, 3)'
                                             [answer]='legalInfo'>
                </widget-question-form-static>
                <widget-question-form-static [title]='"Frecuencia"' [questions]='questionsCodeRed.slice(3, 5)'
                                             [answer]='legalInfo'>
                </widget-question-form-static>
                <widget-question-form-static [title]='"Corto circuito"' [questions]='questionsCodeRed.slice(5, 8)'
                                             [answer]='legalInfo'>
                </widget-question-form-static>
                <widget-question-form-static [title]='"Protecciones"' [questions]='questionsCodeRed.slice(8, 9)'
                                             [answer]='legalInfo'>
                </widget-question-form-static>
                <widget-question-form-static [title]='"Intercambio de información"'
                                             [questions]='questionsCodeRed.slice(9, 11)'
                                             [answer]='legalInfo'>
                </widget-question-form-static>
                <widget-question-form-static [title]='"Control"' [questions]='questionsCodeRed.slice(11, 13)'
                                             [answer]='legalInfo'>
                </widget-question-form-static>
              </nz-collapse-panel>
            </nz-collapse>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </nz-card>
</section>

<ng-template #statusView>
  <ng-container *ngIf='!isLoadingPlantData else loaderCircles'>
    <ng-container *ngTemplateOutlet='errorMessage'></ng-container>
  </ng-container>
</ng-template>

<ng-template #loaderCircles>
  <widget-loader-circles></widget-loader-circles>
</ng-template>

<ng-template #errorMessage>
  <widget-wrap-message-info [firstText]='"Esta planta no tiene información."'></widget-wrap-message-info>
</ng-template>
