<nz-layout>
  <nz-header>
    <div
      [ngClass]="{
        topbarGray: isGrayTopbar
      }"
      class="topbar"
    >
      <div class="logoContainer">
        <div class="logo">
          <svg
            height="58px"
            version="1.1"
            viewBox="0 0 200 58"
            width="200px"
            xmlns="http://www.w3.org/2000/svg"
          >
            <image href="./assets/images/brands/logo-cloudpq.svg" height="58" width="200" />
          </svg>
        </div>
      </div>
      <!-- <div class="d-flex flex-row flex-nowrap align-items-center">
        <vb--topbar-language-switcher></vb--topbar-language-switcher>
        <div class="icon">
          <a>
            <i nz-icon nzType="monitor" nzTheme="outline" style="font-size: 1.5rem;"></i>
            <span style="margin-left: 1rem; font-size: 1.2rem;">{{
              'auth.topBar.seeDemo' | translate
            }}</span>
          </a>
        </div>
      </div> -->
    </div>
  </nz-header>
  <nz-content
    [ngStyle]="{
      backgroundColor: '#6DB3F2',
      backgroundRepeat: 'no-repeat',
      backgroundSize: ' cover',
      backgroundPosition: 'center',
      background:
        'linear-gradient(0deg, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0.75) 100%), url(assets/images/background/bg-auth.png)'
    }"
  >
    <div
      [ngClass]="{
        vb__layout__squaredBorders: isSquaredBorders,
        vb__layout__cardsShadow: isCardShadow,
        vb__layout__borderless: isBorderless
      }"
      class="pl-5 pr-5 authContainer"
    >
      <div
        [@fadein]="routeAnimation(outlet, 'fadein')"
        [@slideFadeinRight]="routeAnimation(outlet, 'slideFadeinRight')"
        [@slideFadeinUp]="routeAnimation(outlet, 'slideFadeinUp')"
        [@zoomFadein]="routeAnimation(outlet, 'zoomFadein')"
        style="width: 100%;"
      >
        <router-outlet #outlet="outlet"></router-outlet>
      </div>
    </div>
    <nz-layout>
      <nz-footer>
        <div class="pl-md-5 pb-5 pt-4 align-self-md-start">
          Copyright © {{ date }}
          <a href="https://www.diram.com/" rel="noopener noreferrer" target="_blank">
            diram sa de cv
          </a>
          |
          <a
            href="https://www.diram.com/aviso-de-privacidad?lang=en"
            rel="noopener noreferrer"
            target="_blank"
          >
            {{ 'footer.policyPrivacy' | translate }}
          </a>
        </div>
      </nz-footer>
    </nz-layout>
  </nz-content>
</nz-layout>
