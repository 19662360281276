const APP_VERSION = require('../../package.json').version

const hostAPIV1 = 'https://eu2apifunctioncloudpqprd.azurewebsites.net'
const hostAPIV2 = 'https://eu2apifunctioncloudpqprd.azurewebsites.net'
const hostAPIBilling = 'https://eu2apifunctionfactprd.azurewebsites.net'
const hostAPINotification = 'https://eu2apifunctionnotprd.azurewebsites.net'


const apiVersionV1 = 'v1'
const apiVersionV2 = 'v2'

const apiEndPointV1 = `${hostAPIV1}/api/${apiVersionV1}`
const apiEndPointV2 = `${hostAPIV2}/api/${apiVersionV2}`
const apiEndPointBilling = `${hostAPIBilling}/api/${apiVersionV1}/facturacion`
const apiEndPointNotificationV1 = `${hostAPINotification}/notification/api/${apiEndPointV1}`

export const environment = {
  firebase: {
    projectId: 'cloudpq-prd',
    appId: '1:1058355593649:web:b7f92190af2d3a4ba63d90',
    storageBucket: 'cloudpq-prd.appspot.com',
    apiKey: 'AIzaSyDUENDKIKqyVJZ_ibymBbbFiyNBADNYtXw',
    authDomain: 'cloudpq-prd.firebaseapp.com',
    messagingSenderId: '1058355593649',
    measurementId: 'G-FQJ13SV5B9',
  },
  production: true,
  hostApiV1: hostAPIV1,
  hostApiV2: hostAPIV2,
  apiVersion: apiVersionV1,
  apiEndPoint: apiEndPointV1,
  apiEndPointV2: apiEndPointV2,
  apiEndPointBilling: apiEndPointBilling,
  apiEndPointNotificationV1: apiEndPointNotificationV1,
  authenticated: false,
  hmr: false,
  appVersion: `${APP_VERSION}`,
  SIMPLE_API_KEY: '',
}
