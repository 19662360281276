import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { Router } from '@angular/router'
import { takeUntil } from 'rxjs/operators'
import { MenuService } from '../../services/menu'
import { Subject } from 'rxjs'
import { MenuItem } from '../../interfaces/menu-item'
import { TYPE_MENU_LEFT } from '../../config/enviroment'
import { NzDateMode } from 'ng-zorro-antd/date-picker/standard-types'
import { RangeDate } from '../../interfaces/range-date'

import { SettingsFacade } from './../../store/settings/facade'

/**
 * Componete para menu desplegable izquierdo.
 *
 * @export
 * @class MenuLeftComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'widget-menu-left',
  templateUrl: './menu-left.component.html',
  styleUrls: ['./menu-left.component.scss'],
})
export class MenuLeftComponent implements OnChanges, OnInit, OnDestroy {
  /**
   * Variable para almacenar los datos (Opciones de vistas) del menu.
   *
   * @type {MenuItem[]}
   * @memberof MenuLeftComponent
   */
  @Input() menuData: MenuItem[] = []

  @Input() typeMenu: number = TYPE_MENU_LEFT.NONE

  @Input() isReady: boolean = true

  @Input() modeMenuControl: NzDateMode = 'month'

  @Input() showOption: boolean = false

  @Input() version: number
  /**
   * Evento de salida del componente al seleccionar un elemento del submenu del sidebar
   *
   * @type {EventEmitter<any>}
   * @memberof MenuLeftComponent
   */
  @Output() itemSelected: EventEmitter<any> = new EventEmitter<any>()

  @Output() eventPanelControl: EventEmitter<any> = new EventEmitter<any>()

  @Output() eventGenerate: EventEmitter<RangeDate> = new EventEmitter<RangeDate>()


  public typesMenu = TYPE_MENU_LEFT

  public currentUrl: string = ''
  /**
   * Variable para realizar un cambio de color de menu.
   *
   * @type {String}
   * @memberof MenuLeftComponent
   */
  public menuColor: String
  /**
   * Variable para saber si el menu tiene sombra.
   *
   * @type {Boolean}
   * @memberof MenuLeftComponent
   */
  public isMenuShadow: Boolean
  /**
   * Variable para saber si el menu no esta fijo en la pantalla.
   *
   * @type {Boolean}
   * @memberof MenuLeftComponent
   */
  public isMenuUnfixed: Boolean
  /**
   * Variable para conocer si el sidebar esta abierto.
   *
   * @type {Boolean}
   * @memberof MenuLeftComponent
   */
  public isSidebarOpen: Boolean
  /**
   * Variable para saber si es vista mobile.
   *
   * @type {Boolean}
   * @memberof MenuLeftComponent
   */
  public isMobileView: Boolean
  /**
   * Variable para establecer el ancho del menu izquierdo.
   *
   * @type {number}
   * @memberof MenuLeftComponent
   */
  public leftMenuWidth: number
  /**
   * Variable para conocer si el menu esta collapsado.
   *
   * @type {Boolean}
   * @memberof MenuLeftComponent
   */
  public isMenuCollapsed: Boolean
  /**
   * Variable para almacenar el titulo de la plataforma.
   *
   * @type {String}
   * @memberof MenuLeftComponent
   */
  public logo: String
  /**
   * Variable para almacenar los datos (Opciones de vistas) del menu que se encuentra activo.
   *
   * @type {any[]}
   * @memberof MenuLeftComponent
   */
  public menuDataActivated: any[]
  /**
   * Elemento actualmente seleccionado.
   *
   * @type {*}
   * @memberof MenuLeftComponent
   */
  public currentItem: MenuItem = { title: null, metaData: { id: -1 } }

  public currentLevel: number = 1

  private destroy$: Subject<boolean> = new Subject<boolean>()

  /**
   * Creates an instance of MenuLeftComponent.
   * @param {MenuService} _menuService
   * @param {Store<any>} store
   * @param {Router} _router
   * @param _changeDetection
   * @memberof MenuLeftComponent
   */
  constructor(
    private _menuService: MenuService,
    private _router: Router,
    private _settingsFacade: SettingsFacade
  ) {
    this.currentUrl = this._router.url
    this._settingsFacade.settingsSelector$.pipe(takeUntil(this.destroy$)).subscribe(state => {
      this.menuColor = state.menuColor
      this.isMenuShadow = state.isMenuShadow
      this.isMenuUnfixed = state.isMenuUnfixed
      this.isSidebarOpen = state.isSidebarOpen
      this.isMobileView = state.isMobileView
      this.leftMenuWidth = state.leftMenuWidth
      this.isMenuCollapsed = state.isMenuCollapsed
      this.logo = state.logo
    })

    this._menuService.getMenuData().pipe(takeUntil(this.destroy$)).subscribe(menuData => {
      this.menuDataActivated = this._menuService.activateMenu(this.currentUrl, menuData)
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.menuData && this.typeMenu) {
      this.currentUrl = this._router.url
      const url = this.currentUrl.split('?')[0]
      this._menuService.setMenuData(this.menuData, this.typeMenu, url)
    }
  }

  /**
   * OnInit
   *
   * @memberof MenuLeftComponent
   */
  ngOnInit() {
  }

  /**
   * Callback para realizar un collapse del menu.
   *
   * @param {*} value
   * @memberof MenuLeftComponent
   */
  public onCollapse(value: any) {
    this._settingsFacade.setState({ isMenuCollapsed: value})
  }

  /**
   * Funcion callback para evento de seleccion de elemento en el menu.
   * Se ignora elementos con nivel 0
   * @param {*} item Elemento seleccionado.
   * @param {number} level Nivel del elemento.
   * @memberof MenuLeftComponent
   */
  public onClickItem(item: MenuItem, level: number): void {
    const validationLevel: boolean = this.currentItem?.metaData.id === item?.metaData?.id && this.currentLevel === level
    const validationMenuItemId: boolean = !item?.metaData?.id && item?.metaData?.id !== 0
    if (validationLevel || validationMenuItemId) {
      return
    }

    const itemClone: MenuItem = { ...item, metaData: { ...item.metaData, level } }

    this.currentItem = item
    this.currentLevel = level
    this.itemSelected.emit(itemClone)
  }

  onPanelControl($event: any) {
    this.eventPanelControl.emit($event)
  }

  onGenerate(rangeDates: RangeDate) {
    this.eventGenerate.emit(rangeDates)
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.unsubscribe()
  }
}
