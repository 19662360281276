import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { PlantState } from './plant.reducer'

import * as Reducers from '../../store/reducers'
import * as PlantActions from '../../store/plant/plant.actions'

import { Corporate } from './../../models/corporate.model'


@Injectable({
  providedIn: 'root'
})
export class PlantFacade {

  plantSelector$ = this._store.select(Reducers.getPlant)

  constructor(private _store: Store<PlantState>) { }

  selectPlant(plantId: number): void {
    this._store.dispatch(PlantActions.selectPlant({ plantId }))
  }

  getPlantById(plantId: number): void {
    this._store.dispatch(PlantActions.getPlant({ plantId }))
  }

  getPlantsByCorporate(corporate: Corporate, defualtPlantId?: number): void {
    this._store.dispatch(PlantActions.getPlants({ corporate, defualtPlantId }))
  }

  clearPlantState(): void {
    this._store.dispatch(PlantActions.flushPlant())
  }
}
