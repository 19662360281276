import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { User } from 'src/app/models/models.index'
import { EmailCode } from '../../interfaces/emailCode'
import { PasswordReset } from '../../interfaces/password-reset'
import { AuthRepository } from './auth.repository'

/**
 * Servicio para autorizaciones de usuarios.
 *
 * @export
 * @class AuthService
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  /**
   * Creates an instance of AuthService.
   * @param {HttpClient} _httpClient
   * @param {CommonService} _commonService
   * @memberof AuthService
   */
  constructor(private _authRepository: AuthRepository) {}

  /**
   * Servicio para iniciar sesion.
   *
   * @param {string} email
   * @param {string} password
   * @return {*}  {Observable<any>}
   * @memberof AuthService
   */
  login(email: string, password: string): Observable<any> {
    return this._authRepository.login(email, password)
  }

  /**
   * Servicio para obtener la informacion del usuario actual.
   *
   * @return {*}  {Observable<any>}
   * @memberof AuthService
   */
  currentAccount(): Observable<any> {
    return this._authRepository.currentAccount().pipe(
      map((resp: User) => {
        resp['token'] = JSON.parse(localStorage.getItem('accessToken'))
        return resp
      }),
    )
  }

  /**
   * Servicio para cerrar sesion.
   *
   * @return {*}  {Observable<any>}
   * @memberof AuthService
   */
  logout(): Observable<any> {
    return this._authRepository.logout()
  }

  /**
   * Servicio para verificar correo electronico.
   *
   * @param {string} token
   * @return {*}  {Observable<EmailCode>}
   * @memberof AuthService
   */
  verifyEmail(payload: {email: string, expire_at: string, code: string}): Observable<EmailCode> {
    return this._authRepository.verifyEmail(payload)
  }

  /**
   * TODO VERIFICAR
   * Servicio para cambio de contraseña.
   *
   * @param {PasswordReset} editSecurity
   * @param {string} token
   * @return {*}  {Observable<any>}
   * @memberof AuthService
   */
  changePassword(editSecurity: PasswordReset, token: string): Observable<any> {
    return this._authRepository.changePassword(editSecurity, token)
  }

  /**
   * Servicio para solicitar un recuperacion de contraseña.
   *
   * @param {{ email: string }} payload
   * @return {*}  {Observable<any>}
   * @memberof AuthService
   */
  requestRecoveryPassword(payload: { email: string }): Observable<any> {
    return this._authRepository.requestRecoveryPassword(payload)
  }

  /**
   * Servicio para resetear contraseña.
   *
   * @param {PasswordReset} payload
   * @param {string} token
   * @return {*}  {Observable<any>}
   * @memberof AuthService
   */
  resetPassword(payload: PasswordReset, token: string): Observable<any> {
    return this._authRepository.resetPassword(payload, token)
  }
}
