<section>
  <nz-card>
    <h3 class='title-section'>{{ 'profile.user-sectionTitle' | translate }}</h3>
    <div class='body-details d-flex flex-row'>
      <div class='img-container'>
        <nz-upload
          class='avatar-uploader w-auto'
          [nzAccept]='"image/png, image/jpeg"'
          [nzBeforeUpload]='beforeUpload'
          [nzCustomRequest]='customRequest'
          nzName='avatar'
          nzListType='picture-card'
          [nzShowUploadList]='false'
          (nzChange)='handleChange($event)'>
          <ng-container *ngIf='!avatarUrl'>
            <i class='upload-icon' nz-icon [nzType]="'plus'"></i>
            <div class='ant-upload-text'></div>
          </ng-container>
          <img *ngIf='avatarUrl' height='110px' width='120px' [src]='avatarUrl' />
        </nz-upload>
      </div>
      <div style='width: 35%;'>
        <form [formGroup]='validateForm' autocomplete='off' nz-form>
          <nz-form-item>
            <nz-form-label [nzSpan]='5' nzFor='nickname' nzRequired>Nombre(s)</nz-form-label>
            <nz-form-control [nzSpan]='12' nzErrorTip='¡Introduce un nombre de usuario!'>
              <input formControlName='nickname' id='nickname' nz-input placeholder='Nombre(s) del usuario' />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]='5' nzFor='lastname' nzRequired>Apellido(s)</nz-form-label>
            <nz-form-control [nzSpan]='12' nzErrorTip='¡Introduce un apellido de usuario!'>
              <input formControlName='lastname' id='lastname' nz-input placeholder='Apellido(s) del usuario' />
            </nz-form-control>
          </nz-form-item>
          <!--          <nz-form-item>-->
          <!--            <nz-form-label [nzSpan]='5' nzFor='email' nzRequired>Correo</nz-form-label>-->
          <!--            <nz-form-control [nzSpan]='12' nzErrorTip='¡No es un correo valido!'>-->
          <!--              <input formControlName='email' id='email' nz-input placeholder='Correo del usuario' />-->
          <!--            </nz-form-control>-->
          <!--          </nz-form-item>-->
          <nz-form-item>
            <nz-form-label [nzSpan]='5' nzFor='phoneNumber' nzRequired>Celular</nz-form-label>
            <nz-form-control [nzSpan]='12' [nzErrorTip]=phoneNumberError>
              <nz-input-group [nzAddOnBefore]='addOnBeforeTemplate'>
                <ng-template #addOnBeforeTemplate>
                  <nz-select formControlName='phoneNumberPrefix' class='phone-select'>
                    <nz-option nzLabel='+1' nzValue='+1'></nz-option>
                    <nz-option nzLabel='+52' nzValue='+52'></nz-option>
                  </nz-select>
                </ng-template>
                <input formControlName='phoneNumber' id="'phoneNumber'" nz-input placeholder='Celular del usuario' />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </form>
      </div>
    </div>
    <div class='d-flex flex-row justify-content-start w-100' style='gap: 10px;'>
      <button nz-button class='ant-btn-danger' [routerLink]='["/profile/user"]'>
        Cancelar
      </button>
      <button button-theme='inverter' nz-button nzType='primary' (click)='submitForm()' [nzLoading]='isSaving'
              [disabled]='validateForm.invalid'>
        Actualizar
      </button>
    </div>
  </nz-card>
</section>

<ng-template #phoneNumberError>
  <ng-container *ngIf='phoneNumber.touched && phoneNumber.invalid'>
    <div *ngIf='phoneNumber.errors?.required'>¡Introduce el celular del usuario!</div>
    <div *ngIf='phoneNumber.errors?.pattern'>¡Número celular no valido!</div>
  </ng-container>
</ng-template>
