import { ParamRequest } from 'src/app/interfaces/interfaces.index'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { CDRVersionReport, TYPE_VERSION_REPORT } from '../../config/enviroment'
import { Report } from '../../models/report.model'
import { CrudService } from '../shared/crud.service'
import { ReportsRepository } from './reports.repository'
import { map } from 'rxjs/operators'

/**
 * Servicio base para manejo de informacion de reportes.
 *
 * @export
 * @class ReportsService
 */
@Injectable({
  providedIn: 'root',
})
export abstract class ReportsService extends CrudService {
  /**
   * Creates an instance of ReportsService.
   * @param {ReportsRepository} _reportsRepository
   * @memberof ReportsService
   */
  constructor(protected _reportsRepository: ReportsRepository) {
    super(_reportsRepository)
  }

  /**
   * Servicio para obtener una lista de reportes.
   *
   * @param {number} corporateId Identificador de corporativo.
   * @param {number} clientId Identificador del cliente
   * @param {number} [projectId=null] Identificador del proyecto.
   * @param {number} plantId Identificador de la planta.
   * @param {number} uniPointId Identificador del punto unifilar.
   * @return {*}  {Observable<any>}
   * @memberof ReportsService
   */
  public getReportsByPlant(plantId: number): Observable<any> {
    return this._reportsRepository.getReportsByPlant(plantId)
  }

  /**
   * Servicio para generar un reporte.
   *
   * @param {Report} report Modelo del reporte a generar.
   * @return {*}  {Observable<any>}
   * @memberof ReportsService
   */
  public generateReport(report: Report): Observable<any> {
    return this._reportsRepository.createdElement(report)
  }

  /**
   * Servicio para actualizar un reporte.
   *
   * @param {*} reportId Identificador del reporte.
   * @return {*}  {Observable<any>}
   * @memberof ReportsService
   */
  public getReport(reportId: number, paramsRequest: ParamRequest[] = null): Observable<any> {
    return this._reportsRepository.getElementById(reportId, paramsRequest)
  }

  public getReportsByYear(plantId: number, cdrVersion: CDRVersionReport, year: number, relationships: string[] = []): Observable<Report[]> {
    const paramsRequest: ParamRequest[] = []

    relationships.forEach( relation => {
      paramsRequest.push({param: 'relationships', value: relation})
    })

    return this._reportsRepository.getReportsByYear(plantId, cdrVersion, year, paramsRequest).pipe(
      map((reports: Report[]) => {
        if (reports?.length < 1) {
          return []
        }
        return reports
      })
    )
  }

  /**
   * Servicio para eliminar un reporte.
   *
   * @param {number} reportId
   * @return {*}  {Observable<string>}
   * @memberof ReportsService
   */
  public deleteReport(reportId: number): Observable<string> {
    return this._reportsRepository.deleteElement(reportId)
  }

  public getUrlReportFile(
    reportId: number,
    cdrVersionId: number = TYPE_VERSION_REPORT.REPORT_2022,
  ): Observable<any> {
    return this._reportsRepository.getUrlReportFile(reportId, cdrVersionId)
  }

  public downloadReportByUrl(url: string): void {
    this._reportsRepository.downloadReportByUrl(url)
  }
}
