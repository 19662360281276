import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core'
import { Client } from '../../models/client.model'
import { User } from '../../models/user.model'
import { Rol } from '../../models/rol.model'
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal'
import {
  TIME_SHOW_MSG,
  TYPE_EXTERNAL_USERS,
  TYPE_NOTIFICATION,
  TYPE_USERS_DIRAM,
} from '../../config/enviroment'
import { UsersService } from '../../services/users/users.service'
import { RolService } from '../../services/rol/rol.service'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { distinct, finalize, map, takeUntil } from 'rxjs/operators'
import { environment } from '../../../environments/environment'
import { select, Store } from '@ngrx/store'
import * as Reducers from '../../store/reducers'
import { ActivatedRoute } from '@angular/router'
import { Corporate } from '../../models/corporate.model'
import { Subject } from 'rxjs'

/**
 * Componente para administracion de usuarios.
 *
 * @export
 * @class UsersAdminComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-users-admin',
  templateUrl: './users-admin.component.html',
  styles: [
    `
      .title-section {
        line-height: 2rem;
        font-size: 1.6rem;
        font-weight: bold;
        color: #4d4d4d;
      }

      /* width */
      ::-webkit-scrollbar {
        border-radius: 2px;
        width: 4px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        border-radius: 2px;
        background: #f1f1f1;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background: #888;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    `,
  ],
})
export class UsersAdminComponent implements OnInit, OnDestroy {
  /**
   * Tipo de vista en funcion del usuario.
   *
   * @type {number}
   * @memberof UsersAdminComponent
   */
  @Input() typeOfView: number
  /**
   * Identificador del usuario logged.
   *
   * @type {number}
   * @memberof UsersAdminComponent
   */
  public userIdLogged: number
  /**
   * Arreglo de clientes si es para usuarios externos.
   *
   * @type {Client[]}
   * @memberof UsersAdminComponent
   */
  public clients: Client[] = []
  /**
   * Arreglo de usuarios.
   *
   * @type {User[]}
   * @memberof UsersAdminComponent
   */
  public users: User[] = []
  /**
   * Arreglo de roles del usuario.
   *
   * @type {Rol[]}
   * @memberof UsersAdminComponent
   */
  public roles: Rol[] = []
  /**
   * Tipo de cliente seleccionado.
   *
   * @type {Corporate}
   * @memberof UsersAdminComponent
   */
  public corporateSelected: Corporate
  /**
   * Usuario seleccionado.
   *
   * @type {User}
   * @memberof UsersAdminComponent
   */
  public selectedUser: User
  /**
   * Estado de la carga del modal.
   *
   * @memberof UsersAdminComponent
   */
  public isLoadDataModal = false
  /**
   * Estado de la carga de la vista.
   *
   * @type {boolean}
   * @memberof UsersAdminComponent
   */
  public isLoading: boolean = true
  /**
   * Variable para conocer si el usuario se encuentra cargado.
   *
   * @type {boolean}
   * @memberof UsersAdminComponent
   */
  public isUsersReady: boolean = false
  /**
   * Variable de control para conocer el estado de guardado de un usuario nuevo.
   *
   * @type {boolean}
   * @memberof UsersAdminComponent
   */
  public isSavingUser: boolean = false
  /**
   * Modal actualmente abierto.
   *
   * @type {NzModalRef}
   * @memberof UsersAdminComponent
   */
  public currentModal: NzModalRef
  /**
   * Variable de control para manejar el cambio de acceso de un usuario.
   *
   * @private
   * @type {boolean}
   * @memberof UsersAdminComponent
   */
  private changedAccessUser: boolean = false
  /**
   * Texto para el spinner del loader.
   *
   * @type {string}
   * @memberof UsersAdminComponent
   */
  public textHTMLSpin: string
  /**
   * Texto para la entrada de busqueda.
   *
   * @type {string}
   * @memberof UsersAdminComponent
   */
  public textHTMLInputSearch: string
  /**
   * Texto para mensaje de error.
   *
   * @type {string}
   * @memberof UsersAdminComponent
   */
  public text2MsgError: string
  /**
   * Texto para el tipo de usuario.
   *
   * @private
   * @type {string}
   * @memberof UsersAdminComponent
   */
  private textTypeUser: string
  /**
   * Texto usado para realizar el filtro.
   *
   * @type {string}
   * @memberof UsersAdminComponent
   */
  public textFilter: string
  /**
   * Variable para manejar si una busqueda es realizada por filtro.
   *
   * @type {boolean}
   * @memberof UsersAdminComponent
   */
  public searchingByFilter: boolean

  private destroy$: Subject<boolean> = new Subject<boolean>()

  /**
   * Creates an instance of UsersAdminComponent.
   * @param {NzModalService} _modalService
   * @param {UsersService} _usersService
   * @param {ClientService} _clientService
   * @param {RolService} _rolService
   * @param {NzMessageService} _messageService
   * @param {NzNotificationService} _notificationService
   * @param {Store<any>} store
   * @param _activatedRoute
   * @memberof UsersAdminComponent
   */
  constructor(
    private _modalService: NzModalService,
    private _usersService: UsersService,
    private _rolService: RolService,
    private _messageService: NzMessageService,
    private _notificationService: NzNotificationService,
    private store: Store<any>,
    private _activatedRoute: ActivatedRoute,
  ) {
    this.store.pipe(select(Reducers.getUser), takeUntil(this.destroy$)).subscribe(state => {
      this.userIdLogged = state.id
    })

    this.store.pipe(select(Reducers.getCorporate), distinct(), takeUntil(this.destroy$)).subscribe(state => {
      this.corporateSelected = state.corporate
    })
  }

  /**
   * OnInit
   *
   * @memberof UsersAdminComponent
   */
  ngOnInit(): void {
    this.textHTMLSpin = 'Buscando'
    switch (this.typeOfView) {
      case TYPE_EXTERNAL_USERS:
        this.textHTMLInputSearch = 'Buscar cliente'
        this.textHTMLSpin = this.textHTMLSpin.concat(' clientes...')
        this.text2MsgError = `No hay usuarios disponibles para`
        this.textTypeUser = 'Cliente'
        break
      case TYPE_USERS_DIRAM:
        this.corporateSelected = null
        this.textHTMLInputSearch = 'Buscar usuario'
        this.textHTMLSpin = this.textHTMLSpin.concat(' Usuarios...')
        this.text2MsgError = `No hay usuarios disponibles...`
        this.textTypeUser = 'Usuario'
        break
      default:
        break
    }

    this._activatedRoute.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      const corporateId: number = parseInt(params['corporate_id'], 10)
      if (!isNaN(corporateId)) {
        this.getUsers(corporateId)
      } else {
        this.getUsers()
      }
    })
  }

  /**
   * Metodo para obtener usuarios mediante los servicios.
   *
   * @param {number} corporateId
   * @param {string} [filterText=null]
   * @memberof UsersAdminComponent
   */
  public getUsers(corporateId: number = null, filterText: string = ''): void {
    this.isLoading = true
    this._usersService
      .getUsers(corporateId, filterText)
      .pipe(
        map((users: User[]) => {
          if (users) {
            return users
          }
        }),
        finalize(() => {
          this.isLoading = false
        }),
      ).subscribe((users: User[]) => {
        this.users = users
      },
        error => {
          this.isLoading = false
          this._messageService.create('error', `Algo no esperado ocurrio intente más tarde...`, {
            nzDuration: 5000,
          })
        },
        () => {
          this.isLoading = false
          if (this.users && this.users?.length > 0) {
            this.isUsersReady = true
          } else {
            this.isUsersReady = false
            const whiteSpace = '&#32'
            this._messageService.create(
              'error',
              this.text2MsgError.concat(
                `${this.corporateSelected?.longName ? whiteSpace + this.corporateSelected.longName : ''
                }...`,
              ),
              {
                nzDuration: 5000,
              },
            )
          }
        },
      )
  }

  /**
   * Callback para abrir el modal para crear un usuario.
   *
   * @param {TemplateRef<{}>} modalCreateUser
   * @param {User} user
   * @memberof UsersAdminComponent
   */
  public openCreatorUserModal(modalCreateUser: TemplateRef<{}>, user: User): void {
    if (!user) {
      this.selectedUser = null
    }
    const title: string | TemplateRef<string> = user
      ? `<p>Editar ${this.textTypeUser}</p><p>${user.email}</p>`
      : `Agregar ${this.textTypeUser}`
    this.isLoadDataModal = true
    this.currentModal = this._modalService.create({
      nzContent: modalCreateUser,
      nzMaskClosable: false,
      nzClosable: false,
      nzTitle: title,
      nzFooter: null,
    })

    this._rolService.getAllElements().subscribe(
      resolve => {
        this.roles = resolve.filter(res => res.rol_type === this.typeOfView)
      },
      error => {
        this._modalService.closeAll()
        this._messageService.create('error', `Algo no esperado ocurrio intente más tarde...`, {
          nzDuration: 5000,
        })
      },
      () => {
        this.isLoadDataModal = false
      },
    )
  }

  /**
   * Metodo para abrir modal para editar usuario.
   *
   * @param {User} user
   * @param {TemplateRef<{}>} modalCreateUser
   * @memberof UsersAdminComponent
   */
  public editUser(user: User, modalCreateUser: TemplateRef<{}>): void {
    if (user) {
      this.selectedUser = user
      this.openCreatorUserModal(modalCreateUser, user)
    }
  }

  /**
   * Callback para realizar el cambio de acceso de un usuario.
   *
   * @param {User} user
   * @memberof UsersAdminComponent
   */
  public changeAccessUser(user: User) {
    this.selectedUser = user
    this.changedAccessUser = true
    this.sendUserChange(this.selectedUser)
  }

  /**
   * Callback para confirmar accion del modal.
   *
   * @private
   * @param {{ title: string; body: string }} content
   * @param {number} delay
   * @memberof UsersAdminComponent
   */
  private confirmModal(content: { title: string; body: string }, delay: number): void {
    setTimeout(() => {
      this._modalService.success({
        nzTitle: content.title,
        nzContent: content.body,
        nzOkType: 'primary',
      })
    }, delay)
  }

  /**
   * Metodo para enviar un cambio en los usuarios.
   * Si tenemos usuario lo creamos, de lo contrario
   * se editara un usuario existente.
   *
   * @param {User} user
   * @memberof UsersAdminComponent
   */
  public sendUserChange(user: User): void {
    this.isSavingUser = true
    if (!environment.production) {
      console.log(user)
    }

    if (!this.selectedUser) {
      this.changedAccessUser = false
      this._usersService
        .createdElement(user)
        .pipe(
          map(resp => {
            if (resp?.user) {
              return resp.user
            } else {
              return resp
            }
          }),
        )
        .subscribe(
          respUser => {
            if (
              user.id_corporate === this.corporateSelected?.id ||
              this.typeOfView === TYPE_USERS_DIRAM
            ) {
              if (respUser?.corporate_id) {
                respUser.corporate_id = user.id_corporate
              }
              user = respUser
              this.users.push(user)
              this.users = [...this.users]

              if (!this.isUsersReady) {
                this.isUsersReady = true
              }
            }
          },
          error => {
            this.isSavingUser = false
            this.errorMsg('Error no esperado, intente más tarde...', TIME_SHOW_MSG)
          },
          () => {
            this.isSavingUser = false
            this._modalService.closeAll()
            this.confirmModal(
              { title: `El usuario \"${user.name}\" se creo con éxito`, body: '' },
              200,
            )
          },
        )
    } else {
      delete user.email_verified_at
      this._usersService
        .updateElement(user, this.selectedUser.id)
        .pipe(map((resp: User) => resp))
        .subscribe(
          userEdited => {
            const indexUser = this.users.findIndex(u => u.id === userEdited.id)
            const userCorporateId: number = userEdited.id_corporate
            if (userCorporateId === this.corporateSelected?.id || this.typeOfView === TYPE_USERS_DIRAM) {
              if (indexUser >= 0) {
                this.users[indexUser] = {
                  id: userEdited.id,
                  id_corporate: userCorporateId,
                  email: userEdited.email,
                  country_code: userEdited.country_code,
                  phone: userEdited.phone,
                  email_verified_at: userEdited.email_verified_at,
                  id_rol: userEdited.id_rol,
                  name: userEdited.name,
                  flast_name: userEdited.flast_name,
                  permissions: userEdited.permissions,
                  roles: userEdited.roles,
                  user_type: userEdited.user_type,
                  access: userEdited.access,
                }
                this.users = [...this.users]
              }
            } else {
              if (indexUser >= 0) {
                this.users.splice(indexUser, 1)
              }
            }
          },
          error => {
            if (this.changedAccessUser) {
              this.selectedUser.access = !this.selectedUser.access
            }
            this.isSavingUser = false
            this.changedAccessUser = false
            this.errorMsg('Error no esperado, intente más tarde...', TIME_SHOW_MSG)
          },
          () => {
            this.isSavingUser = false
            this.changedAccessUser = false
            this._modalService.closeAll()
            this.confirmModal(
              {
                title: `El usuario \"${user.name}\" se edito con éxito`,
                body: 'Verifica los cambios aplicados',
              },
              200,
            )
          },
        )
    }
  }

  /**
   * Modal para confirmar la eliminacion de un usuario.
   *
   * @param {User} user
   * @memberof UsersAdminComponent
   */
  public modalDeleteUser(user: User): void {
    this._modalService.confirm({
      nzTitle: `¿Seguro que deseas eliminar el usuario \"${user.name}\"?`,
      nzContent: 'Esta acción no es reversible',
      nzOkText: 'Sí, deseo eliminar',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => this.deleteUser(user),
      nzCancelText: 'No',
      nzOnCancel: () => this._modalService.closeAll(),
    })
  }

  /**
   * Metodo para eliminar un usuario en los servicios.
   *
   * @param {User} user
   * @memberof UsersAdminComponent
   */
  public deleteUser(user: User): void {
    if (user) {
      const userId: number = user.id
      this._usersService.deleteElement(userId).subscribe(
        resp => {
          if (!environment.production) {
            console.log('La peticion fue realizada con exito')
            console.log(resp)
          }
          const indexUserDeleted = this.users.findIndex(u => u.id === userId)
          if (indexUserDeleted >= 0) {
            this.users.splice(indexUserDeleted, 1)
            this.users = [...this.users]
          }
        },
        error => {
          this._modalService.closeAll()
          this.showNotification(
            {
              type: TYPE_NOTIFICATION.ERROR,
              title: 'Lo sentimos...',
              body: 'Ha ocurrido un error intene nuevamente',
            },
            200,
          )
        },
        () => {
          this._modalService.closeAll()
          this.showNotification(
            {
              type: TYPE_NOTIFICATION.SUCCESS,
              title: `¡Exito!`,
              body: `Se ha eliminado correctamente "${user.name}"`,
            },
            200,
          )
        },
      )
    }
  }

  /**
   * Mensaje de error al tener una falla.
   *
   * @private
   * @param {string} msg
   * @param {number} duration
   * @memberof UsersAdminComponent
   */
  private errorMsg(msg: string, duration: number): void {
    this.isLoading = false
    this._messageService.create('error', msg, {
      nzDuration: duration,
    })
  }

  /**
   * Callback para mostrar notificacion al usuario.
   *
   * @private
   * @param {{ type: string; title: string; body: string }} msg
   * @param {number} delay
   * @memberof UsersAdminComponent
   */
  private showNotification(
    msg: { type: string; title: string; body: string },
    delay: number,
  ): void {
    setTimeout(() => {
      this._notificationService.create(msg.type, msg.title, msg.body, { nzDuration: TIME_SHOW_MSG })
    }, delay)
  }

  /**
   * Metodo para realizar un busquede da un usuario mediante un filtro asincrono.
   *
   * @memberof UsersAdminComponent
   */
  public async searchUserByFilter() {
    this.isUsersReady = false
    this.searchingByFilter = true
    if (this.corporateSelected) {
      this.getUsers(this.corporateSelected.id, this.textFilter)
    } else {
      this.getUsers(null, this.textFilter)
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.unsubscribe()
  }
}
