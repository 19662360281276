import { createReducer, on } from '@ngrx/store'
import * as states from '../bar-buttons/actions'

export interface State {
  id?: number
  label?: string
  type?: string
  icon?: any
  selected?: boolean
  typeView?: string,
  changeView?: boolean
}

export const initialState: State = {
  type: 'compliance',
  typeView: null,
}

const _barButtonsReducer = createReducer(
  initialState,
  on(states.buttonClick, (state, { action }) => {
    return { ...action }
  }),
  on(states.resetBarButtonsState, () => {
    return initialState
  }),
)

export function barButtonsReducer(state, action) {
  return _barButtonsReducer(state, action)
}

export const getBarButtons = (action: State) => action
