import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core'
import { timeSince } from '../extensions/controls.extension'

@Pipe({
  name: 'setDateView',
})
export class SetDateViewPipe implements PipeTransform {

  constructor(@Inject(LOCALE_ID) private locale: string) {
  }

  transform(date: string): string {
    const time = new Date(date).toUTC()
    return timeSince(time, this.locale)
  }
}
