<div class='topbar'>
  <div class='logoContainer'>
    <div class='logo'>
      <svg height='58px' version='1.1' viewBox='0 0 200 58' width='200'
           xmlns='http://www.w3.org/2000/svg'>
        <image href='./assets/images/brands/logo-cloudpq.svg'
               height='58' width='200' />
      </svg>
    </div>
  </div>
  <div>
    <ul nz-menu nzMode='horizontal'>
      <ng-container *ngTemplateOutlet='menuTop; context: {$implicit: menuTopDataActivated}'>
      </ng-container>
      <ng-template #menuTop let-menuTopDataActivated>
        <ng-container *ngFor='let item of menuTopDataActivated'>
          <li nz-menu-item *ngIf='item.url && !item.disable' [routerLink]='[item.url]'
              [queryParamsHandling]='item.paramsHandling'
              [nzSelected]='item.selected'>
            <span class='title'>
              {{ item.title | translate }}
            </span>
          </li>
        </ng-container>
      </ng-template>
    </ul>
  </div>
  <div class='d-flex flex-row flex-nowrap align-items-center'>
    <!-- <vb--topbar-language-switcher></vb--topbar-language-switcher> -->
    <widget-alert-menu *ngIf='currentUser.roles[0].slug !== rolesSlugs.admin_diram'
                       [alerts]='alertsNotification' (eventClick)='goToEventAction($event)'></widget-alert-menu>
    <widget-topbar-settings-menu [user]='currentUser'></widget-topbar-settings-menu>
    <vb-topbar-user-menu></vb-topbar-user-menu>
  </div>
</div>
