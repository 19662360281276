import { Injectable } from '@angular/core'
import { CrudService } from '../shared/crud.service'
import { EventsRepository } from './events.respository'

/**
 * Servicios para obtener informacion referentes a eventos.
 *
 * @export
 * @class EventsService
 * @extends {CrudService}
 */
@Injectable({
  providedIn: 'root',
})
export class EventsService extends CrudService {
  /**
   * Creates an instance of EventsService.
   * @param {EventsRepository} _eventsRepository
   * @memberof EventsService
   */
  constructor(protected _corporatesRepository: EventsRepository) {
    super(_corporatesRepository)
  }
}
