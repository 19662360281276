import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { AuthService } from '../../../services/auth/auth.service'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { map } from 'rxjs/operators'
import { PasswordReset } from '../../../interfaces/password-reset'
import { MustMatch } from '../../../extensions/must-match'

/**
 * Funcion para mostar el loader.
 *
 */
declare function loader_show()

/**
 * Funcionar para inciar la vista sin loader.
 *
 */
declare function init_plugins()

/**
 * Componente para la creacion de la vista para reiniciar la contraseña.
 *
 * @export
 * @class ResetPasswordRequestComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'vb-reset-password-request',
  templateUrl: './reset-password-request.component.html',
})
export class ResetPasswordRequestComponent implements OnInit {
  /**
   * Form Group para los inputs de las vistas.
   *
   * @type {FormGroup}
   * @memberof ResetPasswordRequestComponent
   */
  public form: FormGroup
  /**
   * Variable de control para los estado de carga de envio de informacion.
   *
   * @type {boolean}
   * @memberof ResetPasswordRequestComponent
   */
  public loading: boolean = false
  /**
   * Variable para almacernar el correo del usuario obtenido de query param.
   *
   * @private
   * @type {string}
   * @memberof ResetPasswordRequestComponent
   */
  private userEmail: string
  /**
   * Variable para almacenar el token temporal del usuario.
   *
   * @private
   * @type {string}
   * @memberof ResetPasswordRequestComponent
   */
  private tokenTmp: string

  /**
   * Creates an instance of ResetPasswordRequestComponent.
   * @param {FormBuilder} _fb
   * @param {ActivatedRoute} _route
   * @param {Router} _router
   * @param {AuthService} _authService
   * @param {NzNotificationService} _notificationService
   * @memberof ResetPasswordRequestComponent
   */
  constructor(
    private _fb: FormBuilder,
    private _route: ActivatedRoute,
    private _router: Router,
    private _authService: AuthService,
    private _notificationService: NzNotificationService,
  ) {
    loader_show()
  }

  /**
   * onInit.
   *
   * @memberof ResetPasswordRequestComponent
   */
  ngOnInit(): void {
    this.tokenTmp = this._route.snapshot.paramMap.get('code')
    this._route.queryParams
      .pipe(
        map(resp => {
          if (!resp?.email) {
            throw new Error('No existe correo electrónico')
          }
          return resp
        }),
      )
      .subscribe(
        params => {
          this.userEmail = params.email
          init_plugins()
        },
        error => {
          this._router.navigate(['auth/login']).then(() => {
            init_plugins()
            this._notificationService.error('¡Opps! Ha ocurrido un error', `${error.message}`, {
              nzDuration: 3500,
            })
          })
        },
      )

    this.form = this._fb.group(
      {
        newPassword: [null, [Validators.required, Validators.minLength(6)]],
        confirmPassword: [null, [Validators.required]],
      },
      {
        validator: MustMatch('newPassword', 'confirmPassword'),
      },
    )
  }

  /**
   * Callback para enviar la informacion del formulario a los servicios.
   *
   * @memberof ResetPasswordRequestComponent
   */
  public submitForm(): void {
    this.newPassword.markAsDirty()
    this.newPassword.updateValueAndValidity()

    this.confirmPassword?.markAsDirty()
    this.confirmPassword?.updateValueAndValidity()

    if (this.newPassword.invalid || this.confirmPassword.invalid || !this.userEmail) {
      return
    }

    this.loading = true
    const payload: PasswordReset = {
      password: this.newPassword.value,
      cpassword: this.confirmPassword.value,
      email: this.userEmail,
    }

    this._authService.resetPassword(payload, this.tokenTmp).subscribe(
      resp => {},
      error => {
        this.loading = false
        this._notificationService.error('¡Opps! Ha ocurrido un error', `${error.message}`, {
          nzDuration: 3500,
        })
      },
      () => {
        this.loading = false
        this._router.navigate(['auth/login']).then(() => {
          init_plugins()
          this._notificationService.success(
            '¡Contraseña restaurada exitosamente!',
            'Inicie sesión para verificar los cambios',
            {
              nzDuration: 3500,
            },
          )
        })
      },
    )
  }

  /**
   * Getter para obtener el control de la contraseña.
   *
   * @readonly
   * @memberof ResetPasswordRequestComponent
   */
  public get newPassword() {
    return this.form.controls.newPassword
  }

  /**
   * Getter para obtener el control de la confirmacion de contraseña.
   *
   * @readonly
   * @memberof ResetPasswordRequestComponent
   */
  public get confirmPassword() {
    return this.form.controls.confirmPassword
  }
}
