<div class='pt-5'>
  <div class='card boxContainer mt-2'>
    <div class='text-dark font-size-24 font-weight-bold mb-3'>
      Recuperación de contraseña
    </div>
    <div class='text-dark font-size-15 w-400 mt-2 mb-4'>
      Crea una nueva contraseña que reemplace a la anterior para poder acceder a la plataforma de nuevo.
    </div>
    <form (ngSubmit)='submitForm()' [formGroup]='form' [nzLayout]="'vertical'" nz-form role='form'>
      <nz-form-item>
        <nz-form-label><span style='font-weight: bold'>Nueva contraseña</span>
        </nz-form-label>
        <nz-form-control nzErrorTip='¡Introduce tu contraseña nueva!'>
          <input formControlName='newPassword' nz-input placeholder='Nueva contraseña' type='password' />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label><span style='font-weight: bold'>Confirmar  contraseña</span></nz-form-label>
        <nz-form-control [nzErrorTip]='confirmPassword.errors | errorsLabelsConfirmPassword'>
          <input formControlName='confirmPassword' nz-input placeholder='Confirmar contraseña' type='password' />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control>
          <button button-theme='inverter' [nzLoading]='loading' class='text-center w-100' nz-button nzType='primary'>
            <ng-container *ngIf='!loading'>
              <strong>Recuperar contraseña</strong>
            </ng-container>
          </button>
        </nz-form-control>
      </nz-form-item>
    </form>
    <span class='text-center'>
        <a class='vb__utils__link' href='https://www.diram.com/aviso-de-privacidad?lang=en' target='_blank'>Términos del servicio y Política de Privacidad</a>
    </span>
  </div>
</div>
