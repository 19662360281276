import { catchError } from 'rxjs/operators'
import { AVAILABLE_TYPE_USERS } from './../../config/enviroment'
import { ParamRequest } from 'src/app/interfaces/interfaces.index'
import { UsersRepository } from './users.repository'
import { Injectable } from '@angular/core'
import { CrudService } from '../shared/crud.service'
import { Observable, throwError } from 'rxjs'
import { environment } from '../../../environments/environment'
import { User } from '../../models/user.model'

/**
 * Servicio para usuarios.
 *
 * @export
 * @class UsersService
 * @extends {CrudService}
 */
@Injectable({
  providedIn: 'root',
})
export class UsersService extends CrudService {
  /**
   * Servicios para usuarios UsersService.
   * @param {UsersRepository} _userRepository Repositorio de usuarios.
   * @memberof UsersService
   */
  constructor(protected _userRepository: UsersRepository) {
    super(_userRepository)
  }

  /**
   * Servicio para obtener usuario por tipo si es interno o externo.
   *
   * @param {number} [corporateId=null]
   * @param {Map<string, any>} [paramsQuery=this._commonService.setParamNotPaginable()]
   * @return {*}  {Observable<User[]>}
   * @memberof UsersService
   */
  public getUsers(corporateId: number = null, searchName: string = ''): Observable<User[]> {
    const paramsRequest: ParamRequest[] = [
      {param: 'user_type', value: AVAILABLE_TYPE_USERS.internal},
      {param: 'search-name', value: `*${searchName}*`},
      {param: 'relationships', value: 'roles'}
    ]
    if (corporateId) {
      paramsRequest[0].value = AVAILABLE_TYPE_USERS.external
      paramsRequest.push(...[{param: 'id_corporate', value: corporateId}])
    }
    return this._userRepository.getUsers(paramsRequest)
  }

  public updateUserTokenNotificationPush(userId: number, token: string): Observable<any> {
    return this._userRepository.updateUserTokenNotificationPush(userId, token)
  }

  public setImage(userId: number, image: File): Observable<any> {
    return this._userRepository.setImage(userId, image)
  }

  public getImage(userId: number): Observable<string> {
    return this._userRepository.getImage(userId)
  }
}
