import { createAction, props } from '@ngrx/store'
import { CDRVersionReport } from '../../config/enviroment'
import { Report } from '../../models/report.model'

export const ADD_REPORT_PLANT = '[Report] Añadir reporte a planta.'
export const CREATE_REPORT_SUCCESS = '[Report] Reporte añadido'
export const CREATE_REPORT_FAILED = '[Report] Falla al añadir reporte'

export const UPDATE_REPORT = '[Report] Actualizar estado de reporte.'
export const UPDATE_REPORT_SUCCESS = '[Report] Reporte actualizado.'
export const UPDATE_REPORT_FAILED = '[Report] Falla al actualizar reporte.'


export const DELETE_REPORT = '[Report] Eliminar Reporte'
export const DELETE_REPORT_SUCCESS = '[Report] Reporte eliminado'
export const DELETE_REPORT_FAILURE = '[Report] Fallo eliminar reporte'

export const GET_REPORTS_PLANT = '[Report] Obtener reportes de planta por parametros'
export const LOAD_REPORTS_SUCCESS = '[Report] Reportes de planta recuperados'
export const LOAD_REPORTS_FAILURE = '[Report] Reportes de planta no encontrados'

export const DOWNLOAD_REPORT = '[Report] Descargar report'
export const DOWNLOAD_TERMINATED = '[Report] Descargar terminada'

export const CANCEL_REQUEST = '[Report] Solicitud cancelada'

export const addReportPlant = createAction(ADD_REPORT_PLANT, props<{ corporateId: number, plantId: number, userId: number, params: any }>())
export const createReportSuccess = createAction(CREATE_REPORT_SUCCESS, props<{ report: Report }>())
export const createReportFailed = createAction(CREATE_REPORT_FAILED, props<{ any }>())

export const updateReport = createAction(UPDATE_REPORT, props<{ reportId: number }>())
export const updateReportSuccess = createAction(UPDATE_REPORT_SUCCESS, props<{ report: Report }>())
export const updateReportFailed = createAction(UPDATE_REPORT_FAILED, props<{ any }>())

export const deleteReport = createAction(DELETE_REPORT, props<{ report: Report }>())
export const deleteReportSuccess = createAction(DELETE_REPORT_SUCCESS, props<{ reportId: number }>())
export const deleteReportFailure = createAction(DELETE_REPORT_FAILURE, props<{ any }>())

export const getReportsPlant = createAction(GET_REPORTS_PLANT, props<{
  corporateId: number, plantId: number,
  cdrVersion: CDRVersionReport, typeReport: string, params: any
}>())
export const loadReportsSuccessful = createAction(LOAD_REPORTS_SUCCESS, props<{ reports: Report[] }>())
export const loadReportsUnsuccessful = createAction(LOAD_REPORTS_FAILURE, props<{ any }>())

export const downloadReport = createAction(DOWNLOAD_REPORT, props<{ reportId: number, cdrVersion: number, typeReport: string }>())
export const downloadTerminated = createAction(DOWNLOAD_TERMINATED, props<{ any }>())

export const cancelRequest = createAction(CANCEL_REQUEST)
