import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { URL_API_V2 } from 'src/app/config/enviroment'
import { ParamRequest } from 'src/app/interfaces/interfaces.index'
import { CommonService } from './common.service'

/**
 * Servicio base para obtener todos los metodos comunes de un CRUD.
 *
 * @export
 * @abstract
 * @class CrudRepository
 */
export abstract class CrudRepository {

    /**
     * Key of value packet.
     *
     * @private
     * @type {string}
     * @memberof CrudRepository
     */
    private _keyData: string = 'Data'

    /**
     * Creates an instance of CrudRepository.
     * @param {HttpClient} _httpClient Servicios de cliente http.
     * @param {CommonService} _commonService Servicios comunes en la plataforma.
     * @param {string} _endpointService Cadena de identificador para los prefijos de los endpoints.
     * @param {string} _baseUrl Cadena para especificar la url base de los endpoints.
     * @memberof CrudRepository
     */
    protected constructor(
        protected _httpClient: HttpClient,
        protected _commonService: CommonService,
        protected _endpointPrefix: string,
        protected _baseUrl: string = URL_API_V2
    ) {
    }

    /**
     * Envía al API server una petición para obtener todos los elementos del tipo solicitado
     * @abstract
     * @param {Map<string, any>} paramsQuery
     * @returns {Observable<any>}
     * @memberof CrudRepository
     */
    public getAllElements(
        paramsQuery: ParamRequest[] = null,
        paginable: boolean = true, page: number = 1, perPage: number = 10
    ): Observable<any[]> {
        const url = `${this._baseUrl}/${this._endpointPrefix}`
        return this._commonService.handlerGetElements(url, paramsQuery, this._keyData, true, paginable, page, perPage)
    }

    /**
     * Servicio para obtener un elemento por su identificador.
     *
     * @param {number} id Identificador del elemento.
     * @return {*}  {Observable<any>} Devuelve la respuesta del servidor.
     * @memberof CrudRepository
     */
    public getElementById(id: number | string, paramsQuery: ParamRequest[] = null): Observable<any> {
        const url = `${this._baseUrl}/${this._endpointPrefix}/${id}`
        return this._commonService.handlerGetElements(url, paramsQuery, this._keyData)
    }

    /**
     * Servicio para crear un nuevo registro del elmento pasado en data.
     *
     * @param {*} data Instancia o elemento a crear un registro.
     * @return {*}  {Observable<any>} Devuelve la instancia o elemento creado con su identificador.
     * @memberof CrudRepository
     */
    public createdElement(data: any): Observable<any> {
        const url = `${this._baseUrl}/${this._endpointPrefix}`
        if (data.id) {
            delete data.id
        }
        return this._commonService.handlerPostElement(url, null, data, this._keyData)
    }

    /**
     * Servicio para actualizar un elemento mediante su identificador de registro.
     *
     * @param {*} data Informacion de la instancia a modificar.
     * @param {(number | string)} id Identificador del elemento.
     * @return {*}  {Observable<any>} Devuelve la respuesta de la instancia o elemento con la informacion actualizada.
     * @memberof CrudRepository
     */
    public updateElement(data: any, id: number | string): Observable<any> {
        const url = `${this._baseUrl}/${this._endpointPrefix}/${id}`
        return this._commonService.handlerPutElement(url, null, data, this._keyData)
    }

    /**
     * Servicio para actualizar un elemento mediante su identificador de registro usando el metodo patch.
     *
     * @param {*} data Informacion de la instancia a modificar.
     * @param {(number | string)} id Identificador del elemento.
     * @return {*}  {Observable<any>} Devuelve la respuesta de la instancia o elemento con la informacion actualizada.
     * @memberof CrudRepository
     */
    public updateElementPatch(data: any, id: number | string): Observable<any> {
        const url = `${this._baseUrl}/${this._endpointPrefix}/${id}`
        return this._commonService.handlerPatchElement(url, null, data, this._keyData)
    }

    /**
     * Servicio para eliminar el registro de un elemento o instancia mediante su identificador.
     *
     * @param {number} id Identificador del elemento.
     * @return {*}  {Observable<any>} Devuelve la respuesta del estatus del servidor.
     * @memberof CrudRepository
     */
    public deleteElement(id: number): Observable<any> {
        const url = `${this._baseUrl}/${this._endpointPrefix}/${id}`
        return this._commonService.handlerDeleteElement(url, null, this._keyData)
    }
}
