import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { StateNotification } from 'src/app/interfaces/interfaces.index'
import { Notification } from 'src/app/models/models.index'
import { TYPE_NOTIFICATION_USER } from '../../../config/enviroment'

/**
 * Componente para opciones de notificaciones, incluye los switch y la informacion de la persona autorizada.
 *
 * @export
 * @class NotificationOptionsCardComponent
 * @implements {OnInit}
 * @implements {OnChanges}
 */
@Component({
  selector: 'vb-notification-options-card',
  templateUrl: './notification-options-card.component.html',
  styleUrls: ['./notification-options-card.component.scss'],
})
export class NotificationOptionsCardComponent implements OnInit, OnChanges {
  /**
   * Persona autorizada
   *
   * @type {Person}
   * @memberof NotificationOptionsCardComponent
   */
  @Input() notification: Notification
  /**
   * Variable de control para estado de carga.
   *
   * @type {boolean}
   * @memberof NotificationOptionsCardComponent
   */
  @Input() isLoading: boolean = false
  /**
   * Variable de control para saber si es el ultimo elemento de la lista de personas autorizadas.
   *
   * @type {boolean}
   * @memberof NotificationOptionsCardComponent
   */
  @Input() isLast: boolean
  /**
   * Event emitter para el cambio del estado de los switch
   *
   * @type {EventEmitter<StateNotification>}
   * @memberof NotificationOptionsCardComponent
   */
  @Output() statusChanged: EventEmitter<StateNotification> = new EventEmitter<StateNotification>()
  /**
   * Persona seleccionada actualmente.
   *
   * @type {Person}
   * @memberof NotificationOptionsCardComponent
   */
  public notificationSelect: Notification = null
  /**
   * Swicth recientemente relecionado.
   *
   * @type {string}
   * @memberof NotificationOptionsCardComponent
   */
  public currentSwitch: string

  /**
   * Tipos de notifiaciones que se pueden tener en el portal.
   *
   * @type {{ sms: string, mail: string }}
   * @memberof NotificationOptionsCardComponent
   */
  public typeNotification: { sms: string; mail: string } = TYPE_NOTIFICATION_USER

  /**
   * Creates an instance of NotificationOptionsCardComponent.
   * @memberof NotificationOptionsCardComponent
   */
  constructor() {}

  /**
   * OnInit
   *
   * @memberof NotificationOptionsCardComponent
   */
  ngOnInit(): void {}

  /**
   * OnChanges
   *
   * @param {SimpleChanges} changes
   * @memberof NotificationOptionsCardComponent
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.isLoading.currentValue && changes.isLoading.previousValue) {
      this.notificationSelect = null
      this.currentSwitch = null
    }
  }

  /**
   * Callback para cambio del estado de switch emite el estado al componente padre.
   *
   * @param {boolean} status
   * @param {Notification} notification
   * @param {string} type
   * @memberof NotificationOptionsCardComponent
   */
  changeStatus(status: boolean, notification: Notification, type: string) {
    this.notificationSelect = notification
    this.currentSwitch = type
    const stateChanged: StateNotification = { status, notification, type }
    this.statusChanged.emit(stateChanged)
  }
}
