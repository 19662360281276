import { PlantsRepository } from './plants.repository'
import { Injectable } from '@angular/core'
import { CrudService } from '../shared/crud.service'
import { Observable } from 'rxjs'
import { Plant } from '../../models/models.index'
import { map } from 'rxjs/operators'

/**
 * Servicio para usuarios.
 *
 * @export
 * @class PlantsService
 * @extends {CrudService}
 */
@Injectable({
  providedIn: 'root',
})
export class PlantsService extends CrudService {
  /**
   * Servicios para plantas PlantsService
   * @param {PlantsRepository} _plantsRepository Repositorio de usuarios.
   * @memberof PlantsService
   */
  constructor(protected _plantsRepository: PlantsRepository) {
    super(_plantsRepository)
  }

  /**
   * Servicio para obtener las plantas relacionadas a un cliente.
   * @param {number} corporateId Identificador del cliente
   */
  public getPlantsByCorporate(corporateId: number): Observable<Plant[]> {
    return this._plantsRepository.getPlantsByCorporate(corporateId).pipe( map(
       response => response.map( r => r['plant'])))
  }

  /**
   * Servicio para guardar una imagen de perfil para una planta dada.
   *
   * @param {number} plantId
   * @param {File} image
   * @return {*}  {Observable<any>}
   * @memberof PlantsService
   */
  public setImage(plantId: number, image: File): Observable<any> {
    return this._plantsRepository.setImage(plantId, image)
  }

  /**
   * Servicio para obtener una imagen de perfil de una planta dada.
   *
   * @param {number} plantId
   * @return {*}  {Observable<any>}
   * @memberof PlantsService
   */
  public getImage(plantId: number): Observable<any> {
    return this._plantsRepository.getImage(plantId)
  }
}
