import { Component } from '@angular/core'
import { RouterOutlet } from '@angular/router'
import { select, Store } from '@ngrx/store'
import * as Reducers from 'src/app/store/reducers'
import { slideFadeinUp, slideFadeinRight, zoomFadein, fadein } from '../router-animations'

/**
 * Componente layout para base de los componentes de autorización a la plataforma
 *
 * @export
 * @class LayoutAuthComponent
 */
@Component({
  selector: 'layout-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  animations: [slideFadeinUp, slideFadeinRight, zoomFadein, fadein],
})
export class LayoutAuthComponent {
  /**
   * Texto para el nombre de la marca.
   *
   * @type {String}
   * @memberof LayoutAuthComponent
   */
  logo: String
  /**
   * Estado si el topbar es gris.
   *
   * @type {Boolean}
   * @memberof LayoutAuthComponent
   */
  isGrayTopbar: Boolean
  /**
   * Estado si el layout los elementos tienen sombras.
   *
   * @type {Boolean}
   * @memberof LayoutAuthComponent
   */
  isCardShadow: Boolean
  /**
   * Estado si el layout los elementos tienen bordes cuadrados.
   *
   * @type {Boolean}
   * @memberof LayoutAuthComponent
   */
  isSquaredBorders: Boolean
  /**
   * Estado si el layout tiene bordes.
   *
   * @type {Boolean}
   * @memberof LayoutAuthComponent
   */
  isBorderless: Boolean
  /**
   * Color del layout.
   *
   * @type {String}
   * @memberof LayoutAuthComponent
   */
  authPagesColor: String
  /**
   * Tipo de animacion.
   *
   * @type {string}
   * @memberof LayoutAuthComponent
   */
  routerAnimation: string
  /**
   * Año actual.
   * @type {number}
   * @memberof LayoutAuthComponent
   */
  date: number = new Date().getFullYear()

  /**
   * Creates an instance of LayoutAuthComponent.
   * @param {Store<any>} store
   * @memberof LayoutAuthComponent
   */
  constructor(private store: Store<any>) {
    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
      this.logo = state.logo
      this.isGrayTopbar = state.isGrayTopbar
      this.isCardShadow = state.isCardShadow
      this.isSquaredBorders = state.isSquaredBorders
      this.isBorderless = state.isBorderless
      this.authPagesColor = state.authPagesColor
      this.routerAnimation = state.routerAnimation
    })
  }

  /**
   * Funcion para realizar la animacion de cambio de vista.
   *
   * @param {RouterOutlet} outlet Ruta activa
   * @param {string} animation Animacion activada.
   * @return {*} Si realiza la animación.
   * @memberof LayoutAuthComponent
   */
  routeAnimation(outlet: RouterOutlet, animation: string) {
    const mapAnimation = {
      'slide-fadein-up': 'slideFadeinUp',
      'slide-fadein-right': 'slideFadeinRight',
      fadein: 'zoomFadein',
      'zoom-fadein': 'fadein',
    }
    if (animation === mapAnimation[this.routerAnimation]) {
      return outlet.isActivated && outlet.activatedRoute.routeConfig.path
    }
  }
}
