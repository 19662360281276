<section>
  <nz-card style='height: calc(100vh - 234px); position: relative; overflow: auto;'>
    <ng-container *ngIf='plantSelected'>
      <div class='row mb-2' *ngIf='currentUser | hideMenu'>
        <div class='col-md-6 col-12'>
          <div class='w-100 m-0 p-0'>
            <h3 class='title-section'>{{ 'profile.electric-secctionTitle' | translate }}</h3>
          </div>
        </div>
        <div *ngIf='!isLoadingPlantData' class='col-md-6 col-12 d-flex justify-content-end align-items-end'>
          <button class='w-100 bottom-edit btn btn-lg' (click)='goToEditPlant()'>
            <i nz-icon nzType='edit' nzTheme='outline'></i>
            Editar planta
          </button>
        </div>
      </div>

      <ng-container *ngIf='!isLoadingPlantData && plantInfoReady else statusView'>
        <div class='row mb-3'>
          <div class='col-12'>
            <nz-collapse>
              <nz-collapse-panel
                [nzHeader]="'Identificación del punto de carga'"
                [nzActive]='true'
              >
                <div class='row'>
                  <div class='col-12'>
                    <i nz-icon nzType='thunderbolt' nzTheme='outline'></i>
                    <span class='m-0 ml-1 font-weight-bold'>Identificación del centro de carga</span>
                  </div>
                </div>
                <div class='row'>
                  <div class='col-12 col-md-6'>
                    <ng-container *ngFor='let item of questionSectionOne; let i=index;'>
                      <div class='row'>
                        <div class='col-12'
                             *ngIf='formGram[item.formControlName] || formGram[item.formControlName] == 0'>
                          <span class='text-title'>{{ item.question }}:&nbsp;</span>
                          <span class='text-value'>{{ formGram[item.formControlName] }}</span>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <div class='col-12 col-md-6'>
                    <ng-container *ngFor='let item of questionSectionTwo; let i=index;'>
                      <div class='row'>
                        <div class='col-12'>
                          <span class='text-title'>{{ item.question }}:&nbsp;</span>
                          <span
                            class='text-value'>{{ item.formControlName | setValueByParameter : formGram : calculatedParameters }}</span>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </nz-collapse-panel>
            </nz-collapse>
          </div>
        </div>
        <div class='row mb-3' *ngIf='transformers.length > 0'>
          <div class='col-12'>
            <nz-collapse>
              <nz-collapse-panel
                [nzHeader]="'Información de transformadores'"
                [nzActive]='true'
              >
                <div class='row'>
                  <ng-container *ngFor='let transform of transformers; let i = index'>
                    <div class='col-12 col-md-6 col-lg-3 mb-3'>
                      <p>
                        <i class='mr-2' nz-icon nzType='thunderbolt' nzTheme='outline'></i>
                        <span class='text-title'>
                          Transformador {{ i + 1 }}
                          <button *ngIf='currentUser | hideMenu' nz-button nzDanger nzShape='circle' nzSize='small'
                                  (click)='deleteTransformer(transform)'>
                            <i nz-icon nzType='delete' nzTheme='outline'></i>
                          </button>
                        </span>
                      </p>
                      <div class='d-flex flex-column'>
                        <div>
                          <span class='text-p p-normal'>Potencia nominal (MVA): </span>
                          <span class='text-p p-light'>{{ transform.nominal_power.toString() | textVerify }}</span>
                        </div>
                        <div>
                          <span class='text-p p-normal'>Voltaje en alta (kV): </span>
                          <span class='text-p p-light'>{{ transform.high_voltage_plate.toString() | textVerify }}</span>
                        </div>
                        <div>
                          <span class='text-p p-normal'>Voltaje en baja (kV): </span>
                          <span class='text-p p-light'>{{ transform.low_voltage_plate.toString() | textVerify }}</span>
                        </div>
                        <div>
                          <span class='text-p p-normal'>Medido en: </span>
                          <span class='text-p p-light'>{{ transform.measured_at | textVerify }}</span>
                        </div>
                        <div>
                          <span class='text-p p-normal'>Z+ (%): </span>
                          <span class='text-p p-light'>{{ transform.z_p | textVerify }}</span>
                        </div>
                        <div>
                          <span class='text-p p-normal'>Z0 (%): </span>
                          <span class='text-p p-light'>{{ transform.z_0 | textVerify }}</span>
                        </div>
                        <div>
                          <span class='text-p p-normal'>Arreglo vectorial (1-12): </span>
                          <span class='text-p p-light'>{{ transform.vectorial_array.toString() | textVerify }}</span>
                        </div>
                        <div>
                          <span class='text-p p-normal'>Tipo de carga: </span>
                          <span class='text-p p-light'>{{ transform.type_load | textVerify }}</span>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </nz-collapse-panel>
            </nz-collapse>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </nz-card>
</section>

<ng-template #statusView>
  <ng-container *ngIf='!isLoadingPlantData else loaderCircles'>
    <ng-container *ngTemplateOutlet='errorMessage'></ng-container>
  </ng-container>
</ng-template>

<ng-template #loaderCircles>
  <widget-loader-circles></widget-loader-circles>
</ng-template>

<ng-template #errorMessage>
  <widget-wrap-message-info [firstText]='"Esta planta no tiene información."'>
  </widget-wrap-message-info>
</ng-template>
