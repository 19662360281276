import { CalculatedParametersRepository } from './calculated-parameters.repository'
import { Injectable } from '@angular/core'
import { CrudService } from '../shared/crud.service'
import { Observable } from 'rxjs'
import { CalculatedParameters } from '../../models/models.index'

/**
 * Servicio para obtener la informacion de los parametros electricos calculados mediante la API.
 *
 * @export
 * @class CalculatedParametersService
 * @extends {CrudService}
 */
@Injectable({
  providedIn: 'root',
})
export class CalculatedParametersService extends CrudService {
  /**
   * Creates an instance of CalculatedParametersService.
   * @param {CalculatedParametersRepository} _calculatedParametersRepository
   * @memberof CalculatedParametersService
   */
  constructor(protected _calculatedParametersRepository: CalculatedParametersRepository) {
    super(_calculatedParametersRepository)
  }

  /**
   * Funcion para servicio para obtener los transformadores de una empresa.
   *
   * @param {number} formGramId Identificador de la planta.
   * @return {Observable<FormGram>}  {Observable<FormGram>} Observable con la respuesta de nuestro peticion.
   * @memberof TransformerService
   */
  public getParametersByFormGram(formGramId: number): Observable<CalculatedParameters[]> {
    return this._calculatedParametersRepository.getParametersByFormGram(formGramId)
  }
}
