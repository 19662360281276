import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import * as Reducers from '../../store/reducers'
import * as SettingsActions from './actions'


@Injectable({
    providedIn: 'root'
})
export class SettingsFacade {
    settingsSelector$ = this._store.select(Reducers.getSettings)

    constructor(private _store: Store<any>) {}

    setState(payload: object): void {
        this._store.dispatch(new SettingsActions.SetStateAction(payload))
    }

    changeSetting(payload: object): void {
        this._store.dispatch(new SettingsActions.ChangeSettingAction(payload))
    }

    changeSettingBulk(payload: object): void {
        this._store.dispatch(new SettingsActions.ChangeSettingBulkAction(payload))
    }
}
