export default {
  'topBar.issuesHistory': 'Issues',
  'topBar.projectManagement': 'Projects',
  'topBar.typeToSearch': 'Search...',
  'topBar.findPages': 'Find pages...',
  'topBar.actions': 'Actions',
  'topBar.status': 'Status',
  'topBar.profileMenu.hello': 'Hello',
  'topBar.profileMenu.billingPlan': 'Billing Plan',
  'topBar.profileMenu.role': 'Role',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Phone',
  'topBar.profileMenu.myProfile': 'My profile',
  'topBar.profileMenu.contact': 'Contact us',
  'topBar.profileMenu.editProfile': 'Edit Profile',
  'topBar.profileMenu.logout': 'Logout',
  'topBar.settingMenu.profile-plant': 'Profile plant',
  'topBar.settingMenu.electrical-plant': 'Electrical Plant Information',
  'topBar.settingMenu.notifications': 'Notification settings',
  'topBar.settingMenu.users': 'Users management',
  'alertMenu.seeAll': 'See all',
  'alertMenu.emptyNotification': 'There not notifications avaibles.',
  'topBar.menuTop.home': 'Home',
  'topBar.menuTop.managers': 'Management',
  'topBar.menuTop.reports': 'Reports',
  'topBar.menuTop.events': 'Events',
  'home.welcome': 'Hi',
  'home.welcomePhrase': 'What decision will we make today?',
  'sideBar.menu.targetPoints': 'MONITORED POINTS',
  'footer.policyPrivacy': 'Privacy policies',
  'auth.topBar.seeDemo': 'See demo',
  'auth.yourEmail': 'Your email',
  'auth.yourPassword': 'Your password',
  'auth.forgotPassword': 'Forget your password?',
  'auth.actionTermsAndPoli': 'Terms of Service and Privacy Policy',
  'auth.title.sigIn': 'Login',
  'auth.login.button.sigIn': 'Sign in',
  'auth.login.title.banner': 'Your vital signs in the hands of specialists',
  'auth.login.botton.quotes': 'Ask for your quote',
  'auth.recoveryPassword.title': 'Recovery your password',
  'auth.recoveryPassword.subtitle': 'Write your email and we will send you a link to create a new password for your account.',
  'auth.recoveryPassword.yourEmail': 'Write your email',
  'auth.recoveryPassword.error.yourEmail': 'Enter your password!',
  'auth.recoveryPassword.btnPassword': 'Recover password',
  'auth.recoveryPassword.returnLoginOne': 'Do you already have an account?',
  'profile.electric-secctionTitle': 'Información eléctrica de planta',
  'profile.bussiness-secctionTitle': 'Perfil de planta',
  'notifications.settings-secctionTitle': 'Configuración de notificaciones',
  'usersAdmin.secctionTitle': 'Administración de usuarios',
  'usersAdmin.internals.title': 'internal',
  'usersAdmin.externals.title': 'external',
  'profile.user-sectionTitle': 'My profile',
  'profile.editTxt-btn': 'Edit profile',
  'notification.text.date.day': 'days'
}
