import { Injectable } from '@angular/core'
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router'
import { Store, select } from '@ngrx/store'
import * as Reducers from '../../store/reducers'
import { Observable } from 'rxjs'
import { Rol } from 'src/app/models/rol.model'
import { environment } from 'src/environments/environment'
import { ROLES_SLUGS } from 'src/app/config/enviroment'

/**
 * Guardiar para limitar rutas para usuarios que no permiten editar vistas.
 *
 * @export
 * @class EditorUserGuard
 * @implements {CanActivate}
 */
@Injectable({
  providedIn: 'root',
})
export class EditorUserGuard implements CanActivate {
  /**
   * Roles del usuario.
   *
   * @private
   * @type {Rol[]}
   * @memberof EditorUserGuard
   */
  private rolesUser: Rol[]
  /**
   * Creates an instance of EditorUserGuard.
   * @param {Store<any>} store
   * @param {Router} _router
   * @memberof EditorUserGuard
   */
  constructor(private store: Store<any>, private _router: Router) {
    this.store.pipe(select(Reducers.getUser)).subscribe(state => {
      this.rolesUser = state.roles
    })
  }

  /**
   * CanActivate
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @return {*}  {(Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree)}
   * @memberof EditorUserGuard
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (
      this.rolesUser[0].slug === ROLES_SLUGS.admin_diram ||
      this.rolesUser[0].slug === ROLES_SLUGS.admin
    ) {
      return true
    }

    if (!environment.production) {
      console.log('Bloqueado por Guardian Admin')
    }

    this._router.navigate(['auth/404']).then()
    return false
  }
}
