<section>
  <div class='container-fluid'>
    <nz-card style='height: calc(100vh - 234px); position: relative; overflow: auto;'>
      <div *ngIf='!isLoadingFile; else loaderFile'>
        <div class='border-bottom mb-3'>
          <div class='info-options'>
            <nz-upload
              class='avatar-uploader w-auto'
              [nzAccept]='"image/png, image/jpeg"'
              [nzBeforeUpload]='beforeUpload'
              [nzCustomRequest]='customRequest'
              nzName='avatar'
              nzListType='picture-card'
              [nzShowUploadList]='false'
              (nzChange)='handleChange($event)'>
              <ng-container *ngIf='!avatarUrl'>
                <i class='upload-icon' nz-icon [nzType]="'plus'"></i>
                <div class='ant-upload-text'></div>
              </ng-container>
              <img *ngIf='avatarUrl' [src]='avatarUrl' style='width: 100%' />
            </nz-upload>
            <div class='details-profile'>
              <div class='font-weight-bold font-size-21 text-dark'>
                {{plantSelected | setNamePlant}}
              </div>
              <p
                class='font-size-15'
                nz-typography
                nzEditable
                [nzContent]='plantDescription'
                (nzContentChange)='editPlantDescription($event)'
                [nzDisabled]='loadingChangedPlant'>
              </p>
            </div>
          </div>
        </div>

        <div class='steps-content' [ngSwitch]='current'>
          <ng-container *ngSwitchCase='0'>
            <ng-container *ngTemplateOutlet='step_one'></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase='1'>
            <ng-container *ngTemplateOutlet='step_two'></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase='2'>
            <ng-container *ngTemplateOutlet='step_three'></ng-container>
          </ng-container>
        </div>

        <div class='steps-action'>
          <button nz-button nzType='default' (click)='pre()' *ngIf='current > 0'>
            <span>Regresar</span>
          </button>
          <button
            nz-button
            class='ant-btn-danger'
            *ngIf='current === 0 || current === 2'
            [routerLink]="['/profile/business']">
            <span>Cancelar</span>
          </button>
          <button
            nz-button
            nzType='primary'
            [disabled]='firstForm?.invalid || secondForm?.invalid'
            (click)='next()'
            *ngIf='current < 2'>
            <span>Siguiente</span>
          </button>
          <button
            nz-button
            nzType='primary'
            [nzLoading]='isSaving'
            [disabled]='thirdForm?.invalid'
            (click)='done()'
            *ngIf='current === 2'>
            <span>Actualizar Planta</span>
          </button>
        </div>
      </div>
    </nz-card>
  </div>
</section>

<ng-template #step_one>
  <div class='row'>
    <div class='col-12'>
      <p class='font-size-15 font-weight-bold'>
        Llena el siguiente formato o sube el archivo con la información de la planta
      </p>
    </div>
  </div>

  <div class='row'>
    <div class='col-12'>
      <input type='file' (change)='onFileChange($event)' multiple='false' accept='.xlsx' />
    </div>
  </div>

  <div class='row mt-3'>
    <div class='col-12'>
      <p class='font-size-15 font-weight-bold'>
        Información legal de la planta
      </p>
    </div>
  </div>
  <form class='form-one' [formGroup]='firstForm'>
    <ng-container *ngFor='let question of firstFormQuestions; let i = index'>
      <div class='row'>
        <nz-form-label
          [nzRequired]='question.isRequired'
          class='col-12 col-md-6 section-questions'
          nzTooltipTitle='{{ question.tooltip }}'>
          {{ question.question }}
        </nz-form-label>
        <div class='col-12 col-md-6'>
          <input
            *ngIf='i < 10'
            nz-input
            placeholder='{{ question.placeholder }}'
            formControlName='{{ question.formControlName }}'
          />
          <textarea
            *ngIf='i === 10'
            nz-input
            nzAutosize
            formControlName='{{ questionsForm[10].formControlName }}'
            placeholder='{{ questionsForm[10].placeholder }}'
          ></textarea>
        </div>
      </div>
    </ng-container>
  </form>
</ng-template>

<ng-template #step_two>
  <div class='row mt-3'>
    <div class='col-12'>
      <p class='font-size-15 font-weight-bold'>
        Datos del representante legal
      </p>
    </div>
  </div>

  <form class='form-one' [formGroup]='secondForm'>
    <ng-container *ngFor='let question of secondFormQuestions'>
      <div class='row'>
        <nz-form-label [nzRequired]='question.isRequired' class='col-12 col-md-6 section-questions'>
          {{ question.question }}
        </nz-form-label>
        <div class='col-12 col-md-6'>
          <input
            nz-input
            placeholder='{{ question.placeholder }}'
            formControlName='{{ question.formControlName }}'
          />
        </div>
      </div>
    </ng-container>

    <div class='row mt-4'>
      <div class='col-12'>
        <p class='font-size-15 font-weight-bold'>
          Personas autorizadas (máximo 3)
        </p>
      </div>
    </div>

    <div class='form-one'>
      <div class='row'>
        <div class='col-12 col-md-6'>
          Número de personas autorizadas:
        </div>
        <div class='col-12 col-md-6'>
          <nz-input-number
            [nzMin]='minNumberPeople'
            [nzMax]='3'
            [nzStep]='1'
            formControlName='inputPerson'>
          </nz-input-number>
        </div>
      </div>
    </div>

    <ng-container *ngFor='let person of personListOfControl; let i = index'>
      <div class='row mt-4'>
        <div class='col-12'>
          <p class='font-size-15 font-weight-bold'>Datos de persona {{ i + 1 }}</p>
        </div>
      </div>
      <ng-container *ngFor='let question of person'>
        <div class='row'>
          <nz-form-label
            [nzRequired]='question.isRequired'
            class='col-12 col-md-6 section-questions'>
            {{ question.question }}
          </nz-form-label>
          <div class='col-12 col-md-6'>
            <input
              nz-input
              placeholder='{{ question.placeholder }}'
              formControlName="{{ question.formControlName.concat('-index-').concat(i.toString()) }}"
            />
          </div>
        </div>
      </ng-container>
    </ng-container>
  </form>
</ng-template>

<ng-template #step_three>
  <div class='row mt-3'>
    <div class='col-12'>
      <p class='font-size-15 font-weight-bold'>
        Requerimientos técnicos del Código de Red obligatorios para el centro de carga
      </p>
    </div>
  </div>
  <form class='form-one' [formGroup]='thirdForm'>
    <ng-container *ngFor='let question of thirdFormQuestions; let i = index'>
      <div *ngIf='question.isRequired' class='row m-0 mb-4'>
        <div class='row w-100 mb-3'>
          <div class='col-12 col-md-6'>
            <div>
              <span class='text-title'>{{ question.question }}</span>
            </div>
          </div>
          <div class='col-12 col-md-6'>
            <nz-select
              class='w-100'
              [nzPlaceHolder]='question.placeholder'
              formControlName='{{ question.formControlName }}'
              (ngModelChange)='valueQuestionChange(question, i)'>
              <nz-option [nzValue]='1' nzLabel='Sí'></nz-option>
              <nz-option [nzValue]='0' nzLabel='No'></nz-option>
              <nz-option [nzValue]='-1' nzLabel='No disponible'></nz-option>
            </nz-select>
          </div>
        </div>
      </div>
    </ng-container>
  </form>
</ng-template>

<ng-template #loaderFile>
  <nz-spin *ngIf='isLoadingFile' [nzSpinning]='true' [nzTip]='textHTMLSpin' class='col-12'>
    <div class='card loader-show w-100'></div>
  </nz-spin>
</ng-template>
