import { Component, OnInit } from '@angular/core'
import { TYPE_EXTERNAL_USERS } from '../../../config/enviroment'

/**
 * Componente para manejo de usuarios de tipo externos.
 *
 * @export
 * @class ClientsComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
})
export class ClientsComponent implements OnInit {
  /**
   * Tipo de vista en funcion del usuario.
   *
   * @type {number}
   * @memberof ClientsComponent
   */
  public typeOfView: number = TYPE_EXTERNAL_USERS

  /**
   * OnInit
   *
   * @memberof ClientsComponent
   */
  ngOnInit(): void {}
}
