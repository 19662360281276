import { URL_API_V2 } from './../../config/enviroment'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { CommonService } from '../shared/common.service'
import { Observable } from 'rxjs'
import { UnifierPoint } from '../../models/models.index'
import { CrudRepository } from '../shared/crud.repository'
import { ParamRequest } from 'src/app/interfaces/interfaces.index'

/**
 * Servicios para puntos de conexion.
 *
 * @export
 * @class PointsRepository
 * @extends {CrudRepository}
 */
@Injectable({
  providedIn: 'root',
})
export class PointsRepository extends CrudRepository {
  /**
   * Creates an instance of PointsService.
   * @param {HttpClient} _httpClient
   * @param {CommonService} _commonService
   * @memberof PointsService
   */
  constructor(protected _httpClient: HttpClient, protected _commonService: CommonService) {
    super(_httpClient, _commonService, 'unifilar-point')
  }

  /**
   * Servicio para obtener puntos unifilares por planta.
   *
   * @param {number} plantId Identificador de planta.
   * @return {*}  {Observable<UnifierPoint[]>} Observable de puntos unifilares.
   * @memberof PointsService
   */
  public getPointsByPlant(plantId: number): Observable<UnifierPoint[]> {
    const url = `${URL_API_V2}/${this._endpointPrefix}`
    const paramsRequest: ParamRequest[] = [{ param: 'id_plant', value: plantId }]
    return this._commonService.handlerGetElements(url, paramsRequest, 'Data')
  }

  /**
   * Servicio para guardar una imagen de diagrama unifilar.
   *
   * @param {number} plantId
   * @param {File} image
   * @return {*}  {Observable<any>}
   * @memberof UnifierPointService
   */
    public setImage(plantId: number, image: File): Observable<any> {
      const url = `${URL_API_V2}/${this._endpointPrefix}/plant/${plantId}/image/`
      return this._commonService.handlerPutElementImage(url, image)
    }

}
