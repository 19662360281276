import { createAction, props } from '@ngrx/store'
import { Plant } from '../../models/plant.model'
import { PlantFiles } from '../../interfaces/plant-files'

export const GET_FILE = '[Files] Obtener un archivo'
export const LOAD_FILE_SUCCESS = '[Files] Descarga de archivo completada'
export const LOAD_FILE_FAILURE = '[Files] Descarga de archivo fallida'

export const GET_FILES = '[Files] Obtiene una lista archivos por plantas'
export const LOAD_FILES_SUCCESS = '[Files] Descarga de archivos completada'
export const LOAD_FILES_FAILURE = '[Files] Descarga de archivos no completada'

export const FLUSH_FILE = '[Files] Flush Files'
export const EMPTY_FILE_ACTION = '[Files] Empty Action files'

export const getFile = createAction(GET_FILE, props<{ typeReport: string, params: any }>())
export const loadFileSuccessful = createAction(LOAD_FILE_SUCCESS, props<{ files: PlantFiles[]}>())
export const loadFileUnsuccessful = createAction(LOAD_FILE_FAILURE, props<{ any }>())

export const getFilesByPlants = createAction(GET_FILES, props<{ plants: Plant[] }>())
export const loadFilesSuccessful = createAction(LOAD_FILES_SUCCESS, props<{ files: PlantFiles[] }>())
export const loadFilesUnsuccessful = createAction(LOAD_FILES_FAILURE, props<{ any }>())

export const flushFile = createAction(FLUSH_FILE)
export const emptyFileAction = createAction(EMPTY_FILE_ACTION)
