export default {
  'topBar.issuesHistory': 'Issues',
  'topBar.projectManagement': 'Projects',
  'topBar.typeToSearch': 'Search...',
  'topBar.findPages': 'Find pages...',
  'topBar.actions': 'Actions',
  'topBar.status': 'Status',
  'topBar.profileMenu.hello': 'Bienvenido',
  'topBar.profileMenu.billingPlan': 'Billing Plan',
  'topBar.profileMenu.role': 'Rol',
  'topBar.profileMenu.email': 'Correo',
  'topBar.profileMenu.phone': 'Celular',
  'topBar.profileMenu.myProfile': 'Mi perfil',
  'topBar.profileMenu.contact': 'Contáctanos',
  'topBar.profileMenu.logout': 'Cerrar sesión',
  'topBar.settingMenu.profile-plant': 'Perfil de planta',
  'topBar.settingMenu.electrical-plant': 'Información eléctrica de planta',
  'topBar.settingMenu.notifications': 'Configuración de notificaciones',
  'topBar.settingMenu.users': 'Administración de usuarios',
  'alertMenu.seeAll': 'Ver notificaciones',
  'alertMenu.emptyNotification': 'No hay notificaciones disponibles.',
  'topBar.menuTop.home': 'Inicio',
  'topBar.menuTop.managers': 'Gestores',
  'topBar.menuTop.reports': 'Reportes',
  'topBar.menuTop.events': 'Eventos',
  'home.welcome': 'Bienvenido',
  'home.welcomePhrase': '¿Qué decisión tomaremos el día de hoy?',
  'sideBar.menu.targetPoints': 'PUNTOS INSTALADOS',
  'footer.policyPrivacy': 'Política de Privacidad',
  'auth.topBar.seeDemo': 'Ver demo',
  'auth.yourEmail': 'Tu correo',
  'auth.yourPassword': 'Tu contraseña',
  'auth.forgotPassword': '¿Olvidaste tu contraseña?',
  'auth.actionTermsAndPoli': 'Términos del servicio y Política de Privacidad',
  'auth.title.sigIn': 'Inicia sesión',
  'auth.login.button.sigIn': 'Inicio de sesión',
  'auth.login.title.banner': 'Tus signos vitales en manos de los especialistas',
  'auth.login.botton.quotes': 'Pide tu cotización',
  'auth.recoveryPassword.title': 'Recuperar tu contraseña',
  'auth.recoveryPassword.subtitle': 'Escribe tu correo y te haremos llegar una liga para crear una nueva contraseña para tu cuenta.',
  'auth.recoveryPassword.yourEmail': 'Escribe tu correo',
  'auth.recoveryPassword.error.yourEmail': '¡Introduce tu contraseña!',
  'auth.recoveryPassword.btnPassword': 'Recuperar contraseña',
  'auth.recoveryPassword.returnLoginOne': '¿Ya tienes una cuenta?',
  'profile.electric-secctionTitle': 'Información eléctrica de planta',
  'profile.bussiness-secctionTitle': 'Perfil de planta',
  'notifications.settings-secctionTitle': 'Configuración de notificaciones',
  'usersAdmin.secctionTitle': 'Administración de usuarios',
  'usersAdmin.internals.title': 'internos',
  'usersAdmin.externals.title': 'externos',
  'profile.user-sectionTitle': 'Mi perfil',
  'profile.editTxt-btn': 'Editar perfil',
  'common.btn.txt.return': 'Regresar',
  'common.btn.txt.more': 'Ver más',
  'notification.text.date.day': 'días'
}
