import { URL_API_V2 } from './../../config/enviroment'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { CommonService } from '../shared/common.service'
import { Observable } from 'rxjs'
import { CrudRepository } from '../shared/crud.repository'
import { ParamRequest } from 'src/app/interfaces/interfaces.index'

/**
 * Serivicio para obtener datos de los proyectos.
 *
 * @export
 * @class ProjectsRepository
 * @extends {CrudRepository}
 */
@Injectable({
  providedIn: 'root',
})
export class ProjectsRepository extends CrudRepository {
  /**
   * Creates an instance of ProjectsRepository.
   * @param {HttpClient} _httpClient
   * @param {CommonService} _commonService
   * @memberof ProjectsRepository
   */
  constructor(protected _httpClient: HttpClient, protected _commonService: CommonService) {
    super(_httpClient, _commonService, 'plant-project')
  }

  /**
   * Repositorio para obtener el projecto activo de la planta.
   *
   * @param {number} plantId Identificador de la planta.
   * @return {*}  {Observable<Project>} Projecto activo.
   * @memberof ProjectsRepository
   */
  public getProjectActiveByPlant(plantId: number): Observable<any> {
    const url = `${URL_API_V2}/${this._endpointPrefix}`
    const paramsRequest: ParamRequest[] = [
        { param: 'id_plant', value: plantId },
        { param: 'search-active', value: 1}
    ]
    return this._commonService.handlerGetElements(url, paramsRequest, 'Data')
  }
}
