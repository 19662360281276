/**
 * Modelo para el manejo de informacion de la informacion del gram.
 *
 * @export
 * @class FormGram
 */
export class FormGram {
  /**
   * Creates an instance of FormGram.
   * @param {string} user_email Correo del usuario.
   * @param {number} plant_voltage Voltaje de planta.
   * @param {number} current_cc_3f Corriente.
   * @param {number} current_cc_1f Corriente.
   * @param {number} xr_3f Reactancia.
   * @param {number} xr_1f Reactancia.
   * @param {number} ev_fp Evaluacion de factor de potencia.
   * @param {number} id_corporate Identificador de corporativo.
   * @param {number} id_client Identificador del cliente.
   * @param {number} id_plant Identificadodr de la planta.
   * @param {number} id_project Identificador del proyecto.
   * @param {number} id_unifilar_point Identificador del punto unifilar.
   * @param {string} electric_substation Subestancion electrica.
   * @param {string} substation_acron Acronimo para subestacion electrica.
   * @param {number} current_cc_3f_max Corriente trifasica maxima.
   * @param {number} current_cc_1f_max Corriente monofasica maxima.
   * @param {number} current_cc_3f_min Corriente trifasica minima.
   * @param {number} current_cc_1f_min Corriente monofasica minima.
   * @param {string} zth_p_3f_max ----.
   * @param {string} zth_0_1f_max ----.
   * @param {string} zth_p_3f_min ----.
   * @param {string} zth_0_1f_min ----.
   * @param {boolean} is_electric_arc_oven Horno electrico en la planta.
   * @param {number} id_commutation Identificador de numero de comutaciones por planta.
   * @param {number} [id] Identificador del form gram.
   * @memberof FormGram
   */
  constructor(
    public user_email: string = null,
    public plant_voltage: number = null,
    public current_cc_3f: number = null,
    public current_cc_1f: number = null,
    public xr_3f: number = null,
    public xr_1f: number = null,
    public ev_fp: number = null,
    public id_corporate: number = null,
    public id_client: number = null,
    public id_plant: number = null,
    public id_project: number = null,
    public id_unifilar_point: number = null,
    public electric_substation: string = null,
    public substation_acron: string = null,
    public current_cc_3f_max: number = null,
    public current_cc_1f_max: number = null,
    public current_cc_3f_min: number = null,
    public current_cc_1f_min: number = null,
    public zth_p_3f_max: string = null,
    public zth_0_1f_max: string = null,
    public zth_p_3f_min: string = null,
    public zth_0_1f_min: string = null,
    public is_electric_arc_oven: boolean = false,
    public id_commutation: number = null,
    public id?: number,
  ) {}
}
