import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { throwError } from 'rxjs'
import { Observable } from 'rxjs/internal/Observable'
import { URL_API_V2 } from 'src/app/config/enviroment'
import { Person } from '../../models/person.model'
import { CommonService } from '../shared/common.service'
import { CrudRepository } from '../shared/crud.repository'
import { ParamRequest } from 'src/app/interfaces/interfaces.index'

/**
 * Servicio para obtener informacion de personas.
 *
 * @export
 * @class PersonRepository
 * @extends {CrudRepository}
 */
@Injectable({
    providedIn: 'root',
})
export class PersonRepository extends CrudRepository {
    /**
     * Creates an instance of PersonRepository.
     * @param {HttpClient} _httpClient
     * @param {CommonService} _commonService
     * @memberof PersonRepository
     */
    constructor(protected _httpClient: HttpClient, protected _commonService: CommonService) {
        super(_httpClient, _commonService, 'person')
    }

    /**
     * Servicio para obtener las personas por planta.
     *
     * @param {number} plantId
     * @return {Observable<Person[]>}  {Observable<UserNotification[]>}
     * @memberof PersonRepository
     */
    public getPeopleByPlant(plantId: number): Observable<Person[]> {
        const url = `${URL_API_V2}/${this._endpointPrefix}`
        const paramsRequest: ParamRequest[] = [{ param: 'id_plant', value: plantId }]
        return this._commonService.handlerGetElements(url, paramsRequest, 'Data')
    }

    /**
     * Servicio para asignarle a una planta una persona.
     *
     * @param {Person} person
     * @param {boolean} setNotification
     * @return {Observable<Person>}
     * @memberof PersonRepository
     */
    public setPersonByPlant(person: Person, setNotification: boolean): Observable<Person> {
        if (person.id && !person.id_plant) {
            return throwError({ message: 'Insert plant id or delete person id', code: 422 })
        }

        const url = `${URL_API_V2}/${this._endpointPrefix}`
        const paramsRequest: ParamRequest[] = [{ param: 'notification', value: setNotification }]
        return this._commonService.handlerPostElement(url, paramsRequest, person)
    }
}
