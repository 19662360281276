export {}

declare global {
  interface Date {
    addDays(days: number, useThis?: boolean): Date

    subtractDays(days: number, useThis?: boolean): Date

    addWeeks(weeks: number, useThis?: boolean): Date

    subtractWeeks(weeks: number, useThis?: boolean): Date

    isToday(): boolean

    clone(): Date

    isAnotherMonth(date: Date): boolean

    isWeekend(): boolean

    isSameDate(date: Date): boolean

    getWeek(): number

    firstDateOfWeek(): Date

    lastDateOfWeek(): Date

    diffInDays(date: Date): number

    greather(date: Date): boolean

    lower(date: Date): boolean

    toUTC(): Date

    fromUnixUTC(unixtTime: number): Date

    getFirstMonday2Month(month: number): Date

    getTimeSeconds(): number

    yyyymmdd(): string
  }
}

Date.prototype.addDays = function(days: number): Date {
  if (!days) {
    return this
  }
  let date = this
  date.setDate(date.getDate() + days)

  return date
}

Date.prototype.subtractDays = function(days: number): Date {
  if (!days) {
    return this
  }
  let date = this
  date.setDate(date.getDate() - days)

  return date
}

Date.prototype.addWeeks = function(weeks: number): Date {
  let date = new Date(this.getFullYear(), this.getMonth(), this.getDate())
  return date.addDays(7 * weeks)
}

Date.prototype.subtractWeeks = function(weeks: number): Date {
  let date = new Date(this.getFullYear(), this.getMonth(), this.getDate())
  return date.subtractDays(7 * weeks)
}

Date.prototype.isToday = function(): boolean {
  let today = new Date()
  return this.isSameDate(today)
}

Date.prototype.clone = function(): Date {
  return new Date(+this)
}

Date.prototype.isAnotherMonth = function(date: Date): boolean {
  return date && this.getMonth() !== date.getMonth()
}

Date.prototype.isWeekend = function(): boolean {
  return this.getDay() === 0 || this.getDay() === 6
}

Date.prototype.isSameDate = function(date: Date): boolean {
  return (
    date &&
    this.getFullYear() === date.getFullYear() &&
    this.getMonth() === date.getMonth() &&
    this.getDate() === date.getDate()
  )
}

Date.prototype.getWeek = function(): number {
  let d = new Date(this.getFullYear(), this.getMonth(), this.getDate())
  let dayNum = d.getDay() || 7
  d.setDate(d.getDate() + 4 - dayNum)
  let yearStart = new Date(d.getFullYear(), 0, 1)
  return Math.ceil(((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7)
}

Date.prototype.firstDateOfWeek = function(): Date {
  let day = this.getDay()
  return new Date(this.subtractDays(day))
}

Date.prototype.lastDateOfWeek = function(): Date {
  let day = 6 - this.getDay()
  return new Date(this.addDays(day))
}

Date.prototype.diffInDays = function(date: Date): number {
  let diffInTime = date.getTime() - this.getTime()
  return diffInTime / (1000 * 3600 * 24)
}

Date.prototype.greather = function(date: Date): boolean {
  return this.diffInDays(date) > 0
}

Date.prototype.lower = function(date: Date): boolean {
  return this.diffInDays(date) < 0
}

Date.prototype.toUTC = function(): Date {
  let date = this
  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds(),
    ),
  )
}

Date.prototype.fromUnixUTC = function(unixtTime): Date {
  let date = this
  return new Date(1000 * (unixtTime + date.getTimezoneOffset() * 60))
}

Date.prototype.getFirstMonday2Month = function(month: number = 0): Date {
  let dateYear: Date
  let day = 1
  do {
    dateYear = new Date(this.getFullYear(), month, day)
    day++
  } while (!(dateYear.getDay() === 1))
  return dateYear
}

Date.prototype.getTimeSeconds = function(): number {
  const microSeconds: number = this.getTime()
  return Math.round(microSeconds / 1000)
}

Date.prototype.yyyymmdd = function(): string {
  const mm: number = this.getMonth() + 1 // getMonth() is zero-based
  const dd: number = this.getDate()

  const hours: number = this.getHours()
  const minutes: number = this.getMinutes()
  const seconds: number = this.getSeconds()

  const date: string = [
    this.getFullYear(),
    (mm > 9 ? '' : '0') + mm,
    (dd > 9 ? '' : '0') + dd,
  ].join('-')

  const time: string = [
    (hours > 9 ? '' : '0') + hours,
    (minutes > 9 ? '' : '0') + minutes,
    (seconds > 9 ? '' : '0') + seconds,
  ].join(':')
  return date.concat(' ', time)
}
