import { Injectable } from '@angular/core'
import { Actions, ofType, createEffect } from '@ngrx/effects'
import * as FilesActions from './files.actions'
import { catchError, concatMap, exhaustMap, last, map, tap } from 'rxjs/operators'
import { forkJoin, from, Observable, of, throwError } from 'rxjs'
import { PlantFiles } from '../../interfaces/plant-files'
import { AVAILABLE_REPORT } from '../../config/enviroment'
import { ParamRequest } from '../../interfaces/param-request'
import { FileWeekService } from '../../services/files/week/file-week.service'
import { FileBillingService } from './../../services/files/billing/file-billing.service'
import { FileOfficialService } from '../../services/files/official/file-official.service'
import { FileTransactionalService } from '../../services/files/transactional/file-transactional.service'
import { Store } from '@ngrx/store'

@Injectable()
export class FilesEffects {

  public plantsFiles: PlantFiles[] = []

  constructor(private _actions$: Actions,
    private _store: Store<any>,
    private _fileOfficialService: FileOfficialService,
    private _fileTransactionalService: FileTransactionalService,
    private _fileWeekService: FileWeekService,
    private _fileBillingService: FileBillingService) {
  }

  getFilesByPlants$ = createEffect(() =>
    this._actions$.pipe(
      ofType(FilesActions.getFilesByPlants),
      exhaustMap(action => from(action.plants)
        .pipe(
          tap(() => {
            if (this.plantsFiles.length > 0) {
              this.plantsFiles.length = 0
            }
          }),
          concatMap(plant => {
            return forkJoin([of(plant), this._fileWeekService.getFileByPlant(plant.id).pipe(map((data: any[]) => data))])
              .pipe(
                map(([plant, files]) => {
                  files = files ? files : []
                  this.plantsFiles.push({ plant, files })
                }),
              )
          },
          ),
          last(),
          map((_) => {
            const files = [...this.plantsFiles]
            return FilesActions.loadFilesSuccessful({ files })
          }),
        ),
      ),
    ),
  )

  getFile$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType(FilesActions.getFile),
      concatMap((action) => {
        const plantId: number = action.params.plantId
        const cdrVersion: number = action.params.cdrVersion
        const params: ParamRequest[] = []
        let fileService: Observable<any[]>
        switch (action.typeReport) {
          case AVAILABLE_REPORT.week:
            fileService = this._fileWeekService.getFileByPlant(plantId, cdrVersion)
            break
          case AVAILABLE_REPORT.transactional:
          case AVAILABLE_REPORT.unofficial:
            const reportId: number = action.params.reportId
            fileService = this._fileTransactionalService.getFileByReport(reportId, cdrVersion)
            break
          case AVAILABLE_REPORT.billing:
            params.push(
              { param: 'estimated', value: 1 },
              { param: 'charts', value: 1 },
              { param: 'summary', value: 1 },
            )
            fileService = this._fileBillingService.getFileByPlant(plantId, null, params)
            break
          case AVAILABLE_REPORT.official:
          default:
            params.push({ param: 'year', value: action.params.year }, { param: 'month', value: action.params.month + 1 })
            fileService = this._fileOfficialService.getFileByPlant(plantId, cdrVersion, params)
            break
        }
        return fileService.pipe(
          catchError(error => throwError(error)),
        )
      }),
      concatMap((files: any[]) => {
        const plantFiles: PlantFiles[] = [{ plant: null, files }]
        return of(FilesActions.loadFileSuccessful({ files: plantFiles }))
      }),
      catchError((error, caught) => {
        this._store.dispatch(FilesActions.loadFileUnsuccessful(error))
        return caught
      }),
    ),
  )
}
