import { ReportTransactionalRepository } from './report-transactional.repository'
import { Injectable } from '@angular/core'
import { ReportsService } from '../reports.service'

/**
 * Servicio para manejo de reportes transacionales.
 *
 * @export
 * @class ReportTransactionalService
 * @extends {ReportsService}
 */
@Injectable({
  providedIn: 'root',
})
export class ReportTransactionalService extends ReportsService {
  /**
   * Creates an instance of ReportTransactionalService.
   * @param {HttpClient} _reportTransactionalRepository
   * @memberof ReportTransactionalService
   */
  constructor(protected _reportTransactionalRepository: ReportTransactionalRepository) {
    super(_reportTransactionalRepository)
  }
}
