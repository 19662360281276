import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { RangeDate } from '../../../interfaces/range-date'

/**
 * Componente para manejo de seleccionar reportes no oficiales.
 *
 * @export
 * @class GenerateReportComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'widget-generate-report',
  templateUrl: './generate-report.component.html',
})
export class GenerateReportComponent implements OnInit {
  /**
   * Variable de entrada para controlar estado de carga.
   *
   * @type {boolean}
   * @memberof UnofficialReportSelectComponent
   */
  @Input() isLoading: boolean = false
  /**
   * Event emitter para generar reportes al componente padre.
   *
   * @type {EventEmitter<{ startDate: Date; endDate: Date }>}
   * @memberof UnofficialReportSelectComponent
   */
  @Output() reportEvent: EventEmitter<RangeDate> = new EventEmitter<RangeDate>()
  /**
   * Variable almacenar fecha del periodo del reporte.
   *
   * @private
   * @type {Date}
   * @memberof UnofficialReportSelectComponent
   */
  private date: Date = null
  /**
   * Numero de semanadas seleccionadas.
   *
   * @private
   * @type {number}
   * @memberof UnofficialReportSelectComponent
   */
  private weeksNumber: number = 0
  /**
   * Variable para el formulario de la fecha.
   *
   * @type {FormControl}
   * @memberof UnofficialReportSelectComponent
   */
  public dateForm: FormControl = new FormControl(null, [Validators.required])
  /**
   * Variable para el formulario del numero de semanas.
   *
   * @type {FormControl}
   * @memberof UnofficialReportSelectComponent
   */
  public weeksForm: FormControl = new FormControl(null, [
    Validators.min(1),
    Validators.max(4),
    Validators.pattern('^[0-9]*$'),
    Validators.required,
  ])

  /**
   * OnInit
   *
   * @memberof UnofficialReportSelectComponent
   */
  ngOnInit(): void {
  }

  /**
   * Callback para generar reporte.
   *
   * @memberof UnofficialReportSelectComponent
   */
  public generate() {
    this.dateFormControl.markAsDirty()
    this.dateFormControl.updateValueAndValidity()
    this.weeksFormControl.markAsDirty()
    this.weeksFormControl.updateValueAndValidity()
    if (this.dateFormControl.invalid || this.weeksFormControl.invalid) {
      return
    }

    this.date = this.dateFormControl.value
    this.weeksNumber = this.weeksFormControl.value
    const currentDay: Date = new Date(this.date)
    const startDate: Date = new Date(
      currentDay.getFullYear(),
      currentDay.getMonth(),
      currentDay.getDate(),
      0,
      0,
      0,
      0,
    )
    const endDate: Date = new Date(currentDay.setDate((currentDay.getDate() + this.weeksNumber * 7) - 1))
    endDate.setHours(23, 59, 59)
    this.reportEvent.emit({ startDate, endDate })
    this.dateFormControl.reset()
    this.weeksFormControl.reset()
  }

  /**
   * Formulario para el control de la fecha.
   *
   * @readonly
   * @type {FormControl}
   * @memberof UnofficialReportSelectComponent
   */
  get dateFormControl(): FormControl {
    return this.dateForm
  }

  /**
   * Formulario para el control del numero de semanas.
   *
   * @readonly
   * @type {FormControl}
   * @memberof UnofficialReportSelectComponent
   */
  get weeksFormControl(): FormControl {
    return this.weeksForm
  }
}
