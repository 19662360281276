import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import * as EventsActions from './notifications.actions'
import { catchError, concatMap, map } from 'rxjs/operators'
import { UsersService } from '../../services/users/users.service'
import { of } from 'rxjs'
import { Injectable } from '@angular/core'
import { NotificationPlant } from '../../models/notification-plant.model'
import { NotificationPlantService } from 'src/app/services/notification-plant/notification-plant.service'

@Injectable()
export class NotificationsEffects {
  constructor(private _actions$: Actions,
              private _store: Store<any>,
              private _notificationPlantService: NotificationPlantService) {
  }

  getNotificationsUser$ = createEffect(() =>
    this._actions$.pipe(
      ofType(EventsActions.getNotificationsUser),
      concatMap(action => {
        const userId: number = action.userId
        const active: number = action.params?.active ? action.params.active : 1
        return this._notificationPlantService.getNotificationPlantByUser(userId, active).pipe(map((notifications) =>
          notifications.sort((a, b) => {
            return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          })))
      }),
      concatMap((notifications: NotificationPlant[]) => {
        return of(EventsActions.loadNotificationsSuccessful({ notifications }))
      }),
      catchError((error, caught) => {
        this._store.dispatch(EventsActions.loadNotificationsUnsuccessful(error))
        return caught
      }),
    ),
  )

  updateNotificationPlant$ = createEffect(() =>
    this._actions$.pipe(
      ofType(EventsActions.updateNotificationPlant),
      concatMap(action => {
        const notification: NotificationPlant = {...action.notification}
        notification.active = action.params?.active ? action.params.active : 0
        return this._notificationPlantService.updateNotificationPlant(notification.id_notification)
      }),
      concatMap((notification: any) => {
        return of(EventsActions.updateNotificationsuccess({ notification }))
      }),
      catchError((error, caught) => {
        this._store.dispatch(EventsActions.updateNotificationFailure(error))
        return caught
      }),
    ),
  )
}
