import { PersonRepository } from './person.repository';
import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { of } from 'rxjs'
import { Observable } from 'rxjs/internal/Observable'
import { map } from 'rxjs/operators'
import { URL_API } from 'src/app/config/enviroment'
import { Person } from '../../models/person.model'
import { environment } from 'src/environments/environment'
import { CommonService } from '../shared/common.service'
import { CrudService } from '../shared/crud.service'

/**
 * Servicio para obtener informacion de notificaciones.
 *
 * @export
 * @class PersonService
 * @extends {CrudService}
 */
@Injectable({
  providedIn: 'root',
})
export class PersonService extends CrudService {
  /**
   * Creates an instance of PersonService.
   * @param {PersonRepository} _personRepository
   * @memberof PersonService
   */
  constructor(protected _personRepository: PersonRepository) {
    super(_personRepository)
  }

  /**
   * Servicio para obtener las personas por planta.
   *
   * @param {number} plantId
   * @return {Observable<Person[]>}
   * @memberof PersonService
   */
  public getPeopleByPlant(plantId: number): Observable<Person[]> {
    return this._personRepository.getPeopleByPlant(plantId)
  }

  /**
   * Servicio para asignarle a una planta una persona.
   *
   * @param {Person} person
   * @param {boolean} setNotification
   * @return {Observable<Person>} {Observable<UserNotification>}
   * @memberof PersonService
   */
  public setPersonByPlant(person: Person, setNotification: boolean): Observable<Person> {
    return this._personRepository.setPersonByPlant(person, setNotification)
  }
}
