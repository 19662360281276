import { Component, Input, OnInit } from '@angular/core'
import { Question } from '../../../interfaces/question'

/**
 * Componente para crear la seccion de preguntas estaticas de informacion legal.
 * El objeto contenedor debe tener la misma key de las preguntas para extraer la respuesta del objeto.
 * @Input {string} title Titulo de la seccion de preguntas.
 * @Input {Question[]} Lista de preguntas que contiene la seccion.
 * @Input {any} Objeto contenador que las respuestas de la preguntas.
 * @export
 * @class QuestionFormStaticComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'widget-question-form-static',
  templateUrl: './question-form-static.component.html',
  styleUrls: ['./question-form-static.component.scss'],
})
export class QuestionFormStaticComponent implements OnInit {
  /**
   * Titulo de la seccion de preguntas.
   *
   * @type {string}
   * @memberof QuestionFormStaticComponent
   */
  @Input() title: string
  /**
   * Listado de preguntas.
   *
   * @type {Question[]}
   * @memberof QuestionFormStaticComponent
   */
  @Input() questions: Question[]
  /**
   * Model de las respuestas al listado de preguntas.
   *
   * @type {*}
   * @memberof QuestionFormStaticComponent
   */
  @Input() answer: any

  /**
   * Creates an instance of QuestionFormStaticComponent.
   * @memberof QuestionFormStaticComponent
   */
  constructor() {}

  /**
   * OnInit
   *
   * @memberof QuestionFormStaticComponent
   */
  ngOnInit(): void {}
}
