import { Injectable } from '@angular/core'
import { CrudService } from '../shared/crud.service'
import { CorporatesRepository } from './corporates.repository'

/**
 * Servicios para obtener informacion referentes a corporativos.
 *
 * @export
 * @class CorporatesService
 * @extends {CrudService}
 */
@Injectable({
  providedIn: 'root',
})
export class CorporatesService extends CrudService {
  /**
   * Creates an instance of CorporatesService.
   * @param {CorporatesRepository} _corporatesRepository
   * @memberof CorporatesService
   */
  constructor(protected _corporatesRepository: CorporatesRepository) {
    super(_corporatesRepository)
  }
}
