import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { CommonService } from '../../shared/common.service'
import { ReportsRepository } from '../reports.repository'

/**
 * Servicio para manejo de reportes oficiales.
 *
 * @export
 * @class ReportOfficialRepository
 * @extends {ReportsService}
 */
@Injectable({
  providedIn: 'root',
})
export class ReportOfficialRepository extends ReportsRepository {
  /**
   * Creates an instance of ReportOfficialRepository.
   * @param {HttpClient} _httpClient
   * @param {CommonService} _commonService
   * @param {string} _endpointPrefix Prefijo para url de reportes oficiales.
   * @memberof ReportOfficialRepository
   */
  constructor(protected _httpClient: HttpClient, protected _commonService: CommonService) {
    super(_httpClient, _commonService, 'official-report')
  }
}
