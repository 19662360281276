<div>
  <div nz-dropdown nzTrigger='click' [nzDropdownMenu]='menu'>
    <nz-avatar nzIcon='user' [nzShape]="'square'" [nzSize]="'large'" [nzSrc]='avatar' class='avatar'></nz-avatar>
  </div>
  <nz-dropdown-menu #menu='nzDropdownMenu'>
    <ul nz-menu style='min-width: 282px !important;'>
      <li nz-menu-item>
        <span style='line-height: 1rem'>
          <strong>{{name || 'Anonymous'}}</strong>
          <span>&nbsp;</span>
          <strong>{{lastname || ''}}</strong>
          <br />
          {{nameRol || '—'}}
        </span>
      </li>
      <li nz-menu-divider></li>
      <li nz-menu-item>
        <a href='javascript: void(0);' routerLink='/profile/user'>
          {{ 'topBar.profileMenu.myProfile' | translate }}
        </a>
      </li>
      <li nz-menu-divider></li>
      <li nz-menu-item>
        <a [href]='emailstring' target="_top">
          {{ 'topBar.profileMenu.contact' | translate }}
        </a>
      </li>
      <li nz-menu-divider></li>
      <li nz-menu-item>
        <a href='javascript: void(0);' (click)='logout()' style='color: red;'>
          <i class='fe fe-log-out mr-2'></i>
          {{ 'topBar.profileMenu.logout' | translate }}
        </a>
      </li>
    </ul>
  </nz-dropdown-menu>
</div>
