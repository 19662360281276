import {
  User,
  Corporate,
  Plant,
  Project,
  Rol,
  UnifierPoint,
} from 'src/app/models/models.index'
import { Level } from '../../../../models/level.model'
import { ROLES_SLUGS, TYPE_USERS_DIRAM } from '../../../../config/enviroment'
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { RolDiram } from '../../../../interfaces/roles-diram'
import { environment } from '../../../../../environments/environment'
import { CorporatesService } from '../../../../services/corporates/corporates.service'
import { PlantsService } from '../../../../services/plants/plants.service'
import { ProjectsService } from '../../../../services/projects/projects.service'
import { NzFormatEmitEvent, NzTreeNode, NzTreeNodeOptions } from 'ng-zorro-antd/tree'
import { map } from 'rxjs/operators'
import { NzCascaderOption } from 'ng-zorro-antd/cascader'
import { isNumber } from '@amcharts/amcharts4/core'
import { NzTreeSelectComponent } from 'ng-zorro-antd/tree-select'
import { ParamRequest } from 'src/app/interfaces/interfaces.index'

/**
 * Componente para crear modales para crear o editar usuarios.
 * Dependiendo del usuario a crear o editar las opciones del formulario cambia.
 * Si hay un usuario en la entrada del componente este entrar en modo edicion.
 *
 * @export
 * @class CreateUserModalComponent
 * @implements {OnInit}
 * @implements {OnChanges}
 */
@Component({
  selector: 'vb-create-user-modal',
  templateUrl: './create-user-modal.component.html',
  styleUrls: ['./create-user-modal.component.scss'],
})
export class CreateUserModalComponent implements OnInit, OnChanges {
  /**
   * Variable de control para el estado de carga de la información.
   *
   * @type {boolean}
   * @memberof CreateUserModalComponent
   */
  @Input() isLoadingData: boolean = true
  /**
   * Variable de control para el estado de envio de informacion.
   *
   * @type {boolean}
   * @memberof CreateUserModalComponent
   */
  @Input() isSaving: boolean = false
  /**
   * Modal actualmente abierto.
   *
   * @type {NzModalRef}
   * @memberof CreateUserModalComponent
   */
  @Input() currentModal: NzModalRef
  /**
   * Tipo de usuario que sera creado.
   *
   * @type {number}
   * @memberof CreateUserModalComponent
   */
  @Input() type: number
  /**
   * Lista de clientes.
   *
   * @type {Client[]}
   * @memberof CreateUserModalComponent
   */
  @Input() corporate: Corporate = null
  /**
   * Usuario actual, si no es null se edita la informacion, de lo contrario crea un nuevo usuario.
   *
   * @type {User}
   * @memberof CreateUserModalComponent
   */
  @Input() currentUser: User
  /**
   * Arreglo de roles
   *
   * @type {Rol[]}
   * @memberof CreateUserModalComponent
   */
  @Input() roles: Rol[] = []
  /**
   * Arreglo de niveles de permisos.
   *
   * @type {Level[]}
   * @memberof CreateUserModalComponent
   */
  @Input() levels: Level[] = []
  /**
   * Evento para emitr un envio de informacion de usuario.
   *
   * @type {EventEmitter<User>}
   * @memberof CreateUserModalComponent
   */
  @Output() sendUser: EventEmitter<User> = new EventEmitter<User>()
  /**
   * Roles disponibles en la plataforma.
   *
   * @type {RolDiram}
   * @memberof CreateUserModalComponent
   */
  public SLUGS_ROLES: RolDiram = ROLES_SLUGS
  /**
   * Grupo de formularios para crear un usuario.
   *
   * @type {FormGroup}
   * @memberof CreateUserModalComponent
   */
  public validateForm!: FormGroup
  /**
   * Arreglos de nodos para el tree select.
   *
   * @type {NzTreeNodeOptions[]}
   * @memberof CreateUserModalComponent
   */
  public nodes: NzTreeNodeOptions[] = []
  /**
   * Rol seleccionado actualmente.
   *
   * @type {Rol}
   * @memberof CreateUserModalComponent
   */
  public selectedRol: Rol = null
  /**
   *
   *
   * @type {boolean}
   * @memberof CreateUserModalComponent
   */
  public isLoadLevels: boolean = true

  /**
   * Creates an instance of CreateUserModalComponent.
   * @param {FormBuilder} _fb
   * @param {LevelService} _levelService
   * @param {CorporatesService} _corporatesService
   * @param {ClientService} _clientService
   * @param {PlantsService} _plantsService
   * @param {ProjectsService} _projectsService
   * @param {PointsService} _measurePointService
   * @memberof CreateUserModalComponent
   */
  constructor(
    private _fb: FormBuilder,
    private _corporatesService: CorporatesService,
    private _plantsService: PlantsService,
    private _projectsService: ProjectsService,
  ) {
  }

  /**
   * OnInit
   *
   * @memberof CreateUserModalComponent
   */
  ngOnInit(): void {
    this.validateForm = this._fb.group({
      nickname: [null, [Validators.required]],
      lastname: [null, [Validators.required]],
      email: [null, [Validators.email, Validators.required]],
      phoneNumberPrefix: ['+52', [Validators.required]],
      phoneNumber: [null, [Validators.pattern('[0-9]{10}$'), Validators.required]],
      client: [null, [Validators.required]],
      rol: [null, [Validators.required]],
    })

    if (this.type !== 0) {
      this.validateForm.removeControl('client')
    }
  }

  /**
   * OnChanges
   *
   * @param {SimpleChanges} changes
   * @memberof CreateUserModalComponent
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isLoadingData) {
      if (!changes.isLoadingData.currentValue && this.currentUser) {
        this.validateForm.removeControl('password')
        this.email.setValue(this.currentUser.email)
        this.nickname.setValue(this.currentUser.name)
        this.lastname.setValue(this.currentUser.flast_name)
        this.phoneNumberPrefix.setValue(this.currentUser?.country_code ? this.currentUser.country_code : '+52')
        this.phoneNumber.setValue(this.currentUser.phone)
        this.rol.setValue(this.currentUser?.roles[0].id)
      }
    }
  }

  /**
   * Funcion para verficar las validaciones de un formulario y realizar el envio de la informacion.
   *
   * @memberof CreateUserModalComponent
   */
  submitForm(): void {
    this.email.markAsDirty()
    this.email.updateValueAndValidity()

    this.nickname.markAsDirty()
    this.nickname.updateValueAndValidity()

    this.rol.markAsDirty()
    this.rol.updateValueAndValidity()

    if (this.selectedRol && this.selectedRol?.slug !== ROLES_SLUGS.admin_diram) {
      if (this.levelAccess) {
        this.levelAccess.markAsDirty()
        this.levelAccess.updateValueAndValidity()
      }
    } else {
      this.validateForm.removeControl('levelAccess')
    }

    if (
      this.email.invalid ||
      this.nickname.invalid ||
      this.rol.invalid ||
      this.levelAccess?.invalid
    ) {
      return
    } else {
      const user: User = {
        name: this.nickname.value,
        flast_name: this.lastname.value,
        email: this.email.value,
        country_code: this.phoneNumberPrefix.value,
        phone: this.phoneNumber.value,
        access: this.currentUser ? this.currentUser.access : true,
        user_type: this.type,
        id_corporate: this.corporate ? this.corporate.id : null,
        id_rol: this.rol.value,
        permissions: this.levelAccess?.value ? this.levelAccess.value : null,
      }

      if (this.type === TYPE_USERS_DIRAM && this.selectedRol.slug === this.SLUGS_ROLES.admin_diram) {
        delete user.permissions
      }
      this.sendUser.emit(user)
    }
  }

  /**
   * Funcion para destruir el modal actual.
   *
   * @memberof CreateUserModalComponent
   */
  public handleCancel(): void {
    this.currentModal.destroy()
  }

  /**
   * Callback para detectar el cambio de rol.
   *
   * @param {number} rolId
   * @memberof CreateUserModalComponent
   */
  public onChangeRol(rolId: number): void {
    this.selectedRol = this.roles.find(role => role.id === rolId)
    if (this.selectedRol && this.selectedRol.slug !== this.SLUGS_ROLES.admin_diram) {
      if (!this.levelAccess) {
        this.validateForm.addControl(
          'levelAccess',
          this._fb.control({ value: '', disabled: true }, Validators.required),
        )
      }

      const corporateId: number = this.corporate?.id
      this.levelAccess.setValue(null)
      this.levelAccess.disable()
      this.getLevelForUsers(corporateId)
    }
  }

  /**
   * Funcion para obtener los niveles de acessos para usuarios internos.
   *
   * @memberof CreateUserModalComponent
   */
  public getLevelForUsers(corporateId: number = null): void {
    const params: ParamRequest[] = [{param: 'active', value: true}]
    this._corporatesService.getAllElements(params).pipe(
      map((corporates: Corporate[]) => {
        corporates = isNumber(corporateId) ? corporates.filter(c => c.id === corporateId) : corporates
        const nodes: NzTreeNodeOptions[] = corporates.map(corporate => ({
          title: corporate.name,
          key: corporate.id.toString(10),
          disabled: this.selectedRol.slug === ROLES_SLUGS.operator,
          expanded: false
        }))
        return nodes
      }),
    )
      .subscribe(
        node => {
          this.nodes = [...node]
          this.levelAccess.enable()
        },
        error => {
          this.nodes = []
        },
      )
  }

  /**
   * Callback para detectar el cambio al abrir un nodo del tree select y carga el siguiente nodo hijo.
   *
   * @param {NzFormatEmitEvent} event Evento emitido del nodo.
   * @memberof CreateUserModalComponent
   */
  public onExpandChange(event: NzFormatEmitEvent): void {
    const node = event.node
    if (node && node.getChildren().length === 0 && node.isExpanded) {
      this.loadNode(node).then(data => {
        node.addChildren(data)
      })
    }
  }

  /**
   * Funcion para cargar nodos del tree select.
   *
   * @private
   * @param {NzTreeNode} treeNode
   * @return {*}  {Promise<NzTreeNodeOptions[]>}
   * @memberof CreateUserModalComponent
   */
  private loadNode(treeNode: NzTreeNode): Promise<NzTreeNodeOptions[]> {
    const childrenPosition: number = treeNode.level
    switch (childrenPosition) {
      case 0:
        const corporateId: number = parseInt(treeNode.key, 10)
        return this._plantsService
          .getPlantsByCorporate(corporateId)
          .pipe(
            map(async (plants: Plant[]) => {
              const nodes: NzCascaderOption[] = await Promise.all(plants.map(async (plant) => {
                const project: Project = await this._projectsService.getProjectActiveByPlant(plant.id).toPromise().then()
                const node = {
                  title: plant.name,
                  key: `${treeNode.key}-${plant.id_client}-${project.id}-${plant.id}`,
                  isLeaf: true
                }
                return node
              }))
              return nodes
            }),
          )
          .toPromise()
          .then()
    }
  }

  /**
   * Callback para cambios en el tree select
   *
   * @param {string} permission
   * @memberof CreateUserModalComponent
   */
  public onChange(permission: string): void {
    if (!environment.production) {
      console.log(this.levelAccess.value)
    }
  }

  /**
   * Getter de form control para email.
   *
   * @readonly
   * @memberof CreateUserModalComponent
   */
  get email() {
    return this.validateForm.controls.email
  }

  /**
   * Getter de form control para nickname.
   *
   * @readonly
   * @memberof CreateUserModalComponent
   */
  get nickname() {
    return this.validateForm.controls.nickname
  }

  /**
   * Getter de form control para lastname.
   *
   * @readonly
   * @memberof CreateUserModalComponent
   */
  get lastname() {
    return this.validateForm.controls.lastname
  }

  /**
   * Getter de form control para phoneNumberPrefix.
   *
   * @readonly
   * @memberof CreateUserModalComponent
   */
  get phoneNumberPrefix() {
    return this.validateForm.controls.phoneNumberPrefix
  }

  /**
   * Getter de form control para phoneNumber.
   *
   * @readonly
   * @memberof CreateUserModalComponent
   */
  get phoneNumber() {
    return this.validateForm.controls.phoneNumber
  }

  /**
   * Getter de form control para rol.
   *
   * @readonly
   * @memberof CreateUserModalComponent
   */
  get rol() {
    return this.validateForm.controls.rol
  }

  /**
   * Getter de form control para niveles de acceso.
   *
   * @readonly
   * @memberof CreateUserModalComponent
   */
  get levelAccess() {
    return this.validateForm.controls.levelAccess
  }
}
