import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { NzDateMode } from 'ng-zorro-antd/date-picker/standard-types'
import { CDRVersionReport, TYPE_VERSION_REPORT } from '../../../config/enviroment'
import { RangeDate } from '../../../interfaces/range-date'


export interface PanelOptionsGroup {
  typeReport: boolean
  managers: boolean
  datePicker: boolean
  version: boolean
}


@Component({
  selector: 'widget-menu-panel-control',
  templateUrl: './menu-panel-control.component.html',
  styleUrls: ['./menu-panel-control.component.scss'],
})
export class MenuPanelControlComponent implements OnInit, OnChanges {

  @Input() mode: NzDateMode = 'month'

  @Input() showOption: boolean = false

  public optionsActive: PanelOptionsGroup

  @Input() version: CDRVersionReport = TYPE_VERSION_REPORT.REPORT_2022

  @Output() clickEvent: EventEmitter<any> = new EventEmitter<any>()

  @Output() generateEvent: EventEmitter<RangeDate> = new EventEmitter<RangeDate>()

  public topTextCalendar = ''

  public dateFormat: string = 'yyyy-MM-dd'

  public date: Date = null

  public actionView: string = 'estimated'

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.showOption) {
      this.optionsActive = { typeReport: true, managers: false, version: true, datePicker: true }
      this.date = null
      this.getTextCalendar(this.mode)
    } else {
      this.actionView = 'estimated'
      this.optionsActive = { typeReport: false, managers: true, version: false, datePicker: false }
    }
  }

  ngOnInit(): void {
  }

  private getTextCalendar(mode: NzDateMode): void {
    switch (mode) {
      case 'year':
        this.dateFormat = 'yyyy'
        this.topTextCalendar = 'Selecciona el año'
        break
      case 'month':
        this.dateFormat = 'yyyy-MM'
        this.topTextCalendar = 'Selecciona el mes'
        break
      case 'week':
        this.dateFormat = 'yyyy-MM-dd'
        this.topTextCalendar = 'Selecciona el periodo'
        break
      default:
        this.topTextCalendar = 'Selección no valida'
        break
    }
  }

  onChangeMode(mode: NzDateMode): void {
    if (this.mode !== mode) {
      this.getTextCalendar(this.mode)
      this.clickEvent.emit({ mode: mode, version: this.version })
    }
  }

  onChange(event: { mode: NzDateMode, version: CDRVersionReport }): void {
    if (this.date) {
      event['date'] = this.date
      this.clickEvent.emit(event)
    }
  }

  onChangeVersion(event: { mode: NzDateMode, version: CDRVersionReport }): void {
    if (this.version !== event.version) {
      this.version = event.version
      this.clickEvent.emit(event)
    }
  }

  onReport(dates: RangeDate): void {
    this.generateEvent.emit(dates)
  }

  onChangeView(action: any) {
    if (action) {
      this.actionView = action.view
      this.clickEvent.emit(action)
    }
  }
}
