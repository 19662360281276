<section>
  <nz-card style="height: calc(100vh - 234px); position: relative; overflow: auto;">
    <h3 class='title-section'>{{ 'notifications.settings-secctionTitle' | translate }}</h3>
    <ng-container *ngIf='!isLoadingPlantData && plantInfoReady else statusView'>
      <div class='row mb-3'>
        <div class='col-12'>
          <nz-collapse>
            <nz-collapse-panel
              [nzHeader]="'Notificaciones de indicadores de Código de Red'"
              [nzActive]='false'>
  
              <div class='row mb-2'>
                <div class='col-12'>
                  <p>
                    <i class='mr-2' nz-icon nzType='user' nzTheme='outline'></i>
                    <span class='text-title'>Personas autorizadas</span>
                  </p>
                </div>
              </div>
  
              <div class='row mb-3'>
                <div class='col-12'>
                  <ng-container *ngFor='let notification of notificationsIndicators; let i = index'>
                    <vb-notification-options-card [notification]='notification'
                                                  [isLast]='i === notificationsIndicators.length - 1'
                                                  [isLoading]='isLoad'
                                                  (statusChanged)='changeStatus($event, notificationsIndicators)'>
                    </vb-notification-options-card>
                  </ng-container>
                </div>
              </div>
            </nz-collapse-panel>
          </nz-collapse>
        </div>
      </div>
      <div class='row mb-3'>
        <div class='col-12'>
          <nz-collapse>
            <nz-collapse-panel
              [nzHeader]="'Notificaciones de reporte de Cumplimiento de código de red'"
              [nzActive]='false'>
  
              <div class='row mb-2'>
                <div class='col-12'>
                  <p>
                    <i class='mr-2' nz-icon nzType='user' nzTheme='outline'></i>
                    <span class='text-title'>Personas autorizadas</span>
                  </p>
                </div>
              </div>
  
              <div class='row mb-3'>
                <div class='col-12'>
                  <ng-container *ngFor='let notification of notificationsReport; let i = index'>
                    <vb-notification-options-card [notification]='notification'
                                                  [isLast]='i === notificationsReport.length - 1'
                                                  [isLoading]='isLoad'
                                                  (statusChanged)='changeStatus($event, notificationsReport)'>
                    </vb-notification-options-card>
                  </ng-container>
                </div>
              </div>
            </nz-collapse-panel>
          </nz-collapse>
        </div>
      </div>
    </ng-container>
  </nz-card>
</section>

<ng-template #statusView>
  <ng-container *ngIf='!isLoadingPlantData else loaderCircles'>
    <ng-container *ngTemplateOutlet='errorMessage'></ng-container>
  </ng-container>
</ng-template>

<ng-template #loaderCircles>
  <widget-loader-circles></widget-loader-circles>
</ng-template>

<ng-template #errorMessage>
  <widget-wrap-message-info [firstText]='"Esta planta no tiene información."'></widget-wrap-message-info>
</ng-template>