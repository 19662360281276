import { Corporate, Plant, User } from '../../models/models.index'
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { ActivatedRoute, NavigationEnd, QueryParamsHandling, Router, RouterOutlet } from '@angular/router'
import { fadein, slideFadeinRight, slideFadeinUp, zoomFadein } from '../router-animations'
import { IdleTimeoutManager } from 'idle-timer-manager'
import { NzModalService } from 'ng-zorro-antd/modal'
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { environment } from '../../../environments/environment'
import {
  AVAILABLE_REPORT,
  CDRVersionReport,
  ID_DEFAULT_CORPORATE,
  ROLES_SLUGS,
  TIME_OUT_SESSION,
  TYPE_MENU_LEFT,
  TYPE_VERSION_REPORT,
} from '../../config/enviroment'
import { MenuItem } from '../../interfaces/menu-item'
import { NzDateMode } from 'ng-zorro-antd/date-picker/standard-types'
import { RangeDate } from '../../interfaces/range-date'
import { BarButtons, ButtonAction } from '../../pages/widgets/bar-buttons/bar-buttons.component'

import { SettingsFacade } from './../../store/settings/facade'
import { UserFacade } from './../../store/user/facade'
import { CorporateFacade } from './../../store/corporate/corporate.facade'
import { PlantFacade } from './../../store/plant/plant.facade'
import { ReportFacade } from './../../store/report/report.facade'
import { BarButtonsFacade } from './../../store/bar-buttons/facade'
import { PanelControlFacade } from './../../store/panel-control/facade'

/**
 * Compenente para el layout principal para los componentes.
 *
 * @export
 * @class LayoutMainComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'layout-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  animations: [slideFadeinUp, slideFadeinRight, zoomFadein, fadein],
})
export class LayoutMainComponent implements OnInit, OnDestroy {
  @ViewChild('timeOutTmpl') timeOutTmpl: TemplateRef<any>
  /**
   * Configuracion del layout.
   *
   * @type {*}
   * @memberof LayoutMainComponent
   */
  settings: any
  /**
   * ----
   *
   * @type {Number}
   * @memberof LayoutMainComponent
   */
  touchStartPrev: Number = 0
  /**
   * ----
   *
   * @type {Boolean}
   * @memberof LayoutMainComponent
   */
  touchStartLocked: Boolean = false
  /**
   * Manejador para expirar el tiempo cuando no se tiene iteraccion el usuario.
   *
   * @type {IdleTimeoutManager}
   * @memberof LayoutMainComponent
   */
  private timer: IdleTimeoutManager
  /**
   * Variable para usuario actual en sesion.
   *
   * @type {User}
   * @memberof LayoutMainComponent
   */
  public currentUser: User = null

  public menuData: MenuItem[] = []

  public typeMenu: number

  public typesMenu = TYPE_MENU_LEFT

  public isReadyMenuData: boolean = false

  public corporates: Corporate[] = []

  private corporateSelect: Corporate

  public plantSelect: Plant = null

  public showPlantName: boolean = true

  public modeMenuControl: NzDateMode

  public cdrVersion: CDRVersionReport = TYPE_VERSION_REPORT.REPORT_2022

  public typeView: string

  public actionButtons: BarButtons[] = null

  public showOption: boolean = false

  private destroy$: Subject<boolean> = new Subject<boolean>()

  /**
   * Creates an instance of LayoutMainComponent.
   * @param {Store<any>} store
   * @param _router
   * @param _routeActive
   * @param {NzModalService} _modalService
   * @param _plantService
   * @memberof LayoutMainComponent
   */
  constructor(
    private _router: Router,
    private _routeActive: ActivatedRoute,
    private _modalService: NzModalService,
    private _settingsFacade: SettingsFacade,
    private _userFacade: UserFacade,
    private _corporateFacade: CorporateFacade,
    private _plantFacade: PlantFacade,
    private _reportFacade: ReportFacade,
    private _barButtonsFacade: BarButtonsFacade,
    private _panelControlFacade: PanelControlFacade
  ) {

    this._settingsFacade.settingsSelector$.pipe(takeUntil(this.destroy$)).subscribe(state => {
      this.settings = state
    })

    this._barButtonsFacade.barButtonsSelector$.pipe(takeUntil(this.destroy$)).subscribe(state => {
      this.showOption = this._router.url.includes('managers') && state.type === 'energy' ? true : false
    })

    this._userFacade.userSelector$.pipe(takeUntil(this.destroy$)).subscribe(state => {
      if (state && state.authorized && !state.loading) {
        this.menuData.length = 0
        this.currentUser = state
        this.corporates = this.currentUser.corporates
        this.corporates.map(d => {
          this.menuData.push({ title: d.name, hidden: false, metaData: { id: d?.id ? d.id : -1 } })
        })
      }
    })

    this._routeActive.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.showPlantName = !this._router.url.includes('/profile/user') &&
        !this._router.url.includes('/home/overview') &&
        !this._router.url.includes('/administration')
      const typeView = params?.type_report
      const cdrVersion = Number(params?.cdr_version)
      this.setMenuOptions(typeView, cdrVersion)
    })

    this._router.events
      .pipe(
        takeUntil(this.destroy$),
        filter(event => event instanceof NavigationEnd),
        map(() => this._routeActive),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild
          }
          return route
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data),
      )
      .subscribe(data => {
        this.typeMenu = data['menu']
        this.actionButtons = data['barButtons']
      })

    // TODO DESACOPLAR LOGICA CONFUSA
    this._plantFacade.plantSelector$
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        if (data.isLoadingSuccess) {
          if (this.menuData.length > 0) {
            this.isReadyMenuData = data.isLoadingSuccess
            this.menuData.map((item) => {
              item.children = []
              if (item.metaData.id === data.corporate.id) {
                const itemChildren: MenuItem[] = []
                data.plants.map((plant) => {
                  itemChildren.push({ title: plant.name, children: null, hidden: false, metaData: { id: plant.id } })
                })
                item.children = itemChildren
              }
            })
            this.menuData = [...this.menuData]
          }
          if (data.plant) {
            this.plantSelect = data.plant
          } else {
            this.plantSelect = null
          }
        }

        if (!data.corporate) {
          const isUserAdmin: boolean = this.currentUser.roles[0].slug === ROLES_SLUGS.admin_diram
          const corporateDefaultId: number = isUserAdmin ? ID_DEFAULT_CORPORATE : this.currentUser.corporates[0].id
          this.searchPlants(corporateDefaultId)
        } else {
          this.corporateSelect = data.corporate
        }
      })
  }

  /**
   * OnInit.
   *
   * @memberof LayoutMainComponent
   */
  ngOnInit() {
    this.timer = new IdleTimeoutManager({
      key: 'app._timeout',
      timeout: TIME_OUT_SESSION,
      onExpired: () => {
        this._modalService.warning({
          nzTitle: '¡La sesión ha expirado!',
          nzContent: this.timeOutTmpl,
          nzOkText: 'Ir al inicio',
          nzClosable: false,
          nzKeyboard: false,
          nzMaskClosable: false,
          nzOnOk: () => {
            this._userFacade.logout()
          },
        })
      },
    })
  }

  private setMenuOptions(typeView: string, cdrVersion: CDRVersionReport = TYPE_VERSION_REPORT.REPORT_2022): void {
    // TODO ANALIZAR CAMBIO
    this.cdrVersion = cdrVersion ? cdrVersion : TYPE_VERSION_REPORT.REPORT_2022
    this.typeView = typeView
    switch (typeView) {
      case AVAILABLE_REPORT.official:
        this.modeMenuControl = 'month'
        break
      case AVAILABLE_REPORT.transactional:
      case AVAILABLE_REPORT.week:
        this.modeMenuControl = 'week'
        break
      default:
        this.modeMenuControl = 'year'
        break
    }
  }

  /**
   * Funcion para disparar las animaciones de cambio de ruta.
   *
   * @param {RouterOutlet} outlet
   * @param {string} animation
   * @return {*}
   * @memberof LayoutMainComponent
   */
  routeAnimation(outlet: RouterOutlet, animation: string) {
    const mapAnimation = {
      'slide-fadein-up': 'slideFadeinUp',
      'slide-fadein-right': 'slideFadeinRight',
      fadein: 'zoomFadein',
      'zoom-fadein': 'fadein',
    }
    if (animation === mapAnimation[this.settings.routerAnimation]) {
      return outlet.isActivated && outlet.activatedRoute.routeConfig.path
    }
  }

  public menuItemSelected(item: MenuItem): void {
    const itemId: number = item.metaData.id
    const levelItem: number = item.metaData.level
    let extras = {}
    switch (levelItem) {
      case 1:
        if (itemId) {
          this.searchPlants(itemId)
        }
        break
      case 2:
        if (this.typeMenu === TYPE_MENU_LEFT.COMPLEX || this.typeMenu === TYPE_MENU_LEFT.COMPOUND) {
          const extraUrl = { corporate_id: this.corporateSelect.id, plant_id: itemId }
          extras = { queryParams: extraUrl, queryParamsHandling: 'merge' }
          this.plantSelected(itemId)
        } else if (this.typeMenu === TYPE_MENU_LEFT.SIMPLE_USERS) {
          const extraUrl = { corporate_id: itemId }
          extras = { queryParams: extraUrl }
          const corporate: Corporate = this.corporates.find(c => c.id === itemId)
          this._corporateFacade.selectCorporate(corporate)
        }
        break
      default:
        if (!environment.production) {
          console.warn('Level item select not valid...')
        }
        break
    }
    if (item.url) {
      this._router.navigate([item.url], extras).then()
    }
  }

  public searchPlants(corporateId: number): void {
    const corporate: Corporate = this.corporates.find(c => c.id === corporateId)
    this._plantFacade.getPlantsByCorporate(corporate)
  }

  public plantSelected(plantId: number): void {
    this._plantFacade.selectPlant(plantId)
  }

  public panelControlCallback(event: any): void {
    if (event?.view) {
      this._panelControlFacade.buttonClick({ type: event.view })
    } else {
      this.changeRouteByPanelControl(event)
    }
  }

  changeRouteByPanelControl(event: any): void {
    let queryParams: any
    const month: number = event.date ? new Date(event.date).getMonth() : null
    const year: number = event.date ? new Date(event.date).getFullYear() : null
    let queryParamsHandling: QueryParamsHandling = ''
    switch (event.mode as NzDateMode) {
      case 'year':
        queryParams = {
          corporate_id: this.corporateSelect.id,
          plant_id: this.plantSelect.id,
          cdr_version: event.version,
          year,
        }
        break
      case 'month':
        queryParams = {
          type_report: AVAILABLE_REPORT.official,
          corporate_id: this.corporateSelect.id,
          plant_id: this.plantSelect.id,
          cdr_version: event.version,
          month,
          year,
        }
        break
      case 'week':
      default:
        if (this.typeView === AVAILABLE_REPORT.transactional) {
          queryParamsHandling = 'merge'
          queryParams = {
            type_report: AVAILABLE_REPORT.transactional,
            corporate_id: this.corporateSelect.id,
            plant_id: this.plantSelect.id,
            cdr_version: event.version,
          }
        } else {
          queryParams = {
            type_report: AVAILABLE_REPORT.week,
            corporate_id: this.corporateSelect.id,
            plant_id: this.plantSelect.id,
            cdr_version: event.version,
          }
        }
        break
    }
    this._router.navigate([], {
      queryParamsHandling,
      relativeTo: this._routeActive,
      queryParams,
    }).then()
  }

  public onClickEventBarButtons(action: ButtonAction) {
    this._barButtonsFacade.buttonClick({ ...action })
  }

  onGenerateReport(dates: RangeDate) {
    this._reportFacade.addReportPlant(this.corporateSelect.id,
      this.plantSelect.id,
      this.currentUser.id,
      { startDate: dates.startDate.yyyymmdd(), endDate: dates.endDate.yyyymmdd() }
    )
  }

  /**
   * onDestroy.
   *
   * @memberof LayoutMainComponent
   */
  ngOnDestroy(): void {
    this.timer.clear()
    this.destroy$.next(true)
    this.destroy$.unsubscribe()
  }
}
