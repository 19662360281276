import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ParamRequest } from 'src/app/interfaces/interfaces.index'
import { environment } from 'src/environments/environment'
import { catchError, map } from 'rxjs/operators'
import { Observable, of, throwError } from 'rxjs'

/**
 * Clase con metodos de servicio de usu comun en la aplicacion.
 *
 * @export
 * @class CommonService
 */
@Injectable({
  providedIn: 'root',
})
export class CommonService {
  /**
   * Etiqueta para obtener el access del token.
   *
   * @private
   * @type {string}
   * @memberof CommonService
   */
  private tokenLabel: string = 'accessToken'

  /**
   * Creates an instance of CommonService.
   * @param {HttpClient} _httpClient
   * @memberof CommonService
   */
  constructor(private _httpClient: HttpClient) { }

  /**
   * Funcion para obtener los tokens que se almacenan al inciar sesión en local storage.
   * Establece las cabeceras para las peticiones http.
   *
   * @return {*}  {HttpHeaders}
   * @memberof CommonService
   */
  public getTokenHeaders(): HttpHeaders {
    const tokenType: string = JSON.parse(localStorage.getItem('TokenType'))
    const token: string = JSON.parse(localStorage.getItem(this.tokenLabel))
    return new HttpHeaders({
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `${tokenType} ${token}`,
    })
  }

  /**
   * Funcion para establecer token temporal para acciones que no requieren inicio de sesión.
   *
   * @param {string} tokenTmp
   * @return {*}  {HttpHeaders}
   * @memberof CommonService
   */
  public getTmpTokenHeaders(tokenTmp: string): HttpHeaders {
    return new HttpHeaders({
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${tokenTmp}`,
    })
  }

  /**
   * Funcion que establece headers comunes para las peticiones http.
   *
   * @return {*}  {HttpHeaders}
   * @memberof CommonService
   */
  getCommonHeaders(): HttpHeaders {
    return new HttpHeaders({
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    })
  }

  /**
   * Funcion para establecer simple headers con una key extraida del entorno del proyecto.
   *
   * @return {*}  {HttpHeaders}
   * @memberof CommonService
   */
  public getSimpleKeyHeaders(): HttpHeaders {
    return new HttpHeaders({
      'X-Requested-With': 'XMLHttpRequest',
      'simple-api-key': environment.SIMPLE_API_KEY,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    })
  }

  /**
   * Funcion para obtener los headers firmado para archivos que no son JSON.
   * Utilizado en imagenes o archivos.
   *
   * @return {*}  {HttpHeaders}
   * @memberof CommonService
   */
  public getTokenFileHeaders(): HttpHeaders {
    const tokenType: string = JSON.parse(localStorage.getItem('TokenType'))
    const token: string = JSON.parse(localStorage.getItem(this.tokenLabel))
    return new HttpHeaders({
      enctype: 'multipart/form-data',
      Accept: 'application/json, text/plain, */*',
      Authorization: `${tokenType} ${token}`,
    })
  }

  /**
   * Funcion para establecer headers para verificacion de correo electronico.
   *
   * @return {*}  {HttpHeaders}
   * @memberof CommonService
   */
  getEmailVerifyHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    })
  }

  /**
   * Funcion que obtiene una cadena de los token que se estan almacenados en el local storage.
   *
   * @return {*}  {string}
   * @memberof CommonService
   */
  public getToken(): string {
    const tokenType: string = localStorage.getItem('TokenType')
    const token: string = localStorage.getItem(this.tokenLabel)
    return `${tokenType} ${token}`
  }

  /**
   * Funcion para realizar un maquepo de y devolver HttpParams de un Map.
   *
   * @param {Map<string, any>} paramsQuery
   * @return {*}  {HttpParams}
   * @memberof CommonService
   */
  public getQueryParamsFilter(paramsQuery: Map<string, any>): HttpParams {
    const entries = paramsQuery.entries()
    const paramObject = Array.from(paramsQuery.entries()).reduce((obj, [key, value]) => {
      obj[key] = String(value)
      return obj
    }, {})

    return new HttpParams({
      fromObject: paramObject,
    })
  }

  /**
   * Funcion para obtener multiples query params para una peticion.
   *
   * @param {string} url
   * @param {string[]} multipleQueryParams
   * @return {*}
   * @memberof CommonService
   */
  public getMultipleQueryParam(url: string, multipleQueryParams: string[]) {
    return `${url}?${multipleQueryParams.join('&')}`
  }

  /**
   * Funcion para establecer como no paginable una peticion con varios registros mediante query params.
   *
   * @return {*}  {Map<string, any>}
   * @memberof CommonService
   */
  public setParamNotPaginable(): Map<string, any> {
    const requestData = new Map<string, any>()
    requestData.set('paginable', false)
    return requestData
  }

  /**
   * Funcion para establecer la paginacion de una peticion con varios registros mediante query params.
   * @param {number} [page = 1] Numero de pagina del conjunto de registros.
   * @param {number} [perPage = 10] Cantidas de registros por peticion.
   * @return {Map<string, any>} {Map<string, any>} Parametros de consulta mapeados.
   * @memberof CommonService
   */
  public setParamPagination(page: number = 1, perPage: number = 10): Map<string, any> {
    const requestData = this.setParams2Request([
      { param: 'page', value: page },
      { param: 'per_page', value: perPage }
    ])
    return requestData
  }

  /**
   * Funcion para establcer una lista de parametros a una peticion, devuelve un map con llave y el valor.
   *
   * @param {ParamRequest[]} params
   * @return {*}  {Map<string, any>}
   * @memberof CommonService
   */
  public setParams2Request(params: ParamRequest[]): Map<string, any> {
    const requestParams = new Map<string, any>()
    if (params) {
      params.forEach((param, index) => {
        requestParams.set(param.param, param.value)
      })
      return requestParams
    }
    return null
  }

  private requestHeaders(auth: boolean): HttpHeaders {
    let headers = null
    if (auth) {
      headers = this.getTokenHeaders()
    } else {
      headers = this.getCommonHeaders()
    }
    return headers
  }

  private handlerResponse(response: any, key: string): any {
    if (response?.hasOwnProperty(key)) {
      return response[key]
    } else {
      return response
    }
  }

  /**
   * Funcion para manejar solicitudes de tipo get.
   *
   * @param {string} url Route of API service.
   * @param {ParamRequest[]} [paramsRequest=null] Array of query params
   * @param {string} [key='data'] key value of packed data array.
   * @param {boolean} [auth=true] If endpoint need API Token of user.
   * @return {Observable<any>} Observable of service with the data
   * @memberof CommonService
   */
  public handlerGetElements(url: string, paramsRequest: ParamRequest[] = null,
    key: string = 'data', auth: boolean = true,
    pagination: boolean = false, page: number = 1, perPage: number = 10): Observable<any> {
    let params: HttpParams = null
    let mapMerge: Map<string, any> = null

    if (pagination) {
      const mapPagination = this.setParamPagination(page, perPage)
      mapMerge = mapPagination
    }

    if (paramsRequest) {
      const mapParams: Map<string, any> = this.setParams2Request(paramsRequest)
      mapMerge = pagination ?
        new Map([...Array.from(mapMerge.entries()), ...Array.from(mapParams.entries())]) :
        mapParams
    }

    params = mapMerge ? this.getQueryParamsFilter(mapMerge) : null

    return this._httpClient.get(url, { headers: this.requestHeaders(auth), params }).pipe(
      map((response: any) => {
        return this.handlerResponse(response, key)
      }),
    )
  }

  /**
   * Funcion para manejar solicitudes de tipo put.
   *
   * @param {string} url Route of API service.
   * @param {ParamRequest[]} [paramsRequest=null] Array of query params
   * @param {any} body object to create or update
   * @param {string} [key='data'] key value of packed data array.
   * @param {boolean} [auth=true] If endpoint need API Token of user.
   * @return {Observable<any>} Observable of service with the data
   * @memberof CommonService
   */
  public handlerPutElement(url: string, paramsRequest: ParamRequest[] = null,
    body: any, key: string = 'data', auth: boolean = true): Observable<any> {
    let params: HttpParams = null
    if (paramsRequest) {
      const mapParams: Map<string, any> = this.setParams2Request(paramsRequest)
      params = this.getQueryParamsFilter(mapParams)
    }
    return this._httpClient.put(url, body, { headers: this.requestHeaders(auth), params }).pipe(
      map((response: any) => {
        return this.handlerResponse(response, key)
      }),
    )
  }

  /**
   * Funcion para manejar solicitudes de tipo patch.
   *
   * @param {string} url Route of API service.
   * @param {ParamRequest[]} [paramsRequest=null] Array of query params
   * @param {any} body object to create or update
   * @param {string} [key='data'] key value of packed data array.
   * @param {boolean} [auth=true] If endpoint need API Token of user.
   * @return {Observable<any>} Observable of service with the data
   * @memberof CommonService
   */
  public handlerPatchElement(url: string, paramsRequest: ParamRequest[] = null,
    body: any, key: string = 'data', auth: boolean = true): Observable<any> {
    let params: HttpParams = null
    if (paramsRequest) {
      const mapParams: Map<string, any> = this.setParams2Request(paramsRequest)
      params = this.getQueryParamsFilter(mapParams)
    }
    return this._httpClient.patch(url, body, { headers: this.requestHeaders(auth), params }).pipe(
      map((response: any) => {
        return this.handlerResponse(response, key)
      }),
    )
  }

  /**
   * Funcion para manejar solicitudes de tipo put.
   *
   * @param {string} url Route of API service.
   * @param {ParamRequest[]} [paramsRequest=null] Array of query params
   * @param {any} body object to create or update
   * @param {string} [key='data'] key value of packed data array.
   * @param {boolean} [auth=true] If endpoint need API Token of user.
   * @return {Observable<any>} Observable of service with the data
   * @memberof CommonService
   */
  public handlerPostElement(url: string,  paramsRequest: ParamRequest[] = null,
    body: any, key: string = 'data', auth: boolean = true): Observable<any> {
    let params: HttpParams = null
    if (paramsRequest) {
      const mapParams: Map<string, any> = this.setParams2Request(paramsRequest)
      params = this.getQueryParamsFilter(mapParams)
    }

    return this._httpClient.post(url, body, { headers: this.requestHeaders(auth), params }).pipe(
      map((response: any) => {
        return this.handlerResponse(response, key)
      }),
    )
  }

  /**
   * Funcion para manejar solicitudes de tipo put.
   *
   * @param {string} url Route of API service.
   * @param {ParamRequest[]} [paramsRequest=null] Array of query params
   * @param {string} [key='data'] key value of packed data array.
   * @param {boolean} [auth=true] If endpoint need API Token of user.
   * @return {Observable<any>} Observable of service with the data
   * @memberof CommonService
   */
  public handlerDeleteElement(url: string, paramsRequest: ParamRequest[] = null,
    key: string = 'data', auth: boolean = true): Observable<any> {
    let params: HttpParams = null
    if (paramsRequest) {
      const mapParams: Map<string, any> = this.setParams2Request(paramsRequest)
      params = this.getQueryParamsFilter(mapParams)
    }

    return this._httpClient.delete(url, { headers: this.requestHeaders(auth), params }).pipe(
      map((response: any) => {
        return this.handlerResponse(response, key)
      }),
    )
  }

  public handlerPutElementImage(url: string, image: File) {
    // TODO mejorar los servicios al hacer uso de throwError y estandarizar las respuestas.
    const formData = new FormData()
    formData.append('image', image)
    return this._httpClient
      .put(url, formData, { headers: this.getTokenFileHeaders() })
      .pipe(
        map((resolve: any) => {
          if (resolve) {
            return resolve.url
          } else {
            throwError({ message: 'Error to send imagen', error: 404 })
          }
        }),
        catchError(error => {
          return of(null)
        })
      )
  }
}
