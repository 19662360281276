<section>
  <nz-card style='height: calc(100vh - 234px); position: relative; overflow: auto;'>
    <div *ngIf='!isLoading else loader'>
      <div class='steps-content' [ngSwitch]='current'>
        <ng-container *ngSwitchCase='0'>
          <ng-container *ngTemplateOutlet='step_one'>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <ng-container *ngTemplateOutlet='add_transformers'>
          </ng-container>
        </ng-container>
      </div>
      <div class='steps-action'>
        <button nz-button nzType='default' (click)='pre()' *ngIf='current > 0'>
          <span>Regresar</span>
        </button>
        <button nz-button class='ant-btn-danger' *ngIf='current === 0 || current === lastPage'
                [routerLink]="['/profile/electric-plants']">
          <span>Cancelar</span>
        </button>
        <button nz-button nzType='primary' (click)='next()' *ngIf='current < lastPage'
                [disabled]='verifyFormsGroupValid || !verifyTotalTransformers'>
          <span>Siguiente</span>
        </button>
        <button nz-button nzType='primary' (click)='done()' *ngIf='current === lastPage'
                [disabled]='verifyFormsGroupValid || !verifyTotalTransformers'>
          <span>Actualizar Planta</span>
        </button>
      </div>
    </div>
  </nz-card>
</section>

<ng-template #step_one>
  <div class='row'>
    <div class='col-12'>
      <p class='font-size-18 font-weight-bold'>
        Información eléctrica de la planta
      </p>
    </div>
  </div>

  <form class='form-one' [formGroup]='formInitialGroup'>
    <div class='row'>
      <nz-form-label
        [nzRequired]='questions[0].isRequired'
        class='col-12 col-md-6 section-questions'
        nzTooltipTitle='{{ questions[0].tooltip }}'>
        {{ questions[0].question }}
      </nz-form-label>
      <div class='col-12 col-md-6'>
        <nz-select class='w-100' [formControlName]='questions[0].formControlName'>
          <nz-option [nzValue]='"digital"' nzLabel='Sí'></nz-option>
          <nz-option [nzValue]='"fisica"' nzLabel='No'></nz-option>
        </nz-select>
      </div>
    </div>

    <div class='row' *ngIf='questions[1].isRequired'>
      <nz-form-label
        [nzRequired]='questions[1].isRequired'
        class='col-12 col-md-6 section-questions'
        nzTooltipTitle='{{ questions[1].tooltip }}'>
        {{ questions[1].question }}
      </nz-form-label>
      <div class='col-12 col-md-6'>
        <nz-input-number class='w-100' [nzPlaceHolder]='questions[1].placeholder'
                         [formControlName]=' questions[1].formControlName' [nzMin]='0' [nzStep]='1'>
        </nz-input-number>
      </div>
    </div>

    <div class='row' *ngIf='questions[2].isRequired'>
      <nz-form-label
        [nzRequired]='questions[2].isRequired'
        class='col-12 col-md-6 section-questions'
        nzTooltipTitle='{{ questions[2].tooltip }}'>
        {{ questions[2].question }}
      </nz-form-label>
      <div class='col-12 col-md-6'>
        <nz-input-number class='w-100' [nzPlaceHolder]='questions[2].placeholder'
                         [formControlName]=' questions[2].formControlName' [nzMin]='0' [nzStep]='1'>
        </nz-input-number>
      </div>
    </div>

    <div class='row'>
      <nz-form-label
        [nzRequired]='questions[3].isRequired'
        class='col-12 col-md-6 section-questions'
        nzTooltipTitle='{{ questions[3].tooltip }}'>
        {{ questions[3].question }}
      </nz-form-label>
      <div class='col-12 col-md-6'>
        <nz-select class='w-100' [formControlName]='questions[3].formControlName'>
          <nz-option [nzValue]='true' nzLabel='Sí'></nz-option>
          <nz-option [nzValue]='false' nzLabel='No'></nz-option>
        </nz-select>
      </div>
    </div>
  </form>

  <div class='row'>
    <div class='col-12'>
      <p class='font-size-15 font-weight-bold'>
        Si cuentas con el archivo del punto unifilar, adjuntalo
      </p>
    </div>
  </div>

  <div class='row'>
    <div class='col-12'>
      <nz-upload
        [nzAccept]='"image/png, image/jpeg"'
        [nzBeforeUpload]='beforeUpload'
        [nzCustomRequest]='customRequest'
        [nzShowUploadList]='false'
        (nzChange)='handleChange($event)'>
        <button nz-button>
          <i nz-icon nzType='upload'></i>
          Subir archivo
        </button>
      </nz-upload>
    </div>
  </div>

  <div class='row mt-3'>
    <div class='col-12'>
      <p class='font-size-15 font-weight-bold'>
        Agregue la información del punto principal (acometida)
      </p>
    </div>
  </div>

  <form class='form-one' [formGroup]='formGramGroup'>
    <ng-container *ngFor='let question of questionsFormGram; let i = index'>
      <div class='row'>
        <nz-form-label
          [nzRequired]='question.isRequired'
          class='col-12 col-md-6 section-questions'
          nzTooltipTitle='{{ question.tooltip }}'>
          {{ question.question }}
        </nz-form-label>
        <div class='col-12 col-md-6'>
          <input
            *ngIf='i === 0 || i === 1
            || i === questionsFormGram.length -1
            || i === questionsFormGram.length -2
            || i === questionsFormGram.length -3
            || i === questionsFormGram.length -4
            else numberInput'
            nz-input placeholder='{{question.placeholder}}'
            [formControlName]='question.formControlName' />
          <ng-template #numberInput>
            <nz-input-number class='w-100' [nzPlaceHolder]='question.placeholder'
                             [formControlName]='question.formControlName'>
            </nz-input-number>
          </ng-template>
        </div>
      </div>
    </ng-container>
  </form>
</ng-template>

<ng-template #add_transformers>
  <div class='row'>
    <div class='col-12'>
      <p class='font-size-18 font-weight-bold'>
        Información eléctrica de la planta
      </p>
    </div>
  </div>

  <div class='row'>
    <div class='col-12'>
      <p class='font-size-15 font-weight-bold'>
        Agregue la información del transformador {{current}}
      </p>
    </div>
  </div>

  <form class='form-one' [formGroup]='formTransformer'>
    <ng-container *ngFor='let question of questionsTransformer; let i = index'>
      <div class='row'>
        <nz-form-label
          [nzRequired]='question.isRequired'
          class='col-12 col-md-6 section-questions'
          nzTooltipTitle='{{ question.tooltip }}'>
          {{ question.question }}
        </nz-form-label>
        <div class='col-12 col-md-6'>
          <input
            *ngIf='i === 3 || i === 7
            else numberInput'
            nz-input placeholder='{{question.placeholder}}'
            [formControlName]='question.formControlName' />
          <ng-template #numberInput>
            <nz-input-number class='w-100' [nzPlaceHolder]='question.placeholder'
                             [formControlName]='question.formControlName'>
            </nz-input-number>
          </ng-template>
        </div>
      </div>
    </ng-container>
  </form>
</ng-template>

<ng-template #loader>
  <nz-spin *ngIf='isLoading' [nzSpinning]='true' [nzTip]='"Cargando información..."' class='col-12'>
    <div class='card loader-show w-100'></div>
  </nz-spin>
</ng-template>
