import { Pipe, PipeTransform } from '@angular/core'
import { CalculatedParameters, FormGram } from '../models/models.index'

/**
 * Pipe para establecer los valores con base a los parametros de los objetos
 * que vienen en ellos.
 *
 * @export
 * @class SetValueByParameterPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'setValueByParameter',
})
export class SetValueByParameterPipe implements PipeTransform {
  transform(key: string, formGram: FormGram, calculatedParameters: CalculatedParameters): string {
    if (key in formGram) {
      if (typeof formGram[key] !== 'boolean') {
        return formGram[key]
      }
      return formGram[key] ? 'Sí' : 'No'
    } else {
      if (calculatedParameters) {
        return calculatedParameters[key]
      } else {
        return 'N/A'
      }
    }
  }
}
