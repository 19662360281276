import { Injectable } from '@angular/core'
import { CrudService } from '../shared/crud.service'
import { Observable } from 'rxjs'
import { Transformer } from '../../models/models.index'
import { URL_API } from '../../config/enviroment'
import { map } from 'rxjs/operators'
import { environment } from '../../../environments/environment'
import { TransformerRepsitory } from './transformer.repository'

/**
 * Servicio para manejar la informacion de los transformadores via la API.
 *
 * @export
 * @class TransformerService
 * @extends {CrudService}
 */
@Injectable({
  providedIn: 'root',
})
export class TransformerService extends CrudService {
  /**
   * Creates an instance of TransformerService.
   * @param {TransformerRepsitory} _transformerRepository
   * @memberof TransformerService
   */
  constructor(protected _transformerRepository: TransformerRepsitory) {
    super(_transformerRepository)
  }

  /**
   * Funcion para servicio para obtener los transformadores de una empresa.
   *
   * @param {number} plantId Identificador de la planta.
   * @return {Observable<FormGram>}  {Observable<FormGram>} Observable con la respuesta de nuestro peticion.
   * @memberof TransformerService
   */
  public getTransformersByPlant(plantId: number): Observable<Transformer[]> {
    return this._transformerRepository.getTransformersByPlant(plantId)
  }
}
