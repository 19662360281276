import { INT_POINT_DIGITAL, INT_POINT_REAL, TYPE_REAL, TYPE_VIRTUAL } from './../../../config/enviroment'
import { TransformerService } from './../../../services/transformer/transformer.service'
import { PointsService } from './../../../services/points/points.service'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { Question } from '../../../interfaces/question'
import { QuestionService } from '../../../services/question/question.service'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzMessageService } from 'ng-zorro-antd/message'
import { ActivatedRoute, Router } from '@angular/router'
import { forkJoin, Observable, Observer, of, Subscription } from 'rxjs'
import { finalize, map, mergeMap } from 'rxjs/operators'
import { PlantsService } from '../../../services/plants/plants.service'
import { FormGramService } from '../../../services/form-gram/form-gram.service'
import { Plant, FormGram, UnifierPoint, Transformer } from 'src/app/models/models.index'
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload'
import { NzModalService } from 'ng-zorro-antd/modal'

/**
 *  Type function para el callback de actualizar variables.
 */
type updateValuesCallback = () => void

/**
 * Componente para la vista para editar perfiles electricos.
 *
 * @export
 * @class EditProfileElectricComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-edit-profile-electric',
  templateUrl: './edit-profile-electric.component.html',
  styleUrls: ['./edit-profile-electric.component.scss'],
})
export class EditProfileElectricComponent implements OnInit, OnDestroy {
  /**
   * Arreglos de preguntas disponibles.
   *
   * @type {Question[]}
   * @memberof EditProfileElectricComponent
   */
  public questions: Question[] = []
  /**
   * Variable para alamacenar preguntas iniciales.
   *
   * @type {Question[]}
   * @memberof EditProfileElectricComponent
   */
  public questionsInitial: Question[] = []
  /**
   * Variable para almacenar las preguntas para el formulario del gram.
   *
   * @type {Question[]}
   * @memberof EditProfileElectricComponent
   */
  public questionsFormGram: Question[] = []
  /**
   * Variable para almacenar las preguntas de los transformadores.
   *
   * @type {Question[]}
   * @memberof EditProfileElectricComponent
   */
  public questionsTransformer: Question[] = []
  /**
   * Variable para almacenar la planta seleccionada.
   *
   * @type {Plant}
   * @memberof EditProfileElectricComponent
   */
  public plantSelected: Plant
  /**
   * Identificador del corporativo.
   *
   * @private
   * @type {number}
   * @memberof EditProfileElectricComponent
   */
  private _corporateId: number = null
  /**
   * Identificador del cliente.
   *
   * @private
   * @type {number}
   * @memberof EditProfileElectricComponent
   */
  private _clientId: number = null
  /**
   * Identificador del proyecto
   *
   * @private
   * @type {number}
   * @memberof EditProfileElectricComponent
   */
  private _projectId: number = null
  /**
   * Variable para alamacenar la informacion de punto unifilar de planta.
   *
   * @private
   * @type {UnifierPoint}
   * @memberof EditProfileElectricComponent
   */
  private _uniPoint: UnifierPoint = null
  /**
   * Variable para almacenar la informacion del form gram.
   *
   * @private
   * @type {FormGram}
   * @memberof EditProfileElectricComponent
   */
  private _formGram: FormGram = new FormGram()
  /**
   * Arreglo para almacenar transformadores.
   *
   * @private
   * @type {Transformer[]}
   * @memberof EditProfileElectricComponent
   */
  private _transformers: Transformer[] = []
  /**
   * Variable para grupo de formulario para las principales preguntas.
   *
   * @type {FormGroup}
   * @memberof EditProfileElectricComponent
   */
  public formInitialGroup: FormGroup
  /**
   * Variable para grupo de formulario para el form gram.
   *
   * @type {FormGroup}
   * @memberof EditProfileBusinessComponent
   */
  public formGramGroup: FormGroup
  /**
   * Variable para grupo de formulario para transformadores.
   *
   * @type {FormGroup}
   * @memberof EditProfileBusinessComponent
   */
  public formTransformer: FormGroup
  /**
   * Variable auxiliar para almacenar temporalmente el formulario anterior de transformadores.
   *
   * @type {FormGroup}
   * @memberof EditProfileElectricComponent
   */
  public previusFormTransformer: FormGroup
  /**
   * Pagina actual.
   *
   * @type {number}
   * @memberof EditProfileElectricComponent
   */
  public current: number = 0
  /**
   * Numero de paginas totales, representa los formulario que se llenaran,
   * empezando desde el numero 0.
   *
   * @type {number}
   * @memberof EditProfileElectricComponent
   */
  public lastPage: number = 0
  /**
   * Variable de control para controlar si se tienen que eliminar todos los transformadores.
   *
   * @private
   * @type {boolean}
   * @memberof EditProfileElectricComponent
   */
  private _deleteAllTransformers: boolean = false
  /**
   * Variable de control para controlar la carga de la vista.
   *
   * @type {boolean}
   * @memberof EditProfileElectricComponent
   */
  public isLoading: boolean = false
  /**
   * Estado del envio del formulario.
   *
   * @type {boolean}
   * @memberof EditProfileElectricComponent
   */
  public isSaving: boolean = false
  /**
   * Almacena el estado unicial de tipo de punto de unifilar.
   *
   * @private
   * @type {number}
   * @memberof EditProfileElectricComponent
   */
  private _type_point_initial: string
  /**
   * Suscripciones realizadas.
   *
   * @private
   * @type {Subscription[]}
   * @memberof EditProfileElectricComponent
   */
  private _subscriptions: Subscription[] = []
  /**
   * Variable funcion para realizar solicitudes customizadas a los servicios para almacenar imagenes.
   *
   * @memberof EditProfileElectricComponent
   */
  public customRequest: (item: NzUploadXHRArgs) => Subscription = (item: NzUploadXHRArgs) => {
    const image: File = item.file as any
    return this._pointsService.setImage(this.plantSelected.id, image).subscribe(
      response => {
        item.file.url = response
        item.onSuccess(item.file, item.file, null)
      },
      error => {
        item.onError(error, item.file)
      },
    )
  }
  /**
   * Variable funcion para realizar verificaciones a la imagen antes de enviarse.
   * Esta variable funcion se utiliza en el componente nz-upload al subir imagenes de perfil.
   *
   * @param {NzUploadFile} file
   * @param {NzUploadFile[]} _fileList
   * @memberof EditProfileElectricComponent
   */
  public beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]) =>
    new Observable((observer: Observer<boolean>) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this._nzMessageService.error('¡Solo se pueden subir archivos JPG y PNG!')
        observer.complete()
        return
      }
      const isLt1_5M = file.size / 1024 / 1024 < 1.5
      if (!isLt1_5M) {
        this._nzMessageService.error('¡La imagen debe pesar menos de 1.5MB!')
        observer.complete()
        return
      }
      observer.next(isJpgOrPng && isLt1_5M)
      observer.complete()
    })

  /**
   * Creates an instance of EditProfileElectricComponent.
   * @param {PlantsService} _plantService
   * @param {PointsService} _pointsService
   * @param {FormGramService} _formGramService
   * @param {TransformerService} _transformeService
   * @param {QuestionService} _questionService
   * @param {FormBuilder} _formBuilder
   * @param {NzNotificationService} _nzNotificationService
   * @param {NzMessageService} _nzMessageService
   * @param {Router} _router
   * @param {ActivatedRoute} _activatedRoute
   * @memberof EditProfileElectricComponent
   */
  constructor(
    private _plantService: PlantsService,
    private _pointsService: PointsService,
    private _formGramService: FormGramService,
    private _transformeService: TransformerService,
    private _questionService: QuestionService,
    private _formBuilder: FormBuilder,
    private _modalService: NzModalService,
    private _nzNotificationService: NzNotificationService,
    private _nzMessageService: NzMessageService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
  ) {
    this._getQuestionsForms().then()
    this._getInformationByParams()
  }

  /**
   * OnInit
   */
  ngOnInit(): void {
    this._buildFormInitial()
    this._buildFormGram()
  }

  /**
   * Funcion para obtener las preguntas del cuestionario del servicio.
   *
   * @private
   * @return {Promise<void>}
   */
  private async _getQuestionsForms(): Promise<void> {
    this.questions = await this._questionService.getQuestionsFormGram().toPromise()
    this.questionsInitial = this.questions.slice(0, 4)
    this.questionsFormGram = this.questions.slice(4, this.questions.length)
    this.questionsTransformer = await this._questionService.getQuestionsTransformer().toPromise()
  }

  /**
   * Funcion para obtener la informacion legal apartir de los query params de la ruta.
   *
   * @private
   * @memberof EditProfileBusinessComponent
   */
  private _getInformationByParams(): void {
    this._activatedRoute.queryParams.subscribe(params => {
      this._corporateId = parseInt(params?.corporate_id, 10)
      this._clientId = parseInt(params?.client_id, 10)
      this._projectId = parseInt(params?.project_id, 10)
      const plant_id: number = params?.plant_id
      if (plant_id) {
        this.isLoading = true
        this._getAsyncPlantSelected(plant_id).then(() => {
          if (this.plantSelected) {
            this._getInformationGram(this.plantSelected.id)
          } else {
            this._router.navigate(['/profile/electric-plants']).then(() => {
              this._nzNotificationService.error(
                'Lo sentimos... Ha ocurrido un error',
                'Planta seleccionada no exite.',
                {
                  nzDuration: 2500,
                },
              )
            })
          }
        })
      } else {
        this._router.navigate(['/profile/electric-plants']).then(() => {
          this._nzNotificationService.error(
            'Lo sentimos... Ha ocurrido un error',
            'Planta seleccionada no exite.',
            {
              nzDuration: 2500,
            },
          )
        })
      }
    })
  }

  /**
   * Funcion para obtener la planta seleccionada en los query params
   * de forma sincrona, para utilizarlo en los formularios.
   *
   * @private
   * @param {number} plantId Identificador de la planta.
   * @return {*}  {Promise<void>}
   * @memberof EditProfileElectricComponent
   */
  private async _getAsyncPlantSelected(plantId: number): Promise<void> {
    this.plantSelected = await this._plantService.getElementById(plantId).toPromise()
  }

  /**
   * Funcion para obtener la informacion del formulario del gram
   * en los servicios, si tenemos un punto unifilar en la planta.
   *
   * @private
   * @param {number} plantId Identificador de la planta.
   * @memberof EditProfileElectricComponent
   */
  private _getInformationGram(plantId: number) {
    this._pointsService
      .getPointsByPlant(plantId)
      .pipe(
        mergeMap(uniPoint => {
          this._uniPoint = uniPoint && uniPoint?.length > 0 ? uniPoint[0] : null
          this._type_point_initial =
            this._uniPoint?.id_measure_point === INT_POINT_DIGITAL ? TYPE_VIRTUAL : TYPE_REAL
          this._pointTypeControl.setValue(this._type_point_initial)
          return this._formGramService.getFormGramByPlant(plantId)
        }),
        mergeMap(formGram => {
          if (formGram) {
            this._setFormGram(formGram[0])
          }
          return this._pointTypeControl.value !== TYPE_REAL
            ? this._transformeService.getTransformersByPlant(plantId)
            : of(null)
        }),
        finalize(() => {
          this.isLoading = false
        }),
      )
      .subscribe(
        transformers => {
          if (transformers) {
            this._setTransformers2FormGroup(transformers)
          }
        },
        error => {
          this._nzNotificationService.error(
            'Lo sentimos... Ha ocurrido un error',
            error?.message ? error?.message : 'Vuelva intentarlo más tarde',
            {
              nzDuration: 2500,
            },
          )
        },
      )
  }

  /**
   * Funcion para establecer el numeros de transformadores a los campos del formulario incial
   * y el numero de transformadores totales.
   *
   * @private
   * @param {Transformer[]} transformers Lista de transformadores
   * @memberof EditProfileElectricComponent
   */
  private _setTransformers2FormGroup(transformers: Transformer[]): void {
    this._transformers = transformers
    const numberTransformerHighControl: string = this.questionsInitial[1].formControlName
    const numberTransformerLowControl: string = this.questionsInitial[2].formControlName
    let transformersInHigh = 0
    let transformersInLow = 0
    this._transformers.forEach(transformer => {
      if (transformer.high_voltage_plate < 69) {
        transformersInLow++
      } else {
        transformersInHigh++
      }
    })
    this.formInitialGroup.controls[numberTransformerHighControl].setValue(transformersInHigh)
    this.formInitialGroup.controls[numberTransformerLowControl].setValue(transformersInLow)
    this.lastPage = transformers?.length
  }

  /**
   * Funcion para establecer informacion formGram de planta.
   *
   * @private
   * @param {FormGram} formGram Objecto que contiene la informacion.
   * @memberof EditProfileBusinessComponent
   */
  private _setFormGram(formGram: FormGram) {
    if (formGram) {
      this._formGram = formGram
      this._fillQuestionsToForm(this.questionsFormGram, this.formGramGroup, this._formGram)
      this._fillQuestionsToForm(this.questionsInitial, this.formInitialGroup, this._formGram)
    } else {
      this._nzNotificationService.warning(
        'La planta no aun no tiene informacion eléctrica.',
        'Complete la informacion eléctrica.',
        {
          nzDuration: 2500,
        },
      )
    }
  }

  /**
   * Funcion para construir el formulario inicial.
   *
   * @private
   * @memberof EditProfileElectricComponent
   */
  private _buildFormInitial(): void {
    this.formInitialGroup = this._formBuilder.group({})
    this._addControlForQuestions(this.questionsInitial, this.formInitialGroup)
    const numberTransformerHighControl: string = this.questionsInitial[1].formControlName
    const numberTransformerLowControl: string = this.questionsInitial[2].formControlName
    const subscribe = this._pointTypeControl.valueChanges.subscribe((value: string) => {
      if (value) {
        this._changeValidator(
          this.formInitialGroup.controls[numberTransformerHighControl],
          value,
          this.questionsInitial[1],
        )
        this._changeValidator(
          this.formInitialGroup.controls[numberTransformerLowControl],
          value,
          this.questionsInitial[2],
        )
      }
    })
    const subscribeNumberHigh = this.formInitialGroup.controls[
      numberTransformerHighControl
    ].valueChanges.subscribe(_ => {
      if (this._pointTypeControl.value === TYPE_VIRTUAL) {
        this._setLastPageByControl(numberTransformerHighControl, numberTransformerLowControl)
      }
    })
    const subscribeNumberLow = this.formInitialGroup.controls[
      numberTransformerLowControl
    ].valueChanges.subscribe(_ => {
      if (this._pointTypeControl.value === TYPE_VIRTUAL) {
        this._setLastPageByControl(numberTransformerHighControl, numberTransformerLowControl)
      }
    })
    this._pointTypeControl.setValue(TYPE_REAL)
    this._subscriptions.push(subscribe, subscribeNumberHigh, subscribeNumberLow)
  }

  /**
   * Funcion para establecer el numero total de transformadores en base
   * a los inputs de transformadores en alta y baja.
   *
   * @private
   * @param {string} highControl
   * @param {string} lowControl
   * @memberof EditProfileElectricComponent
   */
  private _setLastPageByControl(highControl: string, lowControl: string): void {
    const x: number = this.formInitialGroup.controls[highControl].value
    const y: number = this.formInitialGroup.controls[lowControl].value
    this.lastPage = x + y
  }

  /**
   * Funion para realizar un cambio de validacion según el valor de entrada.
   * Se utiliza pra activar y desactivar formulario segun si tenemos
   * una acometida digital o fisica.
   *
   * @private
   * @param {AbstractControl} control Control a limpiar los validaciones.
   * @param {string} value Valor de entrada si es fisica o digital.
   * @param {Question} question Pregunta para cambiar su estado de requerimiento.
   * @memberof EditProfileElectricComponent
   */
  private _changeValidator(control: AbstractControl, value: string, question: Question): void {
    if (value === TYPE_REAL) {
      question.isRequired = false
      this.lastPage = 0
      control.clearValidators()
      control.updateValueAndValidity()
    } else {
      question.isRequired = true
      this.lastPage = 1
      control.setValidators([Validators.required])
      control.updateValueAndValidity()
    }
  }

  /**
   * Funcion para construir el formulario de información del gram.
   *
   * @return {void}  {Promise<void>}
   * @memberof EditProfileBusinessComponent
   */
  private _buildFormGram(): void {
    this.formGramGroup = this._formBuilder.group({})
    this._addControlForQuestions(this.questionsFormGram, this.formGramGroup)
  }

  /**
   * Funcion para construir el formulario de transformadres.
   *
   * @private
   * @memberof EditProfileElectricComponent
   */
  private _buildFormTransformer(): void {
    this.formTransformer = this._formBuilder.group({})
    this._addControlForQuestions(this.questionsTransformer, this.formTransformer)
  }

  /**
   * Funcion para añadir controles al formulario con base a la lista de preguntas del formulario.
   * Establece si estos son requeridos o no.
   * @private
   * @param {Question[]} questions Lista de preguntas del formulario.
   * @param {FormGroup} formGroup Grupo de formulario que se le añadiran las preguntas.
   * @memberof EditProfileElectricComponent
   */
  private _addControlForQuestions(questions: Question[], formGroup: FormGroup): void {
    questions.forEach(question => {
      const setValidatorRequired = question.isRequired
        ? Validators.required
        : Validators.nullValidator
      formGroup.addControl(question.formControlName, new FormControl(null, [setValidatorRequired]))
    })
  }

  /**
   * Callback para regresar una página anterior.
   *
   * @memberof EditProfileElectricComponent
   */
  public pre(): void {
    this.current -= 1
    if (this.current !== 0) {
      this._fillQuestionsToForm(
        this.questionsTransformer,
        this.formTransformer,
        this._transformers[this.current - 1],
      )
    }
  }

  /**
   * Callback para procesar las paginas del formulario si es que el formulario continua.
   *
   * @memberof EditProfileElectricComponent
   */
  public next(): void {
    if (this.verifyFormsGroupValid) {
      return
    }

    this.previusFormTransformer = Object.assign({}, this.formTransformer)

    this._buildFormTransformer()

    if (!this._transformers[this.current]) {
      const newTransformer = new Transformer()
      this._transformers.push(newTransformer)
    } else {
      this._fillQuestionsToForm(
        this.questionsTransformer,
        this.formTransformer,
        this._transformers[this.current],
      )
    }

    if (this.current !== 0) {
      this._updateValuesObject(
        this._transformers[this.current - 1],
        this.previusFormTransformer ? this.previusFormTransformer : this.formTransformer,
      )
    }

    this.current += 1
  }

  /**
   * Callback para procesar el final de un formulario.
   *
   * @memberof EditProfileElectricComponent
   */
  public done(): void {
    if (this.verifyFormsGroupValid) {
      return
    }
    const functionUpdate = () => {
      this._updateValuesToSend()
    }
    this._verifyTypePlant(functionUpdate)
  }

  /**
   * Funcion que actualiza los valores y envia el formulario.
   *
   * @private
   * @memberof EditProfileElectricComponent
   */
  private _updateValuesToSend(): void {
    this._updateValuesObject(this._formGram, this.formGramGroup)
    this._updateValuesObject(this._formGram, this.formInitialGroup)
    if (this.lastPage > 0) {
      this._updateValuesObject(this._transformers[this.current - 1], this.formTransformer)
    }
    this._sendForm()
  }

  /**
   * Funcion para verificar el numero minimo de transformadores.
   * El numero de transformadres cuando existen debe ser mayor o igual.
   * Solo se pueden añadir más no eliminarlos.
   *
   * @readonly
   * @type {boolean}
   * @memberof EditProfileElectricComponent
   */
  public get verifyTotalTransformers(): boolean {
    if (
      this._transformers &&
      this._transformers?.length > 0 &&
      this._pointTypeControl.value === TYPE_VIRTUAL
    ) {
      return this.lastPage >= this._transformers?.length
    }
    return true
  }

  /**
   * Funcion para verificar el tipo de plantas existente.
   * Si la planta cambia de un tipo al otro, si se presenta el caso se
   * de tener de tipo digital a real confirmaremos la eliminacion de transformadores.
   *
   * @private
   * @param {updateValuesCallback} updateValues Funcion que actaliza y envia el formulario.
   * @memberof EditProfileElectricComponent
   */
  private _verifyTypePlant(updateValues: updateValuesCallback): void {
    const initialState: string = this._type_point_initial
    const currentState: string = this._pointTypeControl.value
    if (initialState !== currentState) {
      if (currentState === TYPE_REAL && this._formGram?.id) {
        this._deleteAllTransformers = true
        this._modalDeleteTransformers(updateValues)
        return
      }
    }
    this._deleteAllTransformers = false
    updateValues()
  }

  /**
   * Funcion para verificar el estado del envio de la informacion de los objetos.
   *
   * @private
   * @memberof EditProfileElectricComponent
   */
  private _sendForm(): void {
    this.isSaving = true
    const uniPointType: number = this._pointTypeControl.value === TYPE_REAL ? INT_POINT_REAL : INT_POINT_DIGITAL
    this._sendToService(uniPointType)
  }

  /**
   * Funcion para enviar los objectos a los servicios para actualización
   * de registros.
   *
   * @private
   * @param {number} plantId
   * @param {string} uniPointType
   * @memberof EditProfileElectricComponent
   */
  private _sendToService(uniPointType: number): void {
    const transformToSendRequest = []

    this._transformers.forEach(transformer => {
      if (transformer && this.lastPage > 0) {
        transformer.uni_point_id = this._uniPoint.id
        transformer.z_p = transformer.z_p.toString()
        transformer.z_0 = transformer.z_0.toString()
        if (transformer?.id) {
          const transformerId: number = transformer?.id
          Object.keys(transformer).forEach(k => transformer[k] == null && delete transformer[k])
          transformToSendRequest.push(
            this._transformeService.updateElement(transformer, transformerId),
          )
        } else {
          transformToSendRequest.push(this._transformeService.createdElement(transformer))
        }
      } else if (transformer && this.lastPage === 0 && this._deleteAllTransformers) {
        if (transformer?.id) {
          transformToSendRequest.push(this._transformeService.deleteElement(transformer.id))
        }
      }
    })
    this._uniPoint.id_measure_point = uniPointType
    this._pointsService
      .updateElement(this._uniPoint, this._uniPoint.id)
      .pipe(
        mergeMap(uniPoint => {
          this._formGram.id_unifilar_point = uniPoint.id
          const formGram: FormGram = {...this._formGram}
          return this._saveFormGram(formGram)
        }),
        mergeMap(() => {
          if (transformToSendRequest && transformToSendRequest?.length <= 0) {
            return of(null)
          }
          return forkJoin(transformToSendRequest)
        }),
        finalize(() => {
          this.isSaving = false
        }),
      )
      .subscribe(
        _ => {
          this._router.navigate(['/profile/electric-plants']).then(() => {
            this._nzNotificationService.success(
              '¡Guardado exitoso!',
              '¡El perfil electrico de la planta ha sido actualizado!',
            )
          })
        },
        error => {
          this._nzNotificationService.error(
            'Lo sentimos... Ha ocurrido un error',
            error?.message ? error?.message : 'Vuelva intentarlo más tarde',
            {
              nzDuration: 2500,
            },
          )
        },
      )
  }

  /**
   * Funcion para guarda la informacion del formulario del gram.
   *
   * @private
   * @param {FormGram} formGram
   * @return {*}  {Observable<FormGram>}
   * @memberof EditProfileElectricComponent
   */
  private _saveFormGram(formGram: FormGram): Observable<FormGram> {
    if (!formGram?.id && this._corporateId && this._clientId && this._projectId) {
      formGram.id_corporate = this._corporateId
      formGram.id_client = this._clientId
      formGram.id_project = this._projectId
      formGram.id_plant = this.plantSelected.id
      formGram.id_commutation = 1
      return this._formGramService
        .createdElement(formGram)
        .pipe(map((response: FormGram) => response))
    } else {
      const formGramId: number = formGram.id
      Object.keys(formGram).forEach(k => formGram[k] == null && delete formGram[k])
      return this._formGramService
        .updateElement(formGram, formGramId)
        .pipe(map((response: FormGram) => response))
    }
  }

  /**
   * Funcion para verificar la validacion de un grupo de formularios.
   *
   * @readonly
   * @type {boolean} Devuelve la validacion del grupo.
   * @memberof EditProfileElectricComponent
   */
  public get verifyFormsGroupValid(): boolean {
    const nextSectionValidator: boolean = this.formTransformer?.invalid && this.current !== 0
    return this.formInitialGroup?.invalid || this.formGramGroup?.invalid || nextSectionValidator
  }

  /**
   * Funcion para actualizar valores de los formularios reactivos dinamicamente
   * Realiza un emparejamiento entre los atributos debido a que comparten key.
   * @private
   * @param {Object} object Objecto donde se extrae la informacion según los atributos.
   * @param {FormGroup} formGroup Grupo de formularios para insertar el valor.
   * @memberof EditProfileElectricComponent
   */
  private _updateValuesObject(object: Object, formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(key => {
      if (key in object) {
        const value = formGroup.controls[key].value
        object[key] = typeof value === 'string' ? value.trim() : value
      }
    })
  }

  /**
   * Funcion para rellenar respuesta de las preguntas de los formularios cuando se tiene informacion disponible.
   *
   * @private
   * @param {Question[]} questions Lista de preguntas.
   * @param {FormGroup} forms Formulario aplicar respuestas.
   * @param {Object} data Información donde extraer las respuestas.
   * @memberof EditProfileBusinessComponent
   */
  private _fillQuestionsToForm(questions: Question[], forms: FormGroup, data: Object): void {
    questions.map(question => {
      if (question.formControlName in data) {
        forms.get(question.formControlName).setValue(data[question.formControlName])
      }
    })
  }

  /**
   * Callback para manejar los cambios en los estados del envio de la peticion al cambiar imagen.
   *
   * @param {{ file: NzUploadFile }} info Estatus del archivo.
   * @memberof EditProfileElectricComponent
   */
  public handleChange(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        break
      case 'done':
        this._nzMessageService.success('¡Imagen actualizada con exito!')
        break
      case 'error':
        this._nzMessageService.error('Ha ocurrido un error...')
        break
    }
  }

  /**
   * Funcion para contruir y mostrar un modal al usuario.
   *
   * @private
   * @param {Transformer} transformer Transformador que se desea eliminar.
   * @memberof EditProfileElectricComponent
   */
  private _modalDeleteTransformers(updateValues: updateValuesCallback): void {
    this._modalService.confirm({
      nzTitle: `¿Seguro que desea continuar?`,
      nzContent: 'Esta acción eliminará todos los transformadores registrados.',
      nzOkText: 'Sí, continuar',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: updateValues,
      nzCancelText: 'No',
      nzOnCancel: () => this._modalService.closeAll(),
    })
  }

  /**
   * Getter para obtener el form control del tipo del punto unifila.
   *
   * @readonly
   * @private
   * @type {AbstractControl}
   * @memberof EditProfileElectricComponent
   */
  private get _pointTypeControl(): AbstractControl {
    const pointTypeControl: string = this.questionsInitial[0].formControlName
    return this.formInitialGroup.controls[pointTypeControl]
  }

  /**
   * OnDestroy.
   *
   * @memberof EditProfileElectricComponent
   */
  ngOnDestroy() {
    this._subscriptions.forEach(subscription => subscription.unsubscribe())
  }
}
