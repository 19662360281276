import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { forkJoin, Observable, Observer, Subscription, of } from 'rxjs'
import { catchError, debounceTime, distinctUntilChanged, finalize, mergeMap } from 'rxjs/operators'
import { Question } from '../../../interfaces/question'
import { Legal, Person, Plant } from 'src/app/models/models.index'
import { LegalService } from 'src/app/services/legal/legal.service'
import { PersonService } from 'src/app/services/person/person.service'
import { QuestionService } from 'src/app/services/question/question.service'
import * as XLSX from 'xlsx'
import { environment } from '../../../../environments/environment'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { ActivatedRoute, Router } from '@angular/router'
import { PlantsService } from '../../../services/plants/plants.service'
import { NzUploadFile } from 'ng-zorro-antd/upload'
import { NzUploadXHRArgs } from 'ng-zorro-antd/upload/interface'
import { NzMessageService } from 'ng-zorro-antd/message'

/** Descripcion por defecto de la planta. @type {string} */
const DEFAULT_DESCRIPTION = 'Inserte la descripcion de la planta aquí...'

/**
 * Vista para modificar la información legal de una planta
 *
 * @export
 * @class EditProfileBusinessComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-edit-profile-business',
  templateUrl: './edit-profile-business.component.html',
  styleUrls: ['./edit-profile-business.component.scss'],
})
export class EditProfileBusinessComponent implements OnInit {
  /**
   * Planta seleccionada para añadir o editar información legal.
   *
   * @type {Plant}
   * @memberof EditProfileBusinessComponent
   */
  public plantSelected: Plant = new Plant(null, null, null, null, null, null)
  /**
   * Variable para almacenar la descripcion de la planta.
   *
   * @type {string}
   * @memberof EditProfileBusinessComponent
   */
  public plantDescription: string = DEFAULT_DESCRIPTION
  /**
   * Variable para almacenar la información legal de los formularios.
   *
   * @private
   * @type {Legal}
   * @memberof EditProfileBusinessComponent
   */
  private _legalInformation: Legal = new Legal()
  /**
   * Variable para almacenar informacion de personas autorizadas en los formularios.
   *
   * @private
   * @type {Person[]}
   * @memberof EditProfileBusinessComponent
   */
  private _personsAuthorization: Person[] = []
  /**
   * Texto que se muestra al cargar un reporte en el loader.
   *
   * @type {string}
   * @memberof EditProfileBusinessComponent
   */
  public textHTMLSpin: string = 'Cargando información de planta...'
  /**
   * Control del estado de carga al subir un archivo, para controlar la vista.
   *
   * @type {boolean}
   * @memberof EditProfileBusinessComponent
   */
  public isLoadingFile: boolean = false
  /**
   * Variable para saber si un archivo fue subido para rellenar automatico el formulario.
   *
   * @private
   * @type {boolean}
   * @memberof EditProfileBusinessComponent
   */
  private _fileLoaded: boolean = false
  /**
   * Numero minimo para crear personas.
   *
   * @type {number}
   * @memberof EditProfileBusinessComponent
   */
  public minNumberPeople: number = 1
  /**
   * Posicion actual de la vista de la seccion de preguntas.
   *
   * @type {number}
   * @memberof EditProfileBusinessComponent
   */
  public current: number = 0
  /**
   * Arreglo de preguntas que tiene un formulario legal.
   *
   * @type {Question[]}
   * @memberof EditProfileBusinessComponent
   */
  public questionsForm: Question[]
  /**
   * Variable para grupo de formulario para parte de informacion legal.
   *
   * @type {FormGroup}
   * @memberof EditProfileBusinessComponent
   */
  public firstForm: FormGroup
  /**
   * Lista de preguntas de la primera parte del formulario legal.
   *
   * @type {Question[]}
   * @memberof EditProfileBusinessComponent
   */
  public firstFormQuestions: Question[]
  /**
   * Variable para grupo de formulario para parte de informacion legal.
   *
   * @type {FormGroup}
   * @memberof EditProfileBusinessComponent
   */
  public secondForm: FormGroup
  /**
   * Lista de preguntas de la segunda parte del formulario legal.
   *
   * @type {Question[]}
   * @memberof EditProfileBusinessComponent
   */
  public secondFormQuestions: Question[]
  /**
   * Variable para grupo de formulario para parte de informacion legal.
   *
   * @type {FormGroup}
   * @memberof EditProfileBusinessComponent
   */
  public thirdForm: FormGroup
  /**
   * Lista de preguntas de la tercera parte del formulario legal.
   *
   * @type {Question[]}
   * @memberof EditProfileBusinessComponent
   */
  public thirdFormQuestions: Question[]
  /**
   * Estado del boton para habilitar o desactivar segun el formulario.
   *
   * @type {boolean}
   * @memberof EditProfileBusinessComponent
   */
  public buttonDisabled: boolean = false
  /**
   * Variable para almacenar el numero de personas que recibiran notificaciones.
   *
   * @type {number}
   * @memberof EditProfileBusinessComponent
   */
  public totalPerson: number = 1
  /**
   * Lista de formulario para cada nueva persona añadida
   *
   * @type {Array<Question[]>}
   * @memberof EditProfileBusinessComponent
   */
  public personListOfControl: Array<Question[]> = []
  /**
   * Estado del envio del formulario.
   *
   * @type {boolean}
   * @memberof EditProfileBusinessComponent
   */
  public isSaving: boolean = false
  /**
   * Variable que indica que informacion de la planta se encuentra enviando la peticion.
   *
   * @type {boolean}
   * @memberof EditProfileBusinessComponent
   */
  public loadingChangedPlant: boolean = false
  /**
   * Variable para almacenar la imagen de perfil de la planta
   *
   * @type {string}
   * @memberof EditProfileBusinessComponent
   */
  public avatarUrl: string = null
  /**
   * Variable funcion para realizar solicitudes customizadas a los servicios para almacenar imagenes.
   *
   * @memberof EditProfileBusinessComponent
   */
  public customRequest: (item: NzUploadXHRArgs) => Subscription
  /**
   * Variable funcion para realizar verificaciones a la imagen antes de enviarse.
   * Esta variable funcion se utiliza en el componente nz-upload al subir imagenes de perfil.
   *
   * @param {NzUploadFile} file
   * @param {NzUploadFile[]} _fileList
   * @memberof EditProfileBusinessComponent
   */
  public beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]) =>
    new Observable((observer: Observer<boolean>) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this._nzMessageService.error('¡Solo se pueden subir archivos JPG y PNG!')
        observer.complete()
        return
      }
      const isLt1_5M = file.size / 1024 / 1024 < 1.5
      if (!isLt1_5M) {
        this._nzMessageService.error('¡La imagen debe pesar menos de 1.5MB!')
        observer.complete()
        return
      }
      observer.next(isJpgOrPng && isLt1_5M)
      observer.complete()
    })

  /**
   * Creates an instance of EditProfileBusinessComponent.
   * @param {FormBuilder} _formBuilder
   * @param {QuestionService} _questionService
   * @param {PlantsService} _plantService
   * @param {LegalService} _legalService
   * @param {PersonService} _personService
   * @param {NzNotificationService} _nzNotificationService
   * @param {NzMessageService} _nzMessageService
   * @param {Router} _router
   * @param {ActivatedRoute} _activatedRoute
   * @memberof EditProfileBusinessComponent
   */
  constructor(
    private _formBuilder: FormBuilder,
    private _questionService: QuestionService,
    private _plantService: PlantsService,
    private _legalService: LegalService,
    private _personService: PersonService,
    private _nzNotificationService: NzNotificationService,
    private _nzMessageService: NzMessageService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
  ) {
    this._getQuestionsForms().then()
    this.customRequest = (item: NzUploadXHRArgs) => {
      const image: File = item.file as any
      return this._plantService.setImage(this.plantSelected.id, image).subscribe(
        response => {
          item.file.url = response
          item.onSuccess(item.file, item.file, null)
        },
        error => {
          item.onError(error, item.file)
        },
      )
    }
  }

  /**
   * OnInit.
   *
   * @memberof EditProfileBusinessComponent
   */
  ngOnInit() {
    this._buildFirstForm()
    this._getInformationByParams()
  }

  /**
   * Funcion para obtener las preguntas del cuestionario del servicio.
   *
   * @private
   * @return {*}  {Promise<void>}
   * @memberof EditProfileBusinessComponent
   */
  private async _getQuestionsForms(): Promise<void> {
    this.questionsForm = await this._questionService.getQuestionsLegal().toPromise()
  }

  /**
   * Funcion para construir el primer formulario de información legal.
   *
   * @return {void}  {Promise<void>}
   * @memberof EditProfileBusinessComponent
   */
  private _buildFirstForm(): void {
    this.firstForm = this._formBuilder.group({})
    this.firstFormQuestions = this.questionsForm.slice(0, 11)
    this.firstFormQuestions.forEach(question => {
      const setValidatorRequired = question.isRequired
        ? Validators.required
        : Validators.nullValidator
      const setNumberValidator = question.typeData
        ? Validators.pattern('^[0-9]*$')
        : Validators.nullValidator
      this.firstForm.addControl(
        question.formControlName,
        new FormControl(null, [setValidatorRequired, setNumberValidator]),
      )
    })
    this._changeValidatorFormByResponse(
      this.firstFormQuestions[10].formControlName,
      this.firstFormQuestions[9].formControlName,
    )
  }

  /**
   * Funcion para construir el segundo formulario, para representante legal y las personas autorizadas.
   *
   * @private
   * @memberof EditProfileBusinessComponent
   */
  private _buildSecondForm(): void {
    this.secondForm = this._formBuilder.group({})
    this.secondFormQuestions = this.questionsForm.slice(11, 17)
    this.secondFormQuestions.forEach(question => {
      const setValidatorRequired = question.isRequired
        ? Validators.required
        : Validators.nullValidator
      this.secondForm.addControl(
        question.formControlName,
        new FormControl(null, [setValidatorRequired]),
      )
    })
    this.secondForm.addControl(
      'inputPerson',
      new FormControl(null, [Validators.required, Validators.min(1), Validators.max(3)]),
    )

    this.secondForm.controls['inputPerson'].valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((query: number) => {
        if (query) {
          this.personChange()
        }
      })

    if (this._fileLoaded) {
      this._fillQuestionsToForm(this.secondFormQuestions, this.secondForm, this._legalInformation)
      this.totalPerson = this._personsAuthorization.length
    }
    this.secondForm.controls['inputPerson'].setValue(this.totalPerson)
  }

  /**
   * Funcion para construir el tercer formulario, para representante legal y las personas autorizadas.
   *
   * @private
   * @memberof EditProfileBusinessComponent
   */
  private _buildThirdForm(): void {
    this.thirdForm = this._formBuilder.group({})
    this.thirdFormQuestions = this.questionsForm.slice(17, 30)
    this.thirdFormQuestions.forEach(question => {
      const setValidatorRequired = question.isRequired
        ? Validators.required
        : Validators.nullValidator
      this.thirdForm.addControl(
        question.formControlName,
        new FormControl(null, [setValidatorRequired]),
      )
    })

    if (this._fileLoaded) {
      this.thirdFormQuestions.map((question, index) => {
        const value = this._legalInformation[question.formControlName]
        this.thirdForm.get(question.formControlName).setValue(value || value === 0 ? value : null)
        this.valueQuestionChange(question, index)
      })
    }
  }

  /**
   * Funcion para obtener la informacion legal apartir de los query params de la ruta.
   *
   * @private
   * @memberof EditProfileBusinessComponent
   */
  private _getInformationByParams(): void {
    this._activatedRoute.queryParams.subscribe(params => {
      const plant_id: number = params?.plant_id
      const legal_id: number = params?.legal_id
      if (plant_id) {
        this.isLoadingFile = true
        this._getAsyncPlantSelected(plant_id).then(() => {
          if (this.plantSelected) {
            if (legal_id) {
              this._getInformationLegal(this.plantSelected.id)
              return
            }
            this.isLoadingFile = false
          } else {
            this._router.navigate(['/profile/business']).then(() => {
              this._nzNotificationService.error(
                'Lo sentimos... Ha ocurrido un error',
                'Planta seleccionada no exite.',
                {
                  nzDuration: 2500,
                },
              )
            })
          }
        })
      } else {
        this._router.navigate(['/profile/business']).then(() => {
          this._nzNotificationService.error(
            'Lo sentimos... Ha ocurrido un error',
            'Planta seleccionada no exite.',
            {
              nzDuration: 2500,
            },
          )
        })
      }
    })
  }

  /**
   * Metodo para obtener la planta seleccionada de forma asincrona.
   *
   * @private
   * @param {number} plantId
   * @return {*}  {Promise<void>}
   * @memberof EditProfileBusinessComponent
   */
  private async _getAsyncPlantSelected(plantId: number): Promise<void> {
    this.avatarUrl = await this._plantService
      .getElementById(plantId)
      .pipe(
        mergeMap(plant => {
          this.plantSelected = plant
          this.plantDescription = this.plantSelected?.longName
            ? this.plantSelected.longName
            : DEFAULT_DESCRIPTION
          return this._plantService.getImage(plantId)
        }),
        catchError(error => of(null)),
      )
      .toPromise()
  }

  /**
   * Meotodo para obtener la informacion legal.
   *
   * @private
   * @param {number} plantId
   * @memberof EditProfileBusinessComponent
   */
  private _getInformationLegal(plantId: number) {
    this._legalService
      .getLegalInfoByPlant(plantId)
      .pipe(
        mergeMap(legal => {
          this._setLegaInformation(legal)
          return this._personService.getPeopleByPlant(plantId)
        }),
        finalize(() => {
          this.isLoadingFile = false
        }),
      )
      .subscribe(
        people => {
          if (people && people?.length > 0) {
            this._personsAuthorization = people.slice(0, 3)
            this.minNumberPeople = this._personsAuthorization.length
          }
        },
        error => {
          this._nzNotificationService.error(
            'Lo sentimos... Ha ocurrido un error',
            error?.message ? error?.message : 'Vuelva intentarlo más tarde',
            {
              nzDuration: 2500,
            },
          )
        },
      )
  }

  /**
   * Funcion para establecer informacion legal de planta.
   *
   * @private
   * @param {Legal} legal
   * @memberof EditProfileBusinessComponent
   */
  private _setLegaInformation(legal: Legal) {
    if (legal) {
      this._legalInformation = legal
      this._fileLoaded = true
      this._fillQuestionsToForm(this.firstFormQuestions, this.firstForm, this._legalInformation)
    } else {
      this._nzNotificationService.error(
        'La planta no aun no tiene informacion legal.',
        'Complete la informacion legal.',
        {
          nzDuration: 2500,
        },
      )
    }
  }

  /**
   * Callback que añade preguntas al formulario segun la respuesta de una pregunta.
   *
   * @param {Question} question Pregunta con cambio.
   * @param {number} questionIndex Posion actual de la pregunta dentro del arreglo de preguntas.
   * @memberof EditProfileBusinessComponent
   */
  public valueQuestionChange(question: Question, questionIndex: number): void {
    const value = this.thirdForm.controls[question.formControlName].value
    if (question.formControlName === 'q_3_1_1') {
      if (value === 1) {
        this.thirdFormQuestions[questionIndex + 1].isRequired = true
        this.thirdFormQuestions[questionIndex + 2].isRequired = false

        this.thirdForm.controls[
          this.thirdFormQuestions[questionIndex + 1].formControlName
        ].setValidators([Validators.required])
        this.thirdForm.controls[
          this.thirdFormQuestions[questionIndex + 2].formControlName
        ].setValue(null)
        this.thirdForm.controls[
          this.thirdFormQuestions[questionIndex + 2].formControlName
        ].clearValidators()
        this.thirdForm.controls[
          this.thirdFormQuestions[questionIndex + 2].formControlName
        ].updateValueAndValidity()
      } else {
        this.thirdFormQuestions[questionIndex + 1].isRequired = false
        this.thirdFormQuestions[questionIndex + 2].isRequired = true
        this.thirdForm.controls[
          this.thirdFormQuestions[questionIndex + 2].formControlName
        ].setValidators([Validators.required])
        this.thirdForm.controls[
          this.thirdFormQuestions[questionIndex + 1].formControlName
        ].setValue(null)
        this.thirdForm.controls[
          this.thirdFormQuestions[questionIndex + 1].formControlName
        ].clearValidators()
        this.thirdForm.controls[
          this.thirdFormQuestions[questionIndex + 1].formControlName
        ].updateValueAndValidity()
      }
    }

    if (question.formControlName === 'q_3_2_1' || question.formControlName === 'q_3_6_1') {
      if (value === 1) {
        this.thirdFormQuestions[questionIndex + 1].isRequired = true
        this.thirdForm.controls[
          this.thirdFormQuestions[questionIndex + 1].formControlName
        ].setValidators([Validators.required])
      } else {
        this.thirdFormQuestions[questionIndex + 1].isRequired = false
        this.thirdForm.controls[
          this.thirdFormQuestions[questionIndex + 1].formControlName
        ].setValue(null)
        this.thirdForm.controls[
          this.thirdFormQuestions[questionIndex + 1].formControlName
        ].clearValidators()
        this.thirdForm.controls[
          this.thirdFormQuestions[questionIndex + 1].formControlName
        ].updateValueAndValidity()
      }
    }
  }

  /**
   * Funcion que escucha y cambia las validaciones de un form control, segun el valor de otro form control.
   *
   * @private
   * @param {string} valueChange Form control que se queda a la escucha y se evalua su valor actual.
   * @param {string} controlToChange Form control que se desea cambiar la validacion.
   * @memberof EditProfileBusinessComponent
   */
  private _changeValidatorFormByResponse(valueChange: string, controlToChange: string) {
    this.firstForm.controls[valueChange].valueChanges.subscribe((value: string) => {
      if (value) {
        if (this.firstForm.controls[controlToChange].invalid) {
          this.firstForm.controls[controlToChange].clearValidators()
          this.firstForm.controls[controlToChange].updateValueAndValidity()
        }
      } else {
        this.firstForm.controls[controlToChange].setValidators([Validators.required])
        this.firstForm.controls[controlToChange].updateValueAndValidity()
      }
    })
  }

  /**
   * Callback al cambiar el numero de persona en el input numerico.
   *
   * @memberof EditProfileBusinessComponent
   */
  public personChange(): void {
    const sizeList = this.personListOfControl.length
    this.totalPerson = this.secondForm.controls['inputPerson'].value
    if (sizeList < this.totalPerson) {
      this._addPerson(this.totalPerson, sizeList)
    } else {
      this._removePerson(this.totalPerson, sizeList)
    }
  }

  /**
   * Funcion para añadir formulario de persona adicional para notificaciones.
   *
   * @private
   * @param {number} totalPerson Numero total de personas adicionales.
   * @param {number} currentSize Numero actual de formularios creados para personas adicionales.
   * @memberof EditProfileBusinessComponent
   */
  private _addPerson(totalPerson: number, currentSize: number): void {
    const questions = this.questionsForm.slice(11, 16)
    for (let i = currentSize; i < totalPerson; i++) {
      this.personListOfControl.push(questions)
      questions.forEach(question => {
        const setValidatorRequired = question.isRequired
          ? Validators.required
          : Validators.nullValidator
        this.secondForm.addControl(
          question.formControlName.concat(`-index-${i}`),
          new FormControl(null, [setValidatorRequired]),
        )
      })
    }

    if (this._fileLoaded) {
      this._personsAuthorization.forEach((person, index_person) => {
        const paramsPerson = ['name', 'lname', 'mlname', 'phone', 'mail']
        questions.map((question, index_question) => {
          this.secondForm
            .get(`${question.formControlName}-index-${index_person}`)
            .setValue(person[paramsPerson[index_question]])
        })
      })
    }
  }

  /**
   * Funcion para eliminar formulario de persona adicional para notification.
   *
   * @private
   * @param {number} totalPerson Numero total de personas adicionales.
   * @param {number} currentSize Numero actual de formularios creados para personas adicionales.
   * @memberof EditProfileBusinessComponent
   */
  private _removePerson(totalPerson: number, currentSize: number): void {
    if (currentSize > 1) {
      const numberFormDelete = currentSize - totalPerson
      for (let i = 0; i < numberFormDelete; i++) {
        const currentFormIndex = currentSize - i - 1
        const formDelete = this.personListOfControl[currentFormIndex]
        formDelete.forEach(question => {
          this.secondForm.removeControl(
            question.formControlName.concat(`-index-${currentFormIndex}`),
          )
        })
        this.personListOfControl.pop()
      }
    }
  }

  /**
   * Callback para boton para regresar una vista previa en los formularios de preguntas.
   *
   * @memberof EditProfileBusinessComponent
   */
  public pre(): void {
    this.current -= 1

    if (this.current === 0) {
      if (this.secondForm) {
        this.secondForm = null
        this.totalPerson = 1
        this.personListOfControl.length = 0
      }
    }
  }

  /**
   * Callback para boton para el cambio de vista siguiente en los formularios de preguntas.
   *
   * @memberof EditProfileBusinessComponent
   */
  public next(): void {
    if (this.firstForm?.invalid || this.secondForm?.invalid) {
      return
    }

    if (this.current === 0) {
      Object.keys(this.firstForm.controls).forEach(key => {
        if (key in this._legalInformation) {
          this._legalInformation[key] = this.firstForm.controls[key].value
        }
      })
    }

    if (this.current === 1) {
      const answersPersons: any[] = []
      Object.keys(this.secondForm.controls).forEach(key => {
        if (key in this._legalInformation) {
          this._legalInformation[key] = this.secondForm.controls[key].value
        } else {
          if (key !== 'inputPerson') {
            answersPersons.push(this.secondForm.controls[key].value)
          }
        }
      })
      this._personsAuthorization = this.updatePersonsAuthorization(this.totalPerson, answersPersons)
    }

    this.current += 1

    if (this.current === 1) {
      this._buildSecondForm()
    }

    if (this.current === 2) {
      this._buildThirdForm()
    }
  }

  /**
   * Funcion para actualizar la informacion dentro del formulario de cada persona asignada.
   *
   * @param {number} numberPerson
   * @param {string[]} values
   * @return {*}  {UserNotification[]}
   * @memberof EditProfileBusinessComponent
   */
  public updatePersonsAuthorization(numberPerson: number, values: string[]): Person[] {
    const personsAuthorization: Person[] = []
    for (let i = 0; i < numberPerson; i++) {
      const data: string[] = [...values].splice(5 * i, 5)
      const personId = this._personsAuthorization[i]?.id
      const person = Person.buildPerson(data, 1, this.plantSelected.id)
      person.id = personId
      personsAuthorization.push(person)
    }
    return personsAuthorization
  }

  /**
   * Callback para boton al finalizar un formulario.
   *
   * @memberof EditProfileBusinessComponent
   */
  public done(): void {
    if (this.current === 2) {
      Object.keys(this.thirdForm.controls).forEach(key => {
        if (key in this._legalInformation) {
          this._legalInformation[key] = this.thirdForm.controls[key].value
        }
      })
    }
    if (!environment.production) {
      console.log(this._legalInformation)
      console.log(this._personsAuthorization)
    }
    this.sendForms()
  }

  /**
   * Funcion para enviar el formulario a sus respetivos servicios.
   *
   * @memberof EditProfileBusinessComponent
   */
  public sendForms(): void {
    this.isSaving = true
    const plantId = this.plantSelected.id
    const peopleToSend = []

    if (this._legalInformation && this._personsAuthorization?.length > 0) {
      this._personsAuthorization.forEach(person => {
        if (person?.id) {
          const personId: number = person?.id
          Object.keys(person).forEach(k => person[k] == null && delete person[k])
          peopleToSend.push(this._personService.updateElement(person, personId))
        } else {
          peopleToSend.push(this._personService.setPersonByPlant(person, true))
        }
      })

      if (!this._legalInformation.id) {
        this._legalInformation.id_plant = plantId
        this._legalService.createdElement(this._legalInformation)
          .pipe(
            mergeMap(_ => {
              return forkJoin(peopleToSend)
            }),
          )
          .subscribe(
            _ => {
              this.isSaving = false
              this._router.navigate(['/profile/business']).then(() => {
                this._nzNotificationService.success(
                  '¡Guardado exitoso!',
                  '¡El perfil legal de la planta ha sido actualizado!',
                )
              })
            },
            error => {
              this.isSaving = false
              this._nzNotificationService.error(
                'Lo sentimos... Ha ocurrido un error',
                error?.message ? error?.message : 'Vuelva intentarlo más tarde',
                {
                  nzDuration: 2500,
                },
              )
            },
          )
      } else {
        this._legalService
          .updateElement(this._legalInformation, this._legalInformation.id)
          .pipe(
            mergeMap(_ => {
              return forkJoin(peopleToSend)
            }),
          )
          .subscribe(
            _ => {
              this.isSaving = false
              this._router.navigate(['/profile/business']).then(() => {
                this._nzNotificationService.success(
                  '¡Guardado exitoso!',
                  '¡El perfil legal de la planta ha sido actualizado!',
                )
              })
            },
            error => {
              this.isSaving = false
              this._nzNotificationService.error(
                'Lo sentimos... Ha ocurrido un error',
                error?.message ? error?.message : 'Vuelva intentarlo más tarde',
                {
                  nzDuration: 2500,
                },
              )
            },
          )
      }
    }
  }

  /**
   * Funcion para extraer informacion legal de una planta
   *
   * @param {*} fileRead: Archivo excel para extraer informacion
   * @memberof EditProfileBusinessComponent
   */
  public onFileChange(fileRead: any): void {
    this.isLoadingFile = true
    let workBook = null
    let jsonData = null
    const reader = new FileReader()
    const file = fileRead.target.files[0]
    reader.onload = _ => {
      const data = reader.result
      workBook = XLSX.read(data, { type: 'binary' })
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name]
        initial[name] = XLSX.utils.sheet_to_json(sheet)
        return initial
      }, {})
      this.isLoadingFile = false

      if (jsonData['Datos_PDT']) {
        this._fileLoaded = true
        this._legalInformation.parserJsonRaw(jsonData['Datos_PDT'])
        this._personsAuthorization = Person.getPersonsFromRawJson(
          this.plantSelected.id,
          jsonData['Datos_PDT'],
        )
        this._fillQuestionsToForm(this.firstFormQuestions, this.firstForm, this._legalInformation)
      } else {
        this._fileLoaded = false
      }
    }
    reader.readAsBinaryString(file)
  }

  /**
   * Funcion para rellenar respuesta de las preguntas de los formularios cuando se tiene informacion disponible.
   *
   * @private
   * @param {Question[]} questions Lista de preguntas.
   * @param {FormGroup} forms Formulario aplicar respuestas.
   * @param {Object} data Información donde extraer las respuestas.
   * @memberof EditProfileBusinessComponent
   */
  private _fillQuestionsToForm(questions: Question[], forms: FormGroup, data: Object): void {
    questions.map(question => {
      forms.get(question.formControlName).setValue(data[question.formControlName])
    })
  }

  /**
   * Callback para manejar los cambios en los estados del envio de la peticion al cambiar imagen.
   *
   * @param {{ file: NzUploadFile }} info
   * @memberof EditProfileBusinessComponent
   */
  handleChange(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        break
      case 'done':
        this.avatarUrl = null
        this.avatarUrl = info.file.response.url
        this._nzMessageService.success('¡Imagen actualizada con exito!')
        break
      case 'error':
        this._nzMessageService.error('Ha ocurrido un error...')
        break
    }
  }

  /**
   * Callback para editar la descripcion de una planta.
   *
   * @param {string} description
   * @memberof EditProfileBusinessComponent
   */
  public editPlantDescription(description: string): void {
    if (description.localeCompare(this.plantDescription) === 0) {
      return
    }
    if (description) {
      description.trim()
      this.loadingChangedPlant = true
      const plantId: number = this.plantSelected.id
      this.plantSelected.longName = description
      delete this.plantSelected.client
      this._plantService
        .updateElement(this.plantSelected, plantId)
        .pipe(
          finalize(() => {
            this.loadingChangedPlant = false
          }),
        )
        .subscribe(
          (plant: Plant) => {
            this.plantDescription = plant.longName
            this._nzMessageService.success('¡Descripción de planta actualizada!')
          },
          _ => {
            this.plantSelected.longName = this.plantDescription
            this._nzMessageService.error('Ha ocurrido un error...')
          },
        )
    }
  }
}
