import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import * as Reducers from '../../store/reducers'
import * as PanelControlActions from './actions'


@Injectable({
    providedIn: 'root'
})
export class PanelControlFacade {
    panelControlSelector$ = this._store.select(Reducers.getPanelControl)

    constructor(private _store: Store<any>) { }

    buttonClick(action: { type: string }): void {
        this._store.dispatch(PanelControlActions.buttonClick({ action }))
    }

    resetState(): void {
        this._store.dispatch(PanelControlActions.resetPanelControlState())
    }
}
