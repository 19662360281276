/**
 * Menu para paginas con todos los accesos.
 *
 * @constant
 * @type {*}
 * @default
 */
import { MenuItem } from '../../interfaces/menu-item'

export const getMenuData: MenuItem[] = [
  // VB:REPLACE-START:MENU-CONFIG
  {
    title: 'sideBar.menu.targetPoints',
    key: '__dashboard',
    url: '/dashboard',
    hidden: false,
    icon: 'fe fe-crosshair',
    children: null,
  },
  {
    title: 'Perfiles',
    key: '__profile',
    url: '/profile',
    icon: 'fe fe-zap',
    hidden: true,
    children: [
      {
        title: 'Perfil de planta',
        key: '__profile/business',
        url: '/profile/business',
      },
      {
        title: 'Información eléctrica de planta',
        key: '__profile/electric-plants',
        url: '/profile/electric-plants',
      },
      {
        title: 'Editar información de planta',
        key: '__profile/electric-plants',
        url: '/profile/edit/edit-business',
      },
      {
        title: 'Editar información eléctrica',
        key: '__profile/electric-plants',
        url: '/profile/edit/edit-electric',
      },
    ],
  },
  {
    title: 'Notificaciones',
    key: '__notification',
    url: '/notification',
    icon: 'fe fe-zap',
    hidden: true,
    children: [
      {
        title: 'Configuración de notificaciones',
        key: '__notification/notification-settings',
        url: '/notification/notification-settings',
      },
    ],
  },
  {
    title: 'ADMIN DE USUARIOS',
    key: '__notification',
    url: '/administration',
    icon: 'fe fe-users',
    hidden: false,
    children: [
      {
        title: 'Internos',
        key: '__administration/users-diram',
        url: '/administration/users-diram',
        metaData: { id: 0 },
      },
      {
        title: 'Externos',
        key: '__administration/notification-settings',
        url: '/administration/clients',
        children: null,
        metaData: { id: null },
      },
    ],
  },
  // VB:REPLACE-END:MENU-CONFIG
]
