import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import * as EventsActions from './events.actions'
import { catchError, exhaustMap, map } from 'rxjs/operators'
import { EventsService } from '../../services/events/events.service'

@Injectable()
export class EventsEffects {
  constructor(private _actions: Actions, private _eventsService: EventsService) {}

  getEvents = createEffect(() =>
    this._actions.pipe(
      ofType(EventsActions.getEventsByPlant),
      exhaustMap(action =>
        this._eventsService
          .getAllElements([
            { param: 'id_plant', value: action.plantId },
            { param: 'relationships', value: 'event_type' },
          ], true, 1, 50)
          .pipe(
            map(response => {
              return EventsActions.loadEventsSucces({response})
            }),
            catchError(error => [EventsActions.loadEventsFailure(error)]),
          ),
      ),
    ),
  )
}
