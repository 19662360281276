import { Injectable } from '@angular/core'
import { FileService } from '../file.service'
import { FileBillingRepository } from './file-billing.repository'

/**
 * Servicio para manejo de archivos de reportes oficiales.
 *
 * @export
 * @class FileBillingService
 * @extends {FileService}
 */
@Injectable({
  providedIn: 'root',
})
export class FileBillingService extends FileService {
  /**
   * Creates an instance of FileBillingService.
   * @param {HttpClient} _fileOfficialRepository
   * @memberof FileBillingService
   */
  constructor(protected _fileOfficialRepository: FileBillingRepository) {
    super(_fileOfficialRepository)
  }
}
