import { createReducer, on } from '@ngrx/store'
import * as states from './report.actions'
import { Report } from '../../models/report.model'

export interface ReportState {
  reports?: Report[]
  reportSelected: Report
  isLoading?: boolean
  isLoadingSuccess?: boolean
  isLoadingFailure?: boolean
}

export const initialState: ReportState = {
  reports: [],
  reportSelected: null,
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
}

const _reportReducer = createReducer(
  initialState,
  on(states.addReportPlant, (state, _) => {
    return { ...state, isLoading: true }
  }),
  on(states.createReportSuccess, (state, { report }) => {
    const reports: Report[] = [...state.reports]
    reports.unshift(report)
    return { ...state, reports, isLoading: false, isLoadingSuccess: true }
  }),
  on(states.createReportFailed, (state, _) => {
    return {
      ...state,
      reports: state.reports,
      isLoading: false,
      isLoadingFailure: true,
    }
  }),
  on(states.updateReport, (state, { reportId }) => {
    const reports: Report[] = [...state.reports]
    const reportSelected: Report = reports.find(report => report.id === reportId)
    return { ...state, reportSelected, isLoading: true, isLoadingSuccess: false, isLoadingFailure: false }
  }),
  on(states.updateReportSuccess, (state, { report }) => {
    const reports: Report[] = [...state.reports]
    const reportIndex: number = reports.findIndex(r => r.id === report.id)
    reports[reportIndex] = report
    return { ...state, reports, reportSelected: null, isLoading: false, isLoadingSuccess: true }
  }),
  on(states.updateReportFailed, (state, _) => {
    return {
      ...state,
      reports: state.reports,
      isLoading: false,
      isLoadingFailure: true,
    }
  }),
  on(states.deleteReport, (state, { report }) => {
    return {
      ...state,
      reportSelected: report,
      isLoading: true,
      isLoadingSuccess: false,
      isLoadingFailure: false,
    }
  }),
  on(states.deleteReportSuccess, (state, { reportId }) => {
    const reports: Report[] = [...state.reports]
    const reportIndex: number = reports.findIndex(r => r.id === reportId)
    reports.splice(reportIndex, 1)
    return {
      ...state,
      reports,
      reportSelected: null,
      isLoading: false,
      isLoadingSuccess: true,
      isLoadingFailure: false,
    }
  }),
  on(states.deleteReportFailure, (state, _) => {
    return {
      ...state, reportSelected: null, isLoading: false,
      isLoadingFailure: true, isLoadingSuccess: false,
    }
  }),
  on(states.getReportsPlant, (state, _) => {
    return { ...state, reports: [], isLoading: true, isLoadingFailure: false, isLoadingSuccess: false }
  }),
  on(states.loadReportsSuccessful, (state, { reports }) => {
    return {
      ...state,
      reports,
      isLoading: false,
      isLoadingSuccess: true,
    }
  }),
  on(states.loadReportsUnsuccessful, (state, _) => {
    return {
      ...state,
      reports: [],
      isLoading: false,
      isLoadingFailure: true,
    }
  }),
)

export function reportReducer(state, action) {
  return _reportReducer(state, action)
}

export const getReport = (state: ReportState) => state
