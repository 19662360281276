import { Pipe, PipeTransform } from '@angular/core'
import { Legal, Person } from '../models/models.index'

/**
 * Pipe para construir el nombre completo para los objetos de Legal y Notificaciones.
 *
 * @export
 * @class SetFullNamePipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'setFullName',
})
export class SetFullNamePipe implements PipeTransform {
  /**
   * Metodo transform para crear un nombre completo
   *
   * @param {(Person | Legal)} person Objeto que se desea obtener el nombre completo.
   * @return {string}  {string} El nombre completo armado.
   * @memberof SetFullNamePipe
   */
  transform(person: Person | Legal): string {
    if (person instanceof Person || 'name' in person) {
      const lastName = person?.lname ? person.lname : ''
      const motherName = person?.mlname ? person?.mlname : ''
      return person.name.concat(
        `${lastName !== '' ? ' '.concat(lastName) : ''}`,
        `${motherName !== '' ? ' '.concat(motherName) : ''}`,
      )
    } else if (person instanceof Legal || 'rep_fname' in person) {
      const lastName = person?.rep_lname ? person.rep_lname : ''
      const motherName = person?.rep_mlname ? person?.rep_mlname : ''
      return person.rep_fname.concat(
        `${lastName !== '' ? ' '.concat(lastName) : ''}`,
        `${motherName !== '' ? ' '.concat(motherName) : ''}`,
      )
    }
  }
}
