import { Notification } from './models.index'

/**
 * Modelo para el manejo de personas que tiene asignado una planta.
 *
 * @export
 * @class Person
 */
export class Person {
  /**
   * Creates an instance of Person.
   * @param {string} name Nombre de la persona.
   * @param {string} lname Apellido paterno de la persona.
   * @param {string} mlname Apellido materno de la persona.
   * @param {string} phone Numero de la persona.
   * @param {string} mail Correo electronico de la persona.
   * @param {number} id_plant Identificador de la planta asociada a la persona.
   * @param {number} [id] Identificador de la persona.
   * @param {Notification} [notification] Notificacion asociada a la persona.
   * @memberof Person
   */
  constructor(
    public name: string,
    public lname: string,
    public mlname: string,
    public phone: string,
    public mail: string,
    public id_plant: number,
    public id?: number,
    public notification?: Notification,
  ) {}
  /**
   * Funcion para obtener personas autorizadas mediante un archivo json.
   *
   * @static
   * @param {number} plantId Planta a la cual se le van asignar las personas.
   * @param {any[]} json Archivo en el cual se va extraer la informacion.
   * @return {*}  {Person[]}
   * @memberof Person
   */
  public static getPersonsFromRawJson(plantId: number, json: any[]): Person[] {
    const userNotification: Person[] = []

    const personDataJson: string[] = []

    for (let i = 22; i < 27; i++) {
      personDataJson.push(this._getValueByPosition(json, i, 1))
    }

    if (personDataJson[0] && personDataJson[4]) {
      const firstPerson: Person = this.buildPerson(personDataJson, 1, plantId)
      userNotification.push(firstPerson)
    }
    personDataJson.length = 0

    for (let i = 27; i < 32; i++) {
      personDataJson.push(this._getValueByPosition(json, i, 1))
    }

    if (personDataJson[0] && personDataJson[4]) {
      const firstPerson: Person = this.buildPerson(personDataJson, 1, plantId)
      userNotification.push(firstPerson)
    }
    personDataJson.length = 0

    for (let i = 32; i < 37; i++) {
      personDataJson.push(this._getValueByPosition(json, i, 1))
    }

    if (personDataJson[0] && personDataJson[4]) {
      const firstPerson: Person = this.buildPerson(personDataJson, 1, plantId)
      userNotification.push(firstPerson)
    }
    personDataJson.length = 0
    return userNotification
  }
  /**
   * Funcion que construye una persona para notificacion de planta.
   *
   * @static
   * @param {any[]} personData Arreglo de informacion
   * @param {number} typeNotification Tipo de notificacion
   * @param {number} plantId Planta que sera asinada.
   * @return {Person}  {Person} Un objecto de tipo persona
   * @memberof Person
   */
  public static buildPerson(personData: any[], typeNotification: number, plantId: number): Person {
    return new Person(
      personData[0],
      personData[1],
      personData[2],
      personData[3],
      personData[4],
      plantId,
    )
  }
  /**
   * Funcion para extraer la informacion en base a la posicion de la casilla.
   *
   * @private
   * @param {any[]} data Funda donde se encuentra la informacion.
   * @param {number} indexElement Fila donde se encuentra la informacion a extraer.
   * @param {number} indexValue Posion del elemento a leer basado en los datos de entrada.
   * @return {*}  {string} El valor segun la posion.
   * @memberof Person
   */
  private static _getValueByPosition(
    data: any[],
    indexElement: number,
    indexValue: number,
  ): string {
    const value: string = String(Object.values(data[indexElement])[indexValue])
    return value.trim()
  }
}
