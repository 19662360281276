import { environment } from '../environments/environment'

import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgModule, LOCALE_ID } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { FormsModule } from '@angular/forms'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'

import { NgProgressModule } from '@ngx-progressbar/core'
import { NgProgressRouterModule } from '@ngx-progressbar/router'
import { NgProgressHttpModule } from '@ngx-progressbar/http'

import { StoreModule } from '@ngrx/store'
import { StoreRouterConnectingModule } from '@ngrx/router-store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { EffectsModule } from '@ngrx/effects'
import { reducers, metaReducers } from './store/reducers'
import { UserEffects } from './store/user/effects'
import { PlantEffects } from './store/plant/plant.effects'
import { CorporateEffects } from './store/corporate/corporate.effects'
import { FilesEffects } from './store/files/files.effects'
import { ReportEffects } from './store/report/report.effects'
import { NotificationsEffects } from './store/notifications/notifications.effects'
import { EventsEffects } from './store/events/events.effects'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'

import { HttpCallInterceptor } from './services/http-interceptor'
import { HttpErrorInterceptor } from './services/http-interceptor/http-error.interceptor'

// locale resistration
import { registerLocaleData } from '@angular/common'
import { default as localeEn } from '@angular/common/locales/en'
import { default as localeES } from '@angular/common/locales/es'
import { NZ_I18N, en_US as localeZorro } from 'ng-zorro-antd/i18n'
import { AngularFireModule } from '@angular/fire/compat'
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging'

/** Provider para establecer el idioma general del template de NG-ZORRO @type {*} */
const LOCALE_PROVIDERS = [
  { provide: LOCALE_ID, useValue: 'es-MX' },
  { provide: NZ_I18N, useValue: localeZorro },
]

const EFFECTS_RX = [UserEffects, PlantEffects, CorporateEffects, FilesEffects, ReportEffects, NotificationsEffects, EventsEffects]

registerLocaleData(localeEn, 'en')
registerLocaleData(localeES, 'es-MX')

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,

    // translate
    TranslateModule.forRoot(),

    // ngrx
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot(EFFECTS_RX),
    StoreRouterConnectingModule.forRoot(),

    // nprogress
    NgProgressModule.withConfig({
      thick: true,
      spinner: false,
      color: '#F7C025',
    }),
    NgProgressRouterModule,
    NgProgressHttpModule,
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
  ],
  providers: [
    // http interceptors
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCallInterceptor,
      multi: true,
    },

    // locale providers
    ...LOCALE_PROVIDERS,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
