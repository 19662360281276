import { ReportOfficialRepository } from './report-official.repositoy'
import { Injectable } from '@angular/core'
import { ReportsService } from '../reports.service'

/**
 * Servicio para manejo de reportes oficiales.
 *
 * @export
 * @class ReportOfficialService
 * @extends {ReportsService}
 */
@Injectable({
  providedIn: 'root',
})
export class ReportOfficialService extends ReportsService {
  /**
   * Creates an instance of ReportOfficialService.
   * @param {ReportOfficialRepository} _reportOfficialRepository
   * @memberof ReportOfficialService
   */
  constructor(protected _reportOfficialRepository: ReportOfficialRepository) {
    super(_reportOfficialRepository)
  }
}
