import { Component, OnDestroy, OnInit } from '@angular/core'
import { User } from '../../../models/user.model'
import { select, Store } from '@ngrx/store'
import * as Reducers from '../../../store/reducers'
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'

@Component({
  selector: 'app-profile-user',
  templateUrl: './profile-user.component.html',
  styleUrls: ['./profile-user.component.scss'],
})
export class ProfileUserComponent implements OnInit, OnDestroy {
  public currentUser: User = null
  public avatar: string = null

  private destroy$: Subject<boolean> = new Subject<boolean>()

  constructor(private _store: Store<any>) {
    this._store.pipe(select(Reducers.getUser), takeUntil(this.destroy$),
    ).subscribe(state => {
      this.currentUser = state
      this.avatar = state?.avatar ? state.avatar : '../../../assets/images/avatars/avatar-2.png'
    })
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.unsubscribe()
  }
}
