import { Corporate } from 'src/app/models/models.index'
import { Rol } from './rol.model'
import { Client } from './client.model'
import { ROLES_SLUGS } from '../config/enviroment'
import { select, Store } from '@ngrx/store'
import * as Reducers from '../store/reducers'
import { first, map } from 'rxjs/operators'

/**
 * Modelo para el manejo de Usuarios Internos de Diram
 *
 * @export
 * @class User
 */
export class User {
  /**
   * Construye una instancia de Nivel de permiso.
   * @param {string} access bandera para saber el estado de acceso.
   * @param {number} id_client Identificaodr unico del usuario.
   * @param flast_name
   * @param {Client} client Cliente asociado al usuario si es externo.
   * @param {number} id_rol Identificador del rol del usuario.
   * @param {string} email Correo electronico del usuario.
   * @param country_code Codigo telefonico del país.
   * @param id_corporate Identificador del corporativo del usuario.
   * @param corporates Corporativo asignado al usuario.
   * @param {string[]} permissions Niveles de permisos que tiene el usuario.
   * @param {number} type Tipo de usuario {0} para externo {1} para internos.
   * @param {Date} email_verified_at Fecha de verificacion del correo del usuario.
   * @param {string} name Nombre del usuario.
   * @param {number} user_type Tipo de usuario.
   * @param {Rol[]} roles Roles que puede tener un usuario.
   * @param {number} id Id del Nivel de permiso.
   * @param phone Telefono del usuario
   * @param created_at Creado en.
   * @param updated_at Actualizado en.
   * @memberof Level.
   */
  constructor(
    public name: string,
    public flast_name: string,
    public country_code: string,
    public phone: string,
    public email?: string,
    public access?: boolean,
    public id_client?: any,
    public permissions?: string[][],
    public id_corporate?: number,
    public corporates?: Corporate[],
    public id_rol?: number,
    public id?: number,
    public client?: Client,
    public roles?: Rol[],
    public user_type?: number,
    public email_verified_at?: string,
    public created_at?: string,
    public updated_at?: string,
  ) {
  }

  /**
   * Metodo para saber si un usuario tiene rol de operador.
   *
   * @static
   * @param {User} user Usuario
   * @return {*}  {boolean} Verdadero cuando el usuario tiene rol operador.
   * @memberof User
   */
  public static isRoleOperator(user: User): boolean {
    return user.roles[0]?.slug === ROLES_SLUGS.operator
  }

  public static async getUserStore(store: Store<any>): Promise<User> {
    return <User>await store.pipe(select(Reducers.getUser), first(), map((user: User) => user)).toPromise()
  }
}
