import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import * as CorporateActions from './corporate.actions'
import { catchError, exhaustMap, map } from 'rxjs/operators'
import { CorporatesService } from '../../services/corporates/corporates.service'

@Injectable()
export class CorporateEffects {
  constructor(private _actions: Actions, private _corporateService: CorporatesService) {
  }

  getCorporates = createEffect(() =>
    this._actions.pipe(
      ofType(CorporateActions.getCorporates),
      exhaustMap(_ =>
        this._corporateService.getAllElements().pipe(
          map(response => {
            return CorporateActions.loadCorporatesSuccessful(response)
          }),
          catchError(error => [CorporateActions.loadCorporatesUnsuccessful(error)]),
        ),
      ),
    ),
  )
}
