export enum StatesNotification {
  VIEW,
  NOT_VIEW
}

export type StateNotification = StatesNotification.NOT_VIEW | StatesNotification.VIEW

export class NotificationPlant {
  constructor(
    public id_notification: number,
    public id_plant: number,
    public title: string,
    public description: string,
    public id_notification_type: number,
    public active: StateNotification,
    public action: string,
    public created_at?: string,
    public updated_at?: string,
  ) {
  }
}
