import { ParamRequest } from 'src/app/interfaces/interfaces.index'
import { CDRVersionReport, URL_API_V2 } from 'src/app/config/enviroment'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { TYPE_VERSION_REPORT } from '../../config/enviroment'
import { HttpClient } from '@angular/common/http'
import { CommonService } from '../shared/common.service'
import { CrudRepository } from '../shared/crud.repository'


/**
 * Servicio base para manejo de informacion de reportes.
 *
 * @export
 * @class ReportsRepository
 */
@Injectable({
    providedIn: 'root',
})
export abstract class ReportsRepository extends CrudRepository {

    /**
     * Creates an instance of ReportsRepository.
     * @param {HttpClient} _httpClient
     * @param {CommonService} _commonService
     * @param {string} _endpointPrefix Prefijo para url de reportes.
     * @memberof ReportsRepository
     */
     constructor(
        protected _httpClient: HttpClient,
        protected _commonService: CommonService,
        protected _endpointPrefix: string,
        protected _baseUrl: string = URL_API_V2) {
        super(_httpClient, _commonService, _endpointPrefix, _baseUrl)
    }

    /**
     * Servicio para obtener una lista de reportes.
     *
     * @param {number} corporateId Identificador de corporativo.
     * @param {number} clientId Identificador del cliente
     * @param {number} [projectId=null] Identificador del proyecto.
     * @param {number} plantId Identificador de la planta.
     * @param {number} uniPointId Identificador del punto unifilar.
     * @return {*}  {Observable<any>}
     * @memberof ReportsRepository
     */
    public getReportsByPlant(
        plantId: number, page: number = 1, perPage: number = 12
    ): Observable<any> {
        const url = `${this._baseUrl}/${this._endpointPrefix}`
        const paramsRequest: ParamRequest[] = []
        paramsRequest.push({ param: 'id_plant', value: plantId })
        return this._commonService.handlerGetElements(url, paramsRequest, 'Data', true, true, page, perPage)
    }

    public getReportsByYear(plantId: number, cdrVersion: CDRVersionReport, year: number,
        paramsRequest: ParamRequest[] = []): Observable<any[]> {
        const url = `${this._baseUrl}/${this._endpointPrefix}`
        paramsRequest.push(...[
            { param: 'id_plant', value: plantId },
            { param: 'year', value: year },
            { param: 'id_report_version', value: cdrVersion },
        ])
        return this._commonService.handlerGetElements(url, paramsRequest, 'Data', true, true, 1, 12)
    }

    public getUrlReportFile(
        reportId: number,
        cdrVersionId: number = TYPE_VERSION_REPORT.REPORT_2022,
    ): Observable<any> {
        const url = `${this._baseUrl}/${this._endpointPrefix}/${reportId}/download`
        const paramsRequest: ParamRequest[] = [{ param: 'id_report_version', value: cdrVersionId }]
        return this._commonService.handlerGetElements(url, paramsRequest, 'Data')
    }

    // TODO analizar si se puede usar el httpClient
    public downloadReportByUrl(url: string): void {
        window.open(url, '_blank')
    }
}
