import { environment } from 'src/environments/environment'
import { RolDiram } from '../interfaces/roles-diram'

/**
 * Url de la api
 *
 * @constant
 * @type {string}
 * @default
 */
export const URL_API: string = environment.apiEndPoint

export const URL_API_V2: string = environment.apiEndPointV2

export const URL_API_BILLING_V1: string = environment.apiEndPointBilling

export const URL_API_NOTIFICATION_V1: string = environment.apiEndPointNotificationV1

/**
 * Url de la direccion del server de la Api Cloud PQ.
 *
 * @constant
 * @type {string}
 * @default
 */
export const SERVER_CLOUDPQ_API_V1: string = environment.hostApiV1

/**
 * Url de la direccion del server de la Api Cloud PQ.
 *
 * @constant
 * @type {string}
 * @default
 */
export const SERVER_CLOUDPQ_API_V2: string = environment.hostApiV1

/**
 * Tipo de usuario interno diram.
 *
 * @constant
 * @type {number}
 * @default
 */
export const TYPE_USERS_DIRAM = 1
/**
 * Tipo de usuario externo.
 *
 * @constant
 * @type {number}
 * @default
 */
export const TYPE_EXTERNAL_USERS = 0
/**
 * Tipo de usuarios disponibles en la plataforma.
 *
 * @constant
 * @type {*}
 * @default
 */
export const AVAILABLE_TYPE_USERS = { internal: TYPE_USERS_DIRAM, external: TYPE_EXTERNAL_USERS }
/**
 * Timpo de duracion para mostrar un mensaje.
 *
 * @constant
 * @type {number}
 * @default
 */
export const TIME_SHOW_MSG = 5000
/**
 * Tiempo de expiracion de sesion por inactividad.
 *
 * @constant
 * @type {number}
 * @default
 */
export const TIME_OUT_SESSION = 900 // expired after 15 minutes (900 segundos)

export const APP_XHR_TIMEOUT = 30000
/**
 * Tipo de mensajes de notificaciones de manera interna disponibles.
 *
 * @constant
 * @type {*}
 * @default
 */
export const TYPE_NOTIFICATION = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
}
/**
 * Roles registrados en la plataforma
 * @constant
 * @type {RolDiram}
 * @default
 */
export const ROLES_SLUGS: RolDiram = {
  admin: 'admin',
  admin_diram: 'admin_diram',
  operator: 'operador',
  supervisor: 'supervisor',
  supervisor_diram: 'supervisor_diram',
}
/**
 * Tipo de reporte no oficial.
 * @constant
 * @type {string}
 * @default
 */
export const UNOFFICIAL = 'unofficial'

export const TRANSACTIONAL = 'transactional'
/**
 * Tipo de reporte oficial.
 * @constant
 * @type {string}
 * @default
 */
export const OFFICIAL = 'official'
/**
 * Tipo de reporte semanal.
 * @constant
 * @type {string}
 * @default
 */
export const WEEK = 'week'
/**
 * Tipo de reporte facturacion.
 * @constant
 * @type {string}
 * @default
 */
 export const BILLING = 'billing'
/**
 * Listado de tipo de reportes en la plataforma.
 * @constant
 * @type {*}
 * @default
 */
export const AVAILABLE_REPORT = { official: OFFICIAL, unofficial: UNOFFICIAL, week: WEEK, transactional: TRANSACTIONAL, billing: BILLING }
/**
 * Limite de reportes totales que puede generar un usuario.
 * @constant
 * @type {number}
 * @default
 */
export const LIMIT_REPORTS = 15
/**
 * Tipo de disparador por mensajes de texto para notificacion.
 * @constant
 * @type {string}
 * @default
 */
export const SMS = 'sms'
/**
 * Tipo de disparador por correo electronico de texto para notificacion.
 * @constant
 * @type {string}
 * @default
 */
export const MAIL = 'mail'
/**
 * Destino de envio de reporte en pdf.
 * @constant
 * @type {string}
 * @default
 */
export const EMAIL = 'email'
/**
 * Listado de tipo de envios disponibles para enviar los reportes en pdf.
 * @constant
 * @type {*}
 * @default
 */
export const AVAILABLE_DESTINATION_PDF = { email: EMAIL }
/**
 * Listado de tipo de notificaciones que puede recibir un usuario de la plataforma.
 * @constant
 * @type {*}
 * @default
 */
export const TYPE_NOTIFICATION_USER = { sms: SMS, mail: MAIL }
/**
 * Tipo de punto de conexion fisica de una planta.
 * @constant
 * @type {string}
 * @default
 */
export const TYPE_REAL = 'fisica'
/**
 * Tipo de punto de conexion digital de una planta.
 * @constant
 * @type {string}
 * @default
 */
export const TYPE_VIRTUAL = 'digital'
/**
 * Identificador numerico del tipo de punto de conexion fisica de una planta.
 * @constant
 * @type {number}
 * @default
 */
export const INT_POINT_REAL = 1
/**
 * Identificador numerico del tipo de punto de conexion digital de una planta.
 * @constant
 * @type {number}
 * @default
 */
export const INT_POINT_DIGITAL = 5

export enum TYPE_VERSION_REPORT {
  REPORT_2016 = 1,
  REPORT_2022,
}

export declare type CDRVersionReport = TYPE_VERSION_REPORT.REPORT_2016 | TYPE_VERSION_REPORT.REPORT_2022

export enum TYPE_MENU_LEFT {
  NONE,
  SIMPLE,
  SIMPLE_USERS,
  COMPLEX,
  COMPOUND
}

export const ID_DEFAULT_CORPORATE = 49
