import { Client } from './models.index'

/**
 * Modelo para el manejo de plantas
 *
 * @export
 * @class Plant
 */
export class Plant {
  /**
   * Construye una instancia de Plant.
   * @param {string} longName Nombre largo del Plant
   * @param {string} name Nombre corte de la Plant
   * @param id_client Identificador del cliente
   * @param client Cliente
   * @param alias Alias de la planta
   * @param {number} id Id del Planta
   * @memberof Plant
   */
  constructor(
    public name: string,
    public longName?: string,
    public id_client?: number,
    public client?: Client,
    public alias?: string,
    public id?: number,
  ) {}
}
