import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { Observable, throwError, TimeoutError } from 'rxjs'
import { catchError, timeout } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { APP_XHR_TIMEOUT } from '../../config/enviroment'

import { UserFacade } from './../../store/user/facade'

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(
        private _userFacade: UserFacade,
        private _notificationService: NzNotificationService) { }

    /**
     * Interceptor de peticiones con errores.
     *
     * @param {HttpRequest<any>} request
     * @param {HttpHandler} next
     * @return {*}  {Observable<HttpEvent<any>>}
     * @memberof HttpCallInterceptor
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            timeout(APP_XHR_TIMEOUT),
            catchError((errorResponse: HttpErrorResponse) => {
                if (errorResponse instanceof TimeoutError) {
                    const errorCopy = Object.assign({}, { error: errorResponse })
                    errorCopy['status'] = HttpStatusCode.RequestTimeout
                    Object.assign(errorResponse, errorCopy)
                }
                if (!environment.production) {
                }

                if (!environment.production) {
                    console.error(`%cUrl Error: ${errorResponse.url}`, 'color: #FF0000; font-weight: bold;')
                    console.error('An error occurred:', errorResponse.error)
                    console.error(`Backend returned code ${errorResponse.status}, body was: ${errorResponse.error?.message ? errorResponse.error.message : 'Not message'}`)
                }

                if (!errorResponse?.message) {
                    errorResponse.error.message = 'Consulte con su administrador...'
                }

                const errorStatusCode: number = errorResponse?.status ? errorResponse.status : 0
                let typeNotification = 'error'
                let showMessage: boolean
                let titleMessage: string
                let bodyMessage: string

                switch (errorStatusCode) {
                    case 0:
                    case HttpStatusCode.RequestTimeout:
                        titleMessage = 'Lo sentimos... el servidor no ha respondido'
                        bodyMessage = 'Vuelva intentarlo más tarde'
                        showMessage = true
                        break
                    case HttpStatusCode.Unauthorized:
                        typeNotification = 'warning'
                        titleMessage = 'Verifique sus datos'
                        bodyMessage = 'Correo o Contraseña incorrecta'
                        showMessage = true
                        break
                    case HttpStatusCode.Forbidden:
                        this._userFacade.cleanUserState()
                        typeNotification = 'warning'
                        titleMessage = 'Sesión finalizada'
                        bodyMessage = 'Su sesión ha sido finalizada'
                        showMessage = true
                        break
                    case HttpStatusCode.NotFound:
                        titleMessage = 'Lo sentimos... la petición solicitada no se encuentra.'
                        bodyMessage = 'Verifique la información.'
                        showMessage = !request.url.includes('image')
                        break
                    case HttpStatusCode.BadRequest:
                    case HttpStatusCode.NotAcceptable:
                    case HttpStatusCode.MethodNotAllowed:
                    case HttpStatusCode.UnprocessableEntity:
                        titleMessage = 'Lo sentimos... la petición no puede ser procesada'
                        bodyMessage = 'Verifique la información.'
                        showMessage = true
                        break
                    case HttpStatusCode.InternalServerError:
                    default:
                        titleMessage = 'Lo sentimos... Ha ocurrido un error'
                        bodyMessage = 'Consulte con su administrador, si el error es persistente.'
                        showMessage = true
                        break
                }
                if (showMessage) {
                    switch (typeNotification) {
                        case 'warning':
                            this._notificationService.warning(titleMessage, bodyMessage, { nzDuration: 2500 })
                            break
                        case 'error':
                        default:
                            this._notificationService.error(titleMessage, bodyMessage, { nzDuration: 3500 })
                            break
                    }
                }
                return throwError(errorResponse.error)
            }),
        )
    }
}
