<nz-sider [nzWidth]='leftMenuWidth'
          [ngClass]='{
            menu: true,
            white: true,
            unfixed: isMenuUnfixed,
            shadow: !isMobileView}'>
  <div class='menuOuter' [ngStyle]="{
    width: leftMenuWidth + 'px',
    height: isMobileView || isMenuUnfixed ? 'calc(100% - 64px)' : 'calc(100% - 234px)'}">
    <div class='logo-company' [ngStyle]="{
        backgroundImage:'url(../../../assets/images/brands/diram-logo-70h.png)'
      }">
    </div>
    <perfect-scrollbar [ngStyle]="{'height': typeMenu === typesMenu.COMPOUND ? '50%' : '100%' }">
      <ul nz-menu class='navigation' [nzMode]="'inline'" *ngIf='menuDataActivated'>
        <ng-container
          *ngTemplateOutlet='menuTpl; context: {$implicit: menuDataActivated, level: 0}'>
        </ng-container>
        <ng-template #menuTpl let-menuDataActivated let-level='level'>
          <ng-container *ngFor='let item of menuDataActivated'>
            <ng-template #tooltipTpl>
              {{ item.title }}
            </ng-template>
            <!-- submenu item with recursive template -->
            <li nz-submenu *ngIf='item.children && !item?.hidden' (nzOpenChange)='onClickItem(item, level)'
                [nzOpen]='item.children.length > 0'>
              <span title style='width: inherit'>
                <i *ngIf='item.icon && level === 0' [ngClass]="['icon']" nz-icon nzType='deployment-unit'></i>
                <span class='title'>{{item.title | translate}}</span>
              </span>
              <ul>
                <ng-container *ngTemplateOutlet='menuTpl; context: {$implicit: item.children, level: level + 1}'>
                </ng-container>
              </ul>
            </li>
            <!-- main item -->
            <li nz-menu-item *ngIf='!item.children && (isReady || typeMenu === typesMenu.SIMPLE)'
                [nzDisabled]='item.disabled' nz-tooltip nzTooltipPlacement='right'
                [nzTooltipTitle]="level !== 0 ? tooltipTpl : ''" (click)='onClickItem(item, level)'>
                <span [ngStyle]="{'padding-left.px': level * 8}" style='width: inherit'>
                  <span class='title'>{{item.title}}</span>
                </span>
            </li>
          </ng-container>
        </ng-template>
      </ul>
    </perfect-scrollbar>
    <ng-container *ngIf='typeMenu === typesMenu.COMPOUND'>
      <widget-menu-panel-control [showOption]="showOption" [mode]='modeMenuControl' [version]='version'
                                 (clickEvent)='onPanelControl($event)' (generateEvent)='onGenerate($event)'></widget-menu-panel-control>
    </ng-container>
    <nz-footer>
      <vb-footer></vb-footer>
    </nz-footer>
  </div>
</nz-sider>
