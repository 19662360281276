import { createReducer, on } from '@ngrx/store'
import * as states from './files.actions'
import { PlantFiles } from '../../interfaces/plant-files'

export interface FilesState {
  files?: PlantFiles[]
  isLoading?: boolean
  isLoadingSuccess?: boolean
  isLoadingFailure?: boolean
}

export const initialState: FilesState = {
  files: [],
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
}

const _filesReducer = createReducer(
  initialState,
  on(states.getFile, (state, { params }) => {
    return { ...state, files: [], isLoading: true, isLoadingSuccess: false, isLoadingFailure: false, plantId: params.plantId }
  }),
  on(states.loadFileSuccessful, (state, result) => {
    const files: PlantFiles[] = result.files
    return {
      ...state,
      files,
      isLoading: false,
      isLoadingSuccess: true,
    }
  }),
  on(states.loadFileUnsuccessful, (state, _) => {
    return {
      ...state,
      result: null,
      files: [],
      isLoading: false,
      isLoadingFailure: true,
    }
  }),
  on(states.getFilesByPlants, (state, { plants }) => {
    return { ...state, files: [], isLoading: true, isLoadingFailure: false, isLoadingSuccess: false }
  }),
  on(states.loadFilesSuccessful, (state, result) => {
    const files: PlantFiles[] = result.files
    return {
      ...state,
      files: files,
      isLoading: false,
      isLoadingSuccess: true,
    }
  }),
  on(states.loadFilesUnsuccessful, (state, _) => {
    return {
      ...state,
      plants: null,
      isLoading: false,
      isLoadingFailure: true,
    }
  }),
  on(states.flushFile, () => initialState),
  on(states.emptyFileAction, (state) => state),
)

export function filesReducer(state, action) {
  return _filesReducer(state, action)
}

export const getFiles = (state: FilesState) => state
