import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { CommonService } from '../shared/common.service'
import { TYPE_VERSION_REPORT, URL_API_V2 } from '../../config/enviroment'
import { Observable } from 'rxjs'
import { ParamRequest } from '../../interfaces/param-request'

/**
 * Servicio para el manejo de informacion de archivos json de los reportes.
 *
 * @export
 * @class FileRepository
 */
@Injectable({
  providedIn: 'root',
})
export class FileRepository {
  /**
   * Creates an instance of FileRepository.
   * @param {HttpClient} _httpClient
   * @param {CommonService} _commonService
   * @memberof FileRepository
   */
  constructor(
      protected _httpClient: HttpClient,
      protected _commonService: CommonService,
      protected _endpointPrefix: string) {
  }


  public getFile(paramRequest: ParamRequest[]): Observable<any> {
    const url = `${URL_API_V2}/${this._endpointPrefix}`
    return this._commonService.handlerGetElements(url, paramRequest)
  }

  /**
   * Servicio para obtener archivos por reporte.
   *
   * @param {number} reportId
   * @param cdrVersionId
   * @return {*}  {Observable<any>}
   * @memberof FileRepository
   */
  public getFileByReport(
    reportId: number,
    cdrVersionId: number = TYPE_VERSION_REPORT.REPORT_2022
  ): Observable<any> {
    const url = `${URL_API_V2}/${this._endpointPrefix}/${reportId}/files`
    const paramsRequest: ParamRequest[] = [{ param: 'id_report_version', value: cdrVersionId }]
    return this._commonService.handlerGetElements(url, paramsRequest, 'Data')
  }

  public getFileByPlant( plantId: number, paramsRequest: ParamRequest[] ): Observable<any> {
    const url = `${URL_API_V2}/${this._endpointPrefix}/plant/${plantId}/files`
    return this._commonService.handlerGetElements(url, paramsRequest, 'Data')
  }

  public getFileChartsBilling(): Observable<any> {
    const url = `/api/billing/charts`
    return this._commonService.handlerGetElements(url, null, 'Data')
  }
}
