import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Question } from '../../interfaces/question'
import { environment } from 'src/environments/environment'

/**
 * Servicio para obtener las preguntas de los formularios electricos y legal.
 *
 * @export
 * @class QuestionService
 */
@Injectable({
  providedIn: 'root',
})
export class QuestionService {
  /**
   * Creates an instance of QuestionService.
   * @param {HttpClient} _http
   * @memberof QuestionService
   */
  constructor(private _http: HttpClient) {}

  /**
   * Servicio para obtener las preguntas de formulario legal.
   *
   * @return {Observable<Question[]>}  {Observable<Question[]>}
   * @memberof QuestionService
   */
  public getQuestionsLegal(): Observable<Question[]> {
    const url = `/api/questions/legal`
    return this._http.get(url).pipe(
      map((question: Question[]) => {
        if (!environment.production) {
          console.table(question)
        }
        return question
      }),
    )
  }

  /**
   * Servicio para obtener las preguntas de formularios electricos.
   *
   * @return {Observable<Question[]>}  {Observable<Question[]>}
   * @memberof QuestionService
   */
  public getQuestionsElectric(): Observable<Question[]> {
    const url = `/api/questions/electric`
    return this._http.get(url).pipe(
      map((question: Question[]) => {
        if (!environment.production) {
          console.table(question)
        }
        return question
      }),
    )
  }

  /**
   * Servicio para obtener las preguntas de formularios electricos.
   *
   * @return {Observable<Question[]>}  {Observable<Question[]>}
   * @memberof QuestionService
   */
  public getQuestionsFormGram(): Observable<Question[]> {
    const url = `/api/questions/form-gram`
    return this._http.get(url).pipe(
      map((question: Question[]) => {
        if (!environment.production) {
          console.table(question)
        }
        return question
      }),
    )
  }

  /**
   * Servicio para obtener las preguntas de transformadores.
   *
   * @return {Observable<Question[]>}  {Observable<Question[]>}
   * @memberof QuestionService
   */
  public getQuestionsTransformer(): Observable<Question[]> {
    const url = `/api/questions/transformer`
    return this._http.get(url).pipe(
      map((question: Question[]) => {
        if (!environment.production) {
          console.table(question)
        }
        return question
      }),
    )
  }
}
