import { Action } from '@ngrx/store'
/**
 * Label de estado de configuracion.
 *
 * @constant
 * @type {string}
 * @default
 */
export const SET_STATE = '[Settings] Set State'
/**
 * Label para cambio de configuracion.
 *
 * @constant
 * @type {string}
 * @default
 */
export const CHANGE_SETTING = '[Settings] Change Setting'
/**
 * Label para cambio de configuracion por elemento o grupal.
 *
 * @constant
 * @type {string}
 * @default
 */
export const CHANGE_SETTING_BULK = '[Settings] Change Setting Bulk'

/**
 * Action para establecer un estado de configuracion
 *
 * @export
 * @class SetStateAction
 * @implements {Action}
 */
export class SetStateAction implements Action {
  /**
   * Tipo de action
   *
   * @memberof SetStateAction
   */
  readonly type = SET_STATE
  /**
   * Creates an instance of SetStateAction.
   * @param {object} payload informacion a enviar
   * @memberof SetStateAction
   */
  constructor(public payload: object) {}
}

/**
 * Action para establecer un estado de cambio de configuracion.
 *
 * @export
 * @class ChangeSettingAction
 * @implements {Action}
 */
export class ChangeSettingAction implements Action {
  /**
   * Tipo de action
   *
   * @memberof ChangeSettingAction
   */
  readonly type = CHANGE_SETTING
  /**
   * Creates an instance of ChangeSettingAction.
   * @param {object} payload informacion a enviar.
   * @memberof ChangeSettingAction
   */
  constructor(public payload: object) {}
}

/**
 * Action para el cambio de configuracion segun el elemento que se cambia de manera individual o grupal.
 *
 * @export
 * @class ChangeSettingBulkAction
 * @implements {Action}
 */
export class ChangeSettingBulkAction implements Action {
  /**
   * Tipo de action
   *
   * @memberof ChangeSettingBulkAction
   */
  readonly type = CHANGE_SETTING_BULK
  /**
   * Creates an instance of ChangeSettingBulkAction.
   * @param {object} payload informacion a enviar.
   * @memberof ChangeSettingBulkAction
   */
  constructor(public payload: object) {}
}

export type Actions = SetStateAction | ChangeSettingAction | ChangeSettingBulkAction
