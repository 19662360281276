import { Action } from '@ngrx/store'

/**
 * Label de estado de login.
 *
 * @constant
 * @type {string}
 * @default
 */
export const LOGIN = '[Auth] Login'
/**
 * Label de estado de login completado.
 *
 * @constant
 * @type {string}
 * @default
 */
export const LOGIN_SUCCESSFUL = '[Auth] Login Succesful'
/**
 * Label de estado de login no completado.
 *
 * @constant
 * @type {string}
 * @default
 */
export const LOGIN_UNSUCCESSFUL = '[Auth] Login Unsuccesful'
/**
 * Label de estado de registro.
 *
 * @constant
 * @type {string}
 * @default
 */
export const REGISTER = '[Auth] Register'
/**
 * Label de estado de registro completado.
 *
 * @constant
 * @type {string}
 * @default
 */
export const REGISTER_SUCCESSFUL = '[Auth] Register Succesful'
/**
 * Label de estado de registro no completado.
 *
 * @constant
 * @type {string}
 * @default
 */
export const REGISTER_UNSUCCESSFUL = '[Auth] Register Unsuccesful'
/**
 * Label de estado de carga de la cuenta actual.
 *
 * @constant
 * @type {string}
 * @default
 */
export const LOAD_CURRENT_ACCOUNT = '[Auth] Load Current Account'
/**
 * Label de estado de carga de la cuenta actual completada.
 *
 * @constant
 * @type {string}
 * @default
 */
export const LOAD_CURRENT_ACCOUNT_SUCCESSFUL = '[Auth] Load Current Account Succesful'
/**
 * Label de estado de carga de la cuenta actual no completada.
 *
 * @constant
 * @type {string}
 * @default
 */
export const LOAD_CURRENT_ACCOUNT_UNSUCCESSFUL = '[Auth] Load Current Account Unsuccesful'

export const UPDATE_USER = '[Auth] Update User'
/**
 * Label de estado de logout.
 *
 * @constant
 * @type {string}
 * @default
 */
export const LOGOUT = '[Auth] Logout'
/**
 * Label de estado de flush de usuario.
 *
 * @constant
 * @type {string}
 * @default
 */
export const FLUSH_USER = '[Auth] Flush User'
/**
 * Label de estado de vacio.
 *
 * @constant
 * @type {string}
 * @default
 */
export const EMPTY_ACTION = '[Auth] Empty Action'
/**
 * Action para el estado de Login.
 *
 * @export
 * @class Login
 * @implements {Action}
 */
export class Login implements Action {
  /**
   * Tipo de action
   *
   * @memberof Login
   */
  readonly type = LOGIN
  /**
   * Creates an instance of Login.
   * @param {*} payload informacion a enviar.
   * @memberof Login
   */
  constructor(public payload: any) {}
}

/**
 * Action para el estado de login completado.
 *
 * @export
 * @class LoginSuccessful
 * @implements {Action}
 */
export class LoginSuccessful implements Action {
  /**
   * Tipo de action
   *
   * @memberof LoginSuccessful
   */
  readonly type = LOGIN_SUCCESSFUL
  /**
   * Creates an instance of LoginSuccessful.
   * @param {*} payload informacion a enviar.
   * @memberof LoginSuccessful
   */
  constructor(public payload: any) {}
}

/**
 * Action para el estado de login no completado.
 *
 * @export
 * @class LoginUnsuccessful
 * @implements {Action}
 */
export class LoginUnsuccessful implements Action {
  /**
   * Action para el estado de registro.
   *
   * @memberof LoginUnsuccessful
   */
  readonly type = LOGIN_UNSUCCESSFUL
  /**
   * Creates an instance of LoginUnsuccessful.
   * @memberof LoginUnsuccessful
   */
  constructor(public payload: { message: string, status: number, response: any }) {}
}

/**
 * Action para el estado de registro.
 *
 * @export
 * @class Register
 * @implements {Action}
 */
export class Register implements Action {
  /**
   * Tipo de action
   *
   * @memberof Register
   */
  readonly type = REGISTER
  /**
   * Creates an instance of Register.
   * @param {*} payload informacion a enviar.
   * @memberof Register
   */
  constructor(public payload: any) {}
}

/**
 * Action para el estado de registro completado.
 *
 * @export
 * @class RegisterSuccessful
 * @implements {Action}
 */
export class RegisterSuccessful implements Action {
  /**
   * Tipo de action
   *
   * @memberof RegisterSuccessful
   */
  readonly type = REGISTER_SUCCESSFUL
  /**
   * Creates an instance of RegisterSuccessful.
   * @param {*} payload Informacion a enviar.
   * @memberof RegisterSuccessful
   */
  constructor(public payload: any) {}
}

/**
 * Action para el estado de registro no completado.
 *
 * @export
 * @class RegisterUnsuccessful
 * @implements {Action}
 */
export class RegisterUnsuccessful implements Action {
  /**
   * Tipo de action
   *
   * @memberof RegisterUnsuccessful
   */
  readonly type = REGISTER_UNSUCCESSFUL
  /**
   * Creates an instance of RegisterUnsuccessful.
   * @memberof RegisterUnsuccessful
   */
  constructor() {}
}

/**
 * Action para el estado de carga de la cuenta actual.
 *
 * @export
 * @class LoadCurrentAccount
 * @implements {Action}
 */
export class LoadCurrentAccount implements Action {
  /**
   * Tipo de action
   *
   * @memberof LoadCurrentAccount
   */
  readonly type = LOAD_CURRENT_ACCOUNT
  /**
   * Creates an instance of LoadCurrentAccount.
   * @memberof LoadCurrentAccount
   */
  constructor() {}
}

/**
 * Action para el estado de carga de la cuenta actual completada.
 *
 * @export
 * @class LoadCurrentAccountSuccessful
 * @implements {Action}
 */
export class LoadCurrentAccountSuccessful implements Action {
  /**
   * Tipo de action
   *
   * @memberof LoadCurrentAccountSuccessful
   */
  readonly type = LOAD_CURRENT_ACCOUNT_SUCCESSFUL
  /**
   * Creates an instance of LoadCurrentAccountSuccessful.
   * @param {*} payload informacion a enviar.
   * @memberof LoadCurrentAccountSuccessful
   */
  constructor(public payload: any) {}
}

/**
 * Action para el estado de carga de la cuenta actual no completada.
 *
 * @export
 * @class LoadCurrentAccountUnsuccessful
 * @implements {Action}
 */
export class LoadCurrentAccountUnsuccessful implements Action {
  /**
   * Tipo de action
   *
   * @memberof LoadCurrentAccountUnsuccessful
   */
  readonly type = LOAD_CURRENT_ACCOUNT_UNSUCCESSFUL
  /**
   * Creates an instance of LoadCurrentAccountUnsuccessful.
   * @memberof LoadCurrentAccountUnsuccessful
   */
  constructor() {}
}

export class UpdateUser implements Action {
  readonly type = UPDATE_USER
  constructor(public payload: any) {}
}

/**
 * Action para el estado de logout del usuario.
 *
 * @export
 * @class Logout
 * @implements {Action}
 */
export class Logout implements Action {
  /**
   * Tipo de action
   *
   * @memberof Logout
   */
  readonly type = LOGOUT
  /**
   * Creates an instance of Logout.
   * @memberof Logout
   */
  constructor() {}
}

/**
 * Action para limpiar los estado de usuario.
 *
 * @export
 * @class FlushUser
 * @implements {Action}
 */
export class FlushUser implements Action {
  /**
   * Tipo de action
   *
   * @memberof FlushUser
   */
  readonly type = FLUSH_USER
  /**
   * Creates an instance of FlushUser.
   * @memberof FlushUser
   */
  constructor() {}
}

/**
 * Action vacia.
 *
 * @export
 * @class EmptyAction
 * @implements {Action}
 */
export class EmptyAction implements Action {
  /**
   * Tipo de action
   *
   * @memberof EmptyAction
   */
  readonly type = EMPTY_ACTION
  /**
   * Creates an instance of EmptyAction.
   * @memberof EmptyAction
   */
  constructor() {}
}

export type Actions =
  | Login
  | LoginSuccessful
  | LoginUnsuccessful
  | Register
  | RegisterSuccessful
  | RegisterUnsuccessful
  | LoadCurrentAccount
  | LoadCurrentAccountSuccessful
  | LoadCurrentAccountUnsuccessful
  | UpdateUser
  | Logout
  | FlushUser
  | EmptyAction
