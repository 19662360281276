import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { CommonService } from '../../shared/common.service'
import { CrudRepository } from '../../shared/crud.repository'
import { ReportsRepository } from '../reports.repository'

/**
 * Servicio para manejo de reportes transacionales.
 *
 * @export
 * @class ReportTransactionalRepository
 * @extends {ReportsService}
 */
@Injectable({
    providedIn: 'root',
})
export class ReportTransactionalRepository extends ReportsRepository {
    /**
     * Creates an instance of ReportTransactionalRepository.
     * @param {HttpClient} _httpClient
     * @param {CommonService} _commonService
     * @param {string} _endpointPrefix Prefijo para url de reportes transaccionales.
     * @memberof ReportTransactionalRepository
     */
    constructor(protected _httpClient: HttpClient, protected _commonService: CommonService) {
        super(_httpClient, _commonService, 'transactional-report')
    }
}
