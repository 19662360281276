import { Component, OnInit } from '@angular/core'

/**
 * Componente para contener la pagina de reiniciar la contraseña.
 * Para realizar un cambio sin modificar este archivo, solo el html
 * donde se realiza el llamado del componente.
 *
 * @export
 * @class ResetPasswordRequestPage
 * @implements {OnInit}
 */
@Component({
  selector: 'app-reset-password-request',
  templateUrl: './reset-password-request.component.html',
})
export class ResetPasswordRequestPage implements OnInit {
  /**
   * Creates an instance of ResetPasswordRequestPage.
   * @memberof ResetPasswordRequestPage
   */
  constructor() {}
  /**
   * OnInit
   *
   * @memberof ResetPasswordRequestPage
   */
  ngOnInit(): void {}
}
