import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { AuthService } from '../../../services/auth/auth.service'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { map, switchMap } from 'rxjs/operators'
import { PasswordReset } from '../../../interfaces/password-reset'
import { MustMatch } from '../../../extensions/must-match'
import { throwError } from 'rxjs'

/**
 * Funcion para mostar el loader.
 *
 */
declare function loader_show()

/**
 * Funcionar para inciar la vista sin loader.
 *
 */
declare function init_plugins()

/**
 * Componente para la vista de verificar el correo electrónico del usuario.
 *
 * @export
 * @class VerifyEmailComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'vb-verify-email',
  templateUrl: './verify-email.component.html',
})
export class VerifyEmailComponent implements OnInit {
  /**
   * Grupo de formularios para las inputs del formulario.
   *
   * @type {FormGroup}
   * @memberof VerifyEmailComponent
   */
  public form: FormGroup
  /**
   * Variable de control de estado del envio de informacion.
   *
   * @type {boolean}
   * @memberof VerifyEmailComponent
   */
  public loading: boolean = false
  /**
   * Variable para almacenar el correo electronico del usuario obtenido mediante query params.
   *
   * @private
   * @type {string}
   * @memberof VerifyEmailComponent
   */
  private userEmail: string
  /**
   * Variable para almacenar el token temporal del usuario obtenido mediante query params.
   *
   * @private
   * @type {string}
   * @memberof VerifyEmailComponent
   */
  private tokenTmp: string

  /**
   * Creates an instance of VerifyEmailComponent.
   * @param {FormBuilder} _fb
   * @param {ActivatedRoute} _route
   * @param {Router} _router
   * @param {AuthService} _authService
   * @param {NzNotificationService} _notificationService
   * @memberof VerifyEmailComponent
   */
  constructor(
    private _fb: FormBuilder,
    private _route: ActivatedRoute,
    private _router: Router,
    private _authService: AuthService,
    private _notificationService: NzNotificationService,
  ) {
    loader_show()
  }

  /**
   * OnInit
   *
   * @memberof VerifyEmailComponent
   */
  ngOnInit(): void {

    this._route.queryParams.pipe(switchMap(query => {
      this.tokenTmp = query['code']
      this.userEmail = query['email']
      const expireTime = query['expire_at']

      if (!this.tokenTmp || !this.userEmail || !expireTime) {
        return throwError({ message: 'Error on params', code: 422 })
      }

      const payload = { code: this.tokenTmp, email: this.userEmail, expire_at: expireTime }

      return this._authService.verifyEmail(payload)
    })).subscribe(_ => {
      init_plugins()
    },
      error => {
        this._router.navigate(['auth/login']).then(() => {
          init_plugins()
          this._notificationService.error('¡Opps! Ha ocurrido un error', `${error.message}`, {
            nzDuration: 3500,
          })
        })
      },
    )

    this.form = this._fb.group(
      {
        password: [null, [Validators.required, Validators.minLength(6)]],
        confirmPassword: [null, [Validators.required]],
      },
      {
        validator: MustMatch('password', 'confirmPassword'),
      },
    )
  }

  /**
   * Callback para enviar informacion de los formularios a los servicios.
   *
   * @memberof VerifyEmailComponent
   */
  public submitForm(): void {
    this.confirmPassword?.markAsDirty()
    this.confirmPassword?.updateValueAndValidity()
    this.password?.markAsDirty()
    this.password?.updateValueAndValidity()
    if (this.password?.invalid || this.confirmPassword?.invalid || !this.userEmail) {
      return
    }
    this.loading = true
    const payload: PasswordReset = {
      cpassword: this.confirmPassword.value,
      password: this.password.value,
      email: this.userEmail,
    }

    this._authService.resetPassword(payload, this.tokenTmp).subscribe(
      resp => { },
      error => {
        this.loading = false
        this._notificationService.error('¡Opps! Ha ocurrido un error', `${error.message}`, {
          nzDuration: 3500,
        })
      },
      () => {
        this.loading = false
        this._router.navigate(['auth/login']).then(() => {
          init_plugins()
          this._notificationService.success(
            '¡Bienvenido a Cloud PQ!',
            'Su cuenta se encuentra lista para iniciar sesión',
            {
              nzDuration: 3500,
            },
          )
        })
      },
    )
  }

  /**
   * Getter para el control del campo de contraseña del usuario.
   *
   * @readonly
   * @memberof VerifyEmailComponent
   */
  public get password() {
    return this.form.controls.password
  }

  /**
   * Getter para el control del campo de confirmar contraseña del usuario.
   *
   * @readonly
   * @memberof VerifyEmailComponent
   */
  public get confirmPassword() {
    return this.form.controls.confirmPassword
  }
}
