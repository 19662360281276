import { URL_API_V2 } from './../../config/enviroment'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { CommonService } from '../shared/common.service'
import { CrudRepository } from '../shared/crud.repository'

/**
 * Servicio para manejo de roles.
 *
 * @export
 * @class RolRepository
 * @extends {CrudRepository}
 */
@Injectable({
  providedIn: 'root',
})
export class RolRepository extends CrudRepository {
  /**
   * Creates an instance of RolService.
   * @param {HttpClient} _httpClient
   * @param {CommonService} _commonService Funciones con servicios de angular comunes.
   * @memberof RolService
   */
   constructor(protected _httpClient: HttpClient, protected _commonService: CommonService) {
    super(_httpClient, _commonService, 'rol')
  }
}
