import { Injectable } from '@angular/core'
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http'
import { Observable, of } from 'rxjs'
import { Question } from '../../interfaces/question'
import { delay, filter, tap } from 'rxjs/operators'
import { environment } from 'src/environments/environment'

import * as charts_billing from '../../../assets/json/charts_billing.json'

/** Preguntas de informacion legal. @type {Question[]} */
const questionsLegal: Question[] = [
  {
    placeholder: 'Tensión',
    question: 'Nivel de tensión del Centro de Carga en el punto de conexión',
    formControlName: 'voltage_level',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'RMU',
    question: 'Registro de Usuario RMU',
    formControlName: 'rmu',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Centro de carga',
    question: 'Centro de carga es',
    formControlName: 'person',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Nombre o razón social',
    question: 'Nombre o razón social',
    formControlName: 'business_name',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Demanda contratada (kW)',
    question: 'Demanda contratada (kW)',
    formControlName: 'demand',
    isRequired: true,
    tooltip: '----',
    typeData: 'number',
  },
  {
    placeholder: 'Domicilio',
    question: 'Domicilio',
    formControlName: 'address',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Código postal',
    question: 'Código postal',
    formControlName: 'cp',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Municipio o alcaldía',
    question: 'Municipio o alcaldía',
    formControlName: 'municipality',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Estado Federativo',
    question: 'Estado Federativo',
    formControlName: 'state',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Código SCIAN',
    question: 'Código SCIAN',
    formControlName: 'scian_code',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Descripción del SCIAN',
    question: 'Descripción del SCIAN',
    formControlName: 'scian_description',
    isRequired: false,
    tooltip: '----',
  },
  // Second part form:
  {
    placeholder: 'Nombre(s)',
    question: 'Nombre(s)',
    formControlName: 'rep_fname',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Apellido Paterno',
    question: 'Apellido Paterno',
    formControlName: 'rep_lname',
    isRequired: false,
    tooltip: '----',
  },
  {
    placeholder: 'Apellido Materno',
    question: 'Apellido Materno',
    formControlName: 'rep_mlname',
    isRequired: false,
    tooltip: '----',
  },
  {
    placeholder: 'Teléfono',
    question: 'Teléfono',
    formControlName: 'rep_phone',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'E-mail',
    question: 'E-mail',
    formControlName: 'rep_mail',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Domicilio',
    question: 'Domicilio',
    formControlName: 'rep_address',
    isRequired: true,
    tooltip: 'Para recibir notificaciones',
  },
  {
    placeholder: 'Selecciona una respuesta',
    question:
      '¿El centro de carga tiene protecciones por bajo nivel de tensión, alto nivel de tensión o ambas en el punto de conexión?',
    formControlName: 'q_3_1_1',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Selecciona una respuesta',
    question: '¿El ajuste de las protecciones está al menos en el rango requerido?',
    formControlName: 'q_3_1_2_a',
    isRequired: false,
    tooltip: '----',
  },
  {
    placeholder: 'Selecciona una respuesta',
    question:
      '¿Los equipos del Centro de Carga pueden soportar, en su totalidad o parcialmente, las variaciones de tensión establecidas en el Código de Red?',
    formControlName: 'q_3_1_2_b',
    isRequired: false,
    tooltip: '----',
  },
  {
    placeholder: 'Selecciona una respuesta',
    question:
      '¿El centro de carga tiene protecciones por baja frecuencia, alta frecuencia o ambas en el punto de conexión?',
    formControlName: 'q_3_2_1',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Selecciona una respuesta',
    question: '¿Dichas protecciones están ajustadas a los rangos requeridos?',
    formControlName: 'q_3_2_2',
    isRequired: false,
    tooltip: '----',
  },
  {
    placeholder: 'Selecciona una respuesta',
    question: '¿Conoce los valores de corto circuito en su punto de conexión?',
    formControlName: 'q_3_3_1',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Selecciona una respuesta',
    question: 'Ignore esta pregunta',
    formControlName: 'q_3_3_2',
    isRequired: false,
    tooltip: '----',
  },
  {
    placeholder: 'Selecciona una respuesta',
    question:
      '¿Las protecciones del punto de conexión del centro de carga están ajustadas a los valores de corto circuito indicados en el Manual Regulatorio de Requerimientos Técnicos para la Conexión de Centros de Carga?',
    formControlName: 'q_3_3_3',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Selecciona una respuesta',
    question:
      'En el Punto de Conexión ¿Se encuentra coordinado el ajuste de las protecciones con el Transportista o Distribuidor?',
    formControlName: 'q_3_4_1',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Selecciona una respuesta',
    question: '¿Es usuario calificado?',
    formControlName: 'q_3_5_1',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Selecciona una respuesta',
    question:
      'En el punto de conexión, ¿Cuenta con la infraestructura requerida para el control operativo, de conformidad con el Manual de TIC?',
    formControlName: 'q_3_5_2',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Selecciona una respuesta',
    question: '¿El centro de carga oferta demanda controlable?',
    formControlName: 'q_3_6_1',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Selecciona una respuesta',
    question: '¿Cumple con los requisitos establecidos para la demanda controlable?',
    formControlName: 'q_3_6_2',
    isRequired: false,
    tooltip: '----',
  },
]

/** Preguntas de informacion electrica. @type {Question[]} */
const questionsElectric: Question[] = [
  {
    placeholder: 'Subestación eléctrica',
    question: 'Subestación eléctrica',
    formControlName: 'electric_substation',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Acrónimo de subestación',
    question: 'Acrónimo de subestación',
    formControlName: 'substation_acron',
    isRequired: false,
    tooltip: '----',
  },
  {
    placeholder: 'Número de transformadores de alta tensión(bus arriba de 69kV)',
    question: 'Número de transformadores de alta tensión(bus arriba de 69kV)',
    formControlName: 'numbe_transformer_high',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Número de transformadores en media tensión (entre 1kV a 69kV)',
    question: 'Número de transformadores en media tensión (entre 1kV a 69kV)',
    formControlName: 'number_transformer_medium',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Voltaje de planta (kV)',
    question: 'Voltaje de planta (kV)',
    formControlName: 'plant_voltage',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Corriente de corto circuito 3f (kA)',
    question: 'Corriente de corto circuito 3f (kA)',
    formControlName: 'current_cc_3f',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Corriente de corto circuito 1f (kA)',
    question: 'Corriente de corto circuito 1f (kA)',
    formControlName: 'current_cc_1f',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Corriente de corto circuito 3f max (kA)',
    question: 'Corriente de corto circuito 3f max (kA)',
    formControlName: 'current_cc_3f_max',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Corriente de corto circuito 1f max (kA)',
    question: 'Corriente de corto circuito 1f max (kA)',
    formControlName: 'current_cc_1f_max',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Corriente de corto circuito 3f min (kA)',
    question: 'Corriente de corto circuito 3f min (kA)',
    formControlName: 'current_cc_3f_min',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Corriente de corto circuito 1f min (kA)',
    question: 'Corriente de corto circuito 1f min (kA)',
    formControlName: 'current_cc_1f_min',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Zth+ max 3f',
    question: 'Zth+ max 3f',
    formControlName: 'zth_p_3f_max',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Zth0 max 1f',
    question: 'Zth0 max 1f',
    formControlName: 'zth_0_1f_max',
    isRequired: true,
    tooltip: '----',
  },
  // Inicia datos calculados.
  {
    placeholder: 'Corriente de carga iL (A)',
    question: 'Corriente de carga iL (A)',
    formControlName: 'il',
    isRequired: false,
    tooltip: '----',
  },
  {
    placeholder: 'Impedancia relativa (Icc/iL)',
    question: 'Impedancia relativa (Icc/iL)',
    formControlName: 'relative_impedance',
    isRequired: false,
    tooltip: '----',
  },
  {
    placeholder: 'Potencia 3f (MVA)',
    question: 'Potencia 3f (MVA)',
    formControlName: 'power_3f',
    isRequired: false,
    tooltip: '----',
  },
  {
    placeholder: 'Potencia 1f (MVA)',
    question: 'Potencia 1f (MVA)',
    formControlName: 'power_1f',
    isRequired: false,
    tooltip: '----',
  },
  {
    placeholder: 'Potencia 3f max (MVA)',
    question: 'Potencia 3f max (MVA)',
    formControlName: 'power_3f_max',
    isRequired: false,
    tooltip: '----',
  },
  {
    placeholder: 'Potencia 1f max (MVA)',
    question: 'Potencia 1f max (MVA)',
    formControlName: 'power_1f_max',
    isRequired: false,
    tooltip: '----',
  },
  {
    placeholder: 'Potencia 1f max (MVA)',
    question: 'Potencia 1f max (MVA)',
    formControlName: 'power_1f_max',
    isRequired: false,
    tooltip: '----',
  },
  {
    placeholder: 'Potencia 3f min (MVA)',
    question: 'Potencia 3f min (MVA)',
    formControlName: 'power_3f_min',
    isRequired: false,
    tooltip: '----',
  },
  {
    placeholder: 'Potencia 3f min (MVA)',
    question: 'Potencia 3f min (MVA)',
    formControlName: 'power_3f_min',
    isRequired: false,
    tooltip: '----',
  },
  {
    placeholder: 'Potencia 1f min (MVA)',
    question: 'Potencia 1f min (MVA)',
    formControlName: 'power_1f_min',
    isRequired: false,
    tooltip: '----',
  },
  // Finaliza datos calculados.
  {
    placeholder: 'Hornos eléctricos de arco',
    question: 'Hornos eléctricos de arco',
    formControlName: 'is_electric_arc_oven',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'X/R 3f',
    question: 'X/R 3f',
    formControlName: 'xr_3f',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'X/R 1f',
    question: 'X/R 1f',
    formControlName: 'xr_1f',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Zth+ min 3f',
    question: 'Zth+ min 3f',
    formControlName: 'zth_p_3f_min',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Zth0 min 1f',
    question: 'Zth0 min 1f',
    formControlName: 'zth_0_1f_min',
    isRequired: true,
    tooltip: '----',
  },
]

/** Preguntas para el formulario del gram @type {Question[]} */
const questionsFormGram: Question[] = [
  {
    placeholder: 'Es un punto simulado',
    question: '¿Es un punto simulado?',
    formControlName: 'point_type',
    isRequired: true,
    tooltip: '----',
  },
  {
    question: '¿Cuántos transformadores en alta tensión hay?',
    placeholder: 'Transformadores en alta tensión',
    formControlName: 'transformers_high_voltage',
    isRequired: false,
    tooltip: '----',
  },
  {
    placeholder: 'Transformadores en baja tensión hay',
    question: '¿Cuántos transformadores en baja tensión hay?',
    formControlName: 'transformers_low_voltage',
    isRequired: false,
    tooltip: '----',
  },
  {
    placeholder: 'Existe horno eléctrico',
    question: '¿Hornos eléctricos de arco?',
    formControlName: 'is_electric_arc_oven',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Subestación eléctrica',
    question: 'Subestación eléctrica',
    formControlName: 'electric_substation',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Acrónimo de subestación',
    question: 'Acrónimo de subestación',
    formControlName: 'substation_acron',
    isRequired: false,
    tooltip: '----',
  },
  {
    placeholder: 'Voltaje de planta (kV)',
    question: 'Voltaje de planta (kV)',
    formControlName: 'plant_voltage',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Corriente de corto circuito 3f (kA)',
    question: 'Corriente de corto circuito 3f (kA)',
    formControlName: 'current_cc_3f',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Corriente de corto circuito 1f (kA)',
    question: 'Corriente de corto circuito 1f (kA)',
    formControlName: 'current_cc_1f',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Corriente de corto circuito 3f max (kA)',
    question: 'CENACE - Corriente de corto circuito 3f max (kA)',
    formControlName: 'current_cc_3f_max',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Corriente de corto circuito 1f max (kA)',
    question: 'CENACE - Corriente de corto circuito 1f max (kA)',
    formControlName: 'current_cc_1f_max',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Corriente de corto circuito 3f min (kA)',
    question: 'CENACE - Corriente de corto circuito 3f min (kA)',
    formControlName: 'current_cc_3f_min',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Corriente de corto circuito 1f min (kA)',
    question: 'CENACE - Corriente de corto circuito 1f min (kA)',
    formControlName: 'current_cc_1f_min',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'X/R 3f',
    question: 'X/R 3f',
    formControlName: 'xr_3f',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'X/R 1f',
    question: 'X/R 1f',
    formControlName: 'xr_1f',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Zth+ max 3f',
    question: 'Zth+ max 3f',
    formControlName: 'zth_p_3f_max',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Zth0 max 1f',
    question: 'Zth0 max 1f',
    formControlName: 'zth_0_1f_max',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Zth+ min 3f',
    question: 'Zth+ min 3f',
    formControlName: 'zth_p_3f_min',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Zth0 min 1f',
    question: 'Zth0 min 1f',
    formControlName: 'zth_0_1f_min',
    isRequired: true,
    tooltip: '----',
  },
]

/** Preguntas para formulario de transformadores @type {Question[]} */
const questionsTransformer: Question[] = [
  {
    placeholder: 'Potencia nominal (MVA)',
    question: 'Potencia nominal (MVA)',
    formControlName: 'nominal_power',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Voltaje en alta (kV)',
    question: 'Voltaje en alta (kV)',
    formControlName: 'high_voltage_plate',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Voltaje en baja (V)',
    question: 'Voltaje en baja (V)',
    formControlName: 'low_voltage_plate',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Ubicacion',
    question: 'Medido en',
    formControlName: 'measured_at',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Z+ (%)',
    question: 'Z+ (%)',
    formControlName: 'z_p',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Z0 (%)',
    question: 'Z0 (%)',
    formControlName: 'z_0',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Arreglo vectorial (1-12)',
    question: 'Arreglo vectorial (1-12)',
    formControlName: 'vectorial_array',
    isRequired: true,
    tooltip: '----',
  },
  {
    placeholder: 'Tipo de carga',
    question: 'Tipo de carga',
    formControlName: 'type_load',
    isRequired: true,
    tooltip: '----',
  },
]

/**
 * Interceptor para llamados de Http.
 *
 * @export
 * @class HttpCallInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class HttpCallInterceptor implements HttpInterceptor {
  /**
   * Creates an instance of HttpCallInterceptor.
   * @memberof HttpCallInterceptor
   */
  constructor() {
  }

  /**
   * Interceptor de peticiones.
   *
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @return {*}  {Observable<HttpEvent<any>>}
   * @memberof HttpCallInterceptor
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.method === 'GET') {
      if (request.url === '/api/questions/legal') {
        return of(new HttpResponse({ status: 200, body: questionsLegal }))
      }

      if (request.url === '/api/questions/electric') {
        return of(new HttpResponse({ status: 200, body: questionsElectric }))
      }

      if (request.url === '/api/questions/form-gram') {
        return of(new HttpResponse({ status: 200, body: questionsFormGram }))
      }

      if (request.url === '/api/questions/transformer') {
        return of(new HttpResponse({ status: 200, body: questionsTransformer }))
      }

      if (request.url === '/api/billing/charts') {
        return of(new HttpResponse({ status: 200, body: charts_billing['default'] })).pipe(delay(1500))
      }
    }
    return next.handle(request).pipe(
      filter(event => event instanceof HttpResponse),
      tap((response: HttpResponse<any>) => {
        if (!environment.production) {
          console.log(`%cResponse to request: ${response.url}`, 'color: #B1B11B; font-weight: bold;')
          console.log((response.body))
        }
      })
    )
  }
}
