import { ProjectsRepository } from './projects.repository'
import { Injectable } from '@angular/core'
import { CrudService } from '../shared/crud.service'
import { Observable } from 'rxjs'
import { Project } from '../../models/project.model'
import { map } from 'rxjs/operators'

/**
 * Serivicio para obtener datos de los proyectos.
 *
 * @export
 * @class ProjectsService
 * @extends {CrudService}
 */
@Injectable({
  providedIn: 'root',
})
export class ProjectsService extends CrudService {
  /**
   * Creates an instance of ProjectsService.
   * @param {HttpClient} _projectsRepository
   * @param {CommonService} _commonService
   * @memberof ProjectsService
   */
  constructor(protected _projectsRepository: ProjectsRepository) {
    super(_projectsRepository)
  }

  /**
   * Servicio para obtener el projecto activo de la planta.
   *
   * @param {number} plantId Identificador de la planta.
   * @return {*}  {Observable<Project>} Projecto activo.
   * @memberof ProjectsService
   */
  public getProjectActiveByPlant(plantId: number): Observable<Project> {
    return this._projectsRepository.getProjectActiveByPlant(plantId).pipe(map( p => p[0]))
  }
}
