import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'widget-wrap-message-info',
  templateUrl: './wrap-message-info.component.html',
  styleUrls: ['./wrap-message-info.component.scss']
})
export class WrapMessageInfoComponent implements OnInit {

  @Input() firstText: string = 'Esta planta no tiene información.'
  @Input() secondText: string = null
  @Input() thirtText: string = null

  @Input() imageUrl: string = '../../../../assets/images/content/capichi/not-found-1.png'

  constructor() { }

  ngOnInit() {
  }

}
