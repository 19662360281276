import { NotificationPlant } from '../../models/notification-plant.model'
import { createReducer, on } from '@ngrx/store'
import * as states from './notifications.actions'

export interface NotificationsState {
  notifications?: NotificationPlant[]
  selectedNotification: NotificationPlant
  isLoading?: boolean
  isLoadingSuccess?: boolean
  isLoadingFailure?: boolean
}

export const initialState: NotificationsState = {
  notifications: [],
  selectedNotification: null,
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
}

const _notificationsReducer = createReducer(
  initialState,
  on(states.addNotificationPlant, (state, { notification }) => {
    const notifications: NotificationPlant[] = [...state.notifications]
    notifications.unshift(notification)
    return { ...state, notifications, isLoading: false, isLoadingSuccess: true, isLoadingFailure: false }
  }),
  on(states.getNotificationsUser, (state, _) => {
    return { ...state, isLoading: true, isLoadingSuccess: false, isLoadingFailure: false }
  }),
  on(states.loadNotificationsSuccessful, (state, { notifications }) => {
    return { ...state, notifications: notifications, isLoading: false, isLoadingSuccess: true, isLoadingFailure: false }
  }),
  on(states.loadNotificationsUnsuccessful, (state, _) => {
    return { ...state, isLoading: false, isLoadingSuccess: false, isLoadingFailure: true }
  }),
  on(states.updateNotificationPlant, (state, { notification }) => {
    return { ...state, selectedNotification: notification, isLoading: true, isLoadingSuccess: false, isLoadingFailure: false }
  }),
  on(states.updateNotificationsuccess, (state, { notification }) => {
    const notificationId = state.selectedNotification.id_notification
    const notifications: NotificationPlant[] = [...state.notifications]
    const eventIndex = notifications.findIndex(event => event.id_notification === notificationId)
    notifications[eventIndex] = notification
    return { ...state, notifications, selectedNotification: null, isLoading: false, isLoadingSuccess: true, isLoadingFailure: false }
  }),
  on(states.updateNotificationFailure, (state, _) => {
    return { ...state, isLoading: false, isLoadingSuccess: false, isLoadingFailure: true }
  }),
)

export function notificationsReducer(state, action) {
  return _notificationsReducer(state, action)
}

export const getNotifications = (state: NotificationsState) => state
