<div class='container' style='padding: 0'>
  <div class='row'>
    <div class='col-12'>
      <div class='mb-1'>
        <nz-date-picker [formControl]='dateForm' [nzInputReadOnly]='true' class='w-100'
                        nzPlaceHolder='Selecciona una fecha de inicio'></nz-date-picker>
      </div>
      <div *ngIf='dateFormControl.invalid && (dateFormControl.dirty || dateFormControl.touched)'>
        <nz-alert *ngIf='dateFormControl.errors?.required' nzMessage='Se requiere una fecha para el periodo.'
                  nzType='error'>
        </nz-alert>
      </div>
    </div>
  </div>
  <div class='row'>
    <div class='col'>
      <div class='mb-1'>
        <nz-input-group nzSearch [nzAddOnAfter]='suffixIconButton'>
          <input [formControl]='weeksForm' nz-input placeholder='# de semanas' />
        </nz-input-group>
      </div>
      <div *ngIf='weeksFormControl.invalid && (weeksFormControl.dirty || weeksFormControl.touched)'>
        <nz-alert *ngIf='weeksFormControl.errors?.required' nzMessage='Introduzca el # de semanas.'
                  nzType='error'>
        </nz-alert>
        <nz-alert
          *ngIf='weeksFormControl.errors?.pattern && !(weeksFormControl.errors?.min || weeksFormControl.errors?.max)'
          nzMessage='Debe ser numerico.' nzType='error'>
        </nz-alert>
        <nz-alert *ngIf='weeksFormControl.errors?.min' nzMessage='Minimo 1 semana para el periodo.' nzType='error'>
        </nz-alert>
        <nz-alert *ngIf='weeksFormControl.errors?.max' nzMessage='Máximo 4 semanas para el periodo.' nzType='error'>
        </nz-alert>
      </div>
    </div>
  </div>
</div>

<ng-template #suffixIconButton>
  <button (click)='generate()' [disabled]='weeksFormControl.invalid || dateFormControl.invalid'
          [nzLoading]='isLoading' nz-button nzType='primary' nzSearch>
    <i nz-icon nzType='form'></i>
  </button>
</ng-template>
