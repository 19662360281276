import { FileWeekRepository } from './file-week.repository'
import { Injectable } from '@angular/core'
import { FileService } from '../file.service'
@Injectable({
  providedIn: 'root',
})
export class FileWeekService extends FileService {

  constructor(protected _fileWeekRepository: FileWeekRepository) {
    super(_fileWeekRepository)
  }
}
