import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import * as Reducers from '../../store/reducers'
import * as BarButtonsActions from './actions'


@Injectable({
    providedIn: 'root'
})
export class BarButtonsFacade {
    barButtonsSelector$ = this._store.select(Reducers.getBarButtons)

    constructor(private _store: Store<any>) { }

    buttonClick(action: object): void {
        this._store.dispatch(BarButtonsActions.buttonClick({ action }))
    }

    resetState(): void {
        this._store.dispatch(BarButtonsActions.resetBarButtonsState())
    }
}
