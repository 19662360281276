import { NgModule } from '@angular/core'

import { IconDefinition } from '@ant-design/icons-angular'
import * as AllIcons from '@ant-design/icons-angular/icons'

import { NzButtonModule } from 'ng-zorro-antd/button'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzGridModule } from 'ng-zorro-antd/grid'
import { NzLayoutModule } from 'ng-zorro-antd/layout'
import { NzSpaceModule } from 'ng-zorro-antd/space'
import { NzDropDownModule } from 'ng-zorro-antd/dropdown'
import { NzMenuModule } from 'ng-zorro-antd/menu'
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete'
import { NzCascaderModule } from 'ng-zorro-antd/cascader'
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox'
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker'
import { NzFormModule } from 'ng-zorro-antd/form'
import { NzInputModule } from 'ng-zorro-antd/input'
import { NzInputNumberModule } from 'ng-zorro-antd/input-number'
import { NzSelectModule } from 'ng-zorro-antd/select'
import { NzSwitchModule } from 'ng-zorro-antd/switch'
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select'
import { NzUploadModule } from 'ng-zorro-antd/upload'
import { NzAvatarModule } from 'ng-zorro-antd/avatar'
import { NzBadgeModule } from 'ng-zorro-antd/badge'
import { NzCardModule } from 'ng-zorro-antd/card'
import { NzCollapseModule } from 'ng-zorro-antd/collapse'
import { NzPopoverModule } from 'ng-zorro-antd/popover'
import { NzTableModule } from 'ng-zorro-antd/table'
import { NzTagModule } from 'ng-zorro-antd/tag'
import { NzToolTipModule } from 'ng-zorro-antd/tooltip'
import { NzAlertModule } from 'ng-zorro-antd/alert'
import { NzMessageModule } from 'ng-zorro-antd/message'
import { NzModalModule } from 'ng-zorro-antd/modal'
import { NzNotificationModule } from 'ng-zorro-antd/notification'
import { NzProgressModule } from 'ng-zorro-antd/progress'
import { NzSpinModule } from 'ng-zorro-antd/spin'
import { NzDividerModule } from 'ng-zorro-antd/divider'
import { NzEmptyModule } from 'ng-zorro-antd/empty';

/**
 * AntDesign Icons
 */
const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition
}
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])

/**
 * AntDesign Components
 */

const MODULES = [
  NzButtonModule,
  NzGridModule,
  NzLayoutModule,
  NzSpaceModule,
  // NzAffixModule,
  // NzBreadCrumbModule,
  NzDropDownModule,
  NzMenuModule,
  // NzPageHeaderModule,
  // NzPaginationModule,
  // NzStepsModule,
  NzAutocompleteModule,
  NzCascaderModule,
  NzCheckboxModule,
  NzDatePickerModule,
  NzFormModule,
  NzInputModule,
  NzInputNumberModule,
  // NzMentionModule,
  // NzRadioModule,
  // NzRateModule,
  NzSelectModule,
  // NzSliderModule,
  NzSwitchModule,
  // NzTimePickerModule,
  // NzTransferModule,
  NzTreeSelectModule,
  NzUploadModule,
  NzAvatarModule,
  NzBadgeModule,
  // NzCalendarModule,
  NzCardModule,
  // NzCarouselModule,
  NzCollapseModule,
  // NzCommentModule,
  // NzDescriptionsModule,
  NzEmptyModule,
  // NzListModule,
  NzPopoverModule,
  // NzStatisticModule,
  NzTableModule,
  // NzTabsModule,
  NzTagModule,
  // NzTimelineModule,
  NzToolTipModule,
  // NzTreeModule,
  NzAlertModule,
  // NzDrawerModule,
  NzMessageModule,
  NzModalModule,
  NzNotificationModule,
  // NzPopconfirmModule,
  NzProgressModule,
  // NzResultModule,
  // NzSkeletonModule,
  NzSpinModule,
  // NzAnchorModule,
  // NzBackTopModule,
  NzDividerModule,
]

@NgModule({
  imports: [...MODULES, NzIconModule.forRoot(icons)],
  exports: [...MODULES, NzIconModule],
})
export class AntdModule {}
