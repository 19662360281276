<div [ngClass]="{
  'vb__layout__grayBackground': settings.isGrayBackground
}">
  <nz-layout [ngClass]="{
  'vb__layout': true,
  'vb__layout__contentMaxWidth': settings.isContentMaxWidth,
  'vb__layout__appMaxWidth': settings.isAppMaxWidth,
  'vb__layout__squaredBorders': settings.isSquaredBorders,
  'vb__layout__cardsShadow': settings.isCardShadow,
  'vb__layout__borderless': settings.isBorderless
}">
    <nz-layout style='max-height: 64px;'>
      <nz-header>
        <vb--topbar></vb--topbar>
      </nz-header>
    </nz-layout>

    <nz-layout>
      <nz-sider
        *ngIf="settings.layoutMenu === 'classic' && settings.menuLayoutType === 'left' && !settings.isMobileView && settings.isMenuOpen && typeMenu !== typesMenu.NONE"
        [nzWidth]='settings.leftMenuWidth' style='transition: none !important'>
        <widget-menu-left [showOption]="showOption" [menuData]='menuData' [isReady]='isReadyMenuData' [typeMenu]='typeMenu'
                          [modeMenuControl]='modeMenuControl' [version]='cdrVersion'
                          (itemSelected)='menuItemSelected($event)'
                          (eventGenerate)='onGenerateReport($event)'
                          (eventPanelControl)='panelControlCallback($event)'>
        </widget-menu-left>
      </nz-sider>
      <nz-layout class='inner-layout'>
        <div class='d-flex flex-column' style='gap: 0.6rem'>
          <div class='bg-top-gradiant'></div>
          <widget-welcome-top [user]='currentUser' [plant]='showPlantName ? plantSelect : null'></widget-welcome-top>
          <widget-bar-buttons [actionButtons]='actionButtons' (eventAction)='onClickEventBarButtons($event)'>
          </widget-bar-buttons>
        </div>
        <nz-content class='vb__layout__content'>
          <div [@slideFadeinUp]="routeAnimation(outlet, 'slideFadeinUp')" class='h-100'>
            <router-outlet #outlet='outlet'></router-outlet>
          </div>
        </nz-content>
      </nz-layout>
    </nz-layout>
  </nz-layout>
</div>

<ng-template #timeOutTmpl>
  <p style='margin-bottom: 0 !important;'>Tu sesión ha expirado por inactividad</p>
  <p>Para continuar trabajando
    <b>inicie sesión nuevamente</b>
  </p>
</ng-template>
