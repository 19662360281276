import { ReportBillingRepository } from './report-billing.repository'
import { Injectable } from '@angular/core'
import { ReportsService } from '../reports.service'

/**
 * Servicio para manejo de reportes de facturacion.
 *
 * @export
 * @class ReportBillingService
 * @extends {ReportsService}
 */
@Injectable({
    providedIn: 'root',
  })
export class ReportBillingService extends ReportsService {
  /**
   * Creates an instance of ReportBillingService.
   * @param {ReportBillingRepository} _reportBillingRepository
   * @memberof ReportBillingService
   */
    constructor(protected _reportBillingRepository: ReportBillingRepository) {
        super(_reportBillingRepository)
    }
}
