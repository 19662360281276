<div class='footer'>
  <div class='footerInner'>
    <a class='logo' href='#' rel='noopener noreferrer' target='_blank'>
      <strong>{{ settings.logo }}<span
        style='text-transform: lowercase;'>&nbsp;-&nbsp;v{{appVersion}}</span></strong>
    </a>
    <br />
    <p class="m-0">
      Copyright © {{ date }}
      <a href='https://www.diram.com/' rel='noopener noreferrer' target='_blank'>
        diram sa de cv
      </a> |
      <a href='https://www.diram.com/aviso-de-privacidad?lang=en' rel='noopener noreferrer' target='_blank'>
        {{ 'footer.policyPrivacy' | translate }}
      </a>
    </p>
  </div>
</div>
