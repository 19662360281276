import { User } from 'src/app/models/models.index'
import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'widget-topbar-settings-menu',
  templateUrl: './topbar-settings-menu.component.html',
  styleUrls: ['./topbar-settings-menu.component.scss'],
})
export class TopbarSettingsMenuComponent implements OnInit {
  @Input() user: User

  constructor() {}

  ngOnInit() {}
}
