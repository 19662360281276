import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer,
} from '@ngrx/store'
import { environment } from 'src/environments/environment'
import * as fromRouter from '@ngrx/router-store'
import * as fromSettings from './settings/reducers'
import * as fromUser from './user/reducers'
import * as fromPlant from './plant/plant.reducer'
import * as fromCorporate from './corporate/corporate.reducer'
import * as fromFiles from './files/files.reducer'
import * as fromReport from './report/report.reducer'
import * as fromNotifications from './notifications/notifications.reducer'
import * as fromBarButtons from './bar-buttons/reducers'
import * as fromPanelControl from './panel-control/reducers'
import * as fromEvents from './events/events.reducer'

/** Tipos de reducer en la aplicacion @type {*} */
export const reducers: ActionReducerMap<any> = {
  router: fromRouter.routerReducer,
  settings: fromSettings.reducer,
  user: fromUser.reducer,
  plant: fromPlant.plantReducer,
  corporate: fromCorporate.corporateReducer,
  files: fromFiles.filesReducer,
  report: fromReport.reportReducer,
  notifications: fromNotifications.notificationsReducer,
  barButtons: fromBarButtons.barButtonsReducer,
  panelControl: fromPanelControl.panelControlReducer,
  events: fromEvents.eventsReducer
}

/**
 * Logger para acciones de los reducers.
 *
 * @export
 * @param {ActionReducer<any>} reducer
 * @return {*}  {ActionReducer<any>}
 */
export function logger(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state: any, action: any): any => {
    const result = reducer(state, action)
    if (!environment.production) {
      console.groupCollapsed(action.type)
      console.log('prev state', state)
      console.log('action', action)
      console.log('next state', result)
      console.groupEnd()
    }
    return result
  }
}

/** Meta reducer @type {*} */
export const metaReducers: MetaReducer<any>[] = !environment.production ? [logger] : []

/** Selector de estado para configuracion. @type {*} */
export const getSettingsState = createFeatureSelector<any>('settings')
/** Selector para configuraciones. @type {*} */
export const getSettings = createSelector(getSettingsState, fromSettings.getSettings)

/** Feature selector de estado para usuarios. @type {*} */
export const getUserState = createFeatureSelector<any>('user')
/** Selector para usuarios @type {*} */
export const getUser = createSelector(getUserState, fromUser.getUser)

export const getPlantState = createFeatureSelector<any>('plant')
export const getPlant = createSelector(getPlantState, fromPlant.getPlant)

export const getCorporateState = createFeatureSelector<any>('corporate')
export const getCorporate = createSelector(getCorporateState, fromCorporate.getCorporate)

export const getFilesState = createFeatureSelector<any>('files')
export const getFiles = createSelector(getFilesState, fromFiles.getFiles)

export const getReportState = createFeatureSelector<any>('report')
export const getReport = createSelector(getReportState, fromReport.getReport)

export const getNotificationsState = createFeatureSelector<any>('notifications')
export const getNotifications = createSelector(getNotificationsState, fromNotifications.getNotifications)

export const getBarButtonsState = createFeatureSelector<any>('barButtons')
export const getBarButtons = createSelector(getBarButtonsState, fromBarButtons.getBarButtons)

export const getPanelControlState = createFeatureSelector<any>('panelControl')
export const getPanelControl = createSelector(getPanelControlState, fromPanelControl.getPanelControl)

export const getEventsState = createFeatureSelector<any>('events')
export const getEvents = createSelector(getEventsState, fromEvents.getEvetns)

