import { FileRepository } from './../file.repository'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { CommonService } from '../../shared/common.service'

/**
 * Servicio para manejo de archivos de reportes transacionales.
 *
 * @export
 * @class FileTransactionalRepository
 * @extends {FileService}
 */
@Injectable({
  providedIn: 'root',
})
export class FileTransactionalRepository extends FileRepository {
  /**
   * Creates an instance of FileTransactionalRepository.
   * @param {HttpClient} _httpClient
   * @param {CommonService} _commonService
   * @memberof FileTransactionalRepository
   */
  constructor(protected _httpClient: HttpClient, protected _commonService: CommonService) {
    super(_httpClient, _commonService, 'transactional-file')
  }
}
