import { TYPE_USERS_DIRAM } from './../config/enviroment'
import { Plant, User } from 'src/app/models/models.index'
import { Pipe, PipeTransform } from '@angular/core'
import { select, Store } from '@ngrx/store'
import * as Reducers from '../store/reducers'

/**
 * Pipe para establecer el nombre de la planta segun el usuario.
 *
 * @export
 * @class SetNamePlantPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'setNamePlant',
})
export class SetNamePlantPipe implements PipeTransform {
  /**
   * Usuario actual.
   *
   * @private
   * @type {User}
   * @memberof SetNamePlantPipe
   */
  private _currentUser: User

  constructor(private store: Store<any>) {
    this.store.pipe(select(Reducers.getUser)).subscribe(user => {
      this._currentUser = user
    })
  }

  transform(plant: Plant): string {
    if (!plant) {
      return 'Nombre no disponible...'
    }

    if (this._currentUser.roles[0].rol_type === TYPE_USERS_DIRAM) {
      return plant.name
    }
    return plant.alias
  }
}
