import { Pipe, PipeTransform } from '@angular/core'
import { ValidationErrors } from '@angular/forms'

/**
 * Pipe para manejo de label saber si contraseñas coinciden de usuarios.
 *
 * @export
 * @class ErrorsLabelsConfirmPasswordPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'errorsLabelsConfirmPassword',
})
export class ErrorsLabelsConfirmPasswordPipe implements PipeTransform {
  transform(error: ValidationErrors): string {
    if (error?.required) {
      return '¡Verifica tu contraseña!'
    } else if (error?.mustMatch) {
      return '¡Las contraseñas deben cohincidir!'
    }
    return 'error'
  }
}
