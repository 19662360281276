import { Plant } from '../../models/plant.model'
import { createReducer, on } from '@ngrx/store'
import * as states from './plant.actions'
import * as _ from 'lodash'
import { Corporate } from '../../models/corporate.model'

export interface PlantState {
  plants?: Plant[]
  plant?: Plant
  plantId?: number
  corporate?: Corporate
  result?: any
  isLoading?: boolean
  isLoadingSuccess?: boolean
  isLoadingFailure?: boolean
}

export const initialState: PlantState = {
  plants: [],
  plant: null,
  result: null,
  plantId: null,
  corporate: null,
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
}

const _plantReducer = createReducer(
  initialState,
  on(states.getPlant, (state, { plantId }) => {
    return { ...state, plants: [], plant: null, isLoading: true, isLoadingSuccess: false, isLoadingFailure: false, plantId }
  }),
  on(states.loadPlantSuccessful, (state, result) => {
    const currentPlant: Plant = result.currentPlant ? _.cloneDeep(result.currentPlant) : {}
    return {
      ...state,
      plant: currentPlant,
      isLoading: false,
      isLoadingSuccess: true,
    }
  }),
  on(states.loadPlantUnsuccessful, (state, _) => {
    return {
      ...state,
      plant: null,
      isLoading: false,
      isLoadingFailure: true,
    }
  }),

  on(states.getPlants, (state, { corporate, defualtPlantId }) => {

    return { ...state, plants: [], plant: null, plantId: defualtPlantId,
      isLoading: true, isLoadingFailure: false, isLoadingSuccess: false, corporate }
  }),

  on(states.selectPlant, (state, { plantId }) => {
    const plant = state.plants.find(p => p.id === plantId)
    return { ...state, plant: plant, plantId: plant?.id }
  }),

  on(states.loadPlantsSuccessful, (state, result) => {
    const plants: Plant[] = result?.response ? result.response : []
    const defaultPlant: Plant = plants.find(p => p.id === state.plantId)
    return {
      ...state,
      plants,
      plant: defaultPlant ? defaultPlant : plants[0],
      plantId: defaultPlant ? defaultPlant?.id : plants[0].id,
      isLoading: false,
      isLoadingSuccess: true,
    }
  }),
  on(states.loadPlantsUnsuccessful, (state, _) => {
    return {
      ...state,
      plants: null,
      isLoading: false,
      isLoadingFailure: true,
    }
  }),
  on(states.flushPlant, () => initialState),
)

export function plantReducer(state, action) {
  return _plantReducer(state, action)
}

export const getPlant = (state: PlantState) => state
