<div>
  <a nz-dropdown nzTrigger='click' [nzDropdownMenu]='menu'>
    <nz-badge [nzCount]='alerts?.length'>
      <i nz-icon nzType='bell' nzTheme='outline'></i>
    </nz-badge>
  </a>
  <nz-dropdown-menu #menu='nzDropdownMenu'>
    <ul nz-menu style='width: 362px; max-width: 362px !important;'>
      <ng-container *ngIf='alerts?.length > 0; else emptyAlerts'>
        <ng-container *ngTemplateOutlet='alertsTmpl'></ng-container>
      </ng-container>
      <!--      <li nz-menu-item>-->
      <!--        <a routerLink='.'>-->
      <!--          <span>-->
      <!--            {{ 'alertMenu.seeAll' | translate }}-->
      <!--          </span>-->
      <!--        </a>-->
      <!--      </li>-->
      <ng-template #alertsTmpl>
        <ng-container *ngFor='let alert of alerts; let i = index'>
          <li nz-menu-item>
            <a (click)='onClickEvent(alert)'>
              <div class='alert-container'>
                <div class='alert-main'>
                  <span class='text-main' maxlength='25'>
                    {{ alert.title }}
                  </span>
                  <span class='text-second' maxlength='55'>
                    {{ alert.description }}
                  </span>
                </div>
                <div class='alert-date'>
                  <span>
                    {{ alert.created_at | setDateView }}
                  </span>
                </div>
              </div>
            </a>
          </li>
          <li nz-menu-divider></li>
        </ng-container>
      </ng-template>

      <ng-template #emptyAlerts>
        <div class='p-1'>
          {{ 'alertMenu.emptyNotification' | translate }}
        </div>
      </ng-template>
    </ul>
  </nz-dropdown-menu>
</div>
