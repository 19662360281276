import { Pipe, PipeTransform } from '@angular/core'

/**
 * Pipe para verificar si un texto es null para la vista de HTML, da un texto de remplazo.
 *
 * @export
 * @class TextVerifyPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'textVerify',
})
export class TextVerifyPipe implements PipeTransform {
  transform(text: string): string {
    return text ? text : '-'
  }
}
