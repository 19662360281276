import { FormGramRepository } from './form-gram.repository'
import { Injectable } from '@angular/core'
import { CrudService } from '../shared/crud.service'
import { Observable } from 'rxjs'
import { FormGram } from '../../models/models.index'

/**
 * Servicio para manejar la informacion del formulario del gram via la API
 *
 * @export
 * @class FormGramService
 * @extends {CrudService}
 */
@Injectable({
  providedIn: 'root',
})
export class FormGramService extends CrudService {
  /**
   * Creates an instance of CalculatedParametersService.
   * @param {FormGramRepository} _formGramRepository
   * @memberof FormGramService
   */
   constructor(protected _formGramRepository: FormGramRepository) {
    super(_formGramRepository)
  }

  /**
   * Funcion para servicio para obtener la informacion del gram de una empresa.
   *
   * @param {number} plantId Identificador de la planta.
   * @return {Observable<FormGram[]>}  {Observable<FormGram>} Observable con la respuesta de nuestro peticion.
   * @memberof FormGramService
   */
  public getFormGramByPlant(plantId: number): Observable<FormGram[]> {
    return this._formGramRepository.getFormGramByPlant(plantId)
  }
}
