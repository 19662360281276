<ng-container>
  <ul nz-menu class='navigation' [nzMode]="'inline'" style='height: 50%'>
    <li nz-submenu>
        <span title style='width: inherit'>
          <i nz-icon nzType='setting' [ngClass]="['icon']"></i>
          <span class='title'>{{ 'PANEL DE CONTROL' | translate}}</span>
        </span>
    </li>
    <li nz-menu-item [nzSelected]='true' style='height: fit-content; justify-content: center'>
      <div class='d-flex flex-column' style='width: inherit; margin: 10px 0 10px 0;'>
        <div *ngIf='optionsActive.typeReport' class='d-flex justify-content-center' style='gap: 10px'>
          <ng-container *ngIf='mode === "year" else groupButton2'>
            <ng-container *ngTemplateOutlet='groupButton1'></ng-container>
          </ng-container>
        </div>
        <div *ngIf='optionsActive.managers' class='d-flex justify-content-center' style='gap: 10px'>
          <ng-container *ngTemplateOutlet='groupButton3'></ng-container>
        </div>
        <div *ngIf='optionsActive.datePicker' class='d-flex flex-column justify-content-center'>
          <span class='text-center text-default' style='font-weight: 500; font-size: 1rem'>{{ topTextCalendar }}</span>
          <ng-container [ngSwitch]='mode'>
            <ng-container *ngSwitchCase='"week"'>
              <widget-generate-report (reportEvent)='onReport($event)'></widget-generate-report>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <nz-date-picker [nzMode]='mode' [nzFormat]='dateFormat' [(ngModel)]='date'
                              (ngModelChange)='onChange({ mode, version })'>
              </nz-date-picker>
            </ng-container>
          </ng-container>
        </div>
        <div *ngIf='mode !== "year" && optionsActive.version' class='d-flex justify-content-center' style='margin-top: 10px; gap: 10px'>
          <button nz-button nzType='primary' nzBlock style='font-weight: 500; font-size: 1rem'
                  [class.btn-selected]='version === 1'
                  (click)='onChangeVersion({ mode, version: 1 })'>
            CdR 1.0
          </button>
          <button nz-button nzType='primary' nzBlock style='font-weight: 500; font-size: 1rem'
                  [class.btn-selected]='version === 2'
                  (click)='onChangeVersion({ mode,  version: 2 })'>
            CdR 2.0
          </button>
        </div>
      </div>
    </li>
  </ul>
</ng-container>

<ng-template #groupButton1>
  <button nz-button nzType='primary' [class.btn-selected]="mode === 'year'" style='font-weight: 500; font-size: 1rem'
          (click)='onChangeMode("year")'>
    A
  </button>
</ng-template>

<ng-template #groupButton2>
  <button nz-button nzType='primary' [class.btn-selected]="mode === 'month'" style='font-weight: 500; font-size: 1rem'
          (click)='onChangeMode("month")'>
    M
  </button>
  <button nz-button nzType='primary' [class.btn-selected]="mode === 'week'" style='font-weight: 500; font-size: 1rem'
          (click)='onChangeMode("week")'>
    S
  </button>
</ng-template>

<ng-template #groupButton3>
  <button nz-button nzType='primary' [class.btn-selected]="actionView === 'estimated'" style='font-weight: 500; font-size: 1rem'
  (click)='onChangeView({view: "estimated", type: 2})'>
    <i nz-icon nzType="dollar"></i>
  </button>
  <button nz-button nzType='primary' [class.btn-selected]="actionView === 'chart'" style='font-weight: 500; font-size: 1rem'
  (click)='onChangeView({view: "chart", type: 2})'>
    <i nz-icon nzType="pie-chart"></i>
  </button>
  <button nz-button nzType='primary' [class.btn-selected]="actionView === 'summary'" style='font-weight: 500; font-size: 1rem'
  (click)='onChangeView({view: "summary", type: 2})'>
    <i nz-icon nzType="bar-chart"></i>
  </button>
</ng-template>
