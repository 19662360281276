import { URL_API_V2 } from './../../config/enviroment'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { CommonService } from '../shared/common.service'
import { Observable } from 'rxjs'
import { Plant } from '../../models/models.index'
import { CrudRepository } from '../shared/crud.repository'
import { ParamRequest } from 'src/app/interfaces/interfaces.index'

/**
* Servicio para obtener la informacion de los parametros electricos calculados mediante la API.
*
* @export
* @class PlantsRepository
* @extends {CrudRepository}
*/
@Injectable({
  providedIn: 'root',
})
export class PlantsRepository extends CrudRepository {
  /**
   * Creates an instance of PlantService.
   * @param {HttpClient} _httpClient
   * @param {CommonService} _commonService Funciones con servicios de angular comunes.
   * @memberof PlantService
   */
  constructor(protected _httpClient: HttpClient, protected _commonService: CommonService) {
    super(_httpClient, _commonService, 'plant')
  }

  /**
   * Servicio para obtener las plantas que tiene un corporativo
   * @param {number} corporateId Identificador del corporativo
   * @param {number} active Estado de las plantas a obtener
   */
  public getPlantsByCorporate(corporateId: number, active: number = 1): Observable<any[]> {
    const url = `${URL_API_V2}/plant-project`
    const paramsRequest: ParamRequest[] = [{ param: 'id_corporate', value: corporateId },
    { param: 'relationships', value: 'plant' },
    { param: 'active', value: active }]
    return this._commonService.handlerGetElements(url, paramsRequest, 'Data')
  }

  /**
   * Servicio para guardar una imagen de perfil para una planta.
   *
   * @param {number} plantId
   * @param {File} image
   * @return {*}  {Observable<any>}
   * @memberof PlantsService
   */
  public setImage(plantId: number, image: File): Observable<any> {
    const url = `${URL_API_V2}/${this._endpointPrefix}/${plantId}/image`
    return this._commonService.handlerPutElementImage(url, image)
  }

  /**
   * Servicio para obtener la imagen de perfil de una planta.
   *
   * @param {number} plantId
   * @return {*}  {Observable<string>}
   * @memberof PlantsService
   */
  public getImage(plantId: number): Observable<string> {
    const url = `${URL_API_V2}/${this._endpointPrefix}/${plantId}/image`
    return this._commonService.handlerGetElements(url, null, 'url')
  }
}
