import { Injectable } from '@angular/core'
import { TYPE_VERSION_REPORT } from '../../config/enviroment'
import { Observable } from 'rxjs'
import { ParamRequest } from '../../interfaces/param-request'
import { FileRepository } from './file.repository'
import { map } from 'rxjs/operators'
import { FilePortal } from '../../../app/models/models.index'

/**
 * Servicio para el manejo de informacion de archivos json de los reportes.
 *
 * @export
 * @class FileService
 */
@Injectable({
  providedIn: 'root',
})
export class FileService {
  /**
   * Creates an instance of FileService.
   * @param {HttpClient} _httpClient
   * @param {CommonService} _commonService
   * @memberof FileService
   */
  constructor(protected _fileRepository: FileRepository) {
  }


  public getFile(
    plantId: number,
    cdrVersionId: number = TYPE_VERSION_REPORT.REPORT_2022,
    paramRequest: ParamRequest[],
  ): Observable<any> {
    const extraParams: ParamRequest[] = [
      {param: 'id_plant', value: plantId},
      {param: 'id_report_version', value: cdrVersionId},
    ]
    paramRequest.push(...extraParams)
    return this._fileRepository.getFile(paramRequest).pipe(
      map(response => {
        return response ? response : []
      }
    ))
  }

  /**
   * Servicio para obtener archivos por reporte.
   *
   * @param {number} reportId
   * @param cdrVersionId
   * @return {*}  {Observable<any>}
   * @memberof FileService
   */
  public getFileByReport(reportId: number, cdrVersionId: number = TYPE_VERSION_REPORT.REPORT_2022,
    ): Observable<any> {
    return this._fileRepository.getFileByReport(reportId, cdrVersionId).pipe(
      map(response => {
        return response ? response : []
      }
    ))
  }

  public getFileByPlant(plantId: number,
    cdrVersionId: number = TYPE_VERSION_REPORT.REPORT_2022,
    paramsRequest: ParamRequest[] = []
  ): Observable<FilePortal[]> {
    if (paramsRequest) {
      paramsRequest.push(...[{ param: 'id_report_version', value: cdrVersionId }])
    } else {
      paramsRequest = [{ param: 'id_report_version', value: cdrVersionId }]
    }
    return this._fileRepository.getFileByPlant(plantId, paramsRequest).pipe(
      map(response => {
        return response ? response : []
      }
    ))
  }

  public getFileChartsBilling(): Observable<any> {
    return this._fileRepository.getFileChartsBilling()
  }
}
