import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { select, Store } from '@ngrx/store'
import * as Reducers from 'src/app/store/reducers'

/**
 * Guardian para limitar el acceso a las rutas a usuarios no autorizados.
 *
 * @export
 * @class AuthGuard
 * @implements {CanActivate}
 */
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  /**
   * Variable para el control de autorizacion de usuario.
   *
   * @type {boolean}
   * @memberof AuthGuard
   */
  authorized: boolean

  /**
   * Creates an instance of AuthGuard.
   * @param {Store<any>} store
   * @param {Router} router
   * @memberof AuthGuard
   */
  constructor(private store: Store<any>, public router: Router) {
    this.store.pipe(select(Reducers.getUser)).subscribe(state => {
      this.authorized = state.authorized
    })
  }

  /**
   * CanActivate.
   *
   * @param {ActivatedRouteSnapshot} next
   * @param {RouterStateSnapshot} state
   * @return {*}  {(Observable<boolean> | Promise<boolean> | boolean)}
   * @memberof AuthGuard
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (environment.authenticated) {
      // skip guard checking on demo environment serve/build, remove it in your app
      return true
    }

    if (this.authorized) {
      return true
    }

    this.router.navigate(['auth/login'], { queryParams: { returnUrl: state.url } }).then()
    return false
  }
}
