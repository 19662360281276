import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'

export type BarButtons = 'compliance' | 'energy'

export interface ButtonAction {
  id: string | number,
  label: string,
  type: BarButtons,
  icon: string,
  selected: boolean
}

@Component({
  selector: 'widget-bar-buttons',
  templateUrl: './bar-buttons.component.html',
  styleUrls: ['./bar-buttons.component.scss'],
})
export class BarButtonsComponent implements OnChanges, OnInit {

  @Input() actionButtons: BarButtons[] = ['compliance', 'energy']

  private currentButton: ButtonAction = null

  @Output() eventAction: EventEmitter<ButtonAction> = new EventEmitter<ButtonAction>()

  public buttons: ButtonAction[] = []

  private defaultAction: number = 0

  readonly buttonActions: ButtonAction[] = [
    { id: 1, label: 'Cumplimiento', type: 'compliance', icon: 'snippets', selected: false },
    { id: 2, label: 'Energía', type: 'energy', icon: 'bulb', selected: false },
  ]

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.buttons = this.buttonActions
      .filter(b => this.actionButtons?.includes(b.type as BarButtons)).map(b => {
        b.selected = false
        return b
      })
    if (this.buttons.length > 0) {
      this.currentButton = this.buttons[this.defaultAction]
      this.currentButton.selected = true
    }
  }

  ngOnInit() {
  }

  onClickButton(event: ButtonAction): void {
    if (this.currentButton === event) {
      return
    }
    this.currentButton.selected = false
    this.currentButton = event
    this.currentButton.selected = true
    this.eventAction.emit(event)
  }
}
