import { URL_API_V2 } from './../../config/enviroment'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { CommonService } from '../shared/common.service'
import { User } from '../../models/models.index'
import { EmailCode } from 'src/app/interfaces/emailCode'
import { PasswordReset } from 'src/app/interfaces/password-reset'
import { ParamRequest } from 'src/app/interfaces/param-request'

@Injectable({
  providedIn: 'root'
})
export class AuthRepository {
  /**
   * Prefijo para la url.
   *
   * @private
   * @type {string}
   * @memberof AuthService
   */
  private _endpointPrefix: string = 'auth'

  constructor(private _httpClient: HttpClient, private _commonService: CommonService) {
  }

  /**
   * Repositorio para iniciar sesion.
   *
   * @param {string} email
   * @param {string} password
   * @return {*}  {Observable<any>}
   * @memberof AuthService
   */
  login(email: string, password: string): Observable<any> {
    const endpointSuffix = 'login'
    const url = `${URL_API_V2}/${this._endpointPrefix}/${endpointSuffix}`
    return this._httpClient.post(url, { email, password })
  }

  /**
   * Repositorio para obtener la informacion del usuario actual.
   *
   * @return {*}  {Observable<any>}
   * @memberof AuthService
   */
  currentAccount(): Observable<User> {
    const endpointSuffix = 'auth'
    const url = `${URL_API_V2}/${endpointSuffix}`
    return this._httpClient.get<User>(url, { headers: this._commonService.getTokenHeaders() })
  }

  /**
   * Servicio para cerrar sesion.
   *
   * @return {*}  {Observable<any>}
   * @memberof AuthService
   */
  logout(): Observable<any> {
    const endpointSuffix = 'logout'
    const url = `${URL_API_V2}/${this._endpointPrefix}/${endpointSuffix}`
    return this._httpClient.post(url, null, { headers: this._commonService.getTokenHeaders() })
  }

  /**
   * Servicio para verificar correo electronico.
   *
   * @param {string} token
   * @return {*}  {Observable<EmailCode>}
   * @memberof AuthService
   */
  verifyEmail(payload: any): Observable<EmailCode> {
    const endpointSuffix = 'verify-email'
    const url = `${URL_API_V2}/${this._endpointPrefix}/${endpointSuffix}`
    const paramsRequest: ParamRequest[] = [
      { param: 'email', value: payload.email },
      { param: 'expire_at', value: payload.expire_at },
      { param: 'code', value: payload.code }
    ]
    return this._commonService.handlerPostElement(url, paramsRequest, null, 'Data', false)
  }

  /**
   * Servicio para cambio de contraseña.
   *
   * @param {PasswordReset} editSecurity
   * @param {string} token
   * @return {*}  {Observable<any>}
   * @memberof AuthService
   */
  changePassword(editSecurity: PasswordReset, token: string): Observable<any> {
    const endpointSuffix = 'change-password'
    const url = `${URL_API_V2}/${endpointSuffix}`
    return this._httpClient
      .post(url, editSecurity, { headers: this._commonService.getCommonHeaders() })
  }

  /**
   * Servicio para solicitar un recuperacion de contraseña.
   *
   * @param {{ email: string }} payload
   * @return {*}  {Observable<any>}
   * @memberof AuthService
   */
  requestRecoveryPassword(payload: { email: string }): Observable<any> {
    const endpointSuffix = 'reset-password-request'
    const url = `${URL_API_V2}/${this._endpointPrefix}/${endpointSuffix}`
    return this._httpClient
      .post(url, payload, { headers: this._commonService.getCommonHeaders() })
  }

  /**
   * Servicio para resetear contraseña.
   *
   * @param {{ password: string; email: string }} payload
   * @param {string} token
   * @return {*}  {Observable<any>}
   * @memberof AuthService
   */
  resetPassword(payload: { password: string; email: string }, token: string): Observable<any> {
    const endpointSuffix = 'reset-password'
    const url = `${URL_API_V2}/${this._endpointPrefix}/${endpointSuffix}`
    return this._httpClient
      .post(url, payload, { headers: this._commonService.getTmpTokenHeaders(token) })
  }
}
