import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { CommonService } from '../shared/common.service'
import { CrudRepository } from '../shared/crud.repository'

/**
 * Servicios para obtener informacion referentes a corporativos.
 *
 * @export
 * @class CorporatesRepository
 * @extends {CrudRepository}
 */
@Injectable({
  providedIn: 'root',
})
export class CorporatesRepository extends CrudRepository {
  /**
   * Creates an instance of CorporatesRepository.
   * @param {HttpClient} _httpClient
   * @param {CommonService} _commonService
   * @memberof CorporatesService
   */
  constructor(protected _httpClient: HttpClient, protected _commonService: CommonService) {
    super(_httpClient, _commonService, 'corporate')
  }
}
