import { ParamRequest } from './../../interfaces/param-request'
import { Observable } from 'rxjs'
import { CrudRepository } from './crud.repository'

/**
 * Servicio base para obtener todos los metodos comunes de un CRUD.
 *
 * @export
 * @abstract
 * @class CrudService
 */
export abstract class CrudService {
  /**
   * Creates an instance of CrudService.
   * @param {HttpClient} _httpClient Servicios de cliente http.
   * @param {CommonService} _commonService Servicios comunes en la plataforma.
   * @memberof CrudService
   */
  protected constructor(protected _crudRepository: CrudRepository) {
  }

  /**
   * Envía al API server una petición para obtener todos los elementos del tipo solicitado
   * @abstract
   * @param {Map<string, any>} paramsQuery
   * @returns {Observable<any>}
   * @memberof CrudService
   */
  public getAllElements(
    paramsQuery: ParamRequest[] = null,
    paginable: boolean = true, page: number = 1, perPage: number = 10
  ): Observable<any[]> {
    return this._crudRepository.getAllElements(paramsQuery, paginable, page, perPage)
  }

  /**
   * Servicio para obtener un elemento por su identificador.
   *
   * @param {number} id Identificador del elemento.
   * @return {*}  {Observable<any>} Devuelve la respuesta del servidor.
   * @memberof CrudService
   */
  public getElementById(id: number, paramRequest: ParamRequest[] = null): Observable<any> {
    return this._crudRepository.getElementById(id, paramRequest)
  }

  /**
   * Servicio para crear un nuevo registro del elmento pasado en data.
   *
   * @param {*} data Instancia o elemento a crear un registro.
   * @return {*}  {Observable<any>} Devuelve la instancia o elemento creado con su identificador.
   * @memberof CrudService
   */
  public createdElement(data: any): Observable<any> {
    return this._crudRepository.createdElement(data)
  }

  /**
   * Servicio para actualizar un elemento mediante su identificador de registro.
   *
   * @param {*} data Informacion de la instancia a modificar.
   * @param {(number | string)} id Identificador del elemento.
   * @return {*}  {Observable<any>} Devuelve la respuesta de la instancia o elemento con la informacion actualizada.
   * @memberof CrudService
   */
  public updateElement(data: any, id: number | string): Observable<any> {
    return this._crudRepository.updateElement(data, id)
  }

  /**
   * Servicio para actualizar un elemento mediante su identificador de registro usando el metodo patch.
   *
   * @param {*} data Informacion de la instancia a modificar.
   * @param {(number | string)} id Identificador del elemento.
   * @return {*}  {Observable<any>} Devuelve la respuesta de la instancia o elemento con la informacion actualizada.
   * @memberof CrudService
   */
  public updateElementPatch(data: any, id: number | string): Observable<any> {
    return this._crudRepository.updateElementPatch(data, id)
  }

  /**
   * Servicio para eliminar el registro de un elemento o instancia mediante su identificador.
   *
   * @param {number} id Identificador del elemento.
   * @return {*}  {Observable<string>} Devuelve la respuesta del estatus del servidor.
   * @memberof CrudService
   */
  public deleteElement(id: number): Observable<string> {
    return this._crudRepository.deleteElement(id)
  }
}
