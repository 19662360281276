import { environment } from '../../environments/environment'
import { Params } from '@angular/router'

/**
 * Callback para realizar un scroll mediante un identificador de un elemento HTML.
 *
 * @param {*} id
 * @param block
 * @param ms
 * @memberof ReportsComponent
 */
export function scrollByElement(id: string, block: ScrollLogicalPosition = 'start', ms: number = 0): void {
  if (!environment.production) {
    console.log(`scrolling to ${id}`)
  }

  const el = document.getElementById(id)
  if (el) {
    setTimeout(() => el.scrollIntoView({ behavior: 'smooth', block: block }), ms)
  }
}

export function saveBlob(blob, fileName) {
  const a: HTMLAnchorElement = document.createElement('a')
  const objectUrl = URL.createObjectURL(blob)
  a.href = objectUrl
  a.download = fileName
  a.click()
  URL.revokeObjectURL(objectUrl)
}

export function parseQueryParams(params: string): Params {
  return JSON.parse('{"' +
    decodeURI(params
      .replace(/&/g, '","')
      .replace(/=/g, '":"')) +
    '"}')
}

export function timeSince(date: Date, locale: string = 'es-MX'): string {

  const seconds = Math.abs(Math.floor((new Date().getTime() - date.getTime()) / 1000))

  let interval = seconds / 2592000

  if (interval > 1) {
    return date.toLocaleString(locale, { day: 'numeric', month: 'short' })
      .replace('-', ' ')
  }
  interval = seconds / 86400
  if (interval > 1) {
    return Math.floor(interval) + ' días'
  }
  interval = seconds / 3600
  if (interval > 1) {
    return Math.floor(interval) + 'h'
  }
  interval = seconds / 60
  if (interval > 1) {
    return Math.floor(interval) + 'min'
  }
  return 'ahora'
}

