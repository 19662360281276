<section>
  <nz-card>
    <h3 class='title-section'>{{ 'profile.user-sectionTitle' | translate }}</h3>
    <div class='body-details d-flex flex-row'>
      <div class='img-container'>
        <img alt='user-profile-image' height='110px' nz-image [nzSrc]='avatar' width='120px' />
      </div>
      <div>
        <h4 class='text-user'>
          <span>{{ currentUser?.name || 'Name' }}</span>&nbsp;<span>{{ currentUser?.flast_name || '' }}</span>
        </h4>
        <p class='text-details'>{{ currentUser.roles[0].name }}</p>
        <p class='text-details'><span class='text-label'>{{ 'topBar.profileMenu.email' | translate }}&#x3a;</span>&nbsp;<span>{{ currentUser.email }}</span>
        </p>
        <p class='text-details'><span class='text-label'>{{ 'topBar.profileMenu.phone' | translate }}&#x3a;</span>&nbsp;<span>{{ currentUser?.phone ? currentUser.phone : '-----'}}</span>
        </p>
      </div>
    </div>
    <button button-theme='inverter' nz-button nzType='primary' [routerLink]='["/profile/edit/edit-user"]'>
      <i nz-icon nzType='edit' nzTheme='outline'></i>
      {{ 'profile.editTxt-btn' | translate }}
    </button>
  </nz-card>
</section>
