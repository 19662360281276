import { Component, OnInit, OnDestroy } from '@angular/core'
import { Plant } from '../../../models/plant.model'
import { PlantsService } from '../../../services/plants/plants.service'
import { Subject } from 'rxjs'
import { finalize, mergeMap, takeUntil } from 'rxjs/operators'
import { StateNotification } from '../../../interfaces/state-notification'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { MAIL, SMS } from '../../../config/enviroment'
import { NotificationService } from 'src/app/services/notification/notification.service'
import { Notification } from 'src/app/models/models.index'
import { PlantFacade } from './../../../store/plant/plant.facade'

/**
 * Componente para crear vista de configuracion de notificaciones.
 *
 * @export
 * @class NotificationSettingsComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss'],
})
export class NotificationSettingsComponent implements OnInit, OnDestroy {
  /**
   * Planta seleccionada.
   *
   * @type {Plant}
   * @memberof NotificationSettingsComponent
   */
  public plantSelected: Plant = null
  /**
   * Lista de notificaciones de los indicicadores por persona autorizada en la planta.
   *
   * @type {Notification[]}
   * @memberof NotificationSettingsComponent
   */
  public notificationsIndicators: Notification[] = []
  /**
   * Lista de notificaciones de los reportes por persona autorizada en la planta.
   *
   * @type {Notification[]}
   * @memberof NotificationSettingsComponent
   */
  public notificationsReport: Notification[] = []
  /**
   * Variable de control para conocer si la informacion de la planta se encuentra cargada.
   *
   * @type {boolean}
   * @memberof NotificationSettingsComponent
   */
  public plantInfoReady: boolean = false
  /**
   * Variable de control para conocer si se ha realizado una peticion y el estado de este.
   *
   * @type {boolean}
   * @memberof NotificationSettingsComponent
   */
  public isLoad: boolean = false

  public isLoadingPlantData: boolean = true

  public destroy$: Subject<boolean> = new Subject<boolean>()

  /**
   * Creates an instance of NotificationSettingsComponent.
   * @param {PlantsService} _plantService
   * @param {PersonService} _personService
   * @param {NzNotificationService} _nzNotificationService
   * @param _notificationService
   * @memberof NotificationSettingsComponent
   */
  constructor(
    private _plantService: PlantsService,
    private _nzNotificationService: NzNotificationService,
    private _notificationService: NotificationService,
    private _plantFacade: PlantFacade
  ) {
    this._plantFacade.plantSelector$.pipe(takeUntil(this.destroy$))
    .subscribe((state) => {
      if (!state.isLoading && state.isLoadingSuccess) {
        this.isLoadingPlantData = true
        if (state.plant) {
          this.changePlant(state.plant.id)
        } else {
          if (state.corporate) {
            const plant: Plant = state.plants[0]
            this._plantFacade.selectPlant(plant.id)
          }
        }
      }
    })
  }

  /**
   * OnInit
   *
   * @memberof NotificationSettingsComponent
   */
  ngOnInit(): void {}

  /**
   * Callback para detectar un cambio de planta.
   *
   * @param {number} plantId Identificador de la planta seleccionada.
   * @memberof ProfileBusinessComponent
   */
  public changePlant(plant_id: number): void {
    this.clearInfo()
    this.searchInfoToPlant(plant_id)
  }

  /**
   * Funcion para limpiar la informacion del componente.
   *
   * @private
   * @memberof NotificationSettingsComponent
   */
  public clearInfo(): void {
    if (this.plantSelected) {
      this.plantSelected = null
      this.notificationsIndicators = []
      this.notificationsReport = []
      this.plantInfoReady = false
    }
  }

  /**
   * Funcion para buscar la informacion de una planta.
   *
   * @param {number} plantId Identificador de la planta seleccionada.
   * @memberof NotificationSettingsComponent
   */
  public searchInfoToPlant(plantId: number): void {
    this._plantService
      .getElementById(plantId)
      .pipe(
        mergeMap(plant => {
          this.plantSelected = plant
          return this._notificationService.getNotificationsByPlant(this.plantSelected.id)
        }),
      )
      .subscribe(
        notifications => {
          if (notifications && notifications.length > 0) {
            this.notificationsIndicators = notifications
              .filter(notification => notification.id_notification_type === 1)
              .slice(0, 3)
            this.notificationsReport = notifications
              .filter(notification => notification.id_notification_type === 2)
              .slice(0, 3)
          } else {
            this._handleError(
              'No existen personas autorizadas para esta planta.',
              'Actualice su información de planta',
            )
          }
        },
        error => {
          this._handleError('Contacte a su administrador.')
        },
        () => {
          this.isLoadingPlantData = false
          this.plantInfoReady = true
        },
      )
  }

  /**
   * Callback que detecta el cambio de estado de la vista de configuración para cada usuario
   * y realiza una solicitud para modificar la informacion de la notificacion.
   *
   * @param {StateNotification} state
   * @param {Notification[]} notifications Lista de notificaciones actualizar
   * @memberof NotificationSettingsComponent
   */
  public changeStatus(state: StateNotification, notifications: Notification[]) {
    this.isLoad = true
    const notification: Notification = state.notification
    const notificationId = notification.id
    const updateNotification: Notification = {
      sms_enable: notification.sms_enable,
      email_enable: notification.email_enable,
    }
    this._notificationService
      .updateElement(updateNotification, notificationId)
      .pipe(
        finalize(() => {
          this.isLoad = false
        }),
      )
      .subscribe(
        response => {},
        error => {
          const notificationIndex = notifications.findIndex(
            notification => notification.id === notificationId,
          )
          if (state.type === SMS) {
            notifications[notificationIndex].sms_enable = !notification.sms_enable
            notifications = [...notifications]
          } else if (state.type === MAIL) {
            notifications[notificationIndex].email_enable = !notification.email_enable
            notifications = [...notifications]
          }
          this._handleError('No ha sido posible guardar los cambios.')
        },
      )
  }

  /**
   * Manejar errores cuando un elemento se encuentra vacio.
   * Disparamos un error en la subscripcion.
   *
   * @param title Titulo de del error
   * @param {string} msg Mensaje que del error que se dispara.
   * @private
   * @memberof NotificationSettingsComponent
   */
  private _handleError(msg: string, title: string = null): void {
    this._nzNotificationService.error(title ? title : 'Lo sentimos... Ha ocurrido un error', msg, {
      nzDuration: 2500,
    })
  }

  /**
   * onDestroy.
   *
   * @memberof LayoutMainComponent
   */
  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.unsubscribe()
  }
}
