<div class='row m-0 mb-4'>
  <div class='col-12 p-0'>
    <span class='m-0 ml-1 font-weight-bold'>{{title}}</span>
  </div>
  <ng-container *ngFor='let item of questions'>
    <div *ngIf='answer[item.formControlName] || answer[item.formControlName] === 0' class='row w-100 mb-3'>
      <div class='col-12 col-md-6'>
        <div>
          <span class='text-title'>
            {{item.question}}
          </span>
        </div>
      </div>
      <div class='col-12 col-md-6'>
        <nz-select class='w-100' name='select-error' [ngModel]='answer[item.formControlName]' [disabled]='true'>
          <nz-option [nzValue]='1' nzLabel='Sí'></nz-option>
          <nz-option [nzValue]='0' nzLabel='No'></nz-option>
          <nz-option [nzValue]='-1' nzLabel='No disponible'></nz-option>
        </nz-select>
      </div>
    </div>
  </ng-container>
</div>
