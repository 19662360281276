import { Injectable } from '@angular/core'
import { Corporate } from '../../models/models.index'
import { CorporateState } from './corporate.reducer'
import { Store } from '@ngrx/store'
import * as Reducers from '../../store/reducers'
import * as CorporateActions from './corporate.actions'


@Injectable({
    providedIn: 'root'
})
export class CorporateFacade {
    corporateSelector$ = this._store.select(Reducers.getCorporate)

    constructor(private _store: Store<CorporateState>) {
    }

    selectCorporate(corporate: Corporate): void {
        this._store.dispatch(CorporateActions.selectCorporate({corporate}))
    }

    getCorporate(corporateId: number) {
        this._store.dispatch(CorporateActions.getCorporate({corporateId}))
    }

    getCorporates() {
        this._store.dispatch(CorporateActions.getCorporates())
    }

    cleanCorporateState() {
        this._store.dispatch(CorporateActions.flushCorporate())
    }
}
