import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { CommonService } from '../shared/common.service'
import { Observable } from 'rxjs'
import { Transformer } from '../../models/models.index'
import { URL_API_V2 } from '../../config/enviroment'
import { CrudRepository } from '../shared/crud.repository'

/**
 * Servicio para manejar la informacion de los transformadores via la API.
 *
 * @export
 * @class TransformerRepsitory
 * @extends {CrudRepository}
 */
@Injectable({
  providedIn: 'root',
})
export class TransformerRepsitory extends CrudRepository {
  /**
   * Creates an instance of TransformerRepsitory.
   * @param {HttpClient} _httpClient
   * @param {CommonService} _commonService
   * @memberof TransformerRepsitory
   */
  constructor(protected _httpClient: HttpClient, protected _commonService: CommonService) {
    super(_httpClient, _commonService, 'transformer')
  }

  /**
   * Funcion para servicio para obtener los transformadores de una empresa.
   *
   * @param {number} plantId Identificador de la planta.
   * @return {Observable<Transformer[]>}  {Observable<FormGram>} Observable con la respuesta de nuestro peticion.
   * @memberof TransformerRepsitory
   */
  public getTransformersByPlant(plantId: number): Observable<Transformer[]> {
    const url = `${URL_API_V2}/${this._endpointPrefix}/${plantId}/plant`
    return this._commonService.handlerGetElements(url, null, 'Data')
  }
}
