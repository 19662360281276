import { Component, OnDestroy, OnInit } from '@angular/core'
import { Legal, Plant, Person, User, Corporate } from 'src/app/models/models.index'
import { PersonService } from '../../../services/person/person.service'
import { PlantsService } from '../../../services/plants/plants.service'
import { finalize, first, mergeMap, switchMap, takeUntil } from 'rxjs/operators'
import { LegalService } from '../../../services/legal/legal.service'
import { Observable, throwError, Subject } from 'rxjs'
import { QuestionService } from '../../../services/question/question.service'
import { Question } from '../../../interfaces/question'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { ActivatedRoute, Router } from '@angular/router'
import { NzModalService } from 'ng-zorro-antd/modal'
import { TIME_SHOW_MSG, TYPE_NOTIFICATION } from '../../../config/enviroment'
import { PlantFacade } from '../../../store/plant/plant.facade'
import { UserFacade } from '../../../store/user/facade'

/**
 * Componente para crear la vista de perfil de nueva planta.
 *
 * @export
 * @class ProfileBusinessComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-profile-business',
  templateUrl: './profile-business.component.html',
  styleUrls: ['./profile-business.component.scss'],
})
export class ProfileBusinessComponent implements OnInit, OnDestroy {
  /**
   * Usuario actual en la vista.
   *
   * @type {User}
   * @memberof ProfileBusinessComponent
   */
  public currentUser: User
  /**
   * Lista de preguntas.
   *
   * @type {Question[]}
   * @memberof ProfileBusinessComponent
   */
  public questions: Question[] = []
  /**
   * Lista de preguntas para la session de centro de carga.
   *
   * @type {Question[]}
   * @memberof ProfileBusinessComponent
   */
  public questionsPowerCenter: Question[] = []
  /**
   * Lista de preguntas para la session del codigo de red.
   *
   * @type {Question[]}
   * @memberof ProfileBusinessComponent
   */
  public questionsCodeRed: Question[] = []
  /**
   * Corporativo seleccionado.
   *
   * @type {Corporate}
   * @memberof ProfileElectricPlantsComponent
   */
  public corporateSelected: Corporate = null
  /**
   * Planta seleccionada.
   *
   * @type {Plant}
   * @memberof ProfileBusinessComponent
   */
  public plantSelected: Plant = null
  /**
   * Informacion legal de una planta.
   *
   * @type {Legal}
   * @memberof ProfileBusinessComponent
   */
  public legalInfo: Legal = null
  /**
   * Lista de personas autorizadas.
   *
   * @type {Person[]}
   * @memberof ProfileBusinessComponent
   */
  public peopleAuthorization: Person[] = []
  /**
   * Variable de control para conocer si la informacion de la planta se encuentra cargada.
   *
   * @type {boolean}
   * @memberof ProfileBusinessComponent
   */
  public plantInfoReady: boolean = false

  public isLoadingPlants: boolean = true

  public isLoadingPlantData: boolean = true

  public rxStoreDestroy$: Subject<boolean> = new Subject<boolean>()

  /**
   * Creates an instance of ProfileBusinessComponent.
   * @param _store
   * @param {PlantsService} _plantsService
   * @param {LegalService} _legalService
   * @param {PersonService} _personService
   * @param {QuestionService} _questionService
   * @param {NzNotificationService} _nzNotificationService
   * @param {NzModalService} _modalService
   * @param {Router} _router
   * @param {ActivatedRoute} _routeActive
   * @memberof ProfileBusinessComponent
   */
  constructor(
    private _plantsService: PlantsService,
    private _legalService: LegalService,
    private _personService: PersonService,
    private _questionService: QuestionService,
    private _nzNotificationService: NzNotificationService,
    private _modalService: NzModalService,
    private _router: Router,
    private _routeActive: ActivatedRoute,
    private _userFacade: UserFacade,
    private _plantFacade: PlantFacade
  ) {
    this._getQuestionsForms().then()

    this._userFacade.userSelector$.pipe(takeUntil(this.rxStoreDestroy$), first()).subscribe((data) => {
      this.currentUser = data
    })

    this._plantFacade.plantSelector$.pipe(takeUntil(this.rxStoreDestroy$)).subscribe((state) => {
      this.isLoadingPlants = state.isLoading
      if (!state.isLoading && state.isLoadingSuccess) {
        this.isLoadingPlantData = true
        this.corporateSelected = state.corporate
        if (state.plant) {
          this.changePlant(state.plant.id)
        } else {
          if (this.corporateSelected) {
            const plant: Plant = state.plants[0]
            this._plantFacade.selectPlant(plant.id)
          }
        }
      }
    })
  }

  /**
   * OnInit
   *
   * @memberof ProfileBusinessComponent
   */
  ngOnInit(): void {
  }

  /**
   * Funcion para obtener las preguntas del cuestionario del servicio.
   *
   * @private
   * @return {Promise<void>}
   */
  private async _getQuestionsForms(): Promise<void> {
    this.questions = await this._questionService.getQuestionsLegal().toPromise()
    this.questionsPowerCenter = [...this.questions].slice(0, 10)
    this.questionsCodeRed = [...this.questions].slice(17, 30)
  }

  /**
   * Callback para detectar un cambio de planta.
   *
   * @param {number} plantId Identificador de la planta seleccionada.
   * @memberof ProfileBusinessComponent
   */
  public changePlant(plant_id: number): void {
    this.clearInfo()
    this.searchInfoToPlant(plant_id)
  }

  /**
   * Funcion para buscar la informacion de una planta.
   *
   * @param {number} plantId Identificador de la planta seleccionada.
   * @memberof ProfileBusinessComponent
   */
  public searchInfoToPlant(plantId: number): void {
    this._plantsService
      .getElementById(plantId)
      .pipe(
        mergeMap(plant => {
          this.plantSelected = plant
          return this.plantSelected
            ? this._legalService.getLegalInfoByPlant(this.plantSelected.id)
            : this.handleErrorNull('No exite planta')
        }),
        switchMap((legal: Legal) => {
          this.legalInfo = legal
          return this.legalInfo
            ? this._personService.getPeopleByPlant(this.plantSelected.id)
            : this.handleErrorNull(
              'No exite informacion legal de la planta',
              'Añada información faltante',
            )
        }),
        finalize(() => {
          this.isLoadingPlantData = false
        }),
      )
      .subscribe(
        persons => {
          if (persons && persons?.length > 0) {
            this.peopleAuthorization = persons.slice(0, 3)
          } else {
            this.handleWarnigNull('No hay personas asignadas')
            this.peopleAuthorization.length = 0
          }
          this.plantInfoReady = true
        },
        error => {
          this.plantInfoReady = false
          if (error?.code !== 404) {
            this.handleErrorNull(
              'Verifique su conexión, de lo contrario contacte a su administrador.',
            )
          }
        }
      )
  }

  /**
   * Callback para boton para ir a la vista para editar planta.
   * Si tenemos planta seleccionada y tiene informacion legal, sera precargan la informacion,
   * de lo contrario sera crear nueva informacion legal.
   *
   * @memberof ProfileBusinessComponent
   */
  public goToEditPlant(): void {
    if (this.plantSelected && this.legalInfo) {
      this._router
        .navigate(['../edit/edit-business'], {
          relativeTo: this._routeActive,
          queryParams: { plant_id: this.plantSelected.id, legal_id: this.legalInfo.id },
        })
        .then()
    } else if (!this.legalInfo) {
      this._router
        .navigate(['../edit/edit-business'], {
          relativeTo: this._routeActive,
          queryParams: { plant_id: this.plantSelected.id },
        })
        .then()
    }
  }

  /**
   * Manejar errores cuando un elemento se encuentra vacio.
   * Disparamos un error en la subscripcion.
   *
   * @param title Titulo de del error
   * @param {string} msg Mensaje que del error que se dispara.
   * @return {Observable<never>} Devuelve un objeto error.
   * @memberof ProfileBusinessComponent
   */
  public handleErrorNull(msg: string, title: string = null): Observable<never> {
    this._nzNotificationService.error(title ? title : 'Lo sentimos... Ha ocurrido un error', msg, {
      nzDuration: 2500,
    })
    return throwError({ code: 404, msg: msg })
  }

  /**
   * Manejar advertencias cuando un elemento se encuentra vacio.
   * Disparamos un error en la subscripcion.
   *
   * @param title Titulo de la advertencia.
   * @param {string} msg Mensaje que de la advertencia que se dispara.
   * @return {Observable<never>} Devuelve un objeto error.
   * @memberof ProfileBusinessComponent
   */
  public handleWarnigNull(msg: string, title: string = null): Observable<never> {
    this._nzNotificationService.warning(title ? title : 'Información faltante...', msg, {
      nzDuration: 2500,
    })
    return throwError({ code: 404, msg: msg })
  }

  /**
   * Funcion para limpiar la informacion del componente.
   * @memberof ProfileBusinessComponent
   */
  public clearInfo(): void {
    if (this.plantSelected) {
      this.plantSelected = null
      this.legalInfo = null
      this.peopleAuthorization.length = 0
      this.plantInfoReady = false
    }
  }

  /**
   * Callback del boton para eliminar personas autorizadas de planta.
   *
   * @param {Person} person
   * @memberof ProfileBusinessComponent
   */
  deletePerson(person: Person) {
    if (person) {
      this._modalDeleteUser(person)
    }
  }

  /**
   * Funcion para contruir y mostrar un modal al usuario.
   *
   * @private
   * @param {Person} person
   * @memberof ProfileBusinessComponent
   */
  private _modalDeleteUser(person: Person): void {
    this._modalService.confirm({
      nzTitle: `¿Seguro que deseas removocar el permiso a \"${person.name}\"?`,
      nzContent: 'Esta acción no es reversible',
      nzOkText: 'Sí, deseo eliminar',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => this._sendDeletePerson(person),
      nzCancelText: 'No',
      nzOnCancel: () => this._modalService.closeAll(),
    })
  }

  /**
   * Funcion para enviar la peticion de eliminar una persona autorizada de una planta.
   *
   * @private
   * @param {Person} person
   * @memberof ProfileBusinessComponent
   */
  private _sendDeletePerson(person: Person): void {
    const personId: number = person.id
    this._personService
      .deleteElement(person.id)
      .pipe(
        finalize(() => {
          this._modalService.closeAll()
        }),
      )
      .subscribe(
        _ => {
          const findIndex = this.peopleAuthorization.findIndex(person => person.id === personId)
          if (findIndex >= 0) {
            this.peopleAuthorization.splice(findIndex, 1)
            this.peopleAuthorization = [...this.peopleAuthorization]
          }
        },
        error => {
          this._showNotification(
            {
              type: TYPE_NOTIFICATION.ERROR,
              title: 'Lo sentimos...',
              body: 'Ha ocurrido un error intene nuevamente',
            },
            200,
          )
        },
        () => {
          this._showNotification(
            {
              type: TYPE_NOTIFICATION.SUCCESS,
              title: `¡Exito!`,
              body: `Se ha eliminado correctamente "${person.name}"`,
            },
            200,
          )
        },
      )
  }

  /**
   * Funcion para mostrar notificaciones al usuario.
   *
   * @private
   * @param {{ type: string; title: string; body: string }} msg
   * @param {number} delay
   * @memberof ProfileBusinessComponent
   */
  private _showNotification(
    msg: { type: string; title: string; body: string },
    delay: number,
  ): void {
    setTimeout(() => {
      this._nzNotificationService.create(msg.type, msg.title, msg.body, {
        nzDuration: TIME_SHOW_MSG,
      })
    }, delay)
  }

  /**
   * onDestroy.
   *
   * @memberof LayoutMainComponent
   */
  ngOnDestroy(): void {
    this.rxStoreDestroy$.next(true)
    this.rxStoreDestroy$.unsubscribe()
  }
}
