import { URL_API_V2 } from './../../config/enviroment'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { CommonService } from '../shared/common.service'
import { Observable } from 'rxjs'
import { FormGram } from '../../models/models.index'
import { CrudRepository } from '../shared/crud.repository'
import { ParamRequest } from 'src/app/interfaces/interfaces.index'

/**
 * Servicio para manejar la informacion del formulario del gram via la API
 *
 * @export
 * @class FormGramRepository
 * @extends {CrudRepository}
 */
@Injectable({
  providedIn: 'root',
})
export class FormGramRepository extends CrudRepository {
  /**
   * Creates an instance of FormGramRepository.
   * @param {HttpClient} _httpClient
   * @param {CommonService} _commonService
   * @memberof FormGramRepository
   */
  constructor(protected _httpClient: HttpClient, protected _commonService: CommonService) {
    super(_httpClient, _commonService, 'form-gram')
  }

  /**
   * Funcion para repositorio para obtener la informacion del gram de una empresa.
   *
   * @param {number} plantId Identificador de la planta.
   * @return {Observable<FormGram[]>}  {Observable<FormGram>} Observable con la respuesta de nuestro peticion.
   * @memberof FormGramService
   */
  public getFormGramByPlant(plantId: number): Observable<FormGram[]> {
    const url = `${URL_API_V2}/${this._endpointPrefix}`
    const paramsRequest: ParamRequest[] = [{ param: 'id_plant', value: plantId }]
    return this._commonService.handlerGetElements(url, paramsRequest, 'Data')
  }
}
