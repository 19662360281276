import { NgModule } from '@angular/core'
import { SharedModule } from '../shared.module'
import { LayoutAuthComponent } from './Auth/auth.component'
import { LayoutMainComponent } from './Main/main.component'
import { LayoutPublicComponent } from './Public/public.component'

/** Componentes utilizados. @type {*} */
const COMPONENTS = [LayoutAuthComponent, LayoutMainComponent, LayoutPublicComponent]

/**
 * Modulo de layouts.
 *
 * @export
 * @class LayoutsModule
 */
@NgModule({
  imports: [SharedModule],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
})
export class LayoutsModule {}
