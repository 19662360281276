import { RolRepository } from './rol.repository'
import { Injectable } from '@angular/core'
import { CrudService } from '../shared/crud.service'

/**
 * Servicio para manejo de roles.
 *
 * @export
 * @class RolService
 * @extends {CrudService}
 */
@Injectable({
  providedIn: 'root',
})
export class RolService extends CrudService {
  /**
   * Servicios para plantas PlantsService
   * @param {RolRepository} _rolRepository Repositorio de usuarios.
   * @memberof RolService
   */
   constructor(protected _rolRepository: RolRepository) {
    super(_rolRepository)
  }
}
