import { createReducer, on } from '@ngrx/store'
import * as states from '../panel-control/actions'

export interface State {
  type: string
}

export const initialState: State = {
  type: 'estimated',
}

const _panelControlReducer = createReducer(
  initialState,
  on(states.buttonClick, (state, { action } ) => {
    return { ...state, type: action.type }
  }),
  on(states.resetPanelControlState, () => {
    return initialState
  }),
)

export function panelControlReducer(state, action) {
  return _panelControlReducer(state, action)
}

export const getPanelControl = (action: State) => action
