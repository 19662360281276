<div class='wrap-message-error' style="height: fit-content;">
  <h2>
    {{ firstText }}
    <span>
      <br>
      {{ secondText }}
    </span>
    <span>
      <br>
      {{ thirtText }}
    </span>
  </h2>
  <img [src]='imageUrl' width='282' height='380'>
</div>