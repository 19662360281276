import { URL_API_V2 } from 'src/app/config/enviroment'
import { ParamRequest } from 'src/app/interfaces/interfaces.index'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { CommonService } from '../shared/common.service'
import { Observable } from 'rxjs'
import { User } from '../../models/user.model'
import { CrudRepository } from '../shared/crud.repository'

/**
 * Servicio para usuarios.
 *
 * @export
 * @class UsersRepository
 * @extends {CrudService}
 */
@Injectable({
  providedIn: 'root',
})
export class UsersRepository extends CrudRepository {
  /**
   * Servicios para usuarios UsersRepository.
   * @param {HttpClient} _httpClient Cliente HTTP
   * @param {CommonService} _commonService
   * @memberof UsersRepository
   */
  constructor(protected _httpClient: HttpClient, protected _commonService: CommonService) {
    super(_httpClient, _commonService, 'user')
  }

  /**
   * Servicio para obtener usuario por tipo si es interno o externo.
   *
   * @param {Map<string, any>} [paramsQuery=null]
   * @return {*}  {Observable<User[]>}
   * @memberof UsersRepository
   */
  public getUsers(
    paramsQuery: ParamRequest[] = null,
  ): Observable<User[]> {
        return this.getAllElements(paramsQuery)
  }

  public updateUserTokenNotificationPush(userId: number, token: string): Observable<any> {
    const url = `${URL_API_V2}/${this._endpointPrefix}/token`
    const body: {id_user: number, token: string} = {id_user: userId, token}
    return this._commonService.handlerPostElement(url, null, body, 'Data')
  }

  public setImage(userId: number, image: File): Observable<any> {
    const url = `${URL_API_V2}/${this._endpointPrefix}/${userId}/image`
    return this._commonService.handlerPutElementImage(url, image)
  }

  public getImage(userId: number): Observable<string> {
    const url = `${URL_API_V2}/user/${userId}/image`
    return this._commonService.handlerGetElements(url, null, 'url')
  }
}
