import { environment } from './../../../../environments/environment'
import { User } from '../../../models/user.model'
import { Component } from '@angular/core'
import { select, Store } from '@ngrx/store'
import * as UserActions from 'src/app/store/user/actions'
import * as Reducers from 'src/app/store/reducers'

/**
 * Componente para el menu de usuario superior.
 *
 * @export
 * @class TopbarUserMenuComponent
 */
@Component({
  selector: 'vb-topbar-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class TopbarUserMenuComponent {
  /**
   * Variable para el nombre del usuario.
   *
   * @type {string}
   * @memberof TopbarUserMenuComponent
   */
  public name: string = ''

  public lastname: string = ''
  /**
   * Variable para el nombre del rol del usuario.
   *
   * @type {string}
   * @memberof TopbarUserMenuComponent
   */
  public nameRol: string = ''
  /**
   * Variable para el correo del usuario.
   *
   * @type {string}
   * @memberof TopbarUserMenuComponent
   */
  public email: string = ''
  /**
   * Variable para el telefono del usuario.
   *
   * @type {string}
   * @memberof TopbarUserMenuComponent
   */
  public phone: string = ''
  /**
   * Variable para el avatar del usuario.
   *
   * @type {string}
   * @memberof TopbarUserMenuComponent
   */
  public avatar: string = ''
  /**
   * Variable para almacenar la totalidad del usuario.
   *
   * @type {User}
   * @memberof TopbarUserMenuComponent
   */
  public user: User

  public emailstring = `mailto:soporte.cloudpq@diram.com?Subject=Soporte plataforma CloudPQ-v${environment.appVersion}`

  /**
   * Creates an instance of TopbarUserMenuComponent.
   * @param {Store<any>} store
   * @memberof TopbarUserMenuComponent
   */
  constructor(private store: Store<any>) {
    // const uriAvatar = '../../../../assets/images/avatars/avatar-0.png'
    this.store.pipe(select(Reducers.getUser)).subscribe(state => {
      this.user = state
      this.name = state.name
      this.lastname = state.flast_name
      this.nameRol = state?.roles ? state?.roles[0]?.name : ''
      this.email = state.email
      this.avatar =
        state?.avatar ? state.avatar : './../../../assets/images/avatars/avatar-2.png'
    })
  }

  /**
   * Callback para cerrar sesion del usuario.
   *
   * @memberof TopbarUserMenuComponent
   */
  logout() {
    this.store.dispatch(new UserActions.Logout())
  }
}
