import { URL_API_V2 } from './../../config/enviroment'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { CommonService } from '../shared/common.service'
import { Observable } from 'rxjs'
import { CalculatedParameters } from '../../models/models.index'
import { CrudRepository } from '../shared/crud.repository'
import { ParamRequest } from 'src/app/interfaces/interfaces.index'

/**
 * Servicio para obtener la informacion de los parametros electricos calculados mediante la API.
 *
 * @export
 * @class CalculatedParametersRepository
 * @extends {CrudRepository}
 */
@Injectable({
  providedIn: 'root',
})
export class CalculatedParametersRepository extends CrudRepository {
  /**
   * Creates an instance of CalculatedParametersService.
   * @param {HttpClient} _httpClient
   * @param {CommonService} _commonService Funciones con servicios de angular comunes.
   * @memberof CalculatedParametersService
   */
  constructor(protected _httpClient: HttpClient, protected _commonService: CommonService) {
    super(_httpClient, _commonService, 'calculated-param')
  }

  /**
   * Funcion para servicio para obtener los parametros electricos calculados de formulario gram.
   *
   * @param {number} formGramId Identificador de la planta.
   * @return {Observable<FormGram>}  {Observable<FormGram>} Observable con la respuesta de nuestro peticion.
   * @memberof TransformerService
   */
  public getParametersByFormGram(formGramId: number): Observable<CalculatedParameters[]> {
    const url = `${URL_API_V2}/${this._endpointPrefix}`
    const paramsRequest: ParamRequest[] = [{ param: 'id_form_gram', value: formGramId }]
    return this._commonService.handlerGetElements(url, paramsRequest, 'Data')
  }
}
