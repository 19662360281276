import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { NotificationPlant } from 'src/app/models/models.index'
import { CrudService } from '../shared/crud.service'
import { NotificationPlantRepository } from './notification-plant.repository'

@Injectable({
  providedIn: 'root'
})
export class NotificationPlantService extends CrudService {

  constructor(protected _notificationPlantRepository: NotificationPlantRepository) {
    super(_notificationPlantRepository)
  }

  public getNotificationPlantByUser(userId: number, active: number): Observable<NotificationPlant[]> {
    return this._notificationPlantRepository.getNotificationPlantByUser(userId, active)
  }

  public updateNotificationPlant(notificationPlantId: number): Observable<any> {
    return this._notificationPlantRepository.updateNotificationPlant(notificationPlantId)
  }
}
