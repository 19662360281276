import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, of, throwError } from 'rxjs'
import { AngularFireMessaging } from '@angular/fire/compat/messaging'
import { catchError, filter, mergeMap, switchMap, tap } from 'rxjs/operators'
import { environment } from '../../../environments/environment'
import { NzNotificationService } from 'ng-zorro-antd/notification'

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {

  private incomeMessageSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  private incomeMessage$: Observable<any> = this.incomeMessageSubject.asObservable()

  private tokenUserFCM: BehaviorSubject<any> = new BehaviorSubject<any>(null)

  constructor(
    private _fireMessaging: AngularFireMessaging,
    private _nzNotificationService: NzNotificationService,
  ) {
  }

  public requestPermission(): Observable<string> {
    return this._fireMessaging.requestToken.pipe(
      switchMap((token) => {
        if (!token) {
          if (!('PushManager' in window)) {
            return throwError({ message: 'Push messaging isn\'t supported.' })
          }

          if (Notification.permission === 'denied') {
            return throwError({ message: 'The user has blocked notifications.' })
          }
        }

        if (!environment.production) {
          console.log(token)
        }

        this.tokenUserFCM.next(token)
        this.receiveMessage()
        return of(token)
      }),
      catchError(error => {
        if (!environment.production) {
          console.error('Unable to get permission to notify.', error)
        }
        return throwError(error)
      }),
    )
  }

  private receiveMessage(): void {
    if (!environment.production) {
      console.log('Init callback FCM success...')
    }
    this._fireMessaging.messages.subscribe(
      (payload) => {
        if (!environment.production) {
          console.log('new message received. ', payload)
        }
        this.incomeMessage = { ...payload }
      })
  }

  set incomeMessage(message: any) {
    this.incomeMessageSubject.next(message)
  }

  get incomeMessage() {
    return this.incomeMessage$.pipe(
      filter( payload => payload),
      tap((message) => {
        this._nzNotificationService.success(message.notification.title, message.notification.body)
    }))
  }

  public deleteToken(): void {
    this._fireMessaging.getToken
      .pipe(mergeMap(token => this._fireMessaging.deleteToken(token)))
      .subscribe(
        (token) => {
          this.incomeMessage = null
          if (!environment.production) {
            console.log(`${token} deleted!`)
          }
        },
      )
  }
}
