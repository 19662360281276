import { Component, OnInit } from '@angular/core'
import { TYPE_USERS_DIRAM } from '../../../config/enviroment'

/**
 * Componente para manejo de usuario internos.
 *
 * @export
 * @class UsersComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  /**
   * Tipo de vista en funcion del usuario.
   *
   * @type {number}
   * @memberof UsersComponent
   */
  public typeOfView: number = TYPE_USERS_DIRAM

  /**
   * OnInit
   *
   * @memberof UsersComponent
   */
  ngOnInit(): void {}
}
