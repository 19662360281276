import { FileRepository } from './../file.repository'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { CommonService } from '../../shared/common.service'

/**
 * Servicio para manejo de archivos de reportes oficiales.
 *
 * @export
 * @class FileBillingRepository
 * @extends {FileRepository}
 */
@Injectable({
  providedIn: 'root',
})
export class FileBillingRepository extends FileRepository {
  /**
   * Creates an instance of FileBillingRepository.
   * @param {HttpClient} _httpClient
   * @param {CommonService} _commonService
   * @memberof FileBillingRepository
   */
  constructor(protected _httpClient: HttpClient, protected _commonService: CommonService) {
    super(_httpClient, _commonService, 'billing-files')
  }
}
