import { Component, OnInit } from '@angular/core'

/**
 * Componente para contener la pagina de verificar el correo del usuario.
 * Para realizar un cambio sin modificar este archivo, solo el html
 * donde se realiza el llamado del componente.
 *
 * @export
 * @class VerifyEmailPage
 * @implements {OnInit}
 */
@Component({
  selector: 'vb-verify-email-page',
  templateUrl: './verify-email.component.html',
})
export class VerifyEmailPage implements OnInit {
  /**
   * Creates an instance of VerifyEmailPage.
   * @memberof VerifyEmailPage
   */
  constructor() {}
  /**
   * OnInit
   *
   * @memberof VerifyEmailPage
   */
  ngOnInit(): void {}
}
