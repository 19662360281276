import { ParamRequest } from 'src/app/interfaces/interfaces.index'
import { URL_API_V2 } from 'src/app/config/enviroment'
import { CommonService } from '../shared/common.service'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { CrudRepository } from '../shared/crud.repository'
import { Observable } from 'rxjs/internal/Observable'

@Injectable({
    providedIn: 'root'
})
export class NotificationPlantRepository extends CrudRepository {
    /**
     * Servicios para usuarios UsersRepository.
     * @param {HttpClient} _httpClient Cliente HTTP
     * @param {CommonService} _commonService
     * @memberof NotificationPlantRepository
     */
    constructor(protected _httpClient: HttpClient, protected _commonService: CommonService) {
        super(_httpClient, _commonService, 'plant-notification')
    }

    public getNotificationPlantByUser(userId: number, active: number): Observable<any> {
        const url = `${URL_API_V2}/user/${userId}/notifications`
        const paramsRequest: ParamRequest[] = []
        paramsRequest.push({param: 'active', value: active})
        return this._commonService.handlerGetElements(url, paramsRequest, 'Data')
    }

    public updateNotificationPlant(notificationPlantId: number): Observable<any> {
        const url = `${URL_API_V2}/${this._endpointPrefix}/${notificationPlantId}/active`
        const body = {'active': 0}
        return this._commonService.handlerPutElement(url, null, body, 'Data')
    }
}
