import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router'
import { Observable } from 'rxjs'
import { select, Store } from '@ngrx/store'
import * as Reducers from '../../store/reducers'
import { Rol } from '../../models/rol.model'
import { environment } from '../../../environments/environment'

/**
 * Guardian para crear limitar el acceso a las rutas segun el rol de usuario interno.
 *
 * @export
 * @class AdminDiramGuard
 * @implements {CanActivate}
 */
@Injectable({
  providedIn: 'root',
})
export class AdminDiramGuard implements CanActivate {
  /**
   * Listado de roles del usuario.
   *
   * @private
   * @type {Rol[]}
   * @memberof AdminDiramGuard
   */
  private rolesUser: Rol[]

  /**
   * Creates an instance of AdminDiramGuard.
   * @param {Store<any>} store
   * @param {Router} _router
   * @memberof AdminDiramGuard
   */
  constructor(private store: Store<any>, private _router: Router) {
    this.store.pipe(select(Reducers.getUser)).subscribe(state => {
      this.rolesUser = state.roles
    })
  }

  /**
   * CanActivate.
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @return {*}  {(Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree)}
   * @memberof AdminDiramGuard
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.rolesUser[0].slug === 'admin_diram') {
      return true
    }

    if (!environment.production) {
      console.log('Bloqueado por Guardian Admin')
    }

    this._router.navigate(['auth/404']).then()
    return false
  }
}
