import { Injectable } from '@angular/core'
import { FileService } from '../file.service'
import { FileTransactionalRepository } from './file-transactional.repository'

/**
 * Servicio para manejo de archivos de reportes transacionales.
 *
 * @export
 * @class FileTransactionalService
 * @extends {FileService}
 */
@Injectable({
  providedIn: 'root',
})
export class FileTransactionalService extends FileService {
  /**
   * Creates an instance of FileTransactionalService.
   * @param {FileTransactionalRepository} _fileTransactionalRepository
   * @memberof FileTransactionalService
   */
  constructor(protected _fileTransactionalRepository: FileTransactionalRepository) {
    super(_fileTransactionalRepository)
  }
}
