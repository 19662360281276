<div class='top-text-welcome'>
  <h3 class='primary-text'>
    <span>{{ 'home.welcome' | translate }}&nbsp;</span>
    <span>{{user?.name ? user.name : 'Name'}}</span>
    <span>{{user?.flast_name ? '&nbsp;'.concat(user.flast_name, ',&nbsp;') : ',&nbsp;'}}</span>
  </h3>
  <span class='auxiliar-text'>{{ 'home.welcomePhrase' | translate }}</span>
  <br />
  <span class='second-text' *ngIf='plant'>{{ plant ? plant.name : 'Planta Demo' }}</span>
</div>
