import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { CommonService } from '../../shared/common.service'
import { ReportsRepository } from '../reports.repository'
import { URL_API_BILLING_V1 } from 'src/app/config/enviroment'

/**
 * Servicio para manejo de reportes de facturacion.
 *
 * @export
 * @class ReportBillingRepository
 * @extends {ReportsService}
 */
@Injectable({
    providedIn: 'root',
  })
export class ReportBillingRepository extends ReportsRepository {
  /**
   * Creates an instance of ReportBillingRepository.
   * @param {HttpClient} _httpClient
   * @param {CommonService} _commonService
   * @param {string} _endpointPrefix Prefijo para url de reportes de facturacion.
   * @memberof ReportBillingRepository
   */
  constructor(protected _httpClient: HttpClient, protected _commonService: CommonService) {
    super(_httpClient, _commonService, 'billing-report', URL_API_BILLING_V1)
  }
}
