import { Corporate } from '../../models/corporate.model'
import { createReducer, on } from '@ngrx/store'
import * as states from './corporate.actions'

export interface CorporateState {
  corporateId?: number
  corporate?: Corporate
  corporates?: Corporate[]
  result?: any
  isLoading?: boolean
  isLoadingSuccess?: boolean
  isLoadingFailure?: boolean
}

export const initialState: CorporateState = {
  result: null,
  corporateId: null,
  corporates: [],
  corporate: null,
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
}

const _corporateReducer = createReducer(
  initialState,
  on(states.selectCorporate, (state, { corporate }) => {
    return { ...state, corporate: corporate }
  }),
  on(states.getCorporate, (state, { corporateId }) => {
    return { ...state, isLoading: true, corporateId }
  }),
  on(states.loadCorporateSuccessful, (state, result) => {
    return { ...state, isLoading: false }
  }),
  on(states.loadCorporateUnsuccessful, (state, {}) => {
    return {
      ...state,
      corporate: null,
      isLoading: false,
      isLoadingFailure: true,
    }
  }),
  on(states.getCorporates, (state) => {
    return { ...state, isLoading: true }
  }),
  on(states.loadCorporatesSuccessful, (state, result) => {
    const corporates: Corporate[] = result
    return {
      ...state,
      corporates,
      isLoading: false,
      isLoadingSuccess: true,
    }
  }),
  on(states.loadCorporatesUnsuccessful, (state, {}) => {
    return {
      ...state,
      corporates: [],
      isLoading: false,
      isLoadingFailure: true,
    }
  }),
  on(states.flushCorporate, () => initialState)
)

export function corporateReducer(state, action) {
  return _corporateReducer(state, action)
}

export const getCorporate = (state: CorporateState) => state
