import { Plant, User } from 'src/app/models/models.index'
import { Component, OnInit, Input } from '@angular/core'

@Component({
  selector: 'widget-welcome-top',
  templateUrl: './welcome-top.component.html',
  styleUrls: ['./welcome-top.component.scss'],
})
export class WelcomeTopComponent implements OnInit {
  /**
   * Variable para almacenar el usuario de la plataforma.
   *
   * @type {User}
   * @memberof WelcomTopComponent
   */
  @Input() user: User
  /**
   * Variable para almacenar la planta que selecciona mediante un input.
   *
   * @type {Plant}
   * @memberof WelcomTopComponent
   */
  @Input() plant: Plant

  constructor() {}

  ngOnInit() {}
}
