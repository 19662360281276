import { FileRepository } from './../file.repository'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { CommonService } from '../../shared/common.service'

/**
 * Servicio para manejo de archivos de reportes oficiales.
 *
 * @export
 * @class FileOfficialRepository
 * @extends {FileRepository}
 */
@Injectable({
  providedIn: 'root',
})
export class FileOfficialRepository extends FileRepository {
  /**
   * Creates an instance of FileOfficialRepository.
   * @param {HttpClient} _httpClient
   * @param {CommonService} _commonService
   * @memberof FileOfficialRepository
   */
  constructor(protected _httpClient: HttpClient, protected _commonService: CommonService) {
    super(_httpClient, _commonService, 'official-file')
  }
}
