import { NotificationRepository } from './notification.repository'
import { Injectable } from '@angular/core'
import { CrudService } from '../shared/crud.service'
import { Observable } from 'rxjs'
import { Notification } from '../../models/models.index'

/**
* Servicio para obtener las notificaciones.
*
* @export
* @class NotificationService
* @extends {CrudService}
*/
@Injectable({
  providedIn: 'root',
})
export class NotificationService extends CrudService {
  /**
  * Creates an instance of CalculatedParametersService.
  * @param {NotificationRepository} _notificationRepository
  * @memberof NotificationService
  */
  constructor(protected _notificationRepository: NotificationRepository) {
    super(_notificationRepository)
  }

  /**
  * Funcion para servicio para obtener los notificaciones de una planta.
  *
  * @param {number} plantId Identificador de la planta.
  * @return {Observable<Notification[]>}  {Observable<Notification[]>} Observable con la respuesta de nuestro peticion.
  * @memberof NotificationService
  */
  public getNotificationsByPlant(plantId: number): Observable<Notification[]> {
    return this._notificationRepository.getNotificationsByPlant(plantId)
  }

  /**
  * Funcion para servicio para obtener los notificaciones de por tipo.
  *
  * @param {number} notificationTypeId Identificador de la planta.
  * @return {Observable<Notification[]>}  {Observable<Notification[]>} Observable con la respuesta de nuestro peticion.
  * @memberof NotificationService
  */
  public getNotificationsByType(notificationTypeId: number): Observable<Notification[]> {
    return this._notificationRepository.getNotificationsByType(notificationTypeId)
  }

  /**
  * Funcion para servicio para obtener los notificaciones de por persona.
  *
  * @param {number} personId Identificador de la planta.
  * @return {Observable<Notification[]>}  {Observable<Notification[]>} Observable con la respuesta de nuestro peticion.
  * @memberof NotificationService
  */
   public getNotificationsByPerson(personId: number): Observable<Notification[]> {
    return this._notificationRepository.getNotificationsByPerson(personId)
  }
}
