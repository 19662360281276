import { FileRepository } from './../file.repository'
import { Injectable } from '@angular/core'
import { FileService } from '../file.service'
import { HttpClient } from '@angular/common/http'
import { CommonService } from '../../shared/common.service'

@Injectable({
  providedIn: 'root',
})
export class FileWeekRepository extends FileRepository {

  constructor(protected _httpClient: HttpClient, protected _commonService: CommonService) {
    super(_httpClient, _commonService, 'week-file')
  }
}
