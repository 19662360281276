import { Injectable } from '@angular/core'
import { Actions, ofType, createEffect } from '@ngrx/effects'
import { PlantsService } from '../../services/plants/plants.service'
import * as PlantActions from './plant.actions'
import { catchError, exhaustMap, map } from 'rxjs/operators'
import { of } from 'rxjs'

@Injectable()
export class PlantEffects {
  constructor(private _actions$: Actions, private _plantService: PlantsService) {}

  getPlants$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PlantActions.getPlants),
      exhaustMap(action =>
        this._plantService.getPlantsByCorporate(action.corporate.id).pipe(
          map(response => {
            return PlantActions.loadPlantsSuccessful({ response })
          }),
          catchError((error: any) => of(PlantActions.loadPlantsUnsuccessful(error))),
        ),
      ),
    ),
  )

  getPlant$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PlantActions.getPlant),
      exhaustMap(action =>
        this._plantService.getElementById(action.plantId).pipe(
          map(response => PlantActions.loadPlantSuccessful(response)),
          catchError((error: any) => of(PlantActions.loadPlantUnsuccessful(error))),
        ),
      ),
    ),
  )
}
