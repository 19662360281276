import { createAction, props } from '@ngrx/store'
import { Corporate } from '../../models/corporate.model'

export const SELECT_PLANT = '[Plant] Planta seleccionada'

export const GET_PLANT = '[Plant] Obtener una planta'
export const LOAD_PLANT_SUCCESS = '[Plant] Carga de planta completada'
export const LOAD_PLANT_FAILURE = '[Plant] Carga de planta completada'

export const GET_PLANTS = '[Plant] Obtiene una lista plantas por corporativo'
export const LOAD_PLANTS_SUCCESS = '[Plant] Carga de plantas completada'
export const LOAD_PLANTS_FAILURE = '[Plant] Carga de Plantas no completada'

export const FLUSH_PLANT = '[Plant] Flush Plant'
export const EMPTY_PLANT_ACTION = '[Plant] Empty Action plant'

export const getPlant = createAction(GET_PLANT, props<{ plantId: number }>())
export const loadPlantSuccessful = createAction(LOAD_PLANT_SUCCESS, props<any>())
export const loadPlantUnsuccessful = createAction(LOAD_PLANT_FAILURE, props<{ any }>())

export const getPlants = createAction(GET_PLANTS, props<{ corporate: Corporate, defualtPlantId?: number }>())
export const loadPlantsSuccessful = createAction(LOAD_PLANTS_SUCCESS, props<any>())
export const loadPlantsUnsuccessful = createAction(LOAD_PLANTS_FAILURE, props<{ any }>())

export const selectPlant = createAction(SELECT_PLANT, props<{ plantId: number }>())
export const flushPlant = createAction(FLUSH_PLANT)
export const emptyPlantAction = createAction(EMPTY_PLANT_ACTION)
