<div class='row'>
  <div class='col-12 col-lg-4 mb-3'>
    <div class='d-flex flex-column'>
      <div>
        <span class='text-p p-normal'>Nombre: </span>
        <span class='text-p p-light'>{{notification?.person | setFullName}}</span>
      </div>
      <div>
        <span class='text-p p-normal'>Teléfono: </span>
        <span class='text-p p-light'>{{notification?.person?.phone}}</span>
      </div>
      <div>
        <span class='text-p p-normal'>Correo: </span>
        <span class='text-p p-light'>{{notification?.person?.mail}}</span>
      </div>
    </div>
  </div>
  <div class='col-6 col-lg-4 mb-3 notify-style'>
    <div class='d-flex flex-column justify-content-center align-items-center'>
      <div class='d-flex flex-row justify-content-center align-items-center'>
        <nz-switch [(ngModel)]='notification.sms_enable' nzCheckedChildren='' nzUnCheckedChildren=''
                   (ngModelChange)='changeStatus($event, notification, typeNotification.sms)'
                   [disabled]='isLoading'
                   [nzLoading]='isLoading && notificationSelect?.id === notification.id && currentSwitch === typeNotification.sms'></nz-switch>
      </div>
      <span class='text-p p-normal align-self-center'>SMS</span>
      <span class='text-center text-p p-light'>
          El SMS llegará al número registrado
        </span>
    </div>
  </div>
  <div class='col-6 col-lg-4 mb-3 notify-style'>
    <div class='d-flex flex-column justify-content-center align-items-center'>
      <div class='d-flex flex-row justify-content-center align-items-center'>
        <nz-switch [(ngModel)]='notification.email_enable' nzCheckedChildren='' nzUnCheckedChildren=''
                   (ngModelChange)='changeStatus($event, notification, typeNotification.mail)'
                   [disabled]='isLoading'
                   [nzLoading]='isLoading && notificationSelect?.id === notification.id && currentSwitch === typeNotification.mail'></nz-switch>
      </div>
      <span class='text-p p-normal align-self-center'>Email</span>
      <span class='text-center text-p p-light'>
          La notificación llegará al correo registrado
        </span>
    </div>
  </div>
</div>
<div *ngIf='!isLast' class='row mb-2'>
  <div class='col-12'>
    <div class='border-bottom'></div>
  </div>
</div>
