import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'widget-loader-circles',
  templateUrl: './loader-circles.component.html',
  styleUrls: ['./loader-circles.component.scss'],
})
export class LoaderCirclesComponent implements OnInit {

  @Input() textLoader = 'Cargando...'

  constructor() {
  }

  ngOnInit(): void {
  }

}
