/**
 * Modelo para manejar datos de tranformadores.
 *
 * @export
 * @class Transformer
 */
export class Transformer {
  /**
   * Creates an instance of Transformer.
   * @param {number} medidor_id Identificaodr del medidor.
   * @param {number} nominal_power Potencia nominal del trasformador.
   * @param {number} high_voltage_plate Voltaje de alta de la planta.
   * @param {number} low_voltage_plate Voltaje de baja de la planta.
   * @param {string} measured_at Medido en.
   * @param {string} z_p ----
   * @param {string} z_0 ----
   * @param {number} vectorial_array Arreglo vectorial.
   * @param {string} type_load Tipo de carga.
   * @param {number} uni_point_id Identificador del punto unifilar.
   * @param {number} [id] Identificador del transformador.
   * @memberof Transformer
   */
  constructor(
    public nominal_power: number = null,
    public high_voltage_plate: number = null,
    public low_voltage_plate: number = null,
    public measured_at: string = null,
    public z_p: string = null,
    public z_0: string = null,
    public vectorial_array: number = null,
    public type_load: string = null,
    public uni_point_id: number = null,
    public id?: number,
    public medidor_id?: number,
  ) {}
}
